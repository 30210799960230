var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "shifted-content" },
    [
      _c("Dialogs"),
      _c("Appbar", { attrs: { user: true } }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.iframeLoaded,
              expression: "iframeLoaded",
            },
          ],
          attrs: {
            "data-role": "charms",
            id: "agentbuilding",
            "data-position": "right",
            "data-cls-charms":
              "bd-gray bg-white border border-top-none border-bottom-none border-right-none",
          },
        },
        [
          _c("div", { staticClass: "row p-3 mb-4" }, [
            _c("h3", { staticClass: "cell-8 text-left fg-black" }, [
              _vm._v(_vm._s(_vm.$t("Agent building and download"))),
            ]),
            _c("div", { staticClass: "cell-4 d-flex flex-justify-end" }, [
              _c(
                "button",
                {
                  staticClass: "button bg-white",
                  on: { click: _vm.toggleAgent },
                },
                [_c("span", { staticClass: "icon mif mif-close mif-cross" })]
              ),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("div", [
                _c("iframe", {
                  attrs: {
                    allowfullscreen: "",
                    sandbox:
                      "allow-scripts allow-modals allow-forms allow-downloads",
                    src: _vm.parentIframeSrc,
                    width: "500",
                    height: "600",
                    loading: "lazy",
                  },
                  on: { load: _vm.onIframeLoad },
                }),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "aside",
        {
          staticClass: "sidebar z-2 pt-20 no-shadow bg-white",
          staticStyle: { "padding-top": "76px" },
          attrs: {
            "data-role": "sidebar",
            "data-toggle": "#menu-toggle",
            id: "sb4",
            "data-shift": ".docs-content",
            "data-static-shift": ".docs-content",
            "data-static": "lg",
          },
        },
        [
          _c("ul", { staticClass: "sidebar-menu" }, [
            _c(
              "li",
              {},
              [
                _c("router-link", { attrs: { to: "/dashboard" } }, [
                  _c("span", { staticClass: "icon p-0 rounded" }, [
                    _c(
                      "svg",
                      {
                        staticStyle: { width: "26", height: "26" },
                        attrs: {
                          width: "26",
                          height: "26",
                          fill: "none",
                          viewBox: "0 0 24 24",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M11.499 9.5 11.5 21H6.25a3.25 3.25 0 0 1-3.245-3.065L3 17.752V9.499h8.499Zm1.5 5.999H21.5v2.253a3.25 3.25 0 0 1-3.25 3.25L13 21l-.001-5.502Zm5.252-13a3.25 3.25 0 0 1 3.245 3.065l.005.184-.001 8.251h-8.501L13 2.498h5.251ZM11.5 2.497 11.499 8H3V5.748a3.25 3.25 0 0 1 3.25-3.25h5.25Z",
                            fill: "#212121",
                          },
                        }),
                      ]
                    ),
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("Dashboard")))]),
                ]),
              ],
              1
            ),
            _c(
              "li",
              {},
              [
                _c("router-link", { attrs: { to: "/groups" } }, [
                  _c("span", { staticClass: "icon p-0 rounded" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "24",
                          height: "24",
                          fill: "none",
                          viewBox: "0 0 24 24",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M18.25 3A2.75 2.75 0 0 1 21 5.75v12.5A2.75 2.75 0 0 1 18.25 21H5.75A2.75 2.75 0 0 1 3 18.25V5.75A2.75 2.75 0 0 1 5.75 3h12.5Zm0 1.5H5.75c-.69 0-1.25.56-1.25 1.25v12.5c0 .69.56 1.25 1.25 1.25h12.5c.69 0 1.25-.56 1.25-1.25V5.75c0-.69-.56-1.25-1.25-1.25Zm-8.498 8c.966 0 1.75.784 1.75 1.75v2A1.75 1.75 0 0 1 9.752 18h-2a1.75 1.75 0 0 1-1.75-1.75v-2c0-.966.783-1.75 1.75-1.75h2Zm6.497 0c.967 0 1.75.784 1.75 1.75v2A1.75 1.75 0 0 1 16.25 18h-2a1.75 1.75 0 0 1-1.75-1.75v-2c0-.966.784-1.75 1.75-1.75h2ZM9.751 6c.966 0 1.75.784 1.75 1.75v2a1.75 1.75 0 0 1-1.75 1.75h-2A1.75 1.75 0 0 1 6 9.75v-2C6 6.784 6.784 6 7.75 6h2Zm6.497 0c.967 0 1.75.784 1.75 1.75v2a1.75 1.75 0 0 1-1.75 1.75h-2a1.75 1.75 0 0 1-1.75-1.75v-2c0-.966.784-1.75 1.75-1.75h2Z",
                            fill: "#212121",
                          },
                        }),
                      ]
                    ),
                  ]),
                  _c("span", {}, [_vm._v(_vm._s(_vm.$t("Groups")))]),
                ]),
              ],
              1
            ),
            _c(
              "li",
              [
                _c("router-link", { attrs: { to: "/policies" } }, [
                  _c("span", { staticClass: "icon p-0 rounded" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "24",
                          height: "24",
                          fill: "none",
                          viewBox: "0 0 24 24",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M17.934 3.005 17.75 3H6.25l-.184.005A3.25 3.25 0 0 0 3 6.25v11.5l.005.184A3.25 3.25 0 0 0 6.25 21h5.772a6.463 6.463 0 0 1-.848-2H6.25l-.128-.006A1.25 1.25 0 0 1 5 17.75L5.001 8H19v3.174c.721.17 1.395.46 2.001.848V6.25l-.005-.184a3.25 3.25 0 0 0-3.062-3.06Zm-6.94 7.143a.75.75 0 0 0-.744-.648h-3.5l-.102.007A.75.75 0 0 0 6 10.25v7l.007.102A.75.75 0 0 0 6.75 18h3.5l.102-.007A.75.75 0 0 0 11 17.25v-7l-.007-.102ZM7.5 11h2v5.5h-2V11Zm10.5-.75a.75.75 0 0 0-.75-.75h-4.496l-.101.007A.75.75 0 0 0 12.754 11h4.496l.102-.007A.75.75 0 0 0 18 10.25Zm-3.721 3.725a2 2 0 0 1-1.441 2.497l-.585.144a5.729 5.729 0 0 0 .007 1.807l.54.13a2 2 0 0 1 1.45 2.51l-.187.632c.439.386.94.699 1.484.921l.493-.518a2 2 0 0 1 2.9 0l.498.525a5.28 5.28 0 0 0 1.483-.913l-.198-.686a2 2 0 0 1 1.441-2.496l.584-.144a5.729 5.729 0 0 0-.007-1.808l-.539-.13a2 2 0 0 1-1.45-2.51l.187-.63a5.278 5.278 0 0 0-1.485-.923l-.493.519a2 2 0 0 1-2.899 0l-.499-.525c-.543.22-1.043.53-1.482.912l.198.686ZM17.5 19c-.8 0-1.45-.672-1.45-1.5 0-.829.65-1.5 1.45-1.5.801 0 1.45.671 1.45 1.5 0 .828-.649 1.5-1.45 1.5Z",
                            fill: "#212121",
                          },
                        }),
                      ]
                    ),
                  ]),
                  _c("span", {}, [_vm._v(_vm._s(_vm.$t("Policies")))]),
                ]),
              ],
              1
            ),
            _c(
              "li",
              [
                _c("router-link", { attrs: { to: "/users" } }, [
                  _c("span", { staticClass: "icon p-0 rounded" }, [
                    _c(
                      "svg",
                      {
                        staticStyle: { width: "26", height: "26" },
                        attrs: {
                          width: "24",
                          height: "24",
                          fill: "none",
                          viewBox: "0 0 24 24",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M14.75 15c.966 0 1.75.784 1.75 1.75l-.001.962c.117 2.19-1.511 3.297-4.432 3.297-2.91 0-4.567-1.09-4.567-3.259v-1c0-.966.784-1.75 1.75-1.75h5.5Zm-11-5h4.376a4.007 4.007 0 0 0 1.067 3.85l.162.151L9.25 14a2.75 2.75 0 0 0-2.649 2.008l-.034.001C3.657 16.009 2 14.919 2 12.75v-1c0-.966.784-1.75 1.75-1.75Zm16.5 0c.966 0 1.75.784 1.75 1.75l-.001.962c.117 2.19-1.511 3.297-4.432 3.297l-.169-.002a2.756 2.756 0 0 0-2.451-2L14.75 14l-.105.001a3.99 3.99 0 0 0 1.229-4L20.25 10ZM12 8a3 3 0 1 1 0 6 3 3 0 0 1 0-6ZM6.5 3a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm11 0a3 3 0 1 1 0 6 3 3 0 0 1 0-6Z",
                            fill: "#212121",
                          },
                        }),
                      ]
                    ),
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("Users")))]),
                ]),
              ],
              1
            ),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "dropdown-toggle",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      _vm.subMenuOpen =
                        _vm.subMenuOpen != "backup" ? "backup" : ""
                    },
                    blur: function ($event) {
                      _vm.subMenuOpen = ""
                    },
                  },
                },
                [
                  _c("span", { staticClass: "mif-server icon p-0" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("Backup")))]),
                  _c("span", {
                    class: { arrow: true, open: _vm.subMenuOpen == "backup" },
                  }),
                ]
              ),
              _c(
                "ul",
                {
                  staticClass: "d-menu no-shadow bg-light",
                  attrs: { "data-role": "dropdown", id: "backupmenu" },
                },
                [
                  _c(
                    "li",
                    [
                      _c("router-link", { attrs: { to: "/backupstats" } }, [
                        _c("span", { staticClass: "icon p-0 pl-5" }, [
                          _c(
                            "svg",
                            {
                              staticStyle: { width: "26", height: "26" },
                              attrs: {
                                width: "24",
                                height: "24",
                                fill: "none",
                                viewBox: "0 0 24 24",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M10.25 4.25A.75.75 0 0 1 11 5v8h8a.75.75 0 0 1 .743.648l.007.102c0 4.97-4.03 8.5-9 8.5a9 9 0 0 1-9-9c0-4.97 3.53-9 8.5-9Zm3-2.5a9 9 0 0 1 9 9 .75.75 0 0 1-.75.75h-8.25a.75.75 0 0 1-.75-.75V2.5a.75.75 0 0 1 .75-.75Z",
                                  fill: "#212121",
                                },
                              }),
                            ]
                          ),
                        ]),
                        _c("span", { staticClass: "pl-5" }, [
                          _vm._v(_vm._s(_vm.$t("Statistics"))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c("router-link", { attrs: { to: "/accounts" } }, [
                        _c("span", { staticClass: "icon p-0 pl-5" }, [
                          _c(
                            "svg",
                            {
                              staticStyle: { width: "26px", height: "26px" },
                              attrs: {
                                width: "24",
                                height: "24",
                                fill: "none",
                                viewBox: "0 0 24 24",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M4.249 13.995h5.876c-.349.423-.574.952-.62 1.53l-.009.22v4.5c0 .665.236 1.275.63 1.75l-.13.001c-3.42 0-5.943-1.072-7.486-3.236A2.75 2.75 0 0 1 2 17.164v-.92a2.249 2.249 0 0 1 2.249-2.249Zm10.497-2.5h3c.648 0 1.18.492 1.244 1.123l.007.127-.001 1.25h1.25c.967 0 1.75.784 1.75 1.75v4.5a1.75 1.75 0 0 1-1.75 1.75h-8a1.75 1.75 0 0 1-1.75-1.75v-4.5c0-.966.784-1.75 1.75-1.75h1.25v-1.25c0-.647.492-1.18 1.123-1.243l.127-.007h3-3Zm2.75 1.5h-2.5v1h2.5v-1ZM9.997 2a5 5 0 1 1 0 10 5 5 0 0 1 0-10Z",
                                  fill: "#212121",
                                },
                              }),
                            ]
                          ),
                        ]),
                        _c("span", { staticClass: "pl-5" }, [
                          _vm._v(_vm._s(_vm.$t("Credentials"))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c("router-link", { attrs: { to: "/repo" } }, [
                        _c("span", { staticClass: "icon p-0 pl-5" }, [
                          _c(
                            "svg",
                            {
                              staticStyle: { width: "26", height: "26" },
                              attrs: {
                                width: "24",
                                height: "24",
                                fill: "none",
                                viewBox: "0 0 24 24",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M10 2a4 4 0 0 1 4 4v2h2.5A1.5 1.5 0 0 1 18 9.5V11c-.319 0-.637.11-.896.329l-.107.1c-.812.845-1.656 1.238-2.597 1.238-.783 0-1.4.643-1.4 1.416v2.501c0 2.374.924 4.22 2.68 5.418L3.5 22A1.5 1.5 0 0 1 2 20.5v-11A1.5 1.5 0 0 1 3.5 8H6V6a4 4 0 0 1 4-4Zm8.284 10.122c.992 1.036 2.091 1.545 3.316 1.545.193 0 .355.143.392.332l.008.084v2.501c0 2.682-1.313 4.506-3.873 5.395a.385.385 0 0 1-.253 0c-2.476-.86-3.785-2.592-3.87-5.13L14 16.585v-2.5c0-.23.18-.417.4-.417 1.223 0 2.323-.51 3.318-1.545a.389.389 0 0 1 .566 0ZM10 13.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM10 4a2 2 0 0 0-2 2v2h4V6a2 2 0 0 0-2-2Z",
                                  fill: "#212121",
                                },
                              }),
                            ]
                          ),
                        ]),
                        _c("span", { staticClass: "pl-5" }, [
                          _vm._v(_vm._s(_vm.$t("Repositories"))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "dropdown-toggle",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      _vm.subMenuOpen =
                        _vm.subMenuOpen != "remote" ? "remote" : ""
                    },
                    blur: function ($event) {
                      _vm.subMenuOpen = ""
                    },
                  },
                },
                [
                  _c("span", { staticClass: "mif-display icon p-0" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("Remote Access")))]),
                  _c("span", {
                    staticClass: "arrow",
                    class: { open: _vm.subMenuOpen == "remote" },
                  }),
                ]
              ),
              _c(
                "ul",
                {
                  staticClass: "d-menu no-shadow bg-light",
                  attrs: { "data-role": "dropdown", id: "remotemenu" },
                },
                [
                  _c(
                    "li",
                    [
                      _c("router-link", { attrs: { to: "/remotestats" } }, [
                        _c("span", { staticClass: "icon p-0 pl-5" }, [
                          _c(
                            "svg",
                            {
                              staticStyle: { width: "26", height: "26" },
                              attrs: {
                                width: "24",
                                height: "24",
                                fill: "none",
                                viewBox: "0 0 24 24",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M10.25 4.25A.75.75 0 0 1 11 5v8h8a.75.75 0 0 1 .743.648l.007.102c0 4.97-4.03 8.5-9 8.5a9 9 0 0 1-9-9c0-4.97 3.53-9 8.5-9Zm3-2.5a9 9 0 0 1 9 9 .75.75 0 0 1-.75.75h-8.25a.75.75 0 0 1-.75-.75V2.5a.75.75 0 0 1 .75-.75Z",
                                  fill: "#212121",
                                },
                              }),
                            ]
                          ),
                        ]),
                        _c("span", { staticClass: "pl-5" }, [
                          _vm._v(_vm._s(_vm.$t("Statistics"))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: { to: "/sessions", "active-class": "active" },
                        },
                        [
                          _c("span", { staticClass: "icon p-0 pl-5" }, [
                            _c(
                              "svg",
                              {
                                staticStyle: { width: "26", height: "26" },
                                attrs: {
                                  width: "24",
                                  height: "24",
                                  fill: "none",
                                  viewBox: "0 0 24 24",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M4.25 4A2.25 2.25 0 0 0 2 6.25v2.5A2.25 2.25 0 0 0 4.25 11h2.5A2.25 2.25 0 0 0 9 8.75v-2.5A2.25 2.25 0 0 0 6.75 4h-2.5Zm7 1a.75.75 0 0 0 0 1.5h10a.75.75 0 0 0 0-1.5h-10Zm0 3a.75.75 0 0 0 0 1.5h7a.75.75 0 0 0 0-1.5h-7Zm-7 5A2.25 2.25 0 0 0 2 15.25v2.5A2.25 2.25 0 0 0 4.25 20h2.5A2.25 2.25 0 0 0 9 17.75v-2.5A2.25 2.25 0 0 0 6.75 13h-2.5Zm7 1a.75.75 0 0 0 0 1.5h10a.75.75 0 0 0 0-1.5h-10Zm0 3a.75.75 0 0 0 0 1.5h7a.75.75 0 0 0 0-1.5h-7Z",
                                    fill: "#212121",
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _c("span", { staticClass: "pl-5" }, [
                            _vm._v(_vm._s(_vm.$t("sessions"))),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c("router-link", { attrs: { to: "/activesessions" } }, [
                        _c("span", { staticClass: "icon p-0 pl-5" }, [
                          _c(
                            "svg",
                            {
                              staticStyle: { width: "26", height: "26" },
                              attrs: {
                                width: "24",
                                height: "24",
                                fill: "none",
                                viewBox: "0 0 24 24",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "m15.207 2.292 4 3.995a1 1 0 0 1 .084 1.32l-.083.094-4 4.006a1 1 0 0 1-1.498-1.32l.083-.094L16.083 8H5.5a1 1 0 0 1-.994-.883L4.5 7a1 1 0 0 1 .883-.993L5.5 6h10.59l-2.296-2.293a1 1 0 0 1-.084-1.32l.083-.095a1 1 0 0 1 1.32-.084l.094.084 4 3.995-4-3.995Zm4.283 14.591.007.117a1 1 0 0 1-.883.993l-.117.007H7.913l2.294 2.293a1 1 0 0 1 .084 1.32l-.083.094a1 1 0 0 1-1.32.084l-.095-.084-4-3.996a1 1 0 0 1-.083-1.32l.083-.094 4-4.004a1 1 0 0 1 1.498 1.32l-.083.094L7.918 16h10.579a1 1 0 0 1 .993.883l.007.117-.007-.117Z",
                                  fill: "#212121",
                                },
                              }),
                            ]
                          ),
                        ]),
                        _c("span", { staticClass: "pl-5" }, [
                          _vm._v(_vm._s(_vm.$t("Active sessions"))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("li", {}, [
              _c(
                "a",
                {
                  staticClass: "dropdown-toggle",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      _vm.subMenuOpen =
                        _vm.subMenuOpen != "settings" ? "settings" : ""
                    },
                    blur: function ($event) {
                      _vm.subMenuOpen = ""
                    },
                  },
                },
                [
                  _c("span", { staticClass: "icon p-0" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "24",
                          height: "24",
                          fill: "none",
                          viewBox: "0 0 24 24",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M12.012 2.25c.734.008 1.465.093 2.182.253a.75.75 0 0 1 .582.649l.17 1.527a1.384 1.384 0 0 0 1.927 1.116l1.401-.615a.75.75 0 0 1 .85.174 9.792 9.792 0 0 1 2.204 3.792.75.75 0 0 1-.271.825l-1.242.916a1.381 1.381 0 0 0 0 2.226l1.243.915a.75.75 0 0 1 .272.826 9.797 9.797 0 0 1-2.204 3.792.75.75 0 0 1-.848.175l-1.407-.617a1.38 1.38 0 0 0-1.926 1.114l-.169 1.526a.75.75 0 0 1-.572.647 9.518 9.518 0 0 1-4.406 0 .75.75 0 0 1-.572-.647l-.168-1.524a1.382 1.382 0 0 0-1.926-1.11l-1.406.616a.75.75 0 0 1-.849-.175 9.798 9.798 0 0 1-2.204-3.796.75.75 0 0 1 .272-.826l1.243-.916a1.38 1.38 0 0 0 0-2.226l-1.243-.914a.75.75 0 0 1-.271-.826 9.793 9.793 0 0 1 2.204-3.792.75.75 0 0 1 .85-.174l1.4.615a1.387 1.387 0 0 0 1.93-1.118l.17-1.526a.75.75 0 0 1 .583-.65c.717-.159 1.45-.243 2.201-.252ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z",
                            fill: "#212121",
                          },
                        }),
                      ]
                    ),
                  ]),
                  _c("span", {}, [_vm._v(_vm._s(_vm.$t("Settings")))]),
                  _c("span", {
                    staticClass: "arrow",
                    class: { open: _vm.subMenuOpen == "settings" },
                  }),
                ]
              ),
              _c(
                "ul",
                {
                  staticClass: "d-menu no-shadow",
                  attrs: { "data-role": "dropdown" },
                },
                [
                  _c(
                    "li",
                    [
                      _c("router-link", { attrs: { to: "/settings" } }, [
                        _c("span", { staticClass: "icon p-0 pl-5" }, [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "24",
                                height: "24",
                                fill: "none",
                                viewBox: "0 0 24 24",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M12 2c5.523 0 10 4.477 10 10 0 .263-.01.523-.03.78a6.5 6.5 0 0 0-9.19 9.19 9.96 9.96 0 0 1-5.425-1.112l-4.29 1.117A.85.85 0 0 1 2.03 20.94l1.116-4.289A9.959 9.959 0 0 1 2 12C2 6.477 6.477 2 12 2Zm2.278 11.976a2 2 0 0 1-1.441 2.496l-.584.144a5.729 5.729 0 0 0 .006 1.807l.54.13a2 2 0 0 1 1.45 2.51l-.187.632c.44.386.94.699 1.484.922l.494-.52a2 2 0 0 1 2.899.001l.498.525a5.281 5.281 0 0 0 1.483-.913l-.198-.686a2 2 0 0 1 1.441-2.496l.584-.144a5.716 5.716 0 0 0-.006-1.808l-.54-.13a2 2 0 0 1-1.45-2.51l.187-.63a5.28 5.28 0 0 0-1.484-.922l-.493.518a2 2 0 0 1-2.9 0l-.498-.525a5.28 5.28 0 0 0-1.483.912l.198.687ZM17.5 16c.8 0 1.45.672 1.45 1.5S18.3 19 17.5 19c-.8 0-1.45-.672-1.45-1.5S16.7 16 17.5 16Z",
                                  fill: "#212121",
                                },
                              }),
                            ]
                          ),
                        ]),
                        _c("span", { staticClass: "pl-5" }, [
                          _vm._v(_vm._s(_vm.$t("Notification Settings"))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "docs-content bg-light pt-13 h-100" },
        [
          _c(
            "transition",
            { attrs: { name: "slide-fade" } },
            [_c("router-view")],
            1
          ),
        ],
        1
      ),
      _c("FileSystemSidebar"),
      _c("addLicenseDialog", {
        attrs: { license: _vm.license, pricing: _vm.pricing },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }