<template>
    <div class="dialog light w-100-sm w-100-md w-75-lg  w-50-xl h-75 draggable-item" id="group_dialog" v-if="group" v-cloak>
        <div class="dialog-title">
            {{ isNew ? $t('Create New Group') : group.name }}
            <span class="button square closer" @click="$root.$emit('CLOSEGROUPVIEWDIALOG')"></span>
        </div>
        <div class="dialog-content overflow">
            <div id="profile">
                <div class="form-group">
                    <label>{{ $t("Name") }}*</label>
                    <input data-role="input" class="input" required v-model="group.name" type="text"
                        data-prepend="<span class='mif-folder'></span>" />
                    <small class="text-muted">{{ $t("Required") }}</small>
                </div>
                
                <div class="form-group">
                    <label>{{ $t("Users") }}*</label>
                    <select data-role="select" data-cls-selected-item="bg-light border pr-2 rounded"
                        data-filter="false" class="input" multiple id="userselect" name="ids_users" required>
                        <option
                            :selected="group.users.find(elem => elem.id == user.id || elem.id_user == user.id) || user.id == $session.getAuth()?.id_user"
                            v-for="user in users" :value="user.id" :key="user.id"
                            data-template="<span class='mif-user icon'></span> $1"> {{ user.username }} </option>
                    </select>
                    <small>{{ $t("Users that can view this group") }}</small>
                </div>

                <div class="form-group">
                    <label>{{ $t("Devices") }}</label>
                    <select v-if="computers" class="input" data-role="select"
                        data-cls-selected-item="bg-light border pr-2 rounded" data-filter="false" multiple id="computerselect" name="ids_computers" >
                        <option :selected="group.computers.find(elem => elem.id == pc.id)" v-for="pc in computers"
                            :value="pc.id" :key="pc.id" data-template="<span class='mif-laptop icon'></span> $1">
                            {{ pc.name }} </option>
                    </select>
                    <small>{{ $t("Computers in this group") }}</small>
                </div>

                <div class="row">

                    <div class="form-group col-6 ">
                        <div>
                            <label>{{ $t("Email") }}</label>
                            <input data-role="input" class="input" v-model="group.email" type="email"
                            data-prepend="<span class='mif-mail'></span>" />
                        </div>
                    </div>
                    <div class="form-group col-6">
                        <div>
                            <label>{{ $t("Phone Number") }}</label>
                            <input data-role="input" class="input" v-model="group.phone_number" type="number"
                            data-prepend="<span class='mif-phone'></span>" />
                        </div>
                    </div>
                </div>
                <div class="row">

                    <div class="form-group col-6 ">
                        <div>
                            <label>{{ $t("Address") }}</label>
                            <input data-role="input" class="input" v-model="group.address" type="text"
                            data-prepend="<span class='mif-home'></span>" />
                        </div>
                    </div>
                    <div class="form-group col-6">
                        <div class="">
                            <label>{{ $t("Note") }}</label>
                            <input data-role="input" maxlength="255" class="input small" v-model="group.notes" type="text" 
                            data-prepend="<span class='mif-folder'></span>" />
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="dialog-actions">
            <button class="button primary" @click="save" :disabled="!enableSave">{{ isNew ? $t('Create') : $t("Save") }}</button>
            <button v-if="group.name!= 'Shared Computers' && !isNew && group.attribute != 3" class="button error ml-2" @click="deleteGroup">{{ $t("Delete") }}</button>
            <button class="button" @click="$root.$emit('CLOSEGROUPVIEWDIALOG')">{{ $t("Cancel") }}</button>
        </div>
    </div>
</template>
<script>
import $ from 'jquery';
import { draggableMixin } from '../../../mixins/draggable-mixin'
export default {
    mixins: [draggableMixin],
    name: "GroupDialog",
    data() {
        return {

        }
    },
    computed: {
        enableSave() {
            return this.group != null && this.group.name!=""
        },
        isNew() {
            return this.group == null || this.group.id==null;
        }
    },
    props: {

        group: {
            type: Object,
            required: false
        },
        computers: {
            type: Array,
            required: true
        },
        users: {
            type: Array,
            required: true
        },

    },
    created: function(){
        this.$session.setRoomPC( null);
        this.$root.socket("SETPC", null);  
    },
    methods: {

        async save() {
            let self=this;
            if (self.group.name == null || self.group.name == "" || window.Metro.getPlugin($('#userselect'), 'select').val().length==0) {
                Metro.dialog.create({
                    title: this.$t("Error on saving Group"),
                    content: this.$t("*Group name is required"),
                    closeButton: false,
                    clsDialog: "dialog error pos-center ",
                    clsContent: "p-10",
                    closeAction: true,
                    actions: [
                        {
                            caption: this.$t("Close"),
                            cls: "js-dialog-close alert "
                        }
                    ],
                });
                return;
            }

            self.group.ids_computers=window.Metro.getPlugin($("#computerselect"), 'select').val();
            self.group.ids_users=window.Metro.getPlugin($('#userselect'), 'select').val();

            var newGroup=self.group.id==null?
            await self.$api.createGroup(self.group):
            await self.$api.editGroup(self.group);


            if (newGroup==null) {
                return;
            }


            this.$root.$emit("refreshGroups");
            this.$root.$emit('CLOSEGROUPVIEWDIALOG');


        },

        async deleteGroup() {
            var dialogID=Math.floor(100000+Math.random()*900000).toString();
            var groupID=this.group.id;
            //Personalizzo la dialog da mostrare
            var dialogTitle = this.$t("Delete Group");
            var dialogContent = this.$t('deleteConfirmation', { 0: this.group.name });
            
            var okText="Yes";
            var okClass = "mr-2 primary";
            var okResult=true;

            var cancelText="No";
            var cancelClass="alert";
            var cancelResult=false;

            let self=this;
            this.$root.$on(dialogID, async ok => {

                //Se l'utente non conferma la cancellazione, riporto l'URL a /groups
                if (!ok) {
                    self.$router.replace({ path: '/groups' });
                    self.$root.$off(dialogID);
                    return;
                }

                ok=await self.$api.deleteGroup("DELETEGROUP", groupID);

                //Se la cancellazione del gruppo non va a buon fine, riporto l'URL a /groups
                if (!ok) {
                    self.$root.toast(GROUP_MESSAGES.NOT_DELETED);
                    self.$router.replace({ path: '/groups' });
                    self.$root.$off(dialogID);
                    return;
                }

                self.$root.toast(GROUP_MESSAGES.DELETED);

                //Se la cancellazione del gruppo va a buon fine, cancello il gruppo in session
                var groups=this.$session.getGroups();
                groups.splice(groups.findIndex(g => g.id_group==groupID), 1);
                self.$session.setGroups(groups);

                self.$root.$off(dialogID);
                self.$root.$emit('CLOSEGROUPVIEWDIALOG');


            });

            //Avvio la richiesta che mi risponderà con un $emit
            this.$root.openDialogActions(dialogID, dialogTitle, dialogContent, okText, okClass, okResult, cancelText, cancelClass, cancelResult)
        }
    }
}
</script>