var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog light w-100-sm w-100-md w-75-lg w-50-xl",
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.$root.$emit("CLOSERESTOREDIALOG")
        },
      },
    },
    [
      _c("DialogTitle", {
        attrs: {
          title: _vm.$t("Configure ESXI Restore"),
          defaultTitle: _vm.$t("Config ESXI restore"),
          subTitle: _vm.$t("ESXi Backups"),
        },
        on: {
          closeDialog: function ($event) {
            return _vm.$root.$emit("CLOSERESTOREDIALOG")
          },
        },
      }),
      _c("div", { staticClass: "dialog-content p-4" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.steps[0],
                expression: "steps[0]",
              },
            ],
            staticClass: "container-fluid",
          },
          [
            _c("SARepoSelect", {
              attrs: {
                type: _vm.source.type,
                idSA: _vm.source.id_service_account,
                idRepo: null,
              },
              on: { sendSATestResult: _vm.sendSATestResult },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.steps[0] &&
                  typeof _vm.source.id_service_account == "string",
                expression:
                  "steps[0] && (typeof source.id_service_account) == 'string'",
              },
            ],
            staticClass: "container-fluid",
          },
          [
            _vm.source.esxiSource
              ? _c("div", { staticClass: "row mt-4" }, [
                  _c("span", { staticClass: "mif-done mif-lg fg-green" }),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("Connected successfully"))),
                  ]),
                  _c("span", { staticClass: "small-text" }, [
                    _vm._v(
                      " (" +
                        _vm._s(_vm.source.esxiSource.url) +
                        "-" +
                        _vm._s(_vm.source.esxiSource.hostVersion) +
                        ")"
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.source.esxiSource == null
              ? _c("span", { staticClass: "mt-4 mif-spinner2 ani-spin mr-1" })
              : _vm._e(),
            _vm.source.esxiSource == null
              ? _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("Connection in progress, please wait")) + "."
                  ),
                ])
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.steps[1],
                expression: "steps[1]",
              },
            ],
            staticClass: "grid p-10",
          },
          [
            _c("div", { staticClass: "row h6 text-bold mb-4" }, [
              _c("div", { staticClass: "cell-12" }, [
                _vm._v(_vm._s(_vm.$t("Choosing folder containing backup"))),
              ]),
            ]),
            _vm.source.folderSource.paths.length == 0
              ? _c("NoDataAvailable", {
                  attrs: { message: _vm.$t("No item selected") },
                })
              : _c("div", { staticClass: "overflow mt-4 h-vh-33" }, [
                  _c("div", { staticClass: "cell" }, [
                    _c(
                      "table",
                      { staticClass: "table striped d-table-lg text-small" },
                      [
                        _c(
                          "tbody",
                          _vm._l(_vm.source.folderSource.paths, function (res) {
                            return _c("tr", { key: res.id }, [
                              _c("td", { staticClass: "d-flex" }, [
                                _c("span", {
                                  staticClass: "px-2",
                                  class: _vm.getMetroIcon(res.type, res.path),
                                }),
                                _vm.source.options.recreateCompletePath
                                  ? _c(
                                      "span",
                                      { staticClass: "flex-self-center" },
                                      [_vm._v(_vm._s(res.path))]
                                    )
                                  : _c(
                                      "span",
                                      { staticClass: "flex-self-center" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            (res.path.indexOf("/") != -1
                                              ? res.path.split("/")
                                              : res.path.split("\\")
                                            ).at(-1)
                                          )
                                        ),
                                      ]
                                    ),
                              ]),
                              _c("td", [
                                _c("span", {
                                  staticClass: "mif-bin",
                                  on: {
                                    click: function ($event) {
                                      return _vm.$root.$emit("uncheckNode", res)
                                    },
                                  },
                                }),
                              ]),
                            ])
                          }),
                          0
                        ),
                      ]
                    ),
                  ]),
                ]),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.steps[2],
                expression: "steps[2]",
              },
            ],
            staticClass: "container-fluid",
          },
          [
            _vm.listbackup.length < 1
              ? _c("div", {
                  attrs: {
                    "data-role": "activity",
                    "data-type": "metro",
                    "data-style": "dark",
                  },
                })
              : _vm._e(),
            _vm.listbackup.length > 0
              ? _c(
                  "div",
                  {
                    staticClass: "pb-4 w-100",
                    staticStyle: { overflow: "scroll" },
                    attrs: { id: "esxiBackupList" },
                  },
                  [
                    _c(
                      "table",
                      {
                        staticClass: "table striped text-small",
                        attrs: {
                          "data-role": "sorter",
                          "data-sort-dir": "desc",
                          "data-show-search": "true",
                          "data-show-rows-steps": "false",
                          "data-search-min-length": "3",
                          "data-search-threshold": "300",
                        },
                      },
                      [
                        _c("thead", [
                          _c("tr", [
                            _c("td", [_vm._v("Sel")]),
                            _c(
                              "td",
                              {
                                attrs: {
                                  "data-name": "dt_start_utc",
                                  "data-sortable": "true",
                                  "data-format": "int",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("Date")) + " ")]
                            ),
                            _c(
                              "td",
                              {
                                attrs: {
                                  "data-name": "dt_end_utc",
                                  "data-sortable": "true",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("Number")))]
                            ),
                            _c(
                              "td",
                              {
                                attrs: {
                                  "data-name": "backup_size",
                                  "data-sortable": "true",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("Size")))]
                            ),
                            _c(
                              "td",
                              {
                                attrs: {
                                  "data-name": "copied_files",
                                  "data-sortable": "true",
                                  "data-sort-dir": "desc",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("Type")) + " ")]
                            ),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.listbackup, function (lb) {
                            return _c(
                              "tr",
                              {
                                key: lb.dirName,
                                attrs: { "track-by": "log.id" },
                              },
                              [
                                _c("td", [
                                  _c("input", {
                                    staticClass: "ml-5",
                                    attrs: {
                                      name: "r1",
                                      type: "radio",
                                      "data-role": "radio",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.putBackupInEsxiRestoreModel(
                                          lb
                                        )
                                      },
                                    },
                                  }),
                                ]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.convertDate(lb.timeStamp))),
                                ]),
                                _c("td", [_vm._v(_vm._s(lb.dirName))]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(_vm._f("prettyBytes")(lb.dirSize))
                                  ),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.backupTypeToName(lb.type, lb.dirName)
                                    )
                                  ),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.steps[3],
                expression: "steps[3]",
              },
            ],
            staticClass: "container-fluid",
          },
          [
            _vm.dataStoreList.length == 0
              ? _c("div", {
                  attrs: {
                    "data-role": "activity",
                    "data-type": "metro",
                    "data-style": "dark",
                  },
                })
              : _vm._e(),
            _vm.dataStoreList.length > 0
              ? _c("div", { staticClass: "row mt-4" }, [
                  _c("b", [_vm._v(_vm._s(_vm.$t("Select a Datastore")) + ":")]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "row" }, [
              _vm.dataStoreList.length > 0
                ? _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.dataStore,
                          expression: "dataStore",
                        },
                      ],
                      attrs: { "data-role": "select", "data-filter": "false" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.dataStore = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    _vm._l(_vm.dataStoreList, function (datastore, index) {
                      return _c(
                        "option",
                        {
                          key: datastore.datastore.name + "_" + index,
                          domProps: { value: datastore },
                        },
                        [
                          _vm._v(
                            " [" +
                              _vm._s(datastore.datacentername) +
                              "]/[" +
                              _vm._s(datastore.resourcename) +
                              "] " +
                              _vm._s(datastore.datastore.name) +
                              " [" +
                              _vm._s(
                                _vm._f("prettyBytes")(
                                  datastore.datastore.freeSpace
                                )
                              ) +
                              "/" +
                              _vm._s(
                                _vm._f("prettyBytes")(
                                  datastore.datastore.capacity
                                )
                              ) +
                              "] "
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]),
            _vm.dataStore
              ? _c("div", { staticClass: "row mt-4" }, [
                  _c("label", [
                    _c("b", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "Virtual machine name (the name must not already exist)"
                          )
                        ) + ":"
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.dataStore
              ? _c("div", { staticClass: "row" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.suggestedRestoredVmName,
                        expression: "suggestedRestoredVmName",
                      },
                    ],
                    staticClass: "w-50",
                    attrs: { type: "text", "data-role": "textbox" },
                    domProps: { value: _vm.suggestedRestoredVmName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.suggestedRestoredVmName = $event.target.value
                      },
                    },
                  }),
                ])
              : _vm._e(),
            _vm.dataStore
              ? _c("div", { staticClass: "row mt-4" }, [
                  _c("label", [
                    _c("b", [_vm._v(_vm._s(_vm.$t("Port (CBT)")) + ":")]),
                  ]),
                ])
              : _vm._e(),
            _vm.dataStore
              ? _c("div", { staticClass: "row" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.port,
                        expression: "port",
                        modifiers: { number: true },
                      },
                    ],
                    attrs: {
                      type: "number",
                      minlength: "2",
                      maxlength: "5",
                      placeholder: "Port",
                    },
                    domProps: { value: _vm.port },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.port = _vm._n($event.target.value)
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ])
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.steps[4],
                expression: "steps[4]",
              },
            ],
            staticClass: "container-fluid",
          },
          [
            _vm.dataStoreList.length > 0
              ? _c("div", { staticClass: "row mt-4 mb-2" }, [
                  _c("b", [_vm._v(_vm._s(_vm.$t("Summary")) + ":")]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "row mb-2" }, [
              _vm.source.esxiSource != null
                ? _c("div", { staticClass: "cell-3" }, [
                    _vm._v(_vm._s(_vm.$t("Server"))),
                  ])
                : _vm._e(),
              _vm.source.esxiSource != null
                ? _c("div", { staticClass: "cell" }, [
                    _vm._v(
                      _vm._s(_vm.source.esxiSource.url) +
                        "-" +
                        _vm._s(_vm.source.esxiSource.hostVersion)
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "row mb-2" }, [
              _vm.source.folderSource != null &&
              _vm.source.folderSource.paths.length > 0
                ? _c("div", { staticClass: "cell-3" }, [
                    _vm._v(_vm._s(_vm.$t("Backup path"))),
                  ])
                : _vm._e(),
              _vm.source.folderSource != null &&
              _vm.source.folderSource.paths.length > 0
                ? _c("div", { staticClass: "cell" }, [
                    _vm._v(_vm._s(_vm.source.folderSource.paths[0].path)),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "row mb-2" }, [
              _c("div", { staticClass: "cell-3" }, [
                _vm._v(_vm._s(_vm.$t("Selected backup"))),
              ]),
              _c("div", { staticClass: "cell" }, [
                _vm._v(
                  _vm._s(_vm.convertDate(_vm.selBackupInfo.date)) +
                    " " +
                    _vm._s(_vm.selBackupInfo.number) +
                    " " +
                    _vm._s(
                      _vm._f("prettyBytes")(_vm.selBackupInfo.dimensione)
                    ) +
                    " " +
                    _vm._s(_vm.selBackupInfo.tipo)
                ),
              ]),
            ]),
            _vm.dataStore
              ? _c("div", { staticClass: "row mb-2" }, [
                  _c("div", { staticClass: "cell-3" }, [
                    _vm._v(_vm._s(_vm.$t("Destination datastore"))),
                  ]),
                  _c("div", { staticClass: "cell" }, [
                    _vm._v(
                      "[" +
                        _vm._s(_vm.dataStore.datacentername) +
                        "]/[" +
                        _vm._s(_vm.dataStore.resourcename) +
                        "] " +
                        _vm._s(_vm.dataStore.datastore.name) +
                        " [" +
                        _vm._s(
                          _vm._f("prettyBytes")(
                            _vm.dataStore.datastore.freeSpace
                          )
                        ) +
                        "/" +
                        _vm._s(
                          _vm._f("prettyBytes")(
                            _vm.dataStore.datastore.capacity
                          )
                        ) +
                        "]"
                    ),
                  ]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "row mb-2" }, [
              _c("div", { staticClass: "cell-3" }, [
                _vm._v(_vm._s(_vm.$t("Name of the target VM"))),
              ]),
              _c("div", { staticClass: "cell" }, [
                _vm._v(_vm._s(_vm.suggestedRestoredVmName)),
              ]),
            ]),
          ]
        ),
        _vm.steps[1] && _vm.restore
          ? _c("div", { staticClass: "container-fluid" }, [
              _c("div", { staticClass: "row mt-4 ml-2" }, [
                _c("div", { staticClass: "cell-8" }, [
                  _c("label", [_vm._v(_vm._s(_vm.restore.vmname))]),
                  _c("br"),
                  _c(
                    "span",
                    {
                      staticClass:
                        "badge inline bg-cobalt fg-white p-1 mt-2 mr-1",
                    },
                    [_vm._v(_vm._s(_vm.restore.type_name))]
                  ),
                  _c(
                    "span",
                    {
                      staticClass:
                        "badge inline bg-cobalt fg-white p-1 mt-2 mr-1",
                    },
                    [_vm._v(_vm._s(_vm.restore.datacenter))]
                  ),
                  _c(
                    "span",
                    {
                      staticClass:
                        "badge inline bg-cobalt fg-white p-1 mt-2 mr-1",
                    },
                    [_vm._v("n. " + _vm._s(_vm.restore.backupnumber))]
                  ),
                  _c(
                    "span",
                    {
                      staticClass:
                        "badge inline bg-cobalt fg-white p-1 mt-2 mr-1",
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.restore.datastore + "-" + _vm.restore.host)
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "cell-8" }, [
                  _vm.restore.dt_start_utc != "" &&
                  _vm.restore.dt_start_utc != null
                    ? _c("label", [
                        _vm._v(
                          _vm._s(
                            _vm._f("moment")(
                              _vm._f("utcAsLocal")(
                                _vm.restore.dt_start_utc.substr(0, 8) +
                                  "T" +
                                  _vm.restore.dt_start_utc.substr(8)
                              ),
                              "MM/DD/YY hh:mm A"
                            )
                          )
                        ),
                      ])
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  { staticClass: "cell-8" },
                  [
                    _c("RestoreLastResult", {
                      attrs: { lastresult: _vm.restore.lastresult },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "cell-6" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.restore.progress != null &&
                            _vm.restore.progress != 0,
                          expression:
                            "restore.progress != null && restore.progress != 0",
                        },
                      ],
                    },
                    [
                      _c("div", { staticClass: "clear" }, [
                        _vm.restore.current_operation.length <= 50
                          ? _c(
                              "small",
                              {
                                staticClass: "place-left",
                                attrs: {
                                  "data-role": "hint",
                                  "data-hint-position": "top",
                                  "data-hint-text":
                                    _vm.restore.current_operation,
                                },
                              },
                              [_vm._v(_vm._s(_vm.restore.current_operation))]
                            )
                          : _vm.restore.current_operation <= 100
                          ? _c(
                              "small",
                              {
                                staticClass: "place-left",
                                attrs: {
                                  "data-role": "hint",
                                  "data-hint-position": "top",
                                  "data-hint-text":
                                    _vm.restore.current_operation,
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.restore.current_operation.substr(
                                      0,
                                      50
                                    ) + "..."
                                  )
                                ),
                              ]
                            )
                          : _c(
                              "small",
                              {
                                staticClass: "place-left",
                                attrs: {
                                  "data-role": "hint",
                                  "data-hint-position": "top",
                                  "data-hint-text":
                                    _vm.restore.current_operation,
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.restore.current_operation.substr(
                                      0,
                                      50
                                    ) +
                                      "..." +
                                      _vm.restore.current_operation.substr(
                                        -50,
                                        50
                                      )
                                  )
                                ),
                              ]
                            ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "progress small",
                          attrs: {
                            "data-role": "progress",
                            "data-type": "line",
                            "data-value": _vm.restore.progress,
                            "data-small": "true",
                            "data-cls-bar": "bg-cobalt",
                            "data-role-progress": "true",
                          },
                        },
                        [
                          _c("div", {
                            staticClass: "bar ribbed-cobalt",
                            style: { width: _vm.restore.progress + "%" },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "cell-2" }, [
                  _vm.restore.isRunning
                    ? _c(
                        "small",
                        { staticClass: "place-right text-bold enlarge-1" },
                        [_vm._v(_vm._s(_vm.restore.progress) + "%")]
                      )
                    : _vm._e(),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "dialog-actions" }, [
        _c("div", { staticClass: "ml-auto" }, [
          _c(
            "button",
            {
              staticClass: "button error float-right",
              on: {
                click: function ($event) {
                  return _vm.$root.$emit("CLOSERESTOREDIALOG")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("Cancel")))]
          ),
          _c(
            "button",
            {
              staticClass: "button",
              class: { disabled: _vm.steps[0] },
              on: { click: _vm.back },
            },
            [
              _c("span", { staticClass: "mif-arrow-left" }),
              _vm._v(_vm._s(_vm.$t("Prev"))),
            ]
          ),
          _c(
            "button",
            {
              staticClass: "button",
              class: {
                disabled:
                  (_vm.steps[0] && _vm.source.esxiSource == null) ||
                  (_vm.steps[1] && this.source.folderSource.paths.count == 0) ||
                  (_vm.steps[2] && this.bkpDirSelected == "") ||
                  _vm.steps[4] ||
                  _vm.steps[5],
              },
              on: { click: _vm.next },
            },
            [
              _vm._v(_vm._s(_vm.$t("Next")) + " "),
              _c("span", { staticClass: "mif-arrow-right ml-2" }),
            ]
          ),
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.steps[1],
                  expression: "steps[1]",
                },
              ],
              staticClass: "button primary float-right",
              on: {
                click: function ($event) {
                  return _vm.toggleFileSystemSidebar()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("Select items")))]
          ),
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.steps[4],
                  expression: "steps[4]",
                },
              ],
              staticClass: "button primary float-right",
              on: { click: _vm.beginRestore },
            },
            [
              _c("span", { staticClass: "mif-checkmark pr-2" }),
              _vm._v(_vm._s(_vm.$t("Restore")) + " "),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }