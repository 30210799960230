import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        layout: 'simple-layout',
        auth: {},
        license: "",
        computer: "",
        clouds: [],
        roles: [],
        types: [],
        policy_types: [],
        realtime: {
            backups: [],
            messages: "",
            events: "",
            pcInfo: null
        },
        websocket: null
    },
    mutations: {
        SET_LAYOUT(state, layout) {
            state.layout = layout;
        },
        SET_AUTH(state, auth) {
            state.auth = auth;
        },
        SET_LICENSE(state, license) {
            state.license = license;
        },
        SET_COMPUTER(state, computer) {
            state.computer = computer;
        },
        SET_CLOUDS(state, clouds) {
            state.clouds = clouds;
        },
        SET_ROLES(state, roles) {
            state.roles = roles;
        },
        SET_TYPES(state, types) {
            state.types = types;
        },
        SET_POLICY_TYPES(state, types) {
            state.policy_types = types;
        },
        SET_REALTIME_BACKUPS(state, backups) {
            state.realtime.backups = backups;
        },
        SET_REALTIME_MESSAGES(state, messages) {
            state.realtime.messages = messages;
        },
        SET_REALTIME_EVENTS(state, events) {
            state.realtime.events = events;
        },
        SET_REALTIME_PCINFO(state, pcinfo) {
            state.realtime.pcInfo = pcinfo;
        },
        SET_WEBSOCKET(state, websocket) {
            state.websocket = websocket;
        }
    },
    actions: {
        updateLayout({ commit }, layout) {
            // Esempio di azione che esegue una chiamata sincrona
            commit('SET_LAYOUT', layout);
        },
        fetchAuthData({ commit }, authData) {
            // Esempio di azione che può eseguire operazioni asincrone
            return new Promise((resolve) => {
                // Simuliamo un ritardo per esempio (puoi sostituirlo con una vera chiamata API)
                setTimeout(() => {
                    commit('SET_AUTH', authData);
                    resolve();
                }, 1000);
            });
        },
        updateLicense({ commit }, license) {
            commit('SET_LICENSE', license);
        },
        updateComputer({ commit }, computer) {
            commit('SET_COMPUTER', computer);
        },
        updateClouds({ commit }, clouds) {
            commit('SET_CLOUDS', clouds);
        },
        updateRoles({ commit }, roles) {
            commit('SET_ROLES', roles);
        },
        updateTypes({ commit }, types) {
            commit('SET_TYPES', types);
        },
        updatePolicyTypes({ commit }, types) {
            commit('SET_POLICY_TYPES', types);
        },
        updateRealtimeBackups({ commit }, backups) {
            commit('SET_REALTIME_BACKUPS', backups);
        },
        updateRealtimeMessages({ commit }, messages) {
            commit('SET_REALTIME_MESSAGES', messages);
        },
        updateRealtimeEvents({ commit }, events) {
            commit('SET_REALTIME_EVENTS', events);
        },
        updateRealtimePcInfo({ commit }, pcInfo) {
            commit('SET_REALTIME_PCINFO', pcInfo);
        },
        updateWebsocket({ commit }, websocket) {
            commit('SET_WEBSOCKET', websocket);
        }
    },
    getters: {
        layout(state) {
            return state.layout;
        },
        auth(state) {
            return state.auth;
        },
        license(state) {
            return state.license;
        },
        computer(state) {
            return state.computer;
        },
        clouds(state) {
            return state.clouds;
        },
        roles(state) {
            return state.roles;
        },
        types(state) {
            return state.types;
        },
        backups(state) {
            return state.realtime.backups;
        },
        messages(state) {
            return state.realtime.messages;
        },
        events(state) {
            return state.realtime.events;
        },
        pcInfo(state) {
            return state.realtime.pcInfo;
        },
        websocket(state) {
            return state.websocket;
        }
    }
});
