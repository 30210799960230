/*
Metodi di autorizzazione  : https://learn.microsoft.com/en-us/rest/api/storageservices/authorize-requests-to-azure-storage
https://learn.microsoft.com/en-us/rest/api/storageservices/authorize-with-shared-key#blob-queue-and-file-services-shared-key-authorization
*/
import api from "./api";
import { createHmac } from "crypto";
import { ClientTreeNodeMethods, ClientTreeNode } from "../models/TreeNode";
import { FileFolderEntityType, GetAllPaths, PlatformsTypesEnum, applyBucketsRules, getParentOf, isValid } from "../../public/assets/js/utilitiesmodule";

const version = "2014-02-14";
export class AzureBlobClient {
    constructor(accountName, accessKey) {
        this.accountName = accountName;
        this.accessKey = accessKey;
        this.nodes = [];
    }

    /*
    Documentazione : 
    https://learn.microsoft.com/en-us/rest/api/storageservices/authorize-with-shared-key#blob-queue-and-file-services-shared-key-authorization
    https://techcommunity.microsoft.com/t5/azure-paas-blog/the-mac-signature-found-in-the-http-request-xxxx-is-not-the-same/bc-p/3721224#M480
    */
    //Signature=Base64(HMAC-SHA256(UTF8(StringToSign), Base64.decode(<your_azure_storage_account_shared_key>)))
    async getRootNodes() {

        if (this.nodes.length != 0 && this.nodes.some(r => r.nParts == 1))
            return this.nodes.filter(r => r.nParts == 1);

        //Step 1 : Preparo in dati
        var queryObj = {
            comp: "list",
            marker: "" //vedi come si comporta la richiesta
        };
        var urlToRequestContainers = "https://" + this.accountName + ".blob.core.windows.net/?";

        var now, hash, headers, response;

        do {

            now = new Date().toUTCString();

            hash = buildHash(
                this.accessKey, [
                    "x-ms-date:" + now,
                    "x-ms-version:" + version
                ],
                "GET",
                getCanonicalizedResource(this.accountName, null, queryObj)
            );

            headers = {
                "Authorization": "SharedKey " + this.accountName + ":" + hash,
                "x-ms-date": now,
                "x-ms-version": version
            };

            //Step 5 : Effettuo la richiesta
            response = await api.get(urlToRequestContainers + getQueryString(queryObj), {
                    headers: headers
                })
                .then(res => res.data)
                .catch(e => {
                    console.log(e);
                    return null;
                });

            if (response == null)
                break;

            this.nodes.push(...extractNodes(response, "", FileFolderEntityType.Cloud_Bucket));

            queryObj.marker = extractMarker(response);


        } while (queryObj.marker != null);

        return this.nodes;
    }

    async getNodesFrom(resource, searchInResources = true) {
        resource.path = applyBucketsRules(resource.path);

        //Step 1 : Controllo se sono già state salvare in locale le risorse richieste
        if (this.nodes.length != 0) {

            var children = this.nodes.filter(r => r.nParts == resource.nParts + 1);
            children = children.filter(r => r.path.startsWith(resource.path + "/"));
            //Step 2 : Se non sono state salvate o non le devo richiedere al cloud, ritorno le risorse trovate
            if (children.length != 0 || !searchInResources) return children;
        }

        //Step 3 : Altrimenti, devo richiederle al cloud

        //Step 3.1 : Preparo i dati
        var pathParts = extractData(resource.path);

        var queryObj = {
            comp: "list",
            marker: "",
            prefix: encodeURIComponent(pathParts.subPath),
            restype: "container",
        };
        var urlToGetBlobs = "https://" + this.accountName + ".blob.core.windows.net/" + pathParts.rootFolder + "?";

        var now, hash, headers, response;
        var parentsPaths = new Set();

        //Step 3.2 : Effettuo la richiesta
        do {
            now = new Date().toUTCString();
            hash = buildHash(
                this.accessKey, [
                    "x-ms-date:" + now,
                    "x-ms-version:" + version
                ],
                "GET",
                getCanonicalizedResource(this.accountName, pathParts.rootFolder, queryObj)
            );

            headers = {
                "Authorization": "SharedKey " + this.accountName + ":" + hash,
                "x-ms-date": now,
                "x-ms-version": version
            };


            response = await api.get(
                    urlToGetBlobs + getQueryString(queryObj), {
                        headers: headers
                    })
                .then(res => res.data).catch(e => {
                    console.log(e);
                    return null;
                    //return null;
                });

            if (response == null)
                return [];

            extractNodes(response, pathParts.rootFolder, FileFolderEntityType.Cloud_File)
                .forEach(n => {
                    this.nodes.push(n);
                    extractParentsPaths(n.path).forEach(p => parentsPaths.add(p));
                })

            queryObj.marker = extractMarker(response);

        } while (queryObj.marker != null);

        parentsPaths.forEach(p => {
            this.nodes.push(new ClientTreeNode(null, null, p, FileFolderEntityType.Cloud_Folder));
        });

        //console.log(blobs);
        return await this.getNodesFrom(resource, false);
    }

}

export class AzureFileClient {
    constructor(accountName, accessKey) {
        this.accountName = accountName;
        this.accessKey = accessKey;
        this.nodes = [];
    }

    /*
Documentazione : 
    https://learn.microsoft.com/en-us/rest/api/storageservices/authorize-with-shared-key#blob-queue-and-file-services-shared-key-authorization
    https://techcommunity.microsoft.com/t5/azure-paas-blog/the-mac-signature-found-in-the-http-request-xxxx-is-not-the-same/bc-p/3721224#M480
*/
    //Signature=Base64(HMAC-SHA256(UTF8(StringToSign), Base64.decode(<your_azure_storage_account_shared_key>)))
    async getRootNodes() {

        if (this.nodes.length != 0)
            return this.nodes.filter(r => r.nParts == 1);

        var urlToRequestContainers = "https://" + this.accountName + ".file.core.windows.net/?";
        var queryObj = {
            comp: "list",
            marker: ""
        };
        var now, hash, headers, response;

        do {
            now = new Date().toUTCString();

            hash = buildHash(
                this.accessKey, [
                    "x-ms-date:" + now,
                    "x-ms-version:" + version
                ],
                "GET",
                getCanonicalizedResource(this.accountName, null, queryObj)
            );

            headers = {
                "Authorization": "SharedKey " + this.accountName + ":" + hash,
                "x-ms-date": now,
                "x-ms-version": version
            };

            //Step 5 : Effettuo la richiesta
            response = await api.get(urlToRequestContainers + getQueryString(queryObj), {
                    headers: headers
                })
                .then(res => res.data)
                .catch(e => {
                    console.log(e);
                    return null;
                });

            if (response == null)
                return [];

            this.nodes.push(...extractNodes(response, "", FileFolderEntityType.Cloud_Bucket));

            queryObj.marker = extractMarker(response);

        } while (queryObj.marker != null);

        return this.nodes;

    }

    //https://learn.microsoft.com/en-us/rest/api/storageservices/list-directories-and-files
    async getNodesFrom(resource, searchInResources = true) {
        resource.path = applyBucketsRules(resource.path);
        //Step 1 : Controllo se sono già state salvare in locale le risorse richieste
        if (this.nodes) {
            var children = this.nodes.filter(r => r.path.startsWith(resource.path + "/") && r.nParts == resource.nParts + 1);
            //Step 2 : Se non sono state salvate o non le devo richiedere al cloud, ritorno le risorse trovate
            if (children.length != 0 || !searchInResources) return children;
        }
        //Step 3 : Altrimenti, devo richiederle al cloud
        var now, hash, headers, response;
        //var parentsPaths = new Set();
        //Step 3.1 : Preparo i dati

        var urlToGetResources = "https://" + this.accountName + ".file.core.windows.net/" + encodeURI(resource.path) + "?";
        var queryObj = {
            comp: "list",
            marker: "",
            restype: "directory"
        };

        do {

            now = new Date().toUTCString();

            hash = buildHash(
                this.accessKey, [
                    "x-ms-date:" + now,
                    "x-ms-version:" + version
                ],
                "GET",
                getCanonicalizedResource(this.accountName, resource.path, queryObj)
            );

            headers = {
                "Authorization": "SharedKey " + this.accountName + ":" + hash,
                "x-ms-date": now,
                "x-ms-version": version
            };

            //Step 5 : Effettuo la richiesta
            response = await api.get(
                    urlToGetResources + getQueryString(queryObj), {
                        headers: headers
                    })
                .then(res => res.data)
                .catch(e => {
                    console.log(e);
                    return null;
                });

            if (response == null)
                return [];

            response.split("</Name>")
                .filter(html => html.indexOf("<Name>") != -1)
                .map(html =>
                    new ClientTreeNode(
                        null,
                        null,
                        resource.path + "/" + html.split("<Name>")[1],
                        html.indexOf("<Directory><Name>") == -1 ?
                        FileFolderEntityType.Cloud_File :
                        FileFolderEntityType.Cloud_Folder
                    )).forEach(n => {
                    this.nodes.push(n);
                    //extractParentsPaths(n.path).forEach(p => parentsPaths.add(p));
                })

            queryObj.marker = extractMarker(response);

        } while (queryObj.marker != null);

        //parentsPaths.forEach(p => {
        //    this.nodes.push(new ClientTreeNode(null, null, p, FileFolderEntityType.Cloud_Folder));
        //});

        return await this.getNodesFrom(resource, false);
    }
}

function buildHash(accessKey, headers, httpVerb, canonicalizedResource) {

    //Step 1 : Costruisco la stringa
    var VERB = httpVerb;
    var ContentEncoding = "";
    var ContentLanguage = "";
    var ContentLength = "";
    var ContentMD5 = "";
    var ContentType = "";
    var date = "";
    var IfModifiedSince = "";
    var IfMatch = "";
    var IfNoneMatch = "";
    var IfUnmodifiedSince = "";
    var Range = "";
    var CanonicalizedHeaders = headers.join("\n");
    var CanonicalizedResource = canonicalizedResource;


    var StringToSign = VERB + "\n" +
        ContentEncoding + "\n" +
        ContentLanguage + "\n" +
        ContentLength + "\n" +
        ContentMD5 + "\n" +
        ContentType + "\n" +
        date + "\n" +
        IfModifiedSince + "\n" +
        IfMatch + "\n" +
        IfNoneMatch + "\n" +
        IfUnmodifiedSince + "\n" +
        Range + "\n" +
        CanonicalizedHeaders + "\n" +
        CanonicalizedResource;

    //console.log(StringToSign);
    //Step 2 : Codifico la stringa in UTF-8
    var encodedStringToSign = Buffer.from(StringToSign).toString('utf-8');

    //Step 3 : Decodifico l'access key Decode the Base64 storage key.
    var decodedAccessKey = Buffer.from(accessKey, "base64");

    //Step 4 : Cripto encodedString utilizzando l'algoritmo HMAC-SHA256 e la chiave decodedAccessKey. Il tutto viene codificato in base 64
    var hash = createHmac('sha256', decodedAccessKey).update(encodedStringToSign).digest("base64");

    //console.log(hash);

    return hash;
}

//https://learn.microsoft.com/en-us/rest/api/storageservices/enumerating-blob-resources


function extractData(path) {
    var slashIndex = path.indexOf("/");

    if (slashIndex == -1)
        return {
            rootFolder: path,
            subPath: ""
        };

    var pathArray = path.split("/");
    var f = pathArray[0];

    pathArray.splice(0, 1);
    var sp = pathArray.join("/");

    return {
        rootFolder: f,
        subPath: sp
    };
}

function getCanonicalizedResource(accountName, rootFolder, queryObj) {
    var canonicalizedResource = "/" + accountName + "/" + (isValid(rootFolder) ? encodeURI(rootFolder) : "");
    canonicalizedResource += "\n" + Object.keys(queryObj).map(p => p + ":" + queryObj[p]).join("\n");
    return canonicalizedResource;
}


function extractNodes(apiResponse, rootFolder, type) {
    //res.data è un html. I nomi dei containers sono inclusi tra i tags <Name></Name>
    return apiResponse.split("</Name>")
        .filter(html => html.indexOf("<Name>") != -1)
        .map(html =>
            rootFolder != "" ?
            new ClientTreeNode(null, null, rootFolder + "/" + html.split("<Name>")[1], type) :
            new ClientTreeNode(null, null, html.split("<Name>")[1], type)
        );
}

function extractMarker(apiResponse) {
    if (apiResponse.contains("<NextMarker>") && apiResponse.contains("</NextMarker>"))
        return apiResponse.split("</NextMarker>")[0].split("<NextMarker>")[1];
    return null;
}

function extractParentsPaths(path) {
    var parentsPaths = GetAllPaths(path);
    parentsPaths.splice(0, 1); // il primo è lo share o il container
    parentsPaths.pop();
    return parentsPaths;
}

function getQueryString(queryObj) {
    return Object.keys(queryObj)
        .map(p => p + "=" + queryObj[p])
        .join("&")
}