import { BUCKETS_NOT_RETRIEVED } from "../../public/assets/js/messages";
import { trimEnd, waitSeconds, applyBucketsRules, FileFolderEntityType, isValid, PlatformsTypesEnum } from "../../public/assets/js/utilitiesmodule";
import { ClientTreeNode, ClientTreeNodeMethods } from "../models/TreeNode";

export class S3Client {
    constructor(serviceAccount) {
        /* this.accessKey = accessKey;
        this.secretKey = secretKey;
        this.region = region */
        this.serviceAccount = serviceAccount;
        var config = {
            accessKeyId: serviceAccount.client_id,
            secretAccessKey: serviceAccount.client_secret,
            //apiVersion: "2012-10-17"
        }

        if (isValid(serviceAccount.region))
            config.region = serviceAccount.region;
        if (isValid(serviceAccount.url))
            config.endpoint = serviceAccount.url;
        //if (signatureversion != null)
        //    config.signatureVersion = signatureversion;

        var AWS = require('aws-sdk');
        this.s3 = new AWS.S3(config);
        this.serviceName = "sts"; // non so se è lo stesso per tutti gli S3
        this.nodes = [];
    }

    //Lista tutti i buckets e li ritorna
    async getRootNodes() {

        var buckets = [];
        var done = false;

        this.s3.listBuckets(
            (e, data) => {

                if (e != null) {
                    console.log(BUCKETS_NOT_RETRIEVED);
                    console.log(e);
                    buckets = null;
                    done = true;
                    return;
                }

                buckets = data.Buckets.map(b => new ClientTreeNode(null, null, b.Name, FileFolderEntityType.Cloud_Bucket));
                done = true;
            }
        );

        //Attendo per un massimo di 60 secondi
        for (var i = 1; i <= 60; i++) {
            if (done) break;
            await waitSeconds(1);
        }

        return buckets;
    }

    //https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#listObjects-property
    //Ritorna i file e le sottocartelle di resource.path
    async getNodesFrom(resource, searchInResources = true) {
        try {
            var marker = "";
            var isTruncated;
            var response;
            var newPath;
            var newNode;
            resource.path = applyBucketsRules(resource.path);
            console.log(resource.nParts);

            var children = this.nodes.filter(r => r.path.startsWith(resource.path + "/") && r.nParts == resource.nParts + 1);

            if (children.length != 0 || !searchInResources) return children;

            var pathParts = this.extractData(resource.path);

            console.log(pathParts);
            var request = {
                Bucket: pathParts.bucket + this.serviceAccount.region + ".s3",
                Prefix: pathParts.subPath,
                MaxKeys: 1000
            };


            do {

                if (marker != "") {
                    request.ContinuationToken = marker;
                }

                response = await this.s3.send(new ListObjectsV2Command(request));
                //response =  await this.s3.listObjectsV2(request).promise();

                response.Contents.forEach(obj => {
                    //Step 1 : aggiungo in nodes ogni risorsa trovata
                    newPath = pathParts.bucket + "/" + obj.Key;
                    newPath = trimEnd(newPath, "/");
                    newNode = new ClientTreeNode(null, null, newPath, !obj.Key.endsWith("/") ? FileFolderEntityType.Cloud_File : FileFolderEntityType.Cloud_Folder);
                    this.nodes.push(newNode);
                    this.nodes = new ClientTreeNodeMethods().insertParentsInto(newNode, this.nodes, PlatformsTypesEnum.Cloud_AmazonS3Storage);
                });

                isTruncated = response.IsTruncated;
                marker = response.NextContinuationToken;

            } while (isTruncated);

            /*this.nodes.forEach(n => {
                console.log(n.path);
            });*/
            return await this.getNodesFrom(resource, false);
        } catch (e) {
            console.error(e);
            try {
                response = await this.s3.listObjects(request).promise();
            } catch (e) {
                console.error(e);
            }
        }
        return [];
    }

    /**
     * Estrae dalla lista resources, tutti i children della risorsa passata 
     */
    /*     getChildrenOfResource(resource) {

            var children = this.nodes
                .filter(r => r.path.startsWith(resource.path + "/"))
                .map(r => this.convertPathToResource(resource.path, r.path));

            return children;
        } */

    extractData(path) {

        path = applyBucketsRules(path);

        var slashIndex = path.indexOf("/");

        if (slashIndex == -1)
            return {
                bucket: path,
                subPath: "",
                nParts: 1 // numero delle parti del path, compreso il bucket
            };

        var pathArray = path.split("/");
        var b = pathArray[0];

        pathArray.splice(0, 1);
        var sp = pathArray.join("/");

        return {
            bucket: b,
            subPath: sp,
            nParts: pathArray.length
        };
    }
}