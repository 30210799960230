var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog light w-100-sm w-100-md w-75-lg w-50-xl h-75",
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.$root.$emit("CLOSERESTOREDIALOG")
        },
      },
    },
    [
      _c("DialogTitle", {
        attrs: {
          title: _vm.printDialogTitle(),
          defaultTitle: _vm.printDialogTitle(),
          subTitle: _vm.printDialogSubTitle(),
        },
        on: {
          closeDialog: function ($event) {
            return _vm.$root.$emit("CLOSERESTOREDIALOG")
          },
        },
      }),
      _vm.source.folderSource
        ? _c("div", { staticClass: "dialog-content p-4 overflow" }, [
            _vm.steps[0]
              ? _c("div", { staticClass: "p-10" }, [
                  _c(
                    "div",
                    { staticClass: "row from-group mb-4 w-100 text-center" },
                    [
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$t("Choosing folder containing backup"))
                        ),
                      ]),
                      _vm.source.folderSource.paths.length != 0
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.source.folderSource.paths[0].path,
                                expression: "source.folderSource.paths[0].path",
                              },
                            ],
                            staticClass: "input w-75 mr-2",
                            attrs: {
                              type: "text",
                              title: _vm.source.folderSource.paths[0].path,
                            },
                            domProps: {
                              value: _vm.source.folderSource.paths[0].path,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.source.folderSource.paths[0],
                                  "path",
                                  $event.target.value
                                )
                              },
                            },
                          })
                        : _vm._e(),
                      _vm.source.folderSource.paths.length == 0
                        ? _c("input", {
                            staticClass: "input w-75 mr-2",
                            attrs: {
                              placeholder: _vm.$t(
                                "Choosing folder containing backup"
                              ),
                              type: "text",
                            },
                          })
                        : _vm._e(),
                      _c(
                        "a",
                        {
                          staticClass: "button flat-button",
                          attrs: {
                            href: "#",
                            title: _vm.$t("Click to browse the file system"),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.toggleFileSystemSidebar()
                            },
                          },
                        },
                        [_c("span", { staticClass: "mif mif-folder-open" })]
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.steps[1],
                    expression: "steps[1]",
                  },
                ],
                staticClass: "container-fluid",
              },
              [
                _vm.listbackup.length < 1
                  ? _c("div", {
                      attrs: {
                        "data-role": "activity",
                        "data-type": "metro",
                        "data-style": "dark",
                      },
                    })
                  : _vm._e(),
                _vm.HyperVBackupDetails.ibvhconfig
                  ? _c("div", { staticClass: "data" }, [
                      _c("div", { staticClass: "caption" }, [
                        _vm._v(
                          _vm._s(
                            _vm.HyperVBackupDetails.ibvhconfig.vmName +
                              " (" +
                              _vm.HyperVBackupDetails.ibvhconfig.vmId +
                              ")"
                          )
                        ),
                      ]),
                      _c("div", { staticClass: "content" }, [
                        _c("span", { staticClass: "text-muted" }, [
                          _vm._v(_vm._s(_vm.$t("Virtual Machine"))),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm.HyperVBackupDetails.ibvhconfig
                  ? _c("div", { staticClass: "data" }, [
                      _c("div", { staticClass: "caption" }, [
                        _vm._v(_vm._s(_vm.HyperVBackupDetails.path)),
                      ]),
                      _c("div", { staticClass: "content" }, [
                        _c("span", { staticClass: "text-muted" }, [
                          _vm._v(_vm._s(_vm.$t("Path"))),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "border bd-light p-4 w-100 mb-3 mt-3",
                    staticStyle: {
                      "overflow-y": "scroll",
                      "overflow-x": "hidden",
                    },
                    attrs: { id: "HyperVBackupList" },
                  },
                  [
                    _vm.listbackup.length > 0
                      ? _c(
                          "ul",
                          {
                            attrs: {
                              "data-role": "listview",
                              "data-view": "content",
                              "data-select-node": "true",
                            },
                          },
                          _vm._l(_vm.listbackup, function (lb) {
                            return _c("li", {
                              key: lb.numOrder,
                              attrs: {
                                "data-icon": "<span class='mif-stack'>",
                                "data-caption": _vm.convertDate(lb.dt),
                                "data-content": `<span class='text-muted'>${
                                  _vm.$t("Copy number") +
                                  " <b>" +
                                  lb.numOrder.padStart(3, "0") +
                                  "</b> " +
                                  _vm.$t("Type") +
                                  " <b>" +
                                  _vm.backupTypeToName(
                                    lb.backupType,
                                    lb.numOrder
                                  ) +
                                  "</b> " +
                                  _vm.$t("Size") +
                                  " <b>" +
                                  _vm.prettyBytes(lb.dirSize, 2)
                                }</b></span>`,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.putBackupInHyperVRestoreModel(lb)
                                },
                              },
                            })
                          }),
                          0
                        )
                      : _c("p", [
                          _vm._v(_vm._s(_vm.$t("No Backup available"))),
                        ]),
                  ]
                ),
                _vm.HyperVBackupDetails.ibvhconfig
                  ? _c("div", { staticClass: "data" }, [
                      _c("div", { staticClass: "caption" }, [
                        _vm._v(
                          _vm._s(
                            _vm.convertDate(
                              _vm.HyperVBackupDetails.ibvhconfig.firstExecution
                            )
                          )
                        ),
                      ]),
                      _c("div", { staticClass: "content" }, [
                        _c("span", { staticClass: "text-muted" }, [
                          _vm._v(_vm._s(_vm.$t("First run"))),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.steps[2],
                    expression: "steps[2]",
                  },
                ],
                staticClass: "container-fluid",
              },
              [
                !_vm.HyperVBackupDetails.ibvhconfig
                  ? _c("div", {
                      attrs: {
                        "data-role": "activity",
                        "data-type": "metro",
                        "data-style": "dark",
                      },
                    })
                  : _vm._e(),
                _vm.HyperVBackupDetails.ibvhconfig
                  ? _c("div", { staticClass: "row mt-4" }, [
                      _c("b", [
                        _vm._v(
                          _vm._s(_vm.$t("Select the destination of restore")) +
                            ":"
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.HyperVBackupDetails.ibvhconfig
                  ? _c("div", { staticClass: "row mt-4" }, [
                      _c("label", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Virtual machine name (the name must not already exist)"
                            )
                          ) + ":"
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.HyperVBackupDetails.ibvhconfig
                  ? _c("div", { staticClass: "row" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.suggestedRestoredVmName,
                            expression: "suggestedRestoredVmName",
                          },
                        ],
                        staticClass: "w-50",
                        attrs: { type: "text", "data-role": "textbox" },
                        domProps: { value: _vm.suggestedRestoredVmName },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.suggestedRestoredVmName = $event.target.value
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.HyperVBackupDetails.ibvhconfig
                  ? _c("div", { staticClass: "row mt-4" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.picked,
                            expression: "picked",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          "data-role": "radio",
                          id: "default",
                          value: "Default",
                          "data-caption": _vm.$t(
                            "Copy the disk file to the default folder"
                          ),
                        },
                        domProps: { checked: _vm._q(_vm.picked, "Default") },
                        on: {
                          change: function ($event) {
                            _vm.picked = "Default"
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.HyperVBackupDetails.ibvhconfig
                  ? _c("div", { staticClass: "row mt-0" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.picked,
                            expression: "picked",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          "data-role": "radio",
                          id: "custom",
                          value: "Custom",
                          "data-caption": _vm.$t(
                            "Copy the disk file in the following folder"
                          ),
                        },
                        domProps: { checked: _vm._q(_vm.picked, "Custom") },
                        on: {
                          change: function ($event) {
                            _vm.picked = "Custom"
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.HyperVBackupDetails.ibvhconfig
                  ? _c("div", { staticClass: "row mt-1" }, [
                      _c("div", { staticClass: "cell-8" }, [
                        _vm.customCopyFilePath.length == 0
                          ? _c("input", {
                              class: { disabled: _vm.picked != "Custom" },
                              attrs: { type: "text", "data-role": "input" },
                            })
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.customCopyFilePath[0].path,
                                  expression: "customCopyFilePath[0].path",
                                },
                              ],
                              class: { disabled: _vm.picked != "Custom" },
                              attrs: { type: "text", "data-role": "input" },
                              domProps: {
                                value: _vm.customCopyFilePath[0].path,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.customCopyFilePath[0],
                                    "path",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                      ]),
                      _c("div", { staticClass: "cell-4" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button primary ml-2",
                            class: { disabled: _vm.picked != "Custom" },
                            on: {
                              click: function ($event) {
                                return _vm.toggleFileSystemSidebar_CustomCopyPath()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("Select path")))]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.HyperVBackupDetails.ibvhconfig
                  ? _c("div", { staticClass: "row mt-4" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newId,
                            expression: "newId",
                          },
                        ],
                        attrs: {
                          type: "checkbox",
                          "data-role": "checkbox",
                          id: "newid",
                          name: "r1",
                          "data-style": "1",
                          "data-caption": _vm.$t(
                            "Generate new Id if the virtual machine already exists"
                          ),
                        },
                        domProps: {
                          checked: Array.isArray(_vm.newId)
                            ? _vm._i(_vm.newId, null) > -1
                            : _vm.newId,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.newId,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.newId = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.newId = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.newId = $$c
                            }
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.steps[3],
                    expression: "steps[3]",
                  },
                ],
                staticClass: "container-fluid",
              },
              [
                _vm.HyperVTestVmReport
                  ? _c("div", { staticClass: "row mt-3" }, [
                      _c("b", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("Virtual machine compatibility report")
                          ) + ":"
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.scanTest
                  ? _c("div", { staticClass: "row mt-3" }, [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("Virtual Machine information"))),
                      ]),
                    ])
                  : _vm._e(),
                !_vm.scanTest
                  ? _c("div", { staticClass: "w-100 text-center" }, [
                      _c("span", {
                        staticClass: "mif-spinner2 ani-spin mif-3x",
                      }),
                    ])
                  : _vm._e(),
                _vm.scanTest
                  ? _c(
                      "div",
                      {
                        staticClass: "pb-4 w-100",
                        staticStyle: {
                          "overflow-y": "scroll",
                          "overflow-x": "hidden",
                        },
                        attrs: { id: "scanTest" },
                      },
                      [
                        _c(
                          "table",
                          {
                            staticClass: "table striped text-small",
                            attrs: {
                              "data-role": "sorter",
                              "data-sort-dir": "desc",
                              "data-show-search": "true",
                              "data-show-rows-steps": "false",
                              "data-search-min-length": "3",
                              "data-search-threshold": "300",
                            },
                          },
                          [
                            _c("thead", [
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    attrs: {
                                      "data-name": "key",
                                      "data-sortable": "true",
                                      "data-format": "int",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Key")))]
                                ),
                                _c(
                                  "td",
                                  {
                                    attrs: {
                                      "data-name": "value",
                                      "data-sortable": "true",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Value")))]
                                ),
                              ]),
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_vm.scanTest, function (value, key) {
                                return _c("tr", { key: key }, [
                                  _c("td", [_vm._v(_vm._s(key))]),
                                  _c("td", [_vm._v(_vm._s(value))]),
                                ])
                              }),
                              0
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _c("div", { staticClass: "row mt-3" }, [
                  _c("label", [_vm._v(_vm._s(_vm.$t("Compatibility issue")))]),
                ]),
                _vm.incompatIssue.length > 0
                  ? _c(
                      "div",
                      {
                        staticClass: "pb-4 w-100",
                        staticStyle: {
                          "overflow-y": "scroll",
                          "overflow-x": "hidden",
                        },
                        attrs: { id: "incompatIssue" },
                      },
                      [
                        _c(
                          "table",
                          {
                            staticClass: "table striped text-small",
                            attrs: {
                              "data-role": "sorter",
                              "data-sort-dir": "desc",
                              "data-show-search": "true",
                              "data-show-rows-steps": "false",
                              "data-search-min-length": "3",
                              "data-search-threshold": "300",
                            },
                          },
                          [
                            _c("thead", [
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    attrs: {
                                      "data-name": "dt_start_utc",
                                      "data-sortable": "true",
                                      "data-format": "int",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Issue id")) + " ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    attrs: {
                                      "data-name": "dt_end_utc",
                                      "data-sortable": "true",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Issue description")))]
                                ),
                                _c(
                                  "td",
                                  {
                                    attrs: {
                                      "data-name": "backup_size",
                                      "data-sortable": "true",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Solvability")))]
                                ),
                              ]),
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_vm.incompatIssue, function (is) {
                                return _c(
                                  "tr",
                                  {
                                    key: is.errorId,
                                    attrs: { "track-by": "log.id" },
                                  },
                                  [
                                    _c("td", [_vm._v(_vm._s(is.errorId))]),
                                    _c("td", [_vm._v(_vm._s(is.errorMessage))]),
                                    _c("td", [_vm._v(_vm._s(is.isSolvable))]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ]
                    )
                  : _c("div", { staticClass: "row mt-3" }, [
                      _c("span", {
                        staticClass: "mif-verified fg-green mif-2x mr-2",
                      }),
                      _c("span", { staticClass: "label" }, [
                        _vm._v(_vm._s(_vm.$t("No issues found"))),
                      ]),
                    ]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.steps[4],
                    expression: "steps[4]",
                  },
                ],
                staticClass: "container-fluid",
              },
              [
                true
                  ? _c("div", { staticClass: "row mt-4 mb-2" }, [
                      _c("b", [_vm._v(_vm._s(_vm.$t("Summary")) + ":")]),
                    ])
                  : _vm._e(),
                _vm.HyperVTestVMCompatibilityModel.HyperVHost != ""
                  ? _c(
                      "ul",
                      {
                        attrs: {
                          "data-role": "listview",
                          "data-view": "content",
                          "data-select-node": "false",
                        },
                      },
                      [
                        _c("li", {
                          attrs: {
                            "data-icon": "<span class='mif-server'>",
                            "data-caption":
                              _vm.HyperVTestVMCompatibilityModel.HyperVHost +
                              " on " +
                              _vm.getPCOSFullVersion(),
                            "data-content": `<span class='text-muted'>${_vm.$t(
                              "Server"
                            )}</span>`,
                          },
                        }),
                        _c("li", {
                          attrs: {
                            "data-icon": "<span class='mif-folder'>",
                            "data-caption":
                              _vm.HyperVTestVMCompatibilityModel.VmBackupFolder,
                            "data-content": `<span class='text-muted'>${_vm.$t(
                              "Backup path"
                            )}</span>`,
                          },
                        }),
                        _c("li", {
                          attrs: {
                            "data-icon": "<span class='mif-stack'>",
                            "data-caption":
                              _vm.convertDate(_vm.selBackupInfo.date) +
                              " " +
                              (_vm.selBackupInfo.number == 0
                                ? "FULL"
                                : _vm.selBackupInfo.number.padStart(3, "0")) +
                              " " +
                              _vm.prettyBytes(_vm.selBackupInfo.dimensione, 2) +
                              " " +
                              _vm.selBackupInfo.tipo,
                            "data-content": `<span class='text-muted'>${_vm.$t(
                              "Selected backup"
                            )}</span>`,
                          },
                        }),
                        _c("li", {
                          attrs: {
                            "data-icon": "<span class='mif-desktop_windows'>",
                            "data-caption": _vm.suggestedRestoredVmName,
                            "data-content": `<span class='text-muted'>${_vm.$t(
                              "Name of the target VM"
                            )}</span>`,
                          },
                        }),
                        _c("li", { staticClass: "node" }, [
                          _vm._m(0),
                          _c("div", { staticClass: "data" }, [
                            _c("div", { staticClass: "caption" }, [
                              _vm._v(_vm._s(_vm.computedCaption)),
                            ]),
                            _c("div", { staticClass: "content" }, [
                              _c("span", { staticClass: "text-muted" }, [
                                _vm._v(_vm._s(_vm.$t("Compatibility report"))),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
            _vm.steps[5] && _vm.restore
              ? _c("div", { staticClass: "container-fluid" }, [
                  _c("div", { staticClass: "row mt-4 ml-2" }, [
                    [
                      _vm.$root.PlatformsTypesEnum.ESXi,
                      _vm.$root.PlatformsTypesEnum.HyperV,
                    ].includes(_vm.restore.type)
                      ? _c("div", { staticClass: "cell-8" }, [
                          _c("label", [_vm._v(_vm._s(_vm.restore.vmname))]),
                          _c("br"),
                          _c(
                            "span",
                            {
                              staticClass:
                                "badge inline bg-cobalt fg-white p-1 mt-2 mr-1",
                            },
                            [_vm._v(_vm._s(_vm.restore.type_name))]
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "badge inline bg-cobalt fg-white p-1 mt-2 mr-1",
                            },
                            [_vm._v(_vm._s(_vm.restore.datacenter))]
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "badge inline bg-cobalt fg-white p-1 mt-2 mr-1",
                            },
                            [_vm._v("n. " + _vm._s(_vm.restore.backupnumber))]
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "badge inline bg-cobalt fg-white p-1 mt-2 mr-1",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.restore.datastore + "-" + _vm.restore.host
                                )
                              ),
                            ]
                          ),
                        ])
                      : _c("div", { staticClass: "cell-8" }, [
                          _c("label", [
                            _vm._v(_vm._s(_vm.restore.restorename)),
                          ]),
                        ]),
                    _c("div", { staticClass: "cell-8" }, [
                      _vm.restore.dt_start_utc != "" &&
                      _vm.restore.dt_start_utc != null
                        ? _c("label", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("moment")(
                                    _vm._f("utcAsLocal")(
                                      _vm.restore.dt_start_utc.substr(0, 8) +
                                        "T" +
                                        _vm.restore.dt_start_utc.substr(8)
                                    ),
                                    "MM/DD/YY hh:mm A"
                                  )
                                )
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c(
                      "div",
                      { staticClass: "cell-8" },
                      [
                        _c("RestoreLastResult", {
                          attrs: { lastresult: _vm.restore.lastresult },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "cell-6" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.restore.progress != null &&
                                _vm.restore.progress != 0,
                              expression:
                                "restore.progress != null && restore.progress != 0",
                            },
                          ],
                        },
                        [
                          _c("div", { staticClass: "clear" }, [
                            _vm.restore.current_operation.length <= 50
                              ? _c(
                                  "small",
                                  {
                                    staticClass: "place-left",
                                    attrs: {
                                      "data-role": "hint",
                                      "data-hint-position": "top",
                                      "data-hint-text":
                                        _vm.restore.current_operation,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.restore.current_operation)
                                    ),
                                  ]
                                )
                              : _vm.restore.current_operation <= 100
                              ? _c(
                                  "small",
                                  {
                                    staticClass: "place-left",
                                    attrs: {
                                      "data-role": "hint",
                                      "data-hint-position": "top",
                                      "data-hint-text":
                                        _vm.restore.current_operation,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.restore.current_operation.substr(
                                            0,
                                            50
                                          ) + "..."
                                        )
                                    ),
                                  ]
                                )
                              : _c(
                                  "small",
                                  {
                                    staticClass: "place-left",
                                    attrs: {
                                      "data-role": "hint",
                                      "data-hint-position": "top",
                                      "data-hint-text":
                                        _vm.restore.current_operation,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.restore.current_operation.substr(
                                            0,
                                            50
                                          ) +
                                            "..." +
                                            _vm.restore.current_operation.substr(
                                              -50,
                                              50
                                            )
                                        )
                                    ),
                                  ]
                                ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "progress small",
                              attrs: {
                                "data-role": "progress",
                                "data-type": "line",
                                "data-value": _vm.restore.progress,
                                "data-small": "true",
                                "data-cls-bar": "bg-cobalt",
                                "data-role-progress": "true",
                              },
                            },
                            [
                              _c("div", {
                                staticClass: "bar ribbed-cobalt",
                                style: { width: _vm.restore.progress + "%" },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "cell-2" }, [
                      _vm.restore.isRunning
                        ? _c(
                            "small",
                            { staticClass: "place-right text-bold enlarge-1" },
                            [_vm._v(" " + _vm._s(_vm.restore.progress) + "%")]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _c("div", { staticClass: "dialog-actions" }, [
        _c("div", { staticClass: "ml-auto" }, [
          _c(
            "button",
            {
              staticClass: "button error float-right",
              on: {
                click: function ($event) {
                  return _vm.$root.$emit("CLOSERESTOREDIALOG")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("Close")))]
          ),
          _c(
            "button",
            {
              staticClass: "button",
              class: { disabled: _vm.steps[0] },
              on: { click: _vm.back },
            },
            [
              _c("span", { staticClass: "mif-arrow-left" }),
              _vm._v(" " + _vm._s(_vm.$t("Prev"))),
            ]
          ),
          _c(
            "button",
            {
              staticClass: "button",
              class: {
                disabled:
                  (_vm.steps[0] &&
                    (!_vm.source.folderSource.paths ||
                      _vm.source.folderSource.paths.length == 0 ||
                      _vm.source.folderSource.paths[0].path == "")) ||
                  (_vm.steps[1] && _vm.bkpDirSelected == "") ||
                  _vm.steps[4] ||
                  _vm.steps[5] ||
                  (_vm.steps[3] && !_vm.canRestore),
              },
              on: { click: _vm.next },
            },
            [
              _vm._v(_vm._s(_vm.$t("Next")) + " "),
              _c("span", { staticClass: "mif-arrow-right" }),
            ]
          ),
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.steps[4],
                  expression: "steps[4]",
                },
              ],
              staticClass: "button primary float-right",
              on: { click: _vm.beginRestore },
            },
            [
              _c("span", { staticClass: "mif-checkmark pr-2" }),
              _vm._v(_vm._s(_vm.$t("Restore"))),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon" }, [
      _c("span", { staticClass: "mif-codescan" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }