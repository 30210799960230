var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _vm.nOpenDialogs != 0
        ? _c("div", { staticClass: "loader overflow" })
        : _vm._e(),
      _vm.group
        ? _c("GroupDialog", {
            attrs: {
              computers: _vm.computers,
              group: _vm.group,
              users: _vm.users,
            },
          })
        : _vm._e(),
      _vm.policy
        ? _c("PolicyDialog", {
            attrs: { computers: _vm.computers, policy: _vm.policy },
          })
        : _vm._e(),
      _vm.openSched ? _c("SchedulationDialog") : _vm._e(),
      _vm.user
        ? _c("UserDialog", {
            attrs: { selecteduser: _vm.user, checkTFA: _vm.checkTFA },
          })
        : _vm._e(),
      _vm.destination != null
        ? _c("Destination", {
            staticClass: "destinationDialog",
            attrs: { destination: _vm.destination },
          })
        : _vm._e(),
      _vm.source != null &&
      _vm.source.type == _vm.$root.PlatformsTypesEnum.FileFolder
        ? _c("FolderSource", {
            staticClass: "sourceDialog",
            attrs: { source: _vm.source },
          })
        : _vm._e(),
      _vm.source != null &&
      _vm.source.type == _vm.$root.PlatformsTypesEnum.Network
        ? _c("NetworkSource", {
            staticClass: "sourceDialog",
            attrs: { source: _vm.source },
          })
        : _vm._e(),
      _vm.source != null &&
      _vm.source.type == _vm.$root.PlatformsTypesEnum.DriveImage
        ? _c("DriveImageSource", { attrs: { source: _vm.source } })
        : _vm._e(),
      _vm.source != null &&
      _vm.source.type == _vm.$root.PlatformsTypesEnum.HyperV
        ? _c("HyperVSource", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.serviceAccount == null,
                expression: "serviceAccount == null",
              },
            ],
            attrs: { source: _vm.source },
          })
        : _vm._e(),
      _vm.source != null && _vm.source.type == _vm.$root.PlatformsTypesEnum.ESXi
        ? _c("ESXiSourceDestination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.serviceAccount == null,
                expression: "serviceAccount == null",
              },
            ],
            staticClass: "sourceDialog",
            attrs: { source: _vm.source },
          })
        : _vm._e(),
      _vm.source != null && _vm.$root.isExchange(_vm.source.type)
        ? _c("ExchangeSource", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.serviceAccount == null,
                expression: "serviceAccount == null",
              },
            ],
            staticClass: "sourceDialog",
            attrs: { source: _vm.source },
          })
        : _vm._e(),
      _vm.source != null && _vm.$root.isDatabase(_vm.source.type)
        ? _c("DatabaseSource", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.serviceAccount == null,
                expression: "serviceAccount == null",
              },
            ],
            staticClass: "sourceDialog",
            attrs: { source: _vm.source },
          })
        : _vm._e(),
      _vm.source != null &&
      _vm.$root.isMicrosoft(_vm.source.type) &&
      !_vm.$root.isExchange(_vm.source.type)
        ? _c("MicrosoftSource", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.serviceAccount == null,
                expression: "serviceAccount == null",
              },
            ],
            staticClass: "sourceDialog",
            attrs: { source: _vm.source },
          })
        : _vm._e(),
      _vm.restore != null && _vm.$root.isSimpleFileNFolderType(_vm.restore.type)
        ? _c("RestoreFile", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.serviceAccount == null && _vm.repository == null,
                expression: "serviceAccount == null && repository == null",
              },
            ],
            staticClass: "restoreDialog",
            attrs: { restore: _vm.restore },
          })
        : _vm._e(),
      _vm.restore != null && !_vm.isValid(_vm.restore.idBackup)
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.serviceAccount == null,
                  expression: "serviceAccount == null",
                },
              ],
            },
            [
              _vm.restore.type == _vm.$root.PlatformsTypesEnum.HyperV
                ? _c("HyperVManualRestore", {
                    staticClass: "restoreDialog",
                    attrs: { source: _vm.restore },
                  })
                : _vm._e(),
              _vm.restore.type == _vm.$root.PlatformsTypesEnum.ESXi
                ? _c("ESXiManualRestore", {
                    staticClass: "restoreDialog",
                    attrs: { source: _vm.restore },
                  })
                : _vm._e(),
              _vm.restore.type ==
              _vm.$root.PlatformsTypesEnum.Database_SQLServer
                ? _c("DatabaseManualRestore", {
                    staticClass: "restoreDialog",
                    attrs: { source: _vm.restore },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.restore != null && _vm.$root.isExchange(_vm.restore.type)
        ? _c("ExchangeManualRestore", {
            staticClass: "restoreDialog",
            attrs: { restore: _vm.restore },
          })
        : _vm._e(),
      _vm.restore != null && _vm.isValid(_vm.restore.idBackup)
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.serviceAccount == null,
                  expression: "serviceAccount == null",
                },
              ],
            },
            [
              _vm.restore.type == _vm.$root.PlatformsTypesEnum.ESXi
                ? _c("ESXiDestinationRestore", {
                    staticClass: "restoreDialog",
                    attrs: {
                      idJob: _vm.selectedJobId,
                      idPC: _vm.$session.getRoomPC().id,
                    },
                  })
                : _vm._e(),
              _vm.restore.type == _vm.$root.PlatformsTypesEnum.HyperV
                ? _c("HyperVDestinationRestore", {
                    staticClass: "restoreDialog",
                    attrs: {
                      idJob: _vm.restore.idBackup,
                      idPC: _vm.$session.getRoomPC().id,
                    },
                  })
                : _vm._e(),
              _vm.restore.type ==
              _vm.$root.PlatformsTypesEnum.Database_SQLServer
                ? _c("DatabaseDestinationRestore", {
                    staticClass: "restoreDialog",
                    attrs: {
                      idJob: _vm.restore.idBackup,
                      idPC: _vm.$session.getRoomPC().id,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.idBackupForView != null
        ? _c("BackupView", { attrs: { idBackup: _vm.idBackupForView } })
        : _vm._e(),
      _vm.isValid(_vm.repository)
        ? _c("Repository", {
            staticClass: "repositoryDialog",
            attrs: { repository: _vm.repository },
          })
        : _vm._e(),
      _vm.isValid(_vm.serviceAccount)
        ? _c(
            "div",
            [
              _vm.$root.isCloud(_vm.serviceAccount.type) &&
              _vm.serviceAccount.type !=
                _vm.$root.PlatformsTypesEnum.Cloud_OneDrive
                ? _c("CloudServiceAccount", {
                    staticClass: "saDialog",
                    attrs: { serviceAccount: _vm.serviceAccount },
                  })
                : _vm._e(),
              _vm.serviceAccount.type == _vm.$root.PlatformsTypesEnum.FTP
                ? _c("FTPServiceAccount", {
                    staticClass: "saDialog",
                    attrs: { serviceAccount: _vm.serviceAccount },
                  })
                : _vm._e(),
              _vm.serviceAccount.type == _vm.$root.PlatformsTypesEnum.Network
                ? _c("NetworkServiceAccount", {
                    staticClass: "saDialog",
                    attrs: { serviceAccount: _vm.serviceAccount },
                  })
                : _vm._e(),
              _vm.serviceAccount.type == _vm.$root.PlatformsTypesEnum.ESXi
                ? _c("ESXIServiceAccount", {
                    staticClass: "saDialog",
                    attrs: { serviceAccount: _vm.serviceAccount },
                  })
                : _vm._e(),
              _vm.$root.isMicrosoft(_vm.serviceAccount.type) ||
              _vm.serviceAccount.type ==
                _vm.$root.PlatformsTypesEnum.Cloud_OneDrive
                ? _c("MicrosoftServiceAccount", {
                    staticClass: "saDialog",
                    attrs: { serviceAccount: _vm.serviceAccount },
                  })
                : _vm._e(),
              _vm.$root.isDatabase(_vm.serviceAccount.type)
                ? _c("DatabaseServiceAccount", {
                    staticClass: "saDialog",
                    attrs: { serviceAccount: _vm.serviceAccount },
                  })
                : _vm._e(),
              _vm.$root.isEmail(_vm.serviceAccount.type)
                ? _c("EmailServiceAccount", {
                    staticClass: "saDialog",
                    attrs: { serviceAccount: _vm.serviceAccount },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.sarepo != null
        ? _c("SARepoView", {
            attrs: {
              repository: _vm.sarepo.repository,
              serviceAccount: _vm.sarepo.serviceAccount,
            },
          })
        : _vm._e(),
      _vm.createFolderDialog != null
        ? _c("div", { staticClass: "dialog light h-auto" }, [
            _c("div", { staticClass: "dialog-title" }, [
              _vm._v(_vm._s(_vm.$t("Create new folder"))),
            ]),
            _c("div", { staticClass: "p-5" }, [
              _c("div", { staticClass: "d-flex mb-2 va-baseline" }, [
                _c("span", [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "24",
                        height: "24",
                        fill: "none",
                        viewBox: "0 0 24 24",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M13.821 6.5h5.929a2.25 2.25 0 0 1 2.229 1.938l.016.158.005.154v9a2.25 2.25 0 0 1-2.096 2.245L19.75 20H4.25a2.25 2.25 0 0 1-2.245-2.096L2 17.75v-7.251l6.207.001.196-.009a2.25 2.25 0 0 0 1.088-.393l.156-.12L13.821 6.5ZM8.207 4c.46 0 .908.141 1.284.402l.156.12 2.103 1.751-3.063 2.553-.085.061a.75.75 0 0 1-.29.106L8.206 9 2 8.999V6.25a2.25 2.25 0 0 1 2.096-2.245L4.25 4h3.957Z",
                          fill: "#212121",
                        },
                      }),
                    ]
                  ),
                ]),
                _c("span", [_vm._v(_vm._s(_vm.createFolderDialog.parentPath))]),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.createFolderDialog.name,
                    expression: "createFolderDialog.name",
                  },
                ],
                staticClass: "input bg-light",
                attrs: {
                  type: "text",
                  "data-role": "input",
                  placeholder: _vm.createFolderDialog.name,
                },
                domProps: { value: _vm.createFolderDialog.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.createFolderDialog,
                      "name",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "dialog-actions" }, [
              _c(
                "button",
                {
                  staticClass: "button js-dialog-close primary my-3",
                  attrs: { disabled: _vm.createFolderDialog.name == "" },
                  on: { click: _vm.createFolder },
                },
                [_vm._v(_vm._s(_vm.$t("Save")))]
              ),
              _c(
                "button",
                {
                  staticClass: "button js-dialog-close alert",
                  on: { click: _vm.closeCreateFolderDialog },
                },
                [_vm._v(_vm._s(_vm.$t("Cancel")))]
              ),
            ]),
          ])
        : _vm._e(),
      _vm.renameItemDialog != null
        ? _c("div", { staticClass: "dialog light" }, [
            _c("div", { staticClass: "dialog-title" }, [
              _vm._v(_vm._s(_vm.$t("Rename"))),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-column flex-align-center flex-justify-center mb-2 text-small px-4",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-align-center flex-justify-center mb-2 row",
                  },
                  [
                    _c("span", {
                      staticClass: "mr-2",
                      class: _vm.getMetroIcon(_vm.renameItemDialog.resource),
                    }),
                    _c("span", [
                      _vm._v(_vm._s(_vm.renameItemDialog.parentPath)),
                    ]),
                  ]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.renameItemDialog.name,
                      expression: "renameItemDialog.name",
                    },
                  ],
                  staticClass: "input",
                  attrs: { type: "text", "data-role": "input" },
                  domProps: { value: _vm.renameItemDialog.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.renameItemDialog,
                        "name",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]
            ),
            _c("div", { staticClass: "dialog-actions" }, [
              _c(
                "button",
                {
                  staticClass: "button js-dialog-close primary my-3",
                  attrs: { disabled: _vm.renameItemDialog.name == "" },
                  on: { click: _vm.renameItem },
                },
                [_vm._v(_vm._s(_vm.$t("Save")))]
              ),
              _c(
                "button",
                {
                  staticClass: "button js-dialog-close alert",
                  on: { click: _vm.closeRenameItemDialog },
                },
                [_vm._v(_vm._s(_vm.$t("Cancel")))]
              ),
            ]),
          ])
        : _vm._e(),
      _vm.deleteItemDialog != null
        ? _c(
            "div",
            {
              staticClass: "dialog h-auto",
              staticStyle: { "min-height": "fit-content" },
            },
            [
              _c("div", { staticClass: "dialog-title" }, [
                _vm._v(_vm._s(_vm.$t("Do you want delete selected item?"))),
              ]),
              _c("div", { staticClass: "text-small p-4" }, [
                _c("span", {
                  staticClass: "mr-2",
                  class: _vm.getMetroIcon(_vm.deleteItemDialog.resource),
                }),
                _c("span", [
                  _vm._v(_vm._s(_vm.deleteItemDialog.resource.path)),
                ]),
              ]),
              _c("div", { staticClass: "dialog-actions" }, [
                _c(
                  "button",
                  {
                    staticClass: "button js-dialog-close primary my-3",
                    on: { click: _vm.deleteItem },
                  },
                  [_vm._v(_vm._s(_vm.$t("Yes")))]
                ),
                _c(
                  "button",
                  {
                    staticClass: "button js-dialog-close alert",
                    on: { click: _vm.closeDeleteItemDialog },
                  },
                  [_vm._v(_vm._s(_vm.$t("No")))]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.renameJobDialog != null
        ? _c(
            "div",
            {
              staticClass: "dialog light",
              staticStyle: {
                "min-height": "fit-content",
                "padding-top": "8px !important",
                "padding-bottom": "8px !important",
              },
            },
            [
              _c("div", { staticClass: "dialog-title" }, [
                _vm._v(_vm._s(_vm.$t("Rename Job"))),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "dialog-content d-flex flex-column dialog-content flex-justify-center flex-align-center",
                },
                [
                  _c("s", { staticClass: "mb-4" }, [
                    _vm._v(_vm._s(_vm.renameJobDialog.oldName)),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.renameJobDialog.newName,
                        expression: "renameJobDialog.newName",
                      },
                    ],
                    staticClass: "input w-75 mb-4",
                    attrs: {
                      type: "text",
                      "data-role": "input",
                      placeholder: _vm.$t("Enter the new name of the backup"),
                    },
                    domProps: { value: _vm.renameJobDialog.newName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.renameJobDialog,
                          "newName",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]
              ),
              _c("div", { staticClass: "dialog-actions" }, [
                _c(
                  "button",
                  {
                    staticClass: "button js-dialog-close primary my-3",
                    attrs: { disabled: _vm.renameJobDialog.newName == "" },
                    on: { click: _vm.renameJob },
                  },
                  [_vm._v(_vm._s(_vm.$t("Save")))]
                ),
                _c(
                  "button",
                  {
                    staticClass: "button js-dialog-close alert",
                    on: { click: _vm.closeRenameJobDialog },
                  },
                  [_vm._v(_vm._s(_vm.$t("Cancel")))]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.backupLog
        ? _c(
            "div",
            {
              staticClass: "dialog center overflow w-33",
              on: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape",
                    ])
                  )
                    return null
                  return _vm.closeBackupLog()
                },
              },
            },
            [
              _c("div", [
                _c("span", {
                  staticClass: "button square closer",
                  on: {
                    click: function ($event) {
                      return _vm.closeBackupLog()
                    },
                  },
                }),
              ]),
              _c("div", {
                staticClass: "dialog-content py-5 px-8",
                domProps: { innerHTML: _vm._s(_vm.backupLog) },
              }),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }