import addLicenseDialog from '../components/dialogs/AddLicenseDialog.vue'
import Navigation from "../components/utils/Navigation.vue";
const qs = require('querystring')

export default {
    name: "Licenses",
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Licenses',
        // all titles will be injected into this template
        titleTemplate: '%s | Iperius One '
    },
    data() {
        return {

            loader: false,
            licenses: [],
            licenseDetails: null, // Dettagli licenza singola,
            pricing: null,
            license: {
                num_users: 1,
                num_workstations: 0,
                num_servers: 0,
                num_vms: 0,
                plugin_exchange: 0,
                plugin_database: 0,
                plugin_tape: 0,
                subscription_duration: 1,
                discount_code: null,
                currency: 'EUR'
            },

        }
    },
    props: {},
    mounted: function() {
        this.licenses = this.$session.get("licenses");
        this.licenseDetails = this.$session.getLicense();
        this.roles = this.$api.getRoles();
    },
    created: function() {
        //this.$store.commit('SET_LAYOUT', 'nav-layout')
    },
    components: {
        addLicenseDialog,
        Navigation,
    },
    methods: {

        goBack() {
            window.history.length > 1 ? this.$router.go(-1) : this.$router.replace('/')
        },
        getName() {
            return this.auth = this.$root.checkAuth().firstName + " " + this.$root.checkAuth().lastName;
        },


        async getRoles() {
            let self = this;
            var roles = await this.$api.getRoles();
            self.$store.commit('SET_ROLES', roles);
        }
    }
};