<template>
    <div v-on:keyup.esc="$root.$emit('CLOSESOURCEDIALOG')"
        class="dialog light w-100-sm w-100-md w-75-lg  w-50-xl h-75" v-cloak>

        <DialogTitle :title="$t('Configure Source')" :defaultTitle="$t('Configure Source')" :subTitle="source.type_name"
            @closeDialog="$root.$emit('CLOSESOURCEDIALOG')" />

        <div v-show="step == STEP_CHOOSE_MAILBOXES" class="dialog-content">

            <!------------------------------SCELTA DEL SERVICE ACCOUNT--------------------------------------------------------------->
            <!--Questa pagina deve implementare il metodo "sendSATestResult"-->
            <SARepoSelect :type=source.type :idSA=source.id_service_account :idRepo=null
                @sendSATestResult="sendSATestResult" />
            <div v-if="wait || saTest == SA_TEST_FAILED" class="form-group mt-2">
                <h6 v-if="wait"><span class="mif-spinner2 ani-spin ml-2" /> <span>{{ $t("Listing resources from Exchange 365") }}</span></h6>
                <h6 v-else>{{ $t("No mailbox found") }}</h6>
            </div>
            <SelectedResourcesLayout v-if="saTest == SA_TEST_OK" :list="selectedNodes" :singleItem="false"
                :canCancel="true" :buttonText="$t('Select Exchange Mailboxes to backup')" :isDisabled="false"
                :heightPercentage="40" @clickFunction="toggleFileSystemSidebar()" />
        </div>


        <div v-show="step == STEP_BACKUP_MODE_OPTIONS" class="dialog-content">
            <h6 class="form-group text-bold">{{ $t("Backup mode") }}</h6>
            <div class="form-group row">
                <label>{{ $t("Type") }}:</label>
                <select v-model="source.options.backupType" data-role="select" data-filter="false">
                    <option v-for="key in Object.keys($root.selectValues.backupTypes)" :value="key" v-bind:key="key">
                        {{ $t($root.selectValues.backupTypes[key]) }}</option>
                </select>
            </div>

            <div class="form-group row">
                <label>{{ $t("Number of copies") }}:</label>
                <input type="text" data-role="spinner" id="nCopiesSpinner"  v-model="source.options.nCopies" data-default-value="1" data-min-value="1"
                    data-max-value="365" class="w-25 ml-2" />
            </div>

            <div class="form-group">
                <input data-role="radio" class="form-check-input mb-1" type="radio"
                    v-model="source.options.outputFormat" value="PST"
                    :data-caption="$t('Export mailboxes to individual .pst files')"><br>
                <input data-role="radio"
                    :disabled="source.type == $root.PlatformsTypesEnum.Microsoft_ExchangeOnPremises"
                    class="form-check-input mb-1" type="radio" v-model="source.options.outputFormat" value="EML"
                    :data-caption="$t('Export mailboxes to individual .eml/.ics/.vcf files')"><br>
                <input data-role="radio" v-if="source.type != $root.PlatformsTypesEnum.Microsoft_Exchange365"
                    class="form-check-input mb-1" type="radio" v-model="source.options.outputFormat" value="EDB"
                    :data-caption="$t('Export Database Files (.edb)')"><br>
            </div>
        </div>

        <div v-show="step == STEP_OPTIONS" class="dialog-content">
            <h6 class="form-group text-bold">{{ $t("Backup Options") }}</h6>
            <!--FILTRI EMAIL-->
            <div class="form-group d-flex row">
                    <p class="w-50">
                        <input data-role="checkbox" type="checkbox" v-model="source.options.includeInbox" checked />
                        <label>
                            <span class='mif-email mr-2'></span>
                            <span>{{ $t('Include Inbox') }}</span>
                        </label>
                    </p>

                    <p class="w-50">

                        <input data-role="checkbox" type="checkbox" v-model="source.options.includeSentItems" checked />
                        <label>
                            <span class='mif-email mr-2'></span>
                            <span>{{ $t('Include Sent Items') }}</span>
                        </label>
                    </p>

                    <p class="w-50">

                        <input data-role="checkbox" type="checkbox" v-model="source.options.includeDrafts" />
                        <label>
                            <span class='mif-email mr-2'></span>
                            <span>{{ $t('Include Drafts') }}</span>
                        </label>
                    </p>

                    <p class="w-50">

                        <input data-role="checkbox" type="checkbox" v-model="source.options.includeOutbox" />
                        <label>
                            <span class='mif-email mr-2'></span>
                            <span>{{ $t('Include Outbox') }}</span>
                        </label>
                    </p>

                    <p class="w-50">

                        <input data-role="checkbox" type="checkbox" v-model="source.options.includeDeletedItems" />
                        <label>
                            <span class='mif-email mr-2'></span>
                            <span>{{ $t('Include Deleted Items') }}</span>
                        </label>
                    </p>

                    <p class="w-50">

                        <input data-role="checkbox" type="checkbox" v-model="source.options.includeJunkeMails" />
                        <label>
                            <span class='mif-email mr-2'></span>
                            <span>{{ $t('Include Junke Mails') }}</span>
                        </label>
                    </p>

                    <p class="w-50">

                        <input data-role="checkbox" type="checkbox" v-model="source.options.includeArchiveMailbox" />
                        <label>
                            <span class='mif-email mr-2'></span>
                            <span>{{ $t('Include Archive') }}</span>
                        </label>
                    </p>

                    <p class="w-50">

                        <input data-role="checkbox" type="checkbox" v-model="source.options.includePublicFolders" />
                        <label>
                            <span class='mif-email mr-2'></span>
                            <span>{{ $t('Include Public Folders') }}</span>
                        </label>
                    </p>
                <!--FILTRO ALTRI ELEMENTI-->


                    <p class="w-50">

                        <input data-role="checkbox" type="checkbox" v-model="source.options.includeCalendar" />
                        <label>
                            <span class='mif-calendar mr-2'></span>
                            <span>{{ $t('Include Calendar') }}</span>
                        </label>
                    </p>

                    <p class="w-50">

                        <input data-role="checkbox" type="checkbox" v-model="source.options.includeContacts" />
                        <label>
                            <span class='mif-contacts-dialer mr-2'></span>
                            <span>{{ $t('Include Contacts') }}</span>
                        </label>
                    </p>

                    <p class="w-50">

                        <input data-role="checkbox" type="checkbox" v-model="source.options.includeTasks" />
                        <label>
                            <span class='mif-add_task mr-2'></span>
                            <span>{{ $t('Include Tasks') }}</span>
                        </label>
                    </p>

            </div>



            <div class="form-group" v-if="source.type != $root.PlatformsTypesEnum.Microsoft_Exchange365">
                <input data-role="checkbox" type="checkbox" :data-caption="$t('Truncate Exchange log')"
                    data-validate="required" v-model="source.options.truncateExchangeLogs"
                    data-prepend="<span class='mif-server'></span>" />
            </div>
            <div class="form-group row d-flex flex-justify-between">
                <label>{{ $t("Number of main threads") }}:</label>
                <input id="nThreadsSpinner" type="text" data-role="spinner" v-model="source.options.nThreads"
                    class="w-25" data-min-value=1 data-max-value=6>
            </div>
            <div class="form-group row d-flex flex-justify-between">
                <label>{{ $t("Number of download threads") }}:</label>
                <input id="nDownloadThreadsSpinner" type="text" data-role="spinner" v-model="source.options.nDownloadThreads" class="w-25" data-min-value=1
                    data-max-value=20>
            </div>
            <div class="form-group pt-4">
                <label>{{ $t("Buffer size") }}:</label>
                <input id="bufferSpinner" type="text" data-role="spinner" v-model="source.options.buffer" class="w-25" data-min-value="500" data-max-value="1500">
            </div>
            <div class="form-group pt-4">
                <label>{{ $t("Number of bad items for considering failed a mailbox export") }}:</label>
                <input id="badItemsLimitSpinner" type="text" data-role="spinner" class="w-25" data-min-value="1"
                    data-max-value="500">
            </div>
        </div>
        <!-- <div v-show="step == 2" class="dialog-content">
            <h6 class="form-group text-bold">{{ $t("Configure backup options") }}:</h6>

            <div class="form-group">
                <input v-model="source.enableCompression" type="checkbox" data-role="checkbox"
                    :data-caption="$t('Enable Compression')">
            </div>
            <div class="form-group w-50">
                <p>{{ $t("Archive File Custom Name") }} </p>
                <input :disabled="!source.enableCompression" v-model.lazy="source.options.archiveFileCustomName"
                    type="text" data-role="input">
                <label class="text-small text-muted">{{ $t("If blank, default name will be used") }}</label>
            </div>
            <div class="form-group">
                <input :disabled="!source.enableCompression" v-model="source.options.useArchivePassword" type="checkbox"
                    data-role="checkbox" :data-caption="$t('Protect zip with Password')">
            </div>
            <div>
                <div class="form-group w-50">
                    <p>{{ $t("Archive Password") }}</p>
                    <input :disabled="!source.options.useArchivePassword" data-role="input" class="metro-input"
                        type="password" v-model="source.options.archivePassword" :placeholder="$t('Enter Password')"
                        data-prepend="<span class='mif-lock'></span>" />
                    <small class="text-muted">{{ $t("Required") }}</small>

                </div>
            </div>
        </div> -->

        <div class="dialog-actions">
            <button class="button " :disabled="step == 0" @click="step -= 1">
                <span class="mif-arrow-left mr-1" />
                <span>{{ $t("Prev") }}</span>
            </button>

            <button class="button"
                :disabled="source.id_service_account == -2 || step == STEP_OPTIONS || selectedNodes.length == 0"
                @click="step += 1">
                <span>{{ $t("Next") }}</span>
                <span class="mif-arrow-right ml-1" />
            </button>

            <button class="button error float-right ml-2" @click="$root.$emit('CLOSESOURCEDIALOG')">{{ $t("Cancel")
                }}</button>
            <button class="button primary ml-2 float-right" v-show="step == STEP_OPTIONS" @click="saveSource">
                <span>{{ $t("Save") }}</span>
            </button>
        </div>
    </div>
</template>
<script>
import DialogTitle from "../../utils/DialogTitle";
import SARepoSelect from "../../utils/SARepoSelect.vue";
import SelectedResourcesLayout from "../../utils/SelectedResourcesLayout.vue"
import { prettyBytes, syncronizeArray, isValid } from "../../../../public/assets/js/utilitiesmodule";
import { ClientTreeNode, FileSystemSidebarConfig } from "../../../models/TreeNode";

export default {
    name: "ExchangeSource",
    props: {
        source: {
            type: Object,
            required: true,
        }
    },
    components: {
        "DialogTitle": DialogTitle,
        SARepoSelect,
        SelectedResourcesLayout
    },
    filters: {
        prettyBytes: function(bytes, precision) {
            return prettyBytes(bytes, precision);
        }
    },
    data() {
        return {
            step: 0,
            wait: false,
            saTest: -1,
            SA_SELECTED: -1,
            SA_TEST_OK: 1,
            SA_TEST_FAILED: 0,
            selectedNodes: [],
            id_mailbox: null,
            exchangeTree: null,

            STEP_CHOOSE_MAILBOXES: 0,
            STEP_BACKUP_MODE_OPTIONS: 1,
            STEP_OPTIONS: 2
        }
    },
    watch: {
        step: {
            handler: function(value) {
                if (value==1)
                    this.setSpinners();
            },
            immediate: true
        }
    },

    methods: {

        async sendSATestResult(testResult) {
            this.saTest=this.SA_SELECTED;
            this.source.id_service_account=testResult.serviceAccount.id;
            //Caso 1 : Exchange On-Premises
            if (this.source.type!=this.$root.PlatformsTypesEnum.Microsoft_Exchange365) {
                this.source.options.disableDasdIo=true;
                this.source.exchangeSource=testResult.list;
                return;

            }

            //Caso 2 : Exchange 365
            this.wait=true;
            var socketResponse=await this.$root.socket("GetMicrosoft365List", testResult.serviceAccount, this.$root.PlatformsTypesEnum.Microsoft_Exchange365, "");
            if (socketResponse==null) {
                this.saTest=this.SA_TEST_FAILED;
                this.source.exchangeSource=null;
                return;
            }

            this.id_mailbox=socketResponse.id_mailbox;
            this.exchangeTree=socketResponse.tree;

            this.wait=false;

            this.saTest=this.SA_TEST_OK;

            //Se sto creando una nuova sorgente
            if (!isValid(this.source.id)) {
                return;
            }

            var oldMailboxes=this.source.exchangeSource.mailboxes;
            this.source.exchangeSource.mailboxes=syncronizeArray(this.source.type, oldMailboxes, Object.values(this.id_mailbox)).finalArray;
            this.id_mailbox={};
            this.source.exchangeSource.mailboxes.forEach((m, i) => {
                this.id_mailbox[m.id]=m;
                if (m.selected)
                    this.selectedNodes.push(new ClientTreeNode("node-"+i, m.id, m.name, m.exchangeMailboxType))
            });
        },

        toggleFileSystemSidebar() {
            this.$root.$emit(
                "toggleFileSystemSidebar",
                this.selectedNodes,
                new FileSystemSidebarConfig(
                    this.source.type,
                    false,
                    true,//elementi multipli
                    null,
                    null,
                    true,
                    this.exchangeTree
                )
            );
        },
        saveSource() {

            this.source.exchangeSource={
                "name": "Exchange 365 Server",
                "id": "00000000-0000-0000-0000-000000000000",
                "version": "15.20.4087.50",
                "isExchangeOnline": true,
                "mailboxes": [],
                "databases": null
            };

            if (this.selectedNodes.length==1&&this.selectedNodes[0].resID=="ALL") {
                Object.values(this.id_mailbox).forEach(m => {
                    m.selected=true;
                    this.source.exchangeSource.mailboxes.push(m);
                });
            } else {
                this.selectedNodes.forEach(node => {
                    this.id_mailbox[node.resID].selected=true;
                });

                this.source.exchangeSource.mailboxes=Object.values(this.id_mailbox);
            }
            this.$root.$emit("saveBackupSource", this.source);
        },

        setSpinners() {
            let self=this;
            setTimeout(function() {
                //nCopies
                var spinner=window.Metro.get$el("#nCopiesSpinner").data("spinner");
                spinner.val(self.source.options.nCopies);
                spinner=spinner.options;
                spinner.onButtonClick=(x, y, val) => {
                    self.source.options.nCopies=y;
                };
                /*spinner.onArrowClick=(x, y, val) => {
                    self.source.options.nCopies=y;
                };*/

                //nThreads
                var nThreadsSpinner=window.Metro.get$el("#nThreadsSpinner").data("spinner");
                nThreadsSpinner.val(self.source.options.nThreads);
                nThreadsSpinner=nThreadsSpinner.options;
                nThreadsSpinner.onButtonClick=(x, y, val) => {
                    self.source.options.nThreads=y;
                };
                nThreadsSpinner.onArrowClick=(x, y, val) => {
                    self.source.options.nThreads=y;
                };

                //nDownloadThreadsSpinner
                spinner=window.Metro.get$el("#nDownloadThreadsSpinner").data("spinner");
                spinner.val(self.source.options.nDownloadThreads);
                spinner=spinner.options;
                spinner.onButtonClick=(x, y, val) => {
                    self.source.options.nDownloadThreads=y;
                };
                spinner.onArrowClick=(x, y, val) => {
                    self.source.options.nDownloadThreads=y;
                };

                //badItemsLimitSpinner
                spinner=window.Metro.get$el("#badItemsLimitSpinner").data("spinner");
                spinner.val(self.source.options.badItemsLimit);
                spinner=spinner.options;
                spinner.onButtonClick=(x, y, val) => {
                    self.source.options.badItemsLimit=y;
                };
                spinner.onArrowClick=(x, y, val) => {
                    self.source.options.badItemsLimit=y;
                };

                spinner=window.Metro.get$el("#bufferSpinner").data("spinner");
                spinner.val(self.source.options.buffer);
                spinner=spinner.options;
                spinner.onButtonClick=(x, y, val) => {
                    self.source.options.buffer=y;
                };
                spinner.onArrowClick=(x, y, val) => {
                    self.source.options.buffer=y;
                };
            }, 500);
        }
    }
}
</script>