var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.source.driveImgSource
      ? _c(
          "ul",
          { attrs: { "data-role": "treeview" } },
          _vm._l(
            _vm.source.driveImgSource.filter((dis) =>
              dis.partitions.some((p) => p.selected || p.indeterminate)
            ),
            function (d, i) {
              return _c(
                "li",
                {
                  key: d.id,
                  attrs: { "data-caption": _vm.createCaptionForDisk(d, i) },
                },
                [
                  _c(
                    "ul",
                    _vm._l(
                      d.partitions.filter((d) => d.selected || d.indeterminate),
                      function (p) {
                        return _c("li", {
                          key: p.id,
                          attrs: {
                            "data-caption": _vm.createCaptionForPartition(p),
                          },
                        })
                      }
                    ),
                    0
                  ),
                ]
              )
            }
          ),
          0
        )
      : _vm._e(),
    _vm.source.hvSource
      ? _c(
          "ul",
          {
            attrs: {
              id: "hypervTreeview",
              "data-role": "treeview",
              "data-on-expand-node.stop": "",
            },
          },
          [
            _c(
              "li",
              {
                attrs: {
                  "data-icon": "<span class='mif-cabinet pt-1'/>",
                  "data-caption": "localhost",
                },
              },
              [
                _c(
                  "ul",
                  _vm._l(
                    _vm.source.hvSource.filter(
                      (x) => x.selected || x.indeterminate
                    ),
                    function (vm) {
                      return _c(
                        "li",
                        {
                          attrs: {
                            "data-icon": "<span class='mif-stack pt-1'/>",
                            "data-caption": vm.name,
                            id: vm.name.replace(" ", ""),
                          },
                        },
                        [
                          _c("ul", [
                            _c(
                              "ul",
                              _vm._l(vm.virtualHardDisks, function (vd) {
                                return _c("li", {
                                  attrs: {
                                    "data-icon":
                                      "<span class='mif-drive2 pt-1'/>",
                                    "data-caption":
                                      _vm.buildVirtualDiskCaptionHYPERV(vd),
                                  },
                                })
                              }),
                              0
                            ),
                          ]),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ]
            ),
          ]
        )
      : _vm._e(),
    _vm.source.esxiSource
      ? _c("ul", { attrs: { "data-role": "treeview" } }, [
          _c(
            "li",
            { staticClass: "p-1", attrs: { title: _vm.source.esxiSource.url } },
            [
              _c("label", [
                _c("span", { staticClass: "mif-vpn-publ pr-2" }),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.source.esxiSource.url +
                        " (" +
                        _vm.source.esxiSource.hostVersion +
                        ")"
                    )
                  ),
                ]),
              ]),
              _c(
                "ul",
                _vm._l(
                  _vm.source.esxiSource.dataCenters?.filter(
                    (x) => x.selected || x.indeterminate
                  ),
                  function (datacenter) {
                    return _c(
                      "li",
                      {
                        key: datacenter.name,
                        attrs: { "data-collapsed": "true" },
                      },
                      [
                        _c("label", [
                          _c("span", {
                            staticClass: "mif-location-city pt-1 pr-2",
                          }),
                          _vm._v(_vm._s(datacenter.name)),
                        ]),
                        datacenter.computerResourceList.some(
                          (x) => x.selected || x.indeterminate
                        )
                          ? _c(
                              "ul",
                              _vm._l(
                                datacenter.computerResourceList.filter(
                                  (x) => x.selected || x.indeterminate
                                ),
                                function (computerResource) {
                                  return _c(
                                    "li",
                                    {
                                      key: computerResource.name,
                                      staticClass: "p-1",
                                      attrs: {
                                        title: computerResource.name,
                                        "data-collapsed": "true",
                                        "data-icon":
                                          "<span class='mif-cabinet pt-1'></span>",
                                      },
                                    },
                                    [
                                      _c("label", { staticClass: "ml-5" }, [
                                        _vm._v(_vm._s(computerResource.name)),
                                      ]),
                                      datacenter.computerResourceList.some(
                                        (x) => x.selected || x.indeterminate
                                      )
                                        ? _c(
                                            "ul",
                                            _vm._l(
                                              computerResource.datastores.filter(
                                                (x) =>
                                                  x.selected || x.indeterminate
                                              ),
                                              function (datastore) {
                                                return _c(
                                                  "li",
                                                  {
                                                    key: datastore.name,
                                                    staticClass: "p-1",
                                                    attrs: {
                                                      title: datastore.name,
                                                      "data-collapsed": "true",
                                                      "data-icon":
                                                        "<span class='mif-database pt-1'></span>",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      { staticClass: "ml-5" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.buildDatastoreCaption(
                                                              datastore
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    datastore.virtualMachines.some(
                                                      (x) =>
                                                        x.selected ||
                                                        x.indeterminate
                                                    )
                                                      ? _c(
                                                          "ul",
                                                          _vm._l(
                                                            datastore.virtualMachines.filter(
                                                              (x) =>
                                                                x.selected ||
                                                                x.indeterminate
                                                            ),
                                                            function (
                                                              virtualmachine
                                                            ) {
                                                              return _c(
                                                                "li",
                                                                {
                                                                  key: virtualmachine.name,
                                                                  staticClass:
                                                                    "p-1",
                                                                  attrs: {
                                                                    "data-collapsed":
                                                                      "true",
                                                                    "data-icon":
                                                                      "<span class='mif-stack pt-1'></span>",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "label",
                                                                    {
                                                                      staticClass:
                                                                        "ml-5 mif-info",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          virtualmachine.name
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  virtualmachine.virtualDisks.some(
                                                                    (x) =>
                                                                      x.selected ||
                                                                      x.indeterminate
                                                                  )
                                                                    ? _c(
                                                                        "ul",
                                                                        _vm._l(
                                                                          virtualmachine.virtualDisks.filter(
                                                                            (
                                                                              x
                                                                            ) =>
                                                                              x.selected ||
                                                                              x.indeterminate
                                                                          ),
                                                                          function (
                                                                            virtualDisk
                                                                          ) {
                                                                            return _c(
                                                                              "li",
                                                                              {
                                                                                key: virtualDisk.deviceKey,
                                                                                staticClass:
                                                                                  "p-1",
                                                                                attrs:
                                                                                  {
                                                                                    title:
                                                                                      virtualDisk.diskFilename.split(
                                                                                        "/"
                                                                                      )[1],
                                                                                    "data-collapsed":
                                                                                      "true",
                                                                                    "data-icon":
                                                                                      "<span class='mif-drive2 pt-1'></span>",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  {
                                                                                    staticClass:
                                                                                      "ml-5",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.buildVirtualDiskCaptionESXI(
                                                                                          virtualDisk
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            )
                                                                          }
                                                                        ),
                                                                        0
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]
                    )
                  }
                ),
                0
              ),
            ]
          ),
        ])
      : _vm._e(),
    _vm.source.exchangeSource
      ? _c("ul", { attrs: { "data-role": "treeview" } }, [
          _c(
            "li",
            {
              attrs: {
                "data-icon": "<span class='mif-server pt-1'/>",
                "data-caption":
                  "Server ( " + _vm.source.exchangeSource.name + " )",
              },
            },
            [
              _c(
                "ul",
                _vm._l(
                  _vm.source.exchangeSource.mailboxes.filter(
                    (x) => x.selected || x.indeterminate
                  ),
                  function (m) {
                    return _c("li", {
                      key: m.id,
                      attrs: {
                        "data-icon":
                          "<span class='" +
                          _vm.getMetroIcon(m.exchangeMailboxType) +
                          " pr-2'/>",
                        "data-caption": m.name,
                      },
                    })
                  }
                ),
                0
              ),
            ]
          ),
        ])
      : _vm._e(),
    _vm.source.dbSource
      ? _c("ul", { attrs: { "data-role": "treeview" } }, [
          _c("li", { staticClass: "p-1" }, [
            _c("label", [
              _c("span", { staticClass: "mif-server pr-2" }),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.source.dbSource.serverName +
                      "( " +
                      _vm.source.dbSource.serverVersion +
                      " ) "
                  )
                ),
              ]),
            ]),
            _c(
              "ul",
              _vm._l(
                _vm.source.dbSource.databases.filter(
                  (x) => x.selected || x.indeterminate
                ),
                function (db) {
                  return _c(
                    "li",
                    { key: db.name, attrs: { "data-collapsed": "true" } },
                    [
                      _c("span", { staticClass: "mif-database pr-2" }),
                      _c("span", [_vm._v(_vm._s(db.name))]),
                    ]
                  )
                }
              ),
              0
            ),
          ]),
        ])
      : _vm._e(),
    _vm.source.folderSource || _vm.source.networkSource
      ? _c(
          "ul",
          _vm._l(
            _vm.source.type == _vm.$root.PlatformsTypesEnum.Network
              ? _vm.source.networkSource.paths
              : _vm.source.folderSource.paths,
            function (res) {
              return _c(
                "li",
                {
                  key: res.path,
                  staticClass: "d-flex",
                  staticStyle: { "list-style-type": "none" },
                  attrs: { title: res.path },
                },
                [
                  _c("span", {
                    staticClass: "mr-2",
                    class: _vm.getMetroIcon(res.type, res.path),
                  }),
                  _c("span", [_vm._v(_vm._s(res.path))]),
                ]
              )
            }
          ),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }