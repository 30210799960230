const patchCategories = Object.freeze({
          "0fa1201d-4330-4fa8-8ae9-b877473b6441": "Security Updates",
          "e0789628-ce08-4437-be74-2495b842f43b": "Definition Updates",
          "28bc880e-0592-4cbf-8f95-c79b17911d5f": "Update Rollups",
          "68c5b0a3-d1a6-4553-ae49-01d3a7827828": "Service Packs",
          "b54e7d24-7add-428f-8b75-90a396fa584f": "Feature Packs",
          "cd5ffd1e-e932-4e3a-bf74-18bf0b1bbd83": "Updates",
          "e6cf1350-c01b-414d-a61f-263d14d133b4": "Critical Updates",
          "ebfc1fc5-71a4-4f7b-9aca-3b9a503104a0": "Hardware drivers updates",
          "b4832bd8-e735-4761-8daf-37f882276dab": "Tools updates",
          "3689bdc8-b205-4af4-8d4a-a63924c5e9d5": "Upgrades"
});

export default patchCategories;