<template>
	<div v-if="friendlyObj">
		<!--<table v-if="isTable">
			<tr v-for="(prop) in Object.keys(friendlyObj)">
				<td><strong>{{ $t(prop) }} : </strong></td>
				<td class="pl-4"><span>{{ friendlyObj[prop] }}</span></td>
				<td v-if="withCopyButton">
					<button class="mif-copy button bg-transparent ml-auto" @click="copy(friendlyObj[prop])"
                    :title="$t('Click to copy to clipboard')" />
				</td>
			</tr>
		</table>

		<div v-else>-->
			<div v-for="(prop) in Object.keys(friendlyObj)" class="d-flex">
				<span class="cell-4 d-flex flex-align-center p-0"><strong>{{ $t(prop) }}</strong></span>
				<span class="cell-7 d-flex flex-align-center p-0">{{ friendlyObj[prop] }}</span>
				<span v-if="withCopyButton" class="cell-1 p-0">
					<button class="mif-copy button bg-transparent ml-auto" @click="copy(friendlyObj[prop])" :title="$t('Click to copy to clipboard')" />
				</span>
			</div>
		<!--</div>-->
	</div>
</template>
<script>
import { getFriendlyObject } from "../../../../public/assets/js/PropertiesLabels";
export default {
	name: "ObjectView",
	props: {
		obj: {
			type: Object,
			required: true
		},
		withCopyButton:{
			type:Boolean,
			default : false
		},
		/*isTable:{
			type:Boolean,
			default : false
		}*/
	},
	data() {
		return {
			friendlyObj: null,
		}
	},
	created() {
		this.friendlyObj=getFriendlyObject(this.obj)[1];
	},
	methods:{
		async copy(value) {
            await navigator.clipboard.writeText(value);
            console.log(navigator.clipboard);
            console.log(await navigator.clipboard.read());
            console.log(await navigator.clipboard.readText());
            this.$root.toast(this.$t("Copied to clipboard"), 2000, "info");
        },
	}
}
</script>