var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog light w-100-sm w-100-md w-75-lg w-50-xl h-75",
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.$root.$emit("CLOSESOURCEDIALOG")
        },
      },
    },
    [
      _c("DialogTitle", {
        attrs: {
          title: _vm.$t("Configure Source"),
          defaultTitle: _vm.$t("Configure Source"),
          subTitle: _vm.source.type_name,
        },
        on: {
          closeDialog: function ($event) {
            return _vm.$root.$emit("CLOSESOURCEDIALOG")
          },
        },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step == _vm.STEP_CHOOSE_MAILBOXES,
              expression: "step == STEP_CHOOSE_MAILBOXES",
            },
          ],
          staticClass: "dialog-content",
        },
        [
          _c("SARepoSelect", {
            attrs: {
              type: _vm.source.type,
              idSA: _vm.source.id_service_account,
              idRepo: null,
            },
            on: { sendSATestResult: _vm.sendSATestResult },
          }),
          _vm.wait || _vm.saTest == _vm.SA_TEST_FAILED
            ? _c("div", { staticClass: "form-group mt-2" }, [
                _vm.wait
                  ? _c("h6", [
                      _c("span", { staticClass: "mif-spinner2 ani-spin ml-2" }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("Listing resources from Exchange 365"))
                        ),
                      ]),
                    ])
                  : _c("h6", [_vm._v(_vm._s(_vm.$t("No mailbox found")))]),
              ])
            : _vm._e(),
          _vm.saTest == _vm.SA_TEST_OK
            ? _c("SelectedResourcesLayout", {
                attrs: {
                  list: _vm.selectedNodes,
                  singleItem: false,
                  canCancel: true,
                  buttonText: _vm.$t("Select Exchange Mailboxes to backup"),
                  isDisabled: false,
                  heightPercentage: 40,
                },
                on: {
                  clickFunction: function ($event) {
                    return _vm.toggleFileSystemSidebar()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step == _vm.STEP_BACKUP_MODE_OPTIONS,
              expression: "step == STEP_BACKUP_MODE_OPTIONS",
            },
          ],
          staticClass: "dialog-content",
        },
        [
          _c("h6", { staticClass: "form-group text-bold" }, [
            _vm._v(_vm._s(_vm.$t("Backup mode"))),
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _c("label", [_vm._v(_vm._s(_vm.$t("Type")) + ":")]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.backupType,
                    expression: "source.options.backupType",
                  },
                ],
                attrs: { "data-role": "select", "data-filter": "false" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.source.options,
                      "backupType",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              _vm._l(
                Object.keys(_vm.$root.selectValues.backupTypes),
                function (key) {
                  return _c("option", { key: key, domProps: { value: key } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t(_vm.$root.selectValues.backupTypes[key]))
                    ),
                  ])
                }
              ),
              0
            ),
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _c("label", [_vm._v(_vm._s(_vm.$t("Number of copies")) + ":")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.source.options.nCopies,
                  expression: "source.options.nCopies",
                },
              ],
              staticClass: "w-25 ml-2",
              attrs: {
                type: "text",
                "data-role": "spinner",
                id: "nCopiesSpinner",
                "data-default-value": "1",
                "data-min-value": "1",
                "data-max-value": "365",
              },
              domProps: { value: _vm.source.options.nCopies },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.source.options, "nCopies", $event.target.value)
                },
              },
            }),
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.source.options.outputFormat,
                  expression: "source.options.outputFormat",
                },
              ],
              staticClass: "form-check-input mb-1",
              attrs: {
                "data-role": "radio",
                type: "radio",
                value: "PST",
                "data-caption": _vm.$t(
                  "Export mailboxes to individual .pst files"
                ),
              },
              domProps: {
                checked: _vm._q(_vm.source.options.outputFormat, "PST"),
              },
              on: {
                change: function ($event) {
                  return _vm.$set(_vm.source.options, "outputFormat", "PST")
                },
              },
            }),
            _c("br"),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.source.options.outputFormat,
                  expression: "source.options.outputFormat",
                },
              ],
              staticClass: "form-check-input mb-1",
              attrs: {
                "data-role": "radio",
                disabled:
                  _vm.source.type ==
                  _vm.$root.PlatformsTypesEnum.Microsoft_ExchangeOnPremises,
                type: "radio",
                value: "EML",
                "data-caption": _vm.$t(
                  "Export mailboxes to individual .eml/.ics/.vcf files"
                ),
              },
              domProps: {
                checked: _vm._q(_vm.source.options.outputFormat, "EML"),
              },
              on: {
                change: function ($event) {
                  return _vm.$set(_vm.source.options, "outputFormat", "EML")
                },
              },
            }),
            _c("br"),
            _vm.source.type !=
            _vm.$root.PlatformsTypesEnum.Microsoft_Exchange365
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.source.options.outputFormat,
                      expression: "source.options.outputFormat",
                    },
                  ],
                  staticClass: "form-check-input mb-1",
                  attrs: {
                    "data-role": "radio",
                    type: "radio",
                    value: "EDB",
                    "data-caption": _vm.$t("Export Database Files (.edb)"),
                  },
                  domProps: {
                    checked: _vm._q(_vm.source.options.outputFormat, "EDB"),
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.source.options, "outputFormat", "EDB")
                    },
                  },
                })
              : _vm._e(),
            _c("br"),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step == _vm.STEP_OPTIONS,
              expression: "step == STEP_OPTIONS",
            },
          ],
          staticClass: "dialog-content",
        },
        [
          _c("h6", { staticClass: "form-group text-bold" }, [
            _vm._v(_vm._s(_vm.$t("Backup Options"))),
          ]),
          _c("div", { staticClass: "form-group d-flex row" }, [
            _c("p", { staticClass: "w-50" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.includeInbox,
                    expression: "source.options.includeInbox",
                  },
                ],
                attrs: {
                  "data-role": "checkbox",
                  type: "checkbox",
                  checked: "",
                },
                domProps: {
                  checked: Array.isArray(_vm.source.options.includeInbox)
                    ? _vm._i(_vm.source.options.includeInbox, null) > -1
                    : _vm.source.options.includeInbox,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.source.options.includeInbox,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.source.options,
                            "includeInbox",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.source.options,
                            "includeInbox",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.source.options, "includeInbox", $$c)
                    }
                  },
                },
              }),
              _c("label", [
                _c("span", { staticClass: "mif-email mr-2" }),
                _c("span", [_vm._v(_vm._s(_vm.$t("Include Inbox")))]),
              ]),
            ]),
            _c("p", { staticClass: "w-50" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.includeSentItems,
                    expression: "source.options.includeSentItems",
                  },
                ],
                attrs: {
                  "data-role": "checkbox",
                  type: "checkbox",
                  checked: "",
                },
                domProps: {
                  checked: Array.isArray(_vm.source.options.includeSentItems)
                    ? _vm._i(_vm.source.options.includeSentItems, null) > -1
                    : _vm.source.options.includeSentItems,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.source.options.includeSentItems,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.source.options,
                            "includeSentItems",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.source.options,
                            "includeSentItems",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.source.options, "includeSentItems", $$c)
                    }
                  },
                },
              }),
              _c("label", [
                _c("span", { staticClass: "mif-email mr-2" }),
                _c("span", [_vm._v(_vm._s(_vm.$t("Include Sent Items")))]),
              ]),
            ]),
            _c("p", { staticClass: "w-50" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.includeDrafts,
                    expression: "source.options.includeDrafts",
                  },
                ],
                attrs: { "data-role": "checkbox", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.source.options.includeDrafts)
                    ? _vm._i(_vm.source.options.includeDrafts, null) > -1
                    : _vm.source.options.includeDrafts,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.source.options.includeDrafts,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.source.options,
                            "includeDrafts",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.source.options,
                            "includeDrafts",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.source.options, "includeDrafts", $$c)
                    }
                  },
                },
              }),
              _c("label", [
                _c("span", { staticClass: "mif-email mr-2" }),
                _c("span", [_vm._v(_vm._s(_vm.$t("Include Drafts")))]),
              ]),
            ]),
            _c("p", { staticClass: "w-50" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.includeOutbox,
                    expression: "source.options.includeOutbox",
                  },
                ],
                attrs: { "data-role": "checkbox", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.source.options.includeOutbox)
                    ? _vm._i(_vm.source.options.includeOutbox, null) > -1
                    : _vm.source.options.includeOutbox,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.source.options.includeOutbox,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.source.options,
                            "includeOutbox",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.source.options,
                            "includeOutbox",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.source.options, "includeOutbox", $$c)
                    }
                  },
                },
              }),
              _c("label", [
                _c("span", { staticClass: "mif-email mr-2" }),
                _c("span", [_vm._v(_vm._s(_vm.$t("Include Outbox")))]),
              ]),
            ]),
            _c("p", { staticClass: "w-50" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.includeDeletedItems,
                    expression: "source.options.includeDeletedItems",
                  },
                ],
                attrs: { "data-role": "checkbox", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.source.options.includeDeletedItems)
                    ? _vm._i(_vm.source.options.includeDeletedItems, null) > -1
                    : _vm.source.options.includeDeletedItems,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.source.options.includeDeletedItems,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.source.options,
                            "includeDeletedItems",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.source.options,
                            "includeDeletedItems",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.source.options, "includeDeletedItems", $$c)
                    }
                  },
                },
              }),
              _c("label", [
                _c("span", { staticClass: "mif-email mr-2" }),
                _c("span", [_vm._v(_vm._s(_vm.$t("Include Deleted Items")))]),
              ]),
            ]),
            _c("p", { staticClass: "w-50" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.includeJunkeMails,
                    expression: "source.options.includeJunkeMails",
                  },
                ],
                attrs: { "data-role": "checkbox", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.source.options.includeJunkeMails)
                    ? _vm._i(_vm.source.options.includeJunkeMails, null) > -1
                    : _vm.source.options.includeJunkeMails,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.source.options.includeJunkeMails,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.source.options,
                            "includeJunkeMails",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.source.options,
                            "includeJunkeMails",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.source.options, "includeJunkeMails", $$c)
                    }
                  },
                },
              }),
              _c("label", [
                _c("span", { staticClass: "mif-email mr-2" }),
                _c("span", [_vm._v(_vm._s(_vm.$t("Include Junke Mails")))]),
              ]),
            ]),
            _c("p", { staticClass: "w-50" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.includeArchiveMailbox,
                    expression: "source.options.includeArchiveMailbox",
                  },
                ],
                attrs: { "data-role": "checkbox", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(
                    _vm.source.options.includeArchiveMailbox
                  )
                    ? _vm._i(_vm.source.options.includeArchiveMailbox, null) >
                      -1
                    : _vm.source.options.includeArchiveMailbox,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.source.options.includeArchiveMailbox,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.source.options,
                            "includeArchiveMailbox",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.source.options,
                            "includeArchiveMailbox",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.source.options, "includeArchiveMailbox", $$c)
                    }
                  },
                },
              }),
              _c("label", [
                _c("span", { staticClass: "mif-email mr-2" }),
                _c("span", [_vm._v(_vm._s(_vm.$t("Include Archive")))]),
              ]),
            ]),
            _c("p", { staticClass: "w-50" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.includePublicFolders,
                    expression: "source.options.includePublicFolders",
                  },
                ],
                attrs: { "data-role": "checkbox", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(
                    _vm.source.options.includePublicFolders
                  )
                    ? _vm._i(_vm.source.options.includePublicFolders, null) > -1
                    : _vm.source.options.includePublicFolders,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.source.options.includePublicFolders,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.source.options,
                            "includePublicFolders",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.source.options,
                            "includePublicFolders",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.source.options, "includePublicFolders", $$c)
                    }
                  },
                },
              }),
              _c("label", [
                _c("span", { staticClass: "mif-email mr-2" }),
                _c("span", [_vm._v(_vm._s(_vm.$t("Include Public Folders")))]),
              ]),
            ]),
            _c("p", { staticClass: "w-50" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.includeCalendar,
                    expression: "source.options.includeCalendar",
                  },
                ],
                attrs: { "data-role": "checkbox", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.source.options.includeCalendar)
                    ? _vm._i(_vm.source.options.includeCalendar, null) > -1
                    : _vm.source.options.includeCalendar,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.source.options.includeCalendar,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.source.options,
                            "includeCalendar",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.source.options,
                            "includeCalendar",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.source.options, "includeCalendar", $$c)
                    }
                  },
                },
              }),
              _c("label", [
                _c("span", { staticClass: "mif-calendar mr-2" }),
                _c("span", [_vm._v(_vm._s(_vm.$t("Include Calendar")))]),
              ]),
            ]),
            _c("p", { staticClass: "w-50" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.includeContacts,
                    expression: "source.options.includeContacts",
                  },
                ],
                attrs: { "data-role": "checkbox", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.source.options.includeContacts)
                    ? _vm._i(_vm.source.options.includeContacts, null) > -1
                    : _vm.source.options.includeContacts,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.source.options.includeContacts,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.source.options,
                            "includeContacts",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.source.options,
                            "includeContacts",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.source.options, "includeContacts", $$c)
                    }
                  },
                },
              }),
              _c("label", [
                _c("span", { staticClass: "mif-contacts-dialer mr-2" }),
                _c("span", [_vm._v(_vm._s(_vm.$t("Include Contacts")))]),
              ]),
            ]),
            _c("p", { staticClass: "w-50" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.includeTasks,
                    expression: "source.options.includeTasks",
                  },
                ],
                attrs: { "data-role": "checkbox", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.source.options.includeTasks)
                    ? _vm._i(_vm.source.options.includeTasks, null) > -1
                    : _vm.source.options.includeTasks,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.source.options.includeTasks,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.source.options,
                            "includeTasks",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.source.options,
                            "includeTasks",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.source.options, "includeTasks", $$c)
                    }
                  },
                },
              }),
              _c("label", [
                _c("span", { staticClass: "mif-add_task mr-2" }),
                _c("span", [_vm._v(_vm._s(_vm.$t("Include Tasks")))]),
              ]),
            ]),
          ]),
          _vm.source.type != _vm.$root.PlatformsTypesEnum.Microsoft_Exchange365
            ? _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.source.options.truncateExchangeLogs,
                      expression: "source.options.truncateExchangeLogs",
                    },
                  ],
                  attrs: {
                    "data-role": "checkbox",
                    type: "checkbox",
                    "data-caption": _vm.$t("Truncate Exchange log"),
                    "data-validate": "required",
                    "data-prepend": "<span class='mif-server'></span>",
                  },
                  domProps: {
                    checked: Array.isArray(
                      _vm.source.options.truncateExchangeLogs
                    )
                      ? _vm._i(_vm.source.options.truncateExchangeLogs, null) >
                        -1
                      : _vm.source.options.truncateExchangeLogs,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.source.options.truncateExchangeLogs,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.source.options,
                              "truncateExchangeLogs",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.source.options,
                              "truncateExchangeLogs",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(
                          _vm.source.options,
                          "truncateExchangeLogs",
                          $$c
                        )
                      }
                    },
                  },
                }),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "form-group row d-flex flex-justify-between" },
            [
              _c("label", [
                _vm._v(_vm._s(_vm.$t("Number of main threads")) + ":"),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.nThreads,
                    expression: "source.options.nThreads",
                  },
                ],
                staticClass: "w-25",
                attrs: {
                  id: "nThreadsSpinner",
                  type: "text",
                  "data-role": "spinner",
                  "data-min-value": "1",
                  "data-max-value": "6",
                },
                domProps: { value: _vm.source.options.nThreads },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.source.options,
                      "nThreads",
                      $event.target.value
                    )
                  },
                },
              }),
            ]
          ),
          _c(
            "div",
            { staticClass: "form-group row d-flex flex-justify-between" },
            [
              _c("label", [
                _vm._v(_vm._s(_vm.$t("Number of download threads")) + ":"),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.nDownloadThreads,
                    expression: "source.options.nDownloadThreads",
                  },
                ],
                staticClass: "w-25",
                attrs: {
                  id: "nDownloadThreadsSpinner",
                  type: "text",
                  "data-role": "spinner",
                  "data-min-value": "1",
                  "data-max-value": "20",
                },
                domProps: { value: _vm.source.options.nDownloadThreads },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.source.options,
                      "nDownloadThreads",
                      $event.target.value
                    )
                  },
                },
              }),
            ]
          ),
          _c("div", { staticClass: "form-group pt-4" }, [
            _c("label", [_vm._v(_vm._s(_vm.$t("Buffer size")) + ":")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.source.options.buffer,
                  expression: "source.options.buffer",
                },
              ],
              staticClass: "w-25",
              attrs: {
                id: "bufferSpinner",
                type: "text",
                "data-role": "spinner",
                "data-min-value": "500",
                "data-max-value": "1500",
              },
              domProps: { value: _vm.source.options.buffer },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.source.options, "buffer", $event.target.value)
                },
              },
            }),
          ]),
          _c("div", { staticClass: "form-group pt-4" }, [
            _c("label", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "Number of bad items for considering failed a mailbox export"
                  )
                ) + ":"
              ),
            ]),
            _c("input", {
              staticClass: "w-25",
              attrs: {
                id: "badItemsLimitSpinner",
                type: "text",
                "data-role": "spinner",
                "data-min-value": "1",
                "data-max-value": "500",
              },
            }),
          ]),
        ]
      ),
      _c("div", { staticClass: "dialog-actions" }, [
        _c(
          "button",
          {
            staticClass: "button",
            attrs: { disabled: _vm.step == 0 },
            on: {
              click: function ($event) {
                _vm.step -= 1
              },
            },
          },
          [
            _c("span", { staticClass: "mif-arrow-left mr-1" }),
            _c("span", [_vm._v(_vm._s(_vm.$t("Prev")))]),
          ]
        ),
        _c(
          "button",
          {
            staticClass: "button",
            attrs: {
              disabled:
                _vm.source.id_service_account == -2 ||
                _vm.step == _vm.STEP_OPTIONS ||
                _vm.selectedNodes.length == 0,
            },
            on: {
              click: function ($event) {
                _vm.step += 1
              },
            },
          },
          [
            _c("span", [_vm._v(_vm._s(_vm.$t("Next")))]),
            _c("span", { staticClass: "mif-arrow-right ml-1" }),
          ]
        ),
        _c(
          "button",
          {
            staticClass: "button error float-right ml-2",
            on: {
              click: function ($event) {
                return _vm.$root.$emit("CLOSESOURCEDIALOG")
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("Cancel")))]
        ),
        _c(
          "button",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.step == _vm.STEP_OPTIONS,
                expression: "step == STEP_OPTIONS",
              },
            ],
            staticClass: "button primary ml-2 float-right",
            on: { click: _vm.saveSource },
          },
          [_c("span", [_vm._v(_vm._s(_vm.$t("Save")))])]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }