import { WebSocketInfo } from "./WebSocketInfo";
import { waitSeconds } from "../../public/assets/js/utilitiesmodule";

export class SignalRClient {
    constructor(url, idPC, emitFunction, translateFunction) {
        var signalRLibrary = require("@microsoft/signalr");
        this.emitFunction = emitFunction;
        this.translateFunction = translateFunction;

        this.signalr = new signalRLibrary.HubConnectionBuilder()
            .withUrl(url)
            .configureLogging(signalRLibrary.LogLevel.Information)
            .withAutomaticReconnect([1000, 10000, 10000])
            .build();

        this.signalr.qs = {
            "cll": "C_" + idPC,
            //'access_token': auth.getToken()
        };

        this.setSignalRConnectionCallbacks();

        this.setSignalRCallbacks();
    }

    //OK MA DA TESTARE
    async connect() {
        var connected = null;
        this.signalr.start()
            .then(() => {
                this.pc_isconnected = this.signalr.connectionState;
                connected = true;
            })
            .catch(e => {
                console.log(e)
                connected = false;
            });

        while (connected == null) {
            await waitSeconds(1);
        }

        return connected ? this : null;
    }

    /********************************* SOCKET CON SIGNALR - CHIAMATE ********************************************************************/
    //DA RISCRIVERE
    async call(message, arg1, arg2) {

            console.log("Chiamata SignalR " + message + " " + (arg1 != undefined ? arg1 : "") + " " + (arg2 != undefined ? arg2 : ""));
            //await waitSeconds(1);

            if (this.signalr == null || this.signalr.connectionState == "Disconnected") return null;

            let self = this;
            var seconds = 0;
            while (this.signalr.connectionState == "Connecting") {
                await waitSeconds(1);
                seconds += 1;
                if (seconds == 60) {
                    self.$root.$emit("realtimeMessages", this.translateFunction("Unable to connect to server."));
                    return false;
                }
            }


            self.responses[message] = null;
            self.wait = false;
            self.lastMessage = message;

            switch (message) {
                //CHIAMATE SENZA PARAMETRI SENZA RISPOSTA--------------------------------------------------------
                case "ConnectedAgent":
                    self.responses[message] = await this.signalr.invoke(message); // ritorna true se il pc è connesso, false altrimenti
                    break;
                case "StartSendInfo":
                case "StopSendInfo":
                case "UpdateAgent":
                    this.signalr.invoke(message);
                    self.responses[message] = true;
                    break;

                    //CHIAMATE SENZA PARAMETRI CON RISPOSTA-------------------------------------------------------- 
                case "GetVirtualMachinesHyperV":
                case "GetDiskInfo":
                    this.signalr.invoke(message);
                    break;

                    //CHIAMATE CON 1 PARAMETRO SENZA RISPOSTA-----------------------------------------------------
                case "CreateJob":
                case "DeleteJob":
                case "SaveJob":
                case "DeleteItem":
                case "RunJob":
                case "run":
                case "kill":
                case "CreateFolder":
                case "SendEmailTest":
                case "testserviceaccount":
                case "stopjob":
                    this.signalr.invoke(message, arg1);
                    self.responses[message] = true;
                    break;

                    //CHIAMATE CON 1 PARAMETRO CON RISPOSTA-----------------------------------------------------
                case "GetLog":
                case "GetDatabaseList":
                case "GetVirtualMachinesESXi":
                case "GetExchangeMailboxes":
                    this.signalr.invoke(message, arg1);
                    break;

                    //CHIAMATE CON 2 PARAMETRI SENZA RISPOSTA------------------------------------------------------
                case "ChangeVmStatusHyperV":
                case "EditBackupSource":
                case "EditJobName":
                case "RenameItem":
                    this.signalr.invoke(message, arg1, arg2);
                    self.responses[message] = true;
                    break;

                    //CHIAMATE CON 2 PARAMETRI CON RISPOSTA------------------------------------------------------
                case "GetDatastoreFoldersESXi":
                case "GetExploreFs":
                case "GetEventLogs":
                    this.signalr.invoke(message, arg1, arg2);
                    break;
            }

            //Per evitare il ritorno errato delle risposte, ogni chiamata attende la sua risposta (se esiste).
            //Laddove non esistesse una risposta, per default si mette self.responses[message] = true.
            //In caso di errore, viene restituito un messaggio tramite la chiamata realtimeMessages e self.responses[message] sarà uguale ad
            //una stringa vuota
            //Se sono passati 60 secondi e non ho ricevuto alcun messaggio dal server, ritorno una stringa vuota
            while (self.responses[message] == null) {
                await waitSeconds(1);
                seconds += 1;
                if (seconds == 60 && !self.wait) {
                    self.$root.$emit("realtimeMessages", this.$t("No response from server."));
                    return "";
                }
                self = this;
            }

            console.log("Risposta Chiamata SignalR " + message);
            return self.responses[message];

        }
        /********************************* SOCKET CON SIGNALR - DEFINIZIONE CONNESSIONE *****************************************************/

    //OK MA DA TESTARE
    setSignalRConnectionCallbacks() {
        this.signalr.onreconnecting(() => {
            this.pc_isconnected = this.signalr.connectionState;
        });

        this.signalr.onreconnected(() => {
            this.pc_isconnected = this.signalr.connectionState;
            this.signalr.invoke("StartSendInfo").catch(error => { console.log(error) });
        });

        this.signalr.onclose(() => {
            this.pc_isconnected = this.signalr.connectionState;
        });

    }

    setSignalRCallbacks() {
        //02/05/2024 : tolto WebSocketInfo.responseRequest
        /*Object.keys(WebSocketInfo.responseRequest).forEach(responseMessage => {
            self.signalr.on(responseMessage, function(data) {
                var requestMessage = WebSocketInfo.responseRequest[responseMessage].toUpperCase();
                if (requestMessage == undefined) {
                    alert("Trovata una risposta " + responseMessage + "senza richiesta!!!");
                    return;
                }
                this.responses[requestMessage] = WebSocketInfo.buildInfo(requestMessage, false, true, data);
            });
        });*/
    }

    //RISCRITTO SOPRA
    setSignalRCallbacks_old() {
        let self = this;
        //realtime
        this.signalr.on('addRealTimeToPage', function(cpu, ram, disk, processesList, agentVersion) {

            var processArray = [];
            processesList = processesList
                .split('|')
                .map(process => {
                    processArray = process.split(";");
                    process = {
                        "name": processArray[0],
                        "pid": parseInt(processArray[1]),
                        "username": processArray[2],
                        "ram": parseInt(processArray[3])
                    };
                    return process;
                });

            var data = {
                "CPU": cpu,
                "RAM": ram,
                "diskspeed": disk,
                "processesList": processesList,
                "agentVersion": agentVersion
            }

            //Verrà catturata da Computer.js
            self.$root.$emit("addRealTimeToPage", data);
        });

        this.signalr.on('addEventLogsToPage', function(eventLogLites) {

            self.responses["GetEventLogs"] = eventLogLites;
        });

        this.signalr.on('addDiskInfoToPage', function(disks) {
            self.responses["GetDiskInfo"] = disks;
        });

        this.signalr.on('addFilesystemToPage', function(directories, files, drives, network) {
            self.responses["GetExploreFs"] = {
                directories: directories,
                files: files,
                drives: drives,
                network: network,
                path: []
            }
        });

        this.signalr.on('addLogToPage', function(htmllog) {
            self.responses["GetLog"] = htmllog;
        });

        this.signalr.on('addSourceToPage', function(source) {
            self.responses["EditBackupSource"] = source;
        });

        //realtime
        this.signalr.on('addBackupsToPage', function(backups) {
            //Verrà catturata da Computer.js
            self.$root.$emit("addBackupsToPage", backups);

        });

        //03/04/2023 : La funzione "SendDsFoldersESXi" non viene mai chiamata.
        //Nel commento c'è la vecchia implementazione della callback all'interno di Backup.js
        this.signalr.on('addDsFoldersToPage', function(dsFolders) {
            self.responses["GetDatastoreFoldersESXi"] = dsFolders;

            /*

                    self.dataStore.datastore.folders = dsFolders;
                    var options = {
                        showTop: true,
                        timeout: 3000,
                        clsToast: "success"
                    }
                    window.Metro.toast.create(this.$t("Folders successfully retrieved"), null, null, null, options);
            
                    */
        });

        this.signalr.on("addDbObjectToPage", function(dbList) {
            self.responses["GetDatabaseList"] = dbList;
        });

        this.signalr.on("addVmESXiToPage", function(esxiObj) {
            self.responses["GetVirtualMachinesESXi"] = esxiObj;
        });

        this.signalr.on("addVmHypervToPage", function(vmHyperVLists) {
            self.responses["GetVirtualMachinesHyperV"] = vmHyperVLists;
        })

        this.signalr.on("addExObjectToPage", function(exObj) {
            self.responses["GetExchangeMailboxes"] = exObj;
        });

        //realtime
        this.signalr.on("realtimeMessages", data => {

            console.log("realtimeMessages " + data);

            //Gli errori vengono comunicati con questa chiamata e presentano la stringa |ERROR
            if (data.indexOf("|ERROR") != -1) {
                self.responses[self.lastMessage] = "";
                self.lastMessage = "";
            } else if (data.indexOf("|WAIT") != -1) {
                self.wait = true;
            }
            self.$root.$emit("realtimeMessages", data);
        });

        //realtime
        this.signalr.on("realtimeEvents", data => {
            console.log("realtimeEvents " + data);

            self.$root.$emit("realtimeEvents", data);
        });

    }



}