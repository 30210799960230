// src/services/WebRTCSession.js

export default class WebRTCSession {
          /**
           * Costruttore della classe WebRTCSession.
           * @param {string} signalingUrl - URL del server di segnalazione WebSocket.
           * @param {function} onRemoteStream - Callback da chiamare quando viene ricevuto uno stream remoto.
           * @param {function} onConnectionStateChange - Callback da chiamare quando cambia lo stato della connessione.
           */
          constructor(signalingUrl, onRemoteStream, onConnectionStateChange) {
                    this.signalingUrl = signalingUrl; // URL del server di segnalazione WebSocket
                    this.onRemoteStream = onRemoteStream; // Callback per gestire lo stream remoto
                    this.onConnectionStateChange = onConnectionStateChange; // Callback per gestire i cambiamenti di stato

                    this.pc = new RTCPeerConnection(this.getPeerConnectionConfig());
                    this.remoteStream = null;
                    this.signalingSocket = null;

                    this.logger = console; // Puoi sostituirlo con uno strumento di logging più sofisticato

                    // Event listeners per RTCPeerConnection
                    this.pc.onicecandidate = (event) => {
                              if (event.candidate) {
                                        this.sendIceCandidate(event.candidate);
                              }
                    };

                    this.pc.ontrack = (event) => {
                              this.handleRemoteTrack(event);
                    };

                    this.pc.onconnectionstatechange = () => {
                              this.logger.log(`Stato connessione cambiato a: ${this.pc.connectionState}`);
                              if (this.onConnectionStateChange) {
                                        this.onConnectionStateChange(this.pc.connectionState);
                              }
                              if (this.pc.connectionState === 'disconnected' || this.pc.connectionState === 'failed') {
                                        this.closeConnection();
                              }
                    };

                    // Inizializza la connessione al server di segnalazione
                    this.connectSignaling();
          }

          /**
           * Configurazione di RTCPeerConnection, inclusi i server ICE.
           */
          getPeerConnectionConfig() {
                    return {
                              iceServers: [
                                        { urls: 'stun:stun.l.google.com:19302' },
                                        // Puoi aggiungere server TURN qui se necessario
                                        // {
                                        //     urls: 'turn:your.turn.server',
                                        //     username: 'user',
                                        //     credential: 'pass'
                                        // }
                              ],
                              sdpSemantics: 'unified-plan'
                    };
          }

          /**
           * Connessione al server di segnalazione tramite WebSocket.
           */
          connectSignaling() {
                    this.signalingSocket = new WebSocket(this.signalingUrl);

                    this.signalingSocket.onopen = () => {
                              this.logger.log('Connesso al server di segnalazione.');
                              // Se necessario, implementa l'autenticazione o l'identificazione
                              // Ad esempio, invia un messaggio di autenticazione
                              // this.authenticate();
                    };

                    this.signalingSocket.onmessage = async (message) => {
                              const data = JSON.parse(message.data);
                              await this.handleSignalingMessage(data);
                    };

                    this.signalingSocket.onerror = (error) => {
                              this.logger.error('Errore nella connessione WebSocket:', error);
                    };

                    this.signalingSocket.onclose = () => {
                              this.logger.log('Connessione al server di segnalazione chiusa.');
                              this.closeConnection();
                    };
          }

          /**
           * Metodo opzionale per l'autenticazione al server di segnalazione.
           * Implementa questo metodo se il tuo server richiede autenticazione.
           */
          authenticate(username, password) {
                    // Implementa la logica di autenticazione se necessaria
                    // Ad esempio, invia username e password al server
                    const authMessage = {
                              type: 'authenticate',
                              username: username,
                              password: password
                    };
                    this.sendSignalingMessage(authMessage);
          }

          /**
           * Gestisce i messaggi ricevuti dal server di segnalazione.
           * @param {Object} data - Il messaggio di segnalazione ricevuto.
           */
          async handleSignalingMessage(data) {
                    switch (data.type) {
                              case 'offer':
                                        await this.handleOffer(data.offer);
                                        break;
                              case 'answer':
                                        await this.handleAnswer(data.answer);
                                        break;
                              case 'ice-candidate':
                                        await this.handleIceCandidate(data.candidate);
                                        break;
                              case 'authenticate-success':
                                        this.logger.log('Autenticazione riuscita.');
                                        break;
                              case 'authenticate-failure':
                                        this.logger.error('Autenticazione fallita.');
                                        this.closeConnection();
                                        break;
                              default:
                                        this.logger.warn('Tipo di messaggio sconosciuto:', data.type);
                    }
          }

          /**
           * Invia un messaggio di segnalazione tramite WebSocket.
           * @param {Object} message - Il messaggio da inviare.
           */
          sendSignalingMessage(message) {
                    if (this.signalingSocket && this.signalingSocket.readyState === WebSocket.OPEN) {
                              this.signalingSocket.send(JSON.stringify(message));
                    } else {
                              this.logger.error('Socket di segnalazione non aperto.');
                    }
          }

          /**
           * Crea un'offerta SDP e la invia al peer remoto tramite il server di segnalazione.
           */
          async createOffer() {
                    try {
                              const offer = await this.pc.createOffer();
                              await this.pc.setLocalDescription(offer);
                              this.logger.log('Offer creato:', offer);
                              // Invia l'offerta al peer remoto
                              this.sendSignalingMessage({ type: 'offer', offer: this.pc.localDescription });
                    } catch (error) {
                              this.logger.error('Errore nella creazione dell\'offer:', error);
                    }
          }

          /**
           * Gestisce l'offerta SDP ricevuta dal peer remoto.
           * @param {RTCSessionDescriptionInit} offer - L'offerta SDP ricevuta.
           */
          async handleOffer(offer) {
                    try {
                              await this.pc.setRemoteDescription(new RTCSessionDescription(offer));
                              const answer = await this.pc.createAnswer();
                              await this.pc.setLocalDescription(answer);
                              this.logger.log('Answer creato:', answer);
                              // Invia l'answer al peer remoto
                              this.sendSignalingMessage({ type: 'answer', answer: this.pc.localDescription });
                    } catch (error) {
                              this.logger.error('Errore nella gestione dell\'offer:', error);
                    }
          }

          /**
           * Gestisce la risposta SDP ricevuta dal peer remoto.
           * @param {RTCSessionDescriptionInit} answer - La risposta SDP ricevuta.
           */
          async handleAnswer(answer) {
                    try {
                              await this.pc.setRemoteDescription(new RTCSessionDescription(answer));
                    } catch (error) {
                              this.logger.error('Errore nella gestione dell\'answer:', error);
                    }
          }

          /**
           * Gestisce un candidato ICE ricevuto dal peer remoto.
           * @param {RTCIceCandidateInit} candidate - Il candidato ICE ricevuto.
           */
          async handleIceCandidate(candidate) {
                    try {
                              if (this.pc.remoteDescription && this.pc.remoteDescription.type) {
                                        await this.pc.addIceCandidate(new RTCIceCandidate(candidate));
                              } else {
                                        // Bufferizza i candidati ICE se la remote description non è ancora impostata
                                        this.remoteCandidates.push(candidate);
                              }
                    } catch (error) {
                              this.logger.error('Errore nell\'aggiungere il candidato ICE:', error);
                    }
          }

          /**
           * Invia un candidato ICE al peer remoto tramite il server di segnalazione.
           * @param {RTCIceCandidate} candidate - Il candidato ICE da inviare.
           */
          sendIceCandidate(candidate) {
                    this.logger.log('Invio candidato ICE:', candidate);
                    this.sendSignalingMessage({ type: 'ice-candidate', candidate: candidate });
          }

          /**
           * Gestisce il track remoto aggiunto al peer connection.
           * @param {RTCTrackEvent} event - L'evento del track.
           */
          handleRemoteTrack(event) {
                    this.logger.log('Track remoto aggiunto:', event.streams[0]);
                    if (!this.remoteStream) {
                              this.remoteStream = new MediaStream();
                              if (this.onRemoteStream) {
                                        this.onRemoteStream(this.remoteStream);
                              }
                    }
                    this.remoteStream.addTrack(event.track);
          }

          /**
           * Chiude tutte le connessioni e pulisce le risorse.
           */
          closeConnection() {
                    // Chiude la connessione peer
                    if (this.pc) {
                              this.pc.close();
                              this.pc = null;
                    }

                    // Chiude il socket di segnalazione
                    if (this.signalingSocket && this.signalingSocket.readyState === WebSocket.OPEN) {
                              this.signalingSocket.close();
                    }

                    // Cancella i candidati ICE remoti
                    this.remoteCandidates = [];

                    this.logger.log('Connessione WebRTC chiusa.');
          }
}
