var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "dialog light center text-small w-100-sm w-100-md w-75-lg w-50-xl h-vh-75",
    },
    [
      _c("DialogTitle", {
        attrs: {
          title: _vm.$t("Configure a restore"),
          defaultTitle: _vm.$t("Configure a restore"),
          subTitle: _vm.restore.destination.type_name,
        },
        on: {
          closeDialog: function ($event) {
            return _vm.$root.$emit("CLOSERESTOREDIALOG")
          },
        },
      }),
      _vm.step == _vm.STEP_SELECTBACKUPCOPY
        ? _c(
            "div",
            { staticClass: "dialog-content" },
            [
              _vm.isManual
                ? _c("div", { staticClass: "row h6 text-bold m-4" }, [
                    _c("div", { staticClass: "cell-12" }, [
                      _vm._v(_vm._s(_vm.$t("Choose source type"))),
                    ]),
                  ])
                : _vm._e(),
              _vm.isManual
                ? _c("div", { staticClass: "form-group" }, [
                    _c(
                      "ul",
                      {
                        attrs: {
                          "data-role": "listview",
                          "data-view": "icons",
                          "data-select-node": "true",
                          "data-selectable": "false",
                        },
                      },
                      _vm._l(
                        _vm.platformsTypes[_vm.restore.type]
                          .backupDestinationTypes,
                        function (sourceType) {
                          return _c("li", {
                            key: sourceType,
                            attrs: {
                              "data-icon": _vm.platformsTypes[sourceType].icon,
                              "data-caption":
                                _vm.platformsTypes[sourceType].name,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.setSourceType(sourceType)
                              },
                            },
                          })
                        }
                      ),
                      0
                    ),
                  ])
                : _vm._e(),
              ![-1, _vm.$root.PlatformsTypesEnum.FileFolder].includes(
                _vm.restore.source.type
              )
                ? _c("SARepoSelect", {
                    attrs: {
                      type: _vm.restore.source.type,
                      idSA: _vm.restore.source.id_service_account,
                      idRepo: null,
                    },
                    on: { sendSATestResult: _vm.sendSATestResult },
                  })
                : _vm._e(),
              _vm.isManual
                ? _c("SelectedResourcesLayout", {
                    attrs: {
                      list: _vm.sourceData.selectedNodes,
                      singleItem: true,
                      canCancel: false,
                      buttonText: _vm.$t("Select the folder of a backup copy"),
                      isDisabled:
                        _vm.restore.source.type !=
                          _vm.$root.PlatformsTypesEnum.FileFolder &&
                        _vm.sourceData.client == null,
                      heightPercentage: 30,
                    },
                    on: {
                      clickFunction: function ($event) {
                        return _vm.toggleFileSystemSidebar("CHOOSEBACKUPCOPY")
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.step == _vm.STEP_SELECTRESTOREOPTIONS
        ? _c(
            "div",
            { staticClass: "dialog-content" },
            [
              _c("div", { staticClass: "form-group" }, [
                _c("h6", { staticClass: "text-bold" }, [
                  _vm._v(_vm._s(_vm.$t("What is to be recovered?")) + ":"),
                ]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.restore.restoreType,
                        expression: "restore.restoreType",
                      },
                    ],
                    staticClass: "select w-100",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.restore,
                          "restoreType",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c(
                      "option",
                      {
                        attrs: {
                          disabled:
                            _vm.$root.isMicrosoft(_vm.restore.type) ||
                            _vm.isBackupWithZip,
                        },
                        domProps: { value: _vm.RESTORETYPE_SELECTSINGLEITEMS },
                      },
                      [_vm._v(_vm._s(_vm.$t("Single elements")))]
                    ),
                    _c(
                      "option",
                      { domProps: { value: _vm.RESTORETYPE_BUILDFULLCOPY } },
                      [_vm._v(_vm._s(_vm.$t("Recreate full copy from backup")))]
                    ),
                    _c(
                      "option",
                      {
                        domProps: { value: _vm.RESTORETYPE_BUILDSPECIFICCOPY },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("Recreate a specific copy from backup")
                          ) + " "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
              _vm.restore.restoreType == _vm.RESTORETYPE_SELECTSINGLEITEMS
                ? _c("SelectedResourcesLayout", {
                    attrs: {
                      list: _vm.restore.source.paths,
                      singleItem: false,
                      canCancel: true,
                      buttonText: _vm.$t("Select files and folders"),
                      isDisabled: _vm.iterationFolder == null,
                      heightPercentage: 30,
                    },
                    on: {
                      clickFunction: function ($event) {
                        return _vm.toggleFileSystemSidebar("SELECTSINGLEITEMS")
                      },
                    },
                  })
                : _vm.restore.restoreType > _vm.RESTORETYPE_SELECTSINGLEITEMS
                ? _c("div", { staticClass: "form-group" }, [
                    _c("h6", { staticClass: "text-bold" }, [
                      _vm._v(_vm._s(_vm.$t("Select a copy"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "overflow" },
                      _vm._l(_vm.fileConfig.iterations, function (iteration) {
                        return _c(
                          "div",
                          { key: iteration.index, staticClass: "d-flex mb-4" },
                          [
                            _c("input", {
                              staticClass: "mr-5",
                              attrs: {
                                name: "iter",
                                type: "radio",
                                "data-role": "radio",
                              },
                              domProps: {
                                checked: _vm.restore.index == iteration.index,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onIterationChanged(iteration)
                                },
                              },
                            }),
                            _c("div", { staticClass: "pr-4" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-justify-between w-100 pr-3",
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "badge inline bg-cobalt fg-white p-1",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.platformsTypes[
                                                iteration.sourceType
                                              ].name
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("span", {
                                      staticClass: "mif-arrow-right mx-1",
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "badge inline bg-cobalt fg-white p-1",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.platformsTypes[
                                                iteration.destinationType
                                              ].name
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "text-bold" }, [
                                    _c("label", { staticClass: "mr-2" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("Copy number")) + ":"
                                      ),
                                    ]),
                                    _vm._v(
                                      _vm._s(
                                        iteration.index +
                                          " / " +
                                          iteration.nCopies
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c("div", [
                                _c("small", [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(_vm.$t("Backup folder")) + " : "
                                    ),
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(iteration.iterationFolder)
                                  ),
                                ]),
                              ]),
                              _c("small", { staticClass: "text-bold" }, [
                                _c("label", { staticClass: "mr-2" }, [
                                  _vm._v(_vm._s(_vm.$t("Start date")) + ":"),
                                ]),
                                _vm._v(
                                  _vm._s(
                                    _vm._f("moment")(
                                      _vm._f("utcAsLocal")(iteration.lastStart),
                                      "MM/DD/YY hh:mm A"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step == _vm.STEP_FILTERS,
              expression: "step == STEP_FILTERS",
            },
          ],
          staticClass: "dialog-content",
        },
        [
          _c("div", { staticClass: "row h6 text-bold m-4" }, [
            _c("div", { staticClass: "cell-12" }, [
              _vm._v(_vm._s(_vm.$t("Filters"))),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "form-group row pb-5 d-flex flex-align-center" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.restore.source.options.extensionsFilterAction,
                    expression: "restore.source.options.extensionsFilterAction",
                  },
                ],
                attrs: {
                  type: "checkbox",
                  "data-role": "switch",
                  "data-material": "false",
                  "true-value": 1,
                  "false-value": 0,
                },
                domProps: {
                  checked: Array.isArray(
                    _vm.restore.source.options.extensionsFilterAction
                  )
                    ? _vm._i(
                        _vm.restore.source.options.extensionsFilterAction,
                        null
                      ) > -1
                    : _vm._q(
                        _vm.restore.source.options.extensionsFilterAction,
                        1
                      ),
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.restore.source.options.extensionsFilterAction,
                      $$el = $event.target,
                      $$c = $$el.checked ? 1 : 0
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.restore.source.options,
                            "extensionsFilterAction",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.restore.source.options,
                            "extensionsFilterAction",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(
                        _vm.restore.source.options,
                        "extensionsFilterAction",
                        $$c
                      )
                    }
                  },
                },
              }),
              _vm.restore.source.options.extensionsFilterAction == 0
                ? _c("label", [
                    _vm._v(
                      " " + _vm._s(_vm.$t("Exclude specified extensions")) + " "
                    ),
                  ])
                : _c("label", [
                    _vm._v(_vm._s(_vm.$t("Include only specified extensions"))),
                  ]),
            ]
          ),
          _vm._m(0),
          _c("div", { staticClass: "form-group row" }, [
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.restore.source.options.useFileSizeFilter,
                    expression: "restore.source.options.useFileSizeFilter",
                  },
                ],
                attrs: {
                  type: "checkbox",
                  "data-caption": _vm.$t("File size filter"),
                  "data-role": "checkbox",
                },
                domProps: {
                  checked: Array.isArray(
                    _vm.restore.source.options.useFileSizeFilter
                  )
                    ? _vm._i(
                        _vm.restore.source.options.useFileSizeFilter,
                        null
                      ) > -1
                    : _vm.restore.source.options.useFileSizeFilter,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.restore.source.options.useFileSizeFilter,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.restore.source.options,
                            "useFileSizeFilter",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.restore.source.options,
                            "useFileSizeFilter",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(
                        _vm.restore.source.options,
                        "useFileSizeFilter",
                        $$c
                      )
                    }
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "w-100 d-flex flex-align-center" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.restore.source.options.fileSizeFilterAction,
                      expression: "restore.source.options.fileSizeFilterAction",
                    },
                  ],
                  staticClass: "mr-2 h-100",
                  attrs: {
                    disabled: !_vm.restore.source.options.useFileSizeFilter,
                  },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.restore.source.options,
                        "fileSizeFilterAction",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(
                  _vm.$root.selectValues.fileSizeFilterAction,
                  function (value, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: index } },
                      [_vm._v(_vm._s(_vm.$t(value)))]
                    )
                  }
                ),
                0
              ),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.restore.source.options.fileSizeFilterCompare,
                      expression:
                        "restore.source.options.fileSizeFilterCompare",
                    },
                  ],
                  staticClass: "mr-2 h-100",
                  attrs: {
                    disabled: !_vm.restore.source.options.useFileSizeFilter,
                  },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.restore.source.options,
                        "fileSizeFilterCompare",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(
                  _vm.$root.selectValues.fileSizeFilterCompare,
                  function (value, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: index } },
                      [_vm._v(_vm._s(_vm.$t(value)))]
                    )
                  }
                ),
                0
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.restore.source.options.fileSize,
                    expression: "restore.source.options.fileSize",
                  },
                ],
                staticClass: "mr-2",
                staticStyle: { width: "50px" },
                attrs: {
                  type: "number",
                  disabled: !_vm.restore.source.options.useFileSizeFilter,
                },
                domProps: { value: _vm.restore.source.options.fileSize },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.restore.source.options,
                      "fileSize",
                      $event.target.value
                    )
                  },
                },
              }),
              _c("span", [_vm._v(" MB")]),
            ]),
          ]),
        ]
      ),
      _vm.step == _vm.STEP_EXCLUSIONS
        ? _c(
            "div",
            { staticClass: "dialog-content" },
            [
              _c("div", { staticClass: "row h6 text-bold m-4" }, [
                _c("div", { staticClass: "cell-12" }, [
                  _vm._v(_vm._s(_vm.$t("Exclusions"))),
                ]),
              ]),
              _c("div", { staticClass: "form-group text-bold" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "Click on an item to select subfolders or files to exclude"
                    )
                  ) + " "
                ),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "form-group overflow h-vh-20 bd-black border border-radius-10 border-size-1 mx-7",
                },
                [
                  _c(
                    "table",
                    { staticClass: "d-table-lg striped table text-small" },
                    [
                      _c(
                        "tbody",
                        _vm._l(_vm.restore.source.paths, function (res) {
                          return _c(
                            "tr",
                            {
                              key: res.id,
                              on: {
                                click: function ($event) {
                                  return _vm.openFileSystemSidebarFrom(res)
                                },
                              },
                            },
                            [
                              _c("td", { staticClass: "d-flex" }, [
                                _c("span", {
                                  staticClass: "px-2",
                                  class: _vm.getMetroIcon(res.type, res.path),
                                }),
                                _c(
                                  "span",
                                  { staticClass: "flex-self-center" },
                                  [_vm._v(_vm._s(res.path))]
                                ),
                              ]),
                              _c("td", [
                                _c("span", {
                                  staticClass: "mif-bin",
                                  on: {
                                    click: function ($event) {
                                      return _vm.$root.$emit("uncheckNode", res)
                                    },
                                  },
                                }),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ]
              ),
              _c("div", { staticClass: "form-group text-bold mt-6" }, [
                _vm._v(_vm._s(_vm.$t("Paths to exclude selected:"))),
              ]),
              _vm.restore.source.options.excludedPaths.length == 0
                ? _c("NoDataAvailable", {
                    attrs: { message: _vm.$t("No excluded path selected") },
                  })
                : _c(
                    "div",
                    {
                      staticClass:
                        "form-group overflow mt-4 h-vh-20 bd-black border border-radius-10 border-size-1",
                    },
                    [
                      _c(
                        "table",
                        { staticClass: "d-table-lg striped table text-small" },
                        [
                          _c(
                            "tbody",
                            _vm._l(
                              _vm.restore.source.options.excludedPaths,
                              function (res) {
                                return _c(
                                  "tr",
                                  { key: res.id, attrs: { title: res.path } },
                                  [
                                    _c("td", { staticClass: "d-flex" }, [
                                      _c("span", {
                                        staticClass: "px-2",
                                        class: _vm.getMetroIcon(
                                          res.type,
                                          res.path
                                        ),
                                      }),
                                      _c(
                                        "span",
                                        { attrs: { lass: "flex-self-center" } },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.cutText(res.path, 50))
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("td", [
                                      _c("span", {
                                        staticClass: "mif-bin",
                                        on: {
                                          click: function ($event) {
                                            return _vm.$root.$emit(
                                              "uncheckNode",
                                              res
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      ),
                    ]
                  ),
              _c("div", { staticClass: "form-group text-small flex-left" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.restore.source.options.excludeSubFolders,
                      expression: "restore.source.options.excludeSubFolders",
                    },
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(
                      _vm.restore.source.options.excludeSubFolders
                    )
                      ? _vm._i(
                          _vm.restore.source.options.excludeSubFolders,
                          null
                        ) > -1
                      : _vm.restore.source.options.excludeSubFolders,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.restore.source.options.excludeSubFolders,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.restore.source.options,
                              "excludeSubFolders",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.restore.source.options,
                              "excludeSubFolders",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(
                          _vm.restore.source.options,
                          "excludeSubFolders",
                          $$c
                        )
                      }
                    },
                  },
                }),
                _c("label", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "Do not include any subfolders (only files in the root folder will be copied)"
                        )
                      )
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.step == _vm.STEP_DESTINATION
        ? _c(
            "div",
            { staticClass: "dialog-content" },
            [
              _c("div", { staticClass: "row h6 text-bold m-4" }, [
                _c("div", { staticClass: "cell-12" }, [
                  _vm._v(_vm._s(_vm.$t("Configure destination"))),
                ]),
              ]),
              _vm.restore.type != _vm.$root.PlatformsTypesEnum.FileFolder
                ? _c("SARepoSelect", {
                    attrs: {
                      type: _vm.$root.isMicrosoft365(_vm.restore.type)
                        ? _vm.$root.getMicrosoftTypes()
                        : _vm.restore.type,
                      idSA: _vm.restore.destination.id_service_account,
                      idRepo: null,
                    },
                    on: { sendSATestResult: _vm.sendSATestResult },
                  })
                : _vm._e(),
              _vm.$root.isMicrosoft365(_vm.restore.type)
                ? _c("SelectedResourcesLayout", {
                    attrs: {
                      list: _vm.destinationData.selectedNodes,
                      singleItem: false,
                      canCancel: true,
                      buttonText: _vm.destinationData.buttonText,
                      isDisabled: _vm.destinationData.client == null,
                      heightPercentage: 30,
                    },
                    on: {
                      clickFunction: function ($event) {
                        return _vm.toggleFileSystemSidebar(
                          "SELECTITEMSTORESTORE"
                        )
                      },
                    },
                  })
                : _vm._e(),
              _vm.restore.type != _vm.$root.PlatformsTypesEnum.Microsoft_Teams
                ? _c("div", { staticClass: "form-group d-flex flex-column" }, [
                    _c("input", {
                      attrs: {
                        type: "radio",
                        "data-caption": _vm.$t(
                          "Restores items to their original position"
                        ),
                        "data-role": "radio",
                        name: "r1",
                        disabled: _vm.destinationData.client == null,
                        checked: "",
                      },
                      on: {
                        click: function ($event) {
                          _vm.restore.source.options.restoreInOriginalPosition = true
                        },
                      },
                    }),
                    _c("input", {
                      attrs: {
                        type: "radio",
                        "data-caption": _vm.$t(
                          "Restores items to the following folder"
                        ),
                        "data-role": "radio",
                        name: "r1",
                        disabled: _vm.destinationData.client == null,
                      },
                      on: {
                        click: function ($event) {
                          _vm.restore.source.options.restoreInOriginalPosition = false
                        },
                      },
                    }),
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: [
                        _vm.$root.PlatformsTypesEnum
                          .Microsoft_OneDriveForBusiness,
                        _vm.$root.PlatformsTypesEnum.Microsoft_SharePoint,
                      ].includes(_vm.restore.type),
                      expression:
                        "[$root.PlatformsTypesEnum.Microsoft_OneDriveForBusiness, $root.PlatformsTypesEnum.Microsoft_SharePoint].includes(restore.type)",
                    },
                  ],
                  staticClass: "form-group row pb-5 w-100",
                  attrs: {
                    disabled:
                      _vm.restore.source.options.restoreInOriginalPosition,
                  },
                },
                [
                  _c("label", [_vm._v(_vm._s(_vm.$t("Custom folder")) + ":")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.restore.source.options.restoreCustomFolder,
                        expression:
                          "restore.source.options.restoreCustomFolder",
                      },
                    ],
                    staticClass: "metro-input",
                    attrs: {
                      "data-role": "input",
                      type: "text",
                      placeholder: _vm.$t(
                        "Enter the name of the customised folder"
                      ),
                    },
                    domProps: {
                      value: _vm.restore.source.options.restoreCustomFolder,
                    },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.restore.source.options,
                          "restoreCustomFolder",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.$t("Required"))),
                  ]),
                ]
              ),
              [
                _vm.$root.PlatformsTypesEnum.FileFolder,
                _vm.$root.PlatformsTypesEnum.Network,
              ].includes(_vm.restore.type)
                ? _c("SelectedResourcesLayout", {
                    attrs: {
                      list: _vm.destinationData.selectedNodes,
                      singleItem: true,
                      canCancel: true,
                      buttonText: _vm.destinationData.buttonText,
                      isDisabled:
                        _vm.restore.source.options.restoreInOriginalPosition ||
                        (_vm.restore.type ==
                          _vm.$root.PlatformsTypesEnum.Network &&
                          _vm.destinationData.client == null),
                      heightPercentage: 30,
                    },
                    on: {
                      clickFunction: function ($event) {
                        return _vm.toggleFileSystemSidebar(
                          "SELECTDESTINATIONPATH"
                        )
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "dialog-actions" }, [
        _vm.waiting
          ? _c("div", [
              _c("span", {
                staticClass: "mif-spinner2 fg-primary ani-spin ml-5",
              }),
            ])
          : _vm._e(),
        _vm.step != _vm.STEP_SELECTBACKUPCOPY ||
        _vm.restore.source.type != _vm.$root.PlatformsTypesEnum.FileFolder
          ? _c("input", {
              staticClass: "button primary ml-2",
              attrs: {
                disabled: _vm.step == _vm.STEP_SELECTBACKUPCOPY,
                type: "button",
                value: _vm.$t("Prev"),
              },
              on: {
                click: function ($event) {
                  return _vm.setStep(_vm.step - 1)
                },
              },
            })
          : _vm._e(),
        _c("input", {
          staticClass: "button primary ml-2",
          attrs: {
            type: "button",
            value: _vm.$t("Next"),
            disabled: !_vm.enableNextButton,
          },
          on: {
            click: function ($event) {
              return _vm.setStep(_vm.step + 1)
            },
          },
        }),
        _c("input", {
          staticClass: "button primary ml-2",
          attrs: {
            type: "button",
            value: _vm.$t("Start"),
            disabled: !_vm.enableStartButton,
          },
          on: { click: _vm.startRestore },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-group d-flex" }, [
      _c("input", {
        staticClass: "flex-self-center",
        attrs: {
          id: "taginput",
          type: "text",
          "data-role": "tag-input",
          "data-tag-separator": ";",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }