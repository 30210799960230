<template>
    <div class="dialog light center text-small   w-100-sm w-100-md w-75-lg  w-50-xl draggable-item" v-cloak>

        <DialogTitle :title="serviceAccount.id==null ? $t('Add Service Account') : serviceAccount.name"
            :defaultTitle="serviceAccount.id==null ? $t('Add Service Account') : $t('Edit/Test Service Account')"
            :subTitle="serviceAccount.type_name" @closeDialog="$root.$emit('CLOSESERVICEACCOUNTDIALOG')" />


        <div class="dialog-content overlow">

            <!--------------------------FORM--------------------------------------------->

            <div class="form-group">
                <label>{{ $t("Name") }}</label>
                <input data-role="input" type="text" v-model="serviceAccount.name" maxlength="50" :placeholder="$t('Enter name')" data-prepend="<span class='mif-pencil'></span>" />
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>
            <div class="form-group row">
                <div class="cell-4">
                    <label>URL</label>
                    <input data-role="input" type="text" v-model="serviceAccount.url" :placeholder="$t('Enter Address')"
                        data-prepend="<span class='mif-cloud'></span>" />
                    <small class="text-muted">{{ $t("Required") }}</small>
                </div>
                <div class="cell-05 d-flex flex-align-center flex-justify-center"><span>:</span></div>
                <div class="cell-2">
                    <label>{{ $t("Port") }}</label>
                    <input data-role="input" class="metro-input" type="number" v-model="serviceAccount.port"
                        :placeholder="$t('Enter Port')" />
                    <small class="text-muted">{{ $t("Required") }}</small>
                </div>
                <div class="cell-05 d-flex flex-align-center flex-justify-center"><span>/</span></div>
                <div class="cell-5">
                    <label>{{ $t("Path") }}</label>
                    <input data-role="input" type="text" data-validate="required" v-model="serviceAccount.options.path">
                    <small class="text-muted">{{ $t("Required") }}</small>
                </div>
            </div>

            <div class="form-group row">
                <div class="cell-6">
                    <label>{{ $t("Username") }}</label>
                    <input data-role="input" v-model="serviceAccount.username" type="text" required
                        data-prepend="<span class='mif-user'></span>" />
                    <small class="text-muted">{{ $t("Required") }}</small>
                </div>
                <div class="cell-6">
                    <label>{{ $t("Password") }}</label>
                    <input data-role="input" class="metro-input" type="password" v-model="serviceAccount.password"
                        :placeholder="$t('Enter Password')" data-prepend="<span class='mif-lock'></span>" />
                    <small class="text-muted">{{ $t("Required") }}</small>
                </div>
            </div>

            <div class="form-group row">
                <div class="cell-2">
                    <label>{{ $t("Connection Type") }}</label>
                    <select class="select w-100" v-model="serviceAccount.options.connectionType">
                        <option value="ftp">FTP</option>
                        <option value="ftps">FTPS</option>
                        <option value="sftp">SFTP</option>
                    </select>
                </div>
                <div class="cell-3 pt-6">
                    <input data-role="checkbox" type="checkbox" :data-caption="$t('Passive Mode')"
                        data-validate="required" v-model="serviceAccount.options.passiveMode" required
                        data-prepend="<span class='mif-server'></span>" />
                </div>
            </div>


            <!-------------------------------SCELTA PC----------------------------------------->
            <div v-if="$session.getRoomPC() == null" class="form-group">
                <label>
                    <span>{{ $t("Select PC to Test Service Account") }}</span>
                </label>
                <select class="select cell-9" v-model="testPC" :disabled="$session.getPCList().length == 0">
                    <option :data-append="pc.note" v-for="pc in $session.getPCList()" :value="pc"
                        :disabled="!$session.isConnectedToSocket(pc.id)" v-bind:key="pc.id"> {{ pc.name }}
                    </option>
                </select>
            </div>
        </div>

        <!-------------------------------TEST ACCOUNT----------------------------------------->
        <div class="dialog-actions">
            <div v-if="tested == TEST_WAIT"><span class="mif-spinner2 ani-spin" /></div>
            <button class="button alert mr-2" @click="$root.$emit('CLOSESERVICEACCOUNTDIALOG')">{{ $t("Cancel")
                }}</button>
            <button class="button primary mr-2" @click="test" :disabled="!enableTest">
                 {{ $t("Test account") }}
            </button>
            <button class="button primary" :disabled="!enableSave" @click="$root.saveServiceAccount(serviceAccount)">
                {{ $t("Save") }}
            </button>
        </div>
    </div>
</template>

<script>
import { isValid } from "../../../../public/assets/js/utilitiesmodule";
import DialogTitle from "../../utils/DialogTitle.vue";
import { draggableMixin } from '../../../mixins/draggable-mixin'


export default {

    mixins: [draggableMixin],
    name: "FTPServiceAccount",
    components: {
        "DialogTitle": DialogTitle,
    },
    props: {
        serviceAccount: Object,
    },
    data() {
        return {
            // -1 : avviato
            // 0 : non avviatoo fallito
            // 1 : ok
            connected: 0,
            testPC: null,
            // -1 : avviato
            // 0 : non avviato o fallito
            // 1 : ok
            tested: 0,
            TEST_NOTOK : 0,
            TEST_WAIT : -1,
            TEST_OK : 1,
        }
    },
    computed: {
        allInputCompleted() {
            
            return this.serviceAccount.name != "" && (this.$session.getRoomPC() != null || this.testPC != null) && 
                this.serviceAccount.url != "" &&
                this.serviceAccount.username != "" &&
                this.serviceAccount.password != "" &&
                this.serviceAccount.options.path != "" &&
                this.serviceAccount.options.connectionType != "";
        },
        enableTest() {
            return this.allInputCompleted && this.tested == this.TEST_NOTOK;
        },
        enableSave() {
            return this.allInputCompleted && (this.tested == this.TEST_OK || this.tested== this.TEST_NOTOK && this.serviceAccount.id != null);
        }

    },
    created(){
        if(this.serviceAccount.options.lastTestPC !=null)
            this.testPC = this.$session.getPCList().find(pc => pc.id == this.serviceAccount.options.lastTestPC);
        },

    methods: {
        async test() {
            var testResult = await this.$root.testServiceAccount(this.serviceAccount, this.testPC);
            this.tested = testResult.okNumber;
            this.serviceAccount.options.lastTestPC = testResult.serviceAccount.options.lastTestPC;
            this.serviceAccount.options.accountSessionId = testResult.serviceAccount.options.accountSessionId;
            this.serviceAccount.options.path = isValid(testResult.serviceAccount.options.path) ? testResult.serviceAccount.options.path : "";
        }
    }
}

</script>