<template>
    <div class="w-100 overflow">
        <Navigation pageName="groups" />
        <div v-if="groups == null" class="d-flex flex-justify-center  p-5">
            <span class="mif-spinner2 fg-primary ani-spin mif-3x " />
        </div>

        <NoDataAvailable v-else-if="groups.length == 0" :message="$t('No groups available')" />

        <div v-else class="p-5">
            <table class="table table-border compact striped bg-white m-0  " :data-check=false data-check-name="chkBox"
                data-locale="it-IT" :data-table-search-title='$t("Search Groups")'
                data-cls-search="w-100-sm w-25-xl w-25-lg w-50-md" data-show-activity="true" data-cell-wrapper="false"
                data-role="table" data-rows="20" data-show-rows-steps="false"
                :data-show-pagination="groups.length > 20 ? 'true' : 'false'" data-horizontal-scroll="true">
                <thead>
                    <tr>
                        <th class="sortable-column">{{ $t("Name") }}</th>
                        <th class="sortable-column">{{ $t("Description") }}</th>
                        <th class="sortable-column" data-cls-column="text-center  no-wrap">{{ $t("Email") }}</th>
                        <th data-cls-column="text-center no-wrap" class="text-center no-wrap">{{ $t("Phone Number") }}
                        </th>
                        <th>{{ $t("Address") }}</th>
                        <th data-cls-column="text-center  no-wrap" class="text-center no-wrap">{{ $t("Devices") }}</th>
                        <th data-cls-column="text-center  no-wrap" class="text-center no-wrap">{{ $t("Users") }}</th>
                        <th></th>

                    </tr>
                </thead>
                <tbody>

                    <tr v-for="group in groups" class="mx-8 p-4  rounded m-6" v-bind:key="group.id_group">
                        <td>
                            <a class="text-bold text-left m-0 button flat-button" :href="'#' + group.id_group">
                                {{ group.name_group }}
                            </a>
                        </td>
                        <td>
                            {{ group.notes_group }}
                        </td>
                        <td>
                            {{ group.email_group }}
                        </td>
                        <td>
                            {{ group.phone_number }}
                        </td>
                        <td>
                            {{ group.address_group }}
                        </td>

                        <td>
                            {{ group.num_computers }}
                        </td>
                        <td>
                            {{ group.num_users }}
                        </td>
                        <td>
                            <ul class="listview flex-justify-end d-flex ">
                                <router-link :to="'#' + group.id_group" class="">
                                    <li class="button flat-button">
                                        <span class="mif-pencil"></span>
                                    </li>
                                </router-link>
                                <router-link v-if="group.attribute_group!=3" :to="'#' + group.id_group + '/delete'" class="">
                                    <li class="button flat-button">
                                        <span class="mif-bin"></span>
                                    </li>
                                </router-link>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</template>
<script>

import Navigation from './utils/Navigation.vue';
import NoDataAvailable from "./utils/NoDataAvailable.vue";
export default {
    name: "Groups",
    components: {
        Navigation,
        NoDataAvailable
    },
    watch: {
        '$route': {
            handler: function(to, from) {
                if (to.hash!=""&&to.hash.endsWith("/delete")) {
                    var groupID=to.hash.split("/")[0].replace("#", "");
                    var group=this.groups.find(g => g.id_group==groupID);
                    this.deleteGroup(group);
                    return;
                }
                if (to.hash!="") {
                    var id_group=to.hash.replace("#", "");
                    this.openDialogGroup(id_group)
                }
            },
            deep: true,
            immediate: true
        }
    },
    data() { return { groups: null, filteredgroups: null } },
    mounted: function() {
        this.$session.setRoomPC( null);
        this.$root.socket("SETPC", null);  
        this.groups=this.$session.getGroups();
        this.filteredgroups=this.groups;
        this.$root.$on("filteredString", (data) => this.filteredList(data));
        let self=this;
        this.$root.$on("refreshGroups", async () => {
            self.groups=await this.$api.getGroups();
            self.$session.setGroups(self.groups);
            self.filteredgroups=this.groups;
            window.location.reload();
        });
    },
    methods: {
        logNavigation(event) {
            console.log('Navigating to:', { name: 'Group', params: { idGroup: this.group.id_group } });
        },

        async openDialogGroup(id_group) {
            var group=await this.$api.getGroup(id_group);
            this.$root.$emit("OPENGROUPVIEWDIALOG", group, this.$session.getPCList(), this.$session.getUsers());
        },

        filteredList(data) {
            this.filteredgroups=this.groups;
            this.filteredgroups=this.groups.filter((group) => {
                return group.name_group.toLowerCase().includes(data.toLowerCase())
            });
        },


        async deleteGroup(group) {
            //Personalizzo la dialog da mostrare
            var dialogID=Math.floor(100000+Math.random()*900000).toString();
            var dialogTitle=this.$t("Delete Group");
            var dialogContent=this.$t('deleteConfirmation', { 0: group.name_group });

            var okText=this.$t("Yes");
            var okClass="mr-2 primary";
            var okResult=true;

            var cancelText=this.$t("No");
            var cancelClass="alert";
            var cancelResult=false;

            let self=this;
            this.$root.$on(dialogID, async ok => {
                if (!ok){
                    self.$root.$off(dialogID);
                    return;
                }
                ok=await self.$api.deleteGroup(group.id_group);

                //Se la cancellazione del gruppo non va a buon fine, riporto l'URL a /groups
                if (!ok){
                    self.$root.$off(dialogID);
                    return;
                }
                self.groups=await this.$api.getGroups();
                self.$session.setGroups(self.groups);
                self.$root.$off(dialogID);
                window.location.reload();
            });

            //Avvio la richiesta che mi risponderà con un $emit
            this.$root.openDialogActions(dialogID, dialogTitle, dialogContent, okText, okClass, okResult, cancelText, cancelClass, cancelResult)
        }
    }
}
</script>
