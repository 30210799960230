import Navigation from "../components/utils/Navigation";
import SARepoView from '../components/dialogs/view/SARepoView';
import NoDataAvailable from '../components/utils/NoDataAvailable';
import { platformsTypes } from "../../public/assets/js/utilitiesmodule";

export default {
    name: "Repositories",
    data() {
        return {
            repositories: null,
            serviceAccount: null,
            repository: null,
            selectedIndex: -1,

            platformsTypes: [],
            cloudTypes: [],
            microsoftTypes: [],
        };
    },
    props: {
        msg: String,
    },
    watch: {
        '$route': {
            handler: function(to) {

                if (to.hash == "")
                    return;
                var idRepository, repository;
                //Si vuole cancellare
                if (to.hash.startsWith("#delete:")) {
                    idRepository = to.hash.replace("#delete:", "");
                    repository = this.$session.getRepositoryWithID(idRepository);
                    this.deleteRepository(repository);
                    return;
                }

                if (to.hash.startsWith("#details:")) {
                    idRepository = to.hash.replace("#details:", "");
                    repository = this.$session.getRepositoryWithID(idRepository);
                    this.$root.$emit("OPENSAREPOVIEW", this.$session.getServiceAccountWithID(repository.id_service_account), repository);
                    return;
                }

                idRepository = to.hash.replace("#", "");
                repository = this.$session.getRepositoryWithID(idRepository);
                this.$root.$emit('OPENREPOSITORYDIALOG', repository);

            },
            deep: true,
            immediate: true
        }
    },
    beforeDestroy: async function() {
        this.repositories = null;
        this.$root.$off("REFRESHREPOSITORIES");
        this.$root.$off("socketEventConnected");
        this.$root.$off("socketEventDisconnected");
        //alert("destroy repositories.JS");
        //this.$root.checkConnectionTimerAction("stop");
        //this.$root.newPage("repositories", "destroy");
    },
    created: function() {
        this.$session.setRoomPC(null);
        this.$root.socket("SETPC", null);
        //alert("CREATED COMPUTERS.JS");
        //this.$root.newPage("repositories", "created");
        let self = this;
        this.repositories = self.$session.getRepositories();
        this.$root.$on("REFRESHREPOSITORIES", () => {
            self.repositories = self.$session.getRepositories();
        });

        this.platformsTypes = platformsTypes;
        this.cloudTypes = Object.keys(platformsTypes).filter(t => platformsTypes[t].mainType == 1);
        this.microsoftTypes = Object.keys(platformsTypes).filter(t => platformsTypes[t].mainType == 5);
        this.$root.$on("socketEventConnected", () => {
            self.$root.socketConnected = true;
            self.$root.checkConnectionTimerLight();
        });
        //"socketEventDisconnected" viene emesso se la connessione socket non è attiva
        this.$root.$on("socketEventDisconnected", () => {
            self.$root.socketConnected = false;
        });
        this.$root.checkSocket();

    },

    components: {
        NoDataAvailable,
        Navigation,
        SARepoView
    },
    methods: {
        async deleteRepository(repository) {
            var self = this;

            var dialogID = Math.floor(100000 + Math.random() * 900000).toString();
            var dialogTitle = this.$t("Delete Repository \"{0}\"", { 0: repository.name });
            var dialogContent = this.$t('Are you sure to delete repository \"{0}\"? ', { 0: repository.name });

            // var okText = this.$t("Yes");
            // var okClass = "mr-2 primary";
            // var okResult = true;

            // var cancelText = this.$t("No");
            // var cancelClass = "alert";
            // var cancelResult = false;

            this.$root.$on(dialogID, async ok => {
                if (!ok) {
                    self.$root.$off(dialogID);
                    self.$router.removeHash(); // prototype implementato in main.js
                    return;
                }

                ok = await self.$api.deleteRepository(repository.id, repository.name);

                if (!ok) {
                    self.$root.$off(dialogID);
                    self.$router.removeHash(); // prototype implementato in main.js
                    return;
                }
                self.$session.deleteRepository(repository.id);
                self.repositories.splice(self.repositories.findIndex(repo => repo.id == repository.id), 1);
                self.$root.$off(dialogID);
                self.$router.removeHash(); // prototype implementato in main.js
                location.reload();
            });

            //Avvio la richiesta che mi risponderà con un $emit
            this.$root.openYesNoDialog(dialogID, dialogTitle, dialogContent);

        },
        see(repo) {
            //apre un charm nella pagina
            this.serviceAccount = this.$session.getServiceAccounts().find(sa => sa.id == repo.id_service_account);
            this.repository = repo;
            window.Metro.charms.toggle("#reposelected");
        },


        closeCharm() {
            window.Metro.charms.toggle("#reposelected");
        }
    }
}