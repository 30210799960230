var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.pc && _vm.backupJob
    ? _c(
        "div",
        { staticClass: "w-100 overflow" },
        [
          _vm.pc && _vm.backupJob
            ? _c("Navigation", {
                attrs: {
                  pageName: "backup",
                  data: {
                    editing: _vm.editing,
                    backupName: _vm.backupJob.name,
                    pc: _vm.pc,
                  },
                },
              })
            : _vm._e(),
          !_vm.pc || !_vm.backupJob
            ? _c("div", { staticClass: "d-flex flex-justify-center p-10" }, [
                _c("span", {
                  staticClass: "mif-spinner2 fg-primary mif-3x ani-spin",
                }),
              ])
            : _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 col-xl-12 col-md-12" }, [
                  _c(
                    "ul",
                    {
                      attrs: {
                        "data-role": "tabs",
                        "data-tabs-type": "pills",
                        "data-expand": "true",
                        "data-cls-tabs": "bg-transparent p-4",
                      },
                    },
                    [
                      _c(
                        "li",
                        {
                          staticClass: "m-5 p-10 bg-lightgray",
                          class: _vm.saveBackupResult == 1 ? "disabled" : "",
                          staticStyle: { "min-width": "200px" },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "d-flex flex-justify-start m-0 p-0 h5 text-bold pb-3",
                              attrs: { href: "#source" },
                            },
                            [
                              _c("span", { staticClass: "mr-2" }, [
                                _vm._v("1."),
                              ]),
                              _vm._v(" " + _vm._s(_vm.$t("Source")) + " "),
                              _c("span", {
                                staticClass: "mif-chevron-thin-right",
                              }),
                            ]
                          ),
                          _c(
                            "a",
                            { staticClass: "p-0", attrs: { href: "#source" } },
                            [
                              _c("span", { staticClass: "mt-2" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "Select a source is the first step to creating your backup"
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "li",
                        {
                          staticClass: "m-5 p-10 bg-lightgray",
                          class:
                            _vm.backupJob.sources.length == 0 ? "disabled" : "",
                          staticStyle: { "min-width": "200px" },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "d-flex flex-justify-start m-0 p-0 h5 text-bold pb-3",
                              attrs: { href: "#destinations" },
                            },
                            [
                              _c("span", { staticClass: "mr-2" }, [
                                _vm._v("2."),
                              ]),
                              _vm._v(
                                " " + _vm._s(_vm.$t("Destinations")) + " "
                              ),
                              _c("span", {
                                staticClass: "mif-chevron-thin-right",
                              }),
                            ]
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "p-0",
                              attrs: { href: "#destinations" },
                            },
                            [
                              _vm.backupJob.sources.length == 0
                                ? _c("span", { staticClass: "mt-2" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "select a Source before select destination path"
                                        )
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.backupJob.sources.length != 0
                                ? _c("span", { staticClass: "mt-2" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("Select destination path"))
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "li",
                        {
                          staticClass: "m-5 p-10 bg-lightgray",
                          staticStyle: { "min-width": "200px" },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "d-flex flex-justify-start m-0 p-0 h5 text-bold pb-3",
                              attrs: { href: "#scheduling " },
                            },
                            [
                              _c("span", { staticClass: "mr-2" }, [
                                _vm._v("3."),
                              ]),
                              _vm._v(" " + _vm._s(_vm.$t("Scheduling")) + " "),
                              _c("span", {
                                staticClass: "mif-chevron-thin-right",
                              }),
                            ]
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "p-0",
                              attrs: { href: "#scheduling" },
                            },
                            [
                              _c("span", { staticClass: "mt-2" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "Create a schedule for repeatedly running the backup"
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "li",
                        {
                          staticClass: "m-5 p-10 bg-lightgray",
                          staticStyle: { "min-width": "200px" },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "d-flex flex-justify-start m-0 p-0 h5 text-bold pb-3",
                              attrs: { href: "#result" },
                            },
                            [
                              _c("span", { staticClass: "mr-2" }, [
                                _vm._v("4."),
                              ]),
                              _vm._v(" " + _vm._s(_vm.$t("Summary")) + " "),
                            ]
                          ),
                          _c(
                            "a",
                            { staticClass: "p-0", attrs: { href: "#result" } },
                            [
                              _c("span", { staticClass: "mt-2" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "Give your backup a name and save it"
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "p-2" }, [
                    _c("div", { attrs: { id: "source" } }, [
                      _c("section", { staticClass: "page-content p-4" }, [
                        _c(
                          "div",
                          { staticClass: "row d-flex flex-align-center" },
                          [
                            _c("span", [
                              _vm._v(
                                " " + _vm._s(_vm.$t("Add a backup source"))
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "ul",
                          {
                            attrs: {
                              "data-role": "listview",
                              "data-view": "icons-medium",
                              "data-select-node": "true",
                              "data-selectable": "false",
                            },
                          },
                          [
                            _c("li", {
                              attrs: {
                                "data-icon": "/assets/img/icons/drive.svg",
                                "data-caption":
                                  _vm.platformsTypes[
                                    _vm.$root.PlatformsTypesEnum.DriveImage
                                  ].name,
                                disabled: !_vm.isEnabled_source,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$root.$emit(
                                    "OPENSOURCEDIALOG",
                                    _vm.$root.PlatformsTypesEnum.DriveImage
                                  )
                                },
                              },
                            }),
                            _c("li", {
                              attrs: {
                                "data-icon":
                                  "/assets/img/icons/hyperv-microsoft.svg",
                                "data-caption":
                                  _vm.platformsTypes[
                                    _vm.$root.PlatformsTypesEnum.HyperV
                                  ].name,
                                disabled: !_vm.isEnabled_source,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$root.$emit(
                                    "OPENSOURCEDIALOG",
                                    _vm.$root.PlatformsTypesEnum.HyperV
                                  )
                                },
                              },
                            }),
                            _c("li", {
                              attrs: {
                                id: "microsoft_toggle",
                                "data-icon":
                                  "/assets/img/icons/Microsoft Exchange.svg",
                                "data-caption":
                                  _vm.platformsTypes[
                                    _vm.$root.PlatformsTypesEnum.Microsoft
                                  ].name,
                                disabled: _vm.backupJob.sources.length != 0,
                              },
                            }),
                            _c("li", {
                              attrs: {
                                "data-icon": "/assets/img/icons/vmware.png",
                                "data-caption":
                                  _vm.platformsTypes[
                                    _vm.$root.PlatformsTypesEnum.ESXi
                                  ].name,
                                disabled: !_vm.isEnabled_source,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$root.$emit(
                                    "OPENSOURCEDIALOG",
                                    _vm.$root.PlatformsTypesEnum.ESXi
                                  )
                                },
                              },
                            }),
                            _c("li", {
                              attrs: {
                                "data-icon": "/assets/img/icons/sqlserver.svg",
                                "data-caption":
                                  _vm.platformsTypes[
                                    _vm.$root.PlatformsTypesEnum
                                      .Database_SQLServer
                                  ].name,
                                disabled: !_vm.isEnabled_source,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$root.$emit(
                                    "OPENSOURCEDIALOG",
                                    _vm.$root.PlatformsTypesEnum
                                      .Database_SQLServer
                                  )
                                },
                              },
                            }),
                            _c("li", {
                              attrs: {
                                "data-icon":
                                  "/assets/img/icons/disk-network.svg",
                                disabled: !_vm.isEnabled_source,
                                "data-caption": _vm.$t("FileFolder"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$root.$emit(
                                    "OPENSOURCEDIALOG",
                                    _vm.$root.PlatformsTypesEnum.FileFolder
                                  )
                                },
                              },
                            }),
                            _c("li", {
                              attrs: {
                                "data-icon":
                                  "<span class='mif-network fg-black '>",
                                "data-caption":
                                  _vm.platformsTypes[
                                    _vm.$root.PlatformsTypesEnum.Network
                                  ].name,
                                disabled: !_vm.isEnabled_source,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$root.$emit(
                                    "OPENSOURCEDIALOG",
                                    _vm.$root.PlatformsTypesEnum.Network
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                        _c("div", { staticClass: "pos-relative" }, [
                          _c(
                            "div",
                            {
                              attrs: {
                                "data-role": "collapse",
                                "data-toggle-element": "#microsoft_toggle",
                                "data-collapsed": "true",
                              },
                            },
                            [
                              _c(
                                "ul",
                                {
                                  attrs: {
                                    "data-role": "listview",
                                    "data-view": "icons-medium",
                                  },
                                },
                                [
                                  _c(
                                    "li",
                                    { attrs: { "data-caption": "Microsoft" } },
                                    [
                                      _c(
                                        "ul",
                                        _vm._l(
                                          _vm.$root.getMicrosoftTypes(),
                                          function (t) {
                                            return _c("li", {
                                              key: t,
                                              attrs: {
                                                "data-icon":
                                                  _vm.platformsTypes[t].icon,
                                                "data-caption":
                                                  _vm.platformsTypes[t].name,
                                                disabled:
                                                  _vm.platformsTypes[t]
                                                    .needSocketConnection &&
                                                  !_vm.$session.isConnectedToSocket(
                                                    _vm.pc.id
                                                  ),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$root.$emit(
                                                    "OPENSOURCEDIALOG",
                                                    t
                                                  )
                                                },
                                              },
                                            })
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _vm.backupJob.sources.length != 0
                          ? _c(
                              "table",
                              {
                                staticClass:
                                  "table striped d-table-lg mx-3 my-6 bg-white table-bordered",
                              },
                              [
                                _c("thead", [
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          "data-sortable": "true",
                                          "data-sort-dir": "asc",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("Type")))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          "data-sortable": "true",
                                          "data-sort-dir": "asc",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("Selected Items")))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          "data-sortable": "true",
                                          "data-sort-dir": "asc",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("Options")))]
                                    ),
                                    _c("td"),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(
                                    _vm.backupJob.sources,
                                    function (source, index) {
                                      return _c("tr", { key: index }, [
                                        _c("td", [
                                          _c("label", [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "badge inline bg-black fg-white p-1 mt-2",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$root.platformsTypes[
                                                      source.type
                                                    ].name
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                        _c(
                                          "td",
                                          [
                                            _c("SourceView", {
                                              attrs: { source: source },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("td", [
                                          source.options.rct
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "badge inline fg-white m-1",
                                                  class: {
                                                    "bg-dark":
                                                      source.options.rct == "1",
                                                    "bg-darkGray":
                                                      source.options.rct == "2",
                                                  },
                                                },
                                                [_vm._v(" RCT")]
                                              )
                                            : _vm._e(),
                                          source.enableCompression != undefined
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "badge inline fg-white m-1",
                                                  class:
                                                    source.enableCompression
                                                      ? "bg-dark"
                                                      : "bg-darkGray",
                                                },
                                                [_vm._v("ZIP ")]
                                              )
                                            : _vm._e(),
                                          source.options.disableDasdIo !=
                                          undefined
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "badge inline fg-white m-1",
                                                  class: source.options
                                                    .disableDasdIo
                                                    ? "bg-dark"
                                                    : "bg-darkGray",
                                                },
                                                [_vm._v(" DASD")]
                                              )
                                            : _vm._e(),
                                          source.options.backupMode
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "badge inline fg-white bg-dark m-1",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        source.options
                                                          .backupMode
                                                      )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          source.options.backupType != undefined
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "badge inline fg-white m-1",
                                                  class: {
                                                    "bg-dark":
                                                      source.options.backupType,
                                                    "bg-darkGray":
                                                      !source.options
                                                        .backupType,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        source.options.backupType.toUpperCase()
                                                      )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          source.options.outputFormat
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "badge inline fg-white m-1 bg-dark",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        source.options.outputFormat.toUpperCase()
                                                      )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          source.options.backupLog
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "badge inline fg-white m-1 bg-dark",
                                                },
                                                [_vm._v("LOG")]
                                              )
                                            : _vm._e(),
                                        ]),
                                        _c(
                                          "td",
                                          { staticClass: "text-right" },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "button flat-button",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$root.$emit(
                                                      "OPENSOURCEDIALOG",
                                                      source
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass: "mif-pencil",
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "button flat-button",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteSource(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass: "mif-bin",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _c("div", { attrs: { id: "destinations" } }, [
                      _c("section", { staticClass: "page-content p-4" }, [
                        _c(
                          "div",
                          { staticClass: "row d-flex flex-align-center" },
                          [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "Add one or more backup destinations"
                                    )
                                  )
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "ul",
                          {
                            attrs: {
                              "data-role": "listview",
                              "data-view": "icons-medium",
                              "data-select-node": "true",
                              "data-selectable": "false",
                            },
                          },
                          [
                            _c("li", {
                              attrs: {
                                "data-icon":
                                  "<span class='mif-cloud fg-black'>",
                                "data-caption": _vm.$t("Cloud"),
                                id: "cloud_toggle",
                              },
                            }),
                            _c("li", {
                              attrs: {
                                disabled: !_vm.$session.isConnectedToSocket(
                                  _vm.pc.id
                                ),
                                "data-icon":
                                  "/assets/img/icons/disk-network.svg",
                                "data-caption": _vm.$t("FileFolder"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$root.$emit(
                                    "OPENDESTINATIONDIALOG",
                                    8
                                  )
                                },
                              },
                            }),
                            _c("li", {
                              attrs: {
                                disabled: !_vm.$session.isConnectedToSocket(
                                  _vm.pc.id
                                ),
                                "data-icon":
                                  "<span class='mif-network fg-black '>",
                                "data-caption": _vm.$t("Network"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$root.$emit(
                                    "OPENDESTINATIONDIALOG",
                                    81
                                  )
                                },
                              },
                            }),
                            _c("li", {
                              attrs: {
                                disabled: !_vm.$session.isConnectedToSocket(
                                  _vm.pc.id
                                ),
                                "data-icon": "/assets/img/icons/ftp-icon.png",
                                "data-caption": _vm.$t("FTP"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$root.$emit(
                                    "OPENDESTINATIONDIALOG",
                                    2
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                        _c("div", { staticClass: "pos-relative" }, [
                          _c(
                            "div",
                            {
                              attrs: {
                                "data-role": "collapse",
                                "data-toggle-element": "#cloud_toggle",
                                "data-collapsed": "true",
                              },
                            },
                            [
                              _c(
                                "ul",
                                {
                                  attrs: {
                                    "data-role": "listview",
                                    "data-view": "tiles",
                                  },
                                },
                                _vm._l(_vm.$root.getCloudTypes(), function (t) {
                                  return _c("li", {
                                    key: t,
                                    attrs: {
                                      "data-icon": _vm.platformsTypes[t].icon,
                                      "data-caption":
                                        _vm.platformsTypes[t].name,
                                      disabled:
                                        _vm.platformsTypes[t]
                                          .needSocketConnection &&
                                        !_vm.$session.isConnectedToSocket(
                                          _vm.pc.id
                                        ),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$root.$emit(
                                          "OPENDESTINATIONDIALOG",
                                          t
                                        )
                                      },
                                    },
                                  })
                                }),
                                0
                              ),
                            ]
                          ),
                        ]),
                        _vm.backupJob.destinations.length != 0
                          ? _c(
                              "table",
                              {
                                staticClass:
                                  "table striped d-table-lg mx-3 my-6 bg-white table-bordered",
                              },
                              [
                                _c("thead", [
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          "data-sortable": "true",
                                          "data-sort-dir": "asc",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("Type")))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          "data-sortable": "true",
                                          "data-sort-dir": "asc",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("Selected Items")))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          "data-sortable": "true",
                                          "data-sort-dir": "asc",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("Options")))]
                                    ),
                                    _c("td"),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(
                                    _vm.backupJob.destinations,
                                    function (destination, index) {
                                      return _c("tr", { key: index }, [
                                        _c("td", [
                                          _c("label", [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "badge inline bg-black fg-white p-1 mt-2",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$root.platformsTypes[
                                                        destination.type
                                                      ].name
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            " " + _vm._s(destination.path) + " "
                                          ),
                                        ]),
                                        _c("td", [
                                          destination.options.rct
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "badge inline fg-white m-1",
                                                  class: {
                                                    "bg-dark":
                                                      destination.options.rct ==
                                                      "1",
                                                    "bg-darkGray":
                                                      destination.options.rct ==
                                                      "2",
                                                  },
                                                },
                                                [_vm._v(" RCT")]
                                              )
                                            : _vm._e(),
                                          destination.enableCompression !=
                                          undefined
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "badge inline fg-white m-1",
                                                  class:
                                                    destination.enableCompression
                                                      ? "bg-dark"
                                                      : "bg-darkGray",
                                                },
                                                [_vm._v("ZIP ")]
                                              )
                                            : _vm._e(),
                                          destination.options.disableDasdIo !=
                                          undefined
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "badge inline fg-white m-1",
                                                  class: destination.options
                                                    .disableDasdIo
                                                    ? "bg-dark"
                                                    : "bg-darkGray",
                                                },
                                                [_vm._v(" DASD")]
                                              )
                                            : _vm._e(),
                                          destination.options.backupMode
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "badge inline fg-white bg-dark m-1",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        destination.options
                                                          .backupMode
                                                      )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          destination.options.backupType !=
                                          undefined
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "badge inline fg-white m-1",
                                                  class: {
                                                    "bg-dark":
                                                      _vm.source.options
                                                        .backupType,
                                                    "bg-darkGray":
                                                      !destination.options
                                                        .backupType,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        destination.options.backupType.toUpperCase()
                                                      )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          destination.options.outputFormat
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "badge inline fg-white m-1 bg-dark",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        destination.options.outputFormat.toUpperCase()
                                                      )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          destination.options.backupLog
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "badge inline fg-white m-1 bg-dark",
                                                },
                                                [_vm._v("LOG")]
                                              )
                                            : _vm._e(),
                                        ]),
                                        _c(
                                          "td",
                                          { staticClass: "text-right" },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "button flat-button",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$root.$emit(
                                                      "OPENDESTINATIONDIALOG",
                                                      destination
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass: "mif-pencil",
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "button flat-button",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteDestination(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass: "mif-bin",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _c("div", { attrs: { id: "scheduling" } }, [
                      _c("section", { staticClass: "overflow w-100" }, [
                        _c("div", { staticClass: "page-content p-4" }, [
                          _c(
                            "div",
                            {
                              attrs: {
                                "data-role": "accordion",
                                "data-one-frame": "true",
                                "data-show-active": "true",
                              },
                            },
                            [
                              _c("div", { staticClass: "frame active" }, [
                                _c("div", { staticClass: "heading h3 m-0" }, [
                                  _vm._v(_vm._s(_vm.$t("Saved Policies"))),
                                ]),
                                _c("div", { staticClass: "content" }, [
                                  _vm.$session.getPolicies() != null
                                    ? _c("div", { staticClass: "p-4 d-flex" }, [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.backupJob
                                                    .id_scheduling_policy,
                                                expression:
                                                  "backupJob.id_scheduling_policy",
                                              },
                                            ],
                                            staticClass:
                                              "select input w-auto p-1",
                                            on: {
                                              change: function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  _vm.backupJob,
                                                  "id_scheduling_policy",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "option",
                                              { attrs: { value: "-1" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "Select a saved Policy"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._l(
                                              _vm.$session.getPolicies(),
                                              function (policy) {
                                                return policy.id_type == 1
                                                  ? _c(
                                                      "option",
                                                      {
                                                        domProps: {
                                                          value: policy.id,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(policy.name) +
                                                            " (" +
                                                            _vm._s(
                                                              _vm.cronstrue.toString(
                                                                policy.policy
                                                                  .scheduling[0],
                                                                {
                                                                  locale:
                                                                    _vm.$i18n
                                                                      .locale,
                                                                  dayOfWeekStartIndexZero: false,
                                                                }
                                                              )
                                                            ) +
                                                            ")"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e()
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]),
                              _c("div", { staticClass: "frame" }, [
                                _c("div", { staticClass: "heading h3 m-0" }, [
                                  _vm._v(_vm._s(_vm.$t("New Policy"))),
                                ]),
                                _c("div", { staticClass: "content" }, [
                                  _c("div", { staticClass: "row P-2" }, [
                                    _c("div", { staticClass: "w-100" }, [
                                      _c(
                                        "ul",
                                        {
                                          staticStyle: { "z-index": "1" },
                                          attrs: {
                                            "data-role": "tabs",
                                            "data-tabs-type": "",
                                            "data-expand": "true",
                                            "data-cls-tabs": "bg-transparent",
                                          },
                                        },
                                        [
                                          _c("li", [
                                            _c(
                                              "a",
                                              {
                                                attrs: { href: "#minutes" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.toggleTab(
                                                      _vm.cronOptions.minutes
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("Minutes")) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("li", [
                                            _c(
                                              "a",
                                              {
                                                attrs: { href: "#hourly" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.toggleTab(
                                                      _vm.cronOptions.hourly
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("Hourly")) + " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("li", [
                                            _c(
                                              "a",
                                              {
                                                attrs: { href: "#daily" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.toggleTab(
                                                      _vm.cronOptions.daily
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("Daily")) + " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("li", [
                                            _c(
                                              "a",
                                              {
                                                attrs: { href: "#weekly" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.toggleTab(
                                                      _vm.cronOptions.weekly
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.$t("Weekly")))]
                                            ),
                                          ]),
                                          _c("li", [
                                            _c(
                                              "a",
                                              {
                                                attrs: { href: "#montly" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.toggleTab(
                                                      _vm.cronOptions.monthly
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("Monthly")) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("li", [
                                            _c(
                                              "a",
                                              {
                                                attrs: { href: "#yearly" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.toggleTab(
                                                      _vm.cronOptions.yearly
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("Yearly")) + " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "bg-white border no-border-top p-4",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "p-4",
                                              attrs: { id: "minutes" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-row align-items-center",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-lg-1" },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "col-form-label inline",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("Every")
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.cronOptions
                                                                  .minutes
                                                                  .value,
                                                              expression:
                                                                "cronOptions.minutes.value",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control select w-auto",
                                                          staticStyle: {
                                                            display: "inline",
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              var $$selectedVal =
                                                                Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      var val =
                                                                        "_value" in
                                                                        o
                                                                          ? o._value
                                                                          : o.value
                                                                      return val
                                                                    }
                                                                  )
                                                              _vm.$set(
                                                                _vm.cronOptions
                                                                  .minutes,
                                                                "value",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "1",
                                                              },
                                                            },
                                                            [_vm._v("1")]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "2",
                                                              },
                                                            },
                                                            [_vm._v("2")]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "3",
                                                              },
                                                            },
                                                            [_vm._v("3")]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "4",
                                                              },
                                                            },
                                                            [_vm._v("4")]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "5",
                                                              },
                                                            },
                                                            [_vm._v("5")]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "6",
                                                              },
                                                            },
                                                            [_vm._v("6")]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "10",
                                                              },
                                                            },
                                                            [_vm._v("10")]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "15",
                                                              },
                                                            },
                                                            [_vm._v("15")]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "20",
                                                              },
                                                            },
                                                            [_vm._v("20")]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "30",
                                                              },
                                                            },
                                                            [_vm._v("30")]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "col-form-label",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "minute(s)"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "p-4",
                                              attrs: { id: "hourly" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "card-body",
                                                  attrs: { id: "idb" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-inline",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-check-label",
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .cronOptions
                                                                    .hourly
                                                                    .radio,
                                                                expression:
                                                                  "cronOptions.hourly.radio",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-check-input mb-1",
                                                            attrs: {
                                                              "data-role":
                                                                "radio",
                                                              type: "radio",
                                                              name: "pnlDetails:pnlHourly:radioGroup",
                                                              value: "every/",
                                                            },
                                                            domProps: {
                                                              checked: _vm._q(
                                                                _vm.cronOptions
                                                                  .hourly.radio,
                                                                "every/"
                                                              ),
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  _vm
                                                                    .cronOptions
                                                                    .hourly,
                                                                  "radio",
                                                                  "every/"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t("Every")
                                                              ) +
                                                              " "
                                                          ),
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .cronOptions
                                                                      .hourly
                                                                      .value,
                                                                  expression:
                                                                    "cronOptions.hourly.value",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control ml-1 mr-1 select w-auto",
                                                              staticStyle: {
                                                                display:
                                                                  "inline",
                                                              },
                                                              attrs: {
                                                                name: "pnlDetails:pnlHourly:radioGroup:ddHours",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal =
                                                                      Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                    _vm.$set(
                                                                      _vm
                                                                        .cronOptions
                                                                        .hourly,
                                                                      "value",
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "1",
                                                                  },
                                                                },
                                                                [_vm._v("1")]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "2",
                                                                  },
                                                                },
                                                                [_vm._v("2")]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "3",
                                                                  },
                                                                },
                                                                [_vm._v("3")]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "4",
                                                                  },
                                                                },
                                                                [_vm._v("4")]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "6",
                                                                  },
                                                                },
                                                                [_vm._v("6")]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "12",
                                                                  },
                                                                },
                                                                [_vm._v("12")]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "hour(s)"
                                                                )
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-inline mt-3",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-check-label",
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .cronOptions
                                                                    .hourly
                                                                    .radio,
                                                                expression:
                                                                  "cronOptions.hourly.radio",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-check-input mb-1",
                                                            attrs: {
                                                              "data-role":
                                                                "radio",
                                                              type: "radio",
                                                              name: "pnlDetails:pnlHourly:radioGroup",
                                                              value: "at?",
                                                            },
                                                            domProps: {
                                                              checked: _vm._q(
                                                                _vm.cronOptions
                                                                  .hourly.radio,
                                                                "at?"
                                                              ),
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  _vm
                                                                    .cronOptions
                                                                    .hourly,
                                                                  "radio",
                                                                  "at?"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "Starts at"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-inline",
                                                            },
                                                            [
                                                              _c(
                                                                "select",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .cronOptions
                                                                          .hourly
                                                                          .at
                                                                          .hour,
                                                                      expression:
                                                                        "cronOptions.hourly.at.hour",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control mr-1 ml-1 select w-auto",
                                                                  staticStyle: {
                                                                    display:
                                                                      "inline",
                                                                  },
                                                                  attrs: {
                                                                    name: "pnlDetails:pnlHourly:radioGroup:pnlTime:hourPart",
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$selectedVal =
                                                                          Array.prototype.filter
                                                                            .call(
                                                                              $event
                                                                                .target
                                                                                .options,
                                                                              function (
                                                                                o
                                                                              ) {
                                                                                return o.selected
                                                                              }
                                                                            )
                                                                            .map(
                                                                              function (
                                                                                o
                                                                              ) {
                                                                                var val =
                                                                                  "_value" in
                                                                                  o
                                                                                    ? o._value
                                                                                    : o.value
                                                                                return val
                                                                              }
                                                                            )
                                                                        _vm.$set(
                                                                          _vm
                                                                            .cronOptions
                                                                            .hourly
                                                                            .at,
                                                                          "hour",
                                                                          $event
                                                                            .target
                                                                            .multiple
                                                                            ? $$selectedVal
                                                                            : $$selectedVal[0]
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                _vm._l(
                                                                  24,
                                                                  function (n) {
                                                                    return _c(
                                                                      "option",
                                                                      {
                                                                        key:
                                                                          n - 1,
                                                                        domProps:
                                                                          {
                                                                            value:
                                                                              n -
                                                                              1,
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              (
                                                                                n -
                                                                                1
                                                                              )
                                                                                .toString()
                                                                                .padStart(
                                                                                  2,
                                                                                  "0"
                                                                                )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              ),
                                                              _vm._v(" : "),
                                                              _c(
                                                                "select",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .cronOptions
                                                                          .hourly
                                                                          .at
                                                                          .minute,
                                                                      expression:
                                                                        "cronOptions.hourly.at.minute",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control ml-1 select w-auto",
                                                                  staticStyle: {
                                                                    display:
                                                                      "inline",
                                                                  },
                                                                  attrs: {
                                                                    name: "pnlDetails:pnlHourly:radioGroup:pnlTime:minutePart",
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$selectedVal =
                                                                          Array.prototype.filter
                                                                            .call(
                                                                              $event
                                                                                .target
                                                                                .options,
                                                                              function (
                                                                                o
                                                                              ) {
                                                                                return o.selected
                                                                              }
                                                                            )
                                                                            .map(
                                                                              function (
                                                                                o
                                                                              ) {
                                                                                var val =
                                                                                  "_value" in
                                                                                  o
                                                                                    ? o._value
                                                                                    : o.value
                                                                                return val
                                                                              }
                                                                            )
                                                                        _vm.$set(
                                                                          _vm
                                                                            .cronOptions
                                                                            .hourly
                                                                            .at,
                                                                          "minute",
                                                                          $event
                                                                            .target
                                                                            .multiple
                                                                            ? $$selectedVal
                                                                            : $$selectedVal[0]
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                _vm._l(
                                                                  60,
                                                                  function (n) {
                                                                    return _c(
                                                                      "option",
                                                                      {
                                                                        key:
                                                                          n - 1,
                                                                        domProps:
                                                                          {
                                                                            value:
                                                                              n -
                                                                              1,
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              (
                                                                                n -
                                                                                1
                                                                              )
                                                                                .toString()
                                                                                .padStart(
                                                                                  2,
                                                                                  "0"
                                                                                )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "p-4",
                                              attrs: { id: "daily" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "card-body",
                                                  attrs: { id: "idb" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-inline",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-check-label",
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .cronOptions
                                                                    .daily
                                                                    .radio,
                                                                expression:
                                                                  "cronOptions.daily.radio",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-check-input mb-1",
                                                            attrs: {
                                                              "data-role":
                                                                "radio",
                                                              type: "radio",
                                                              name: "pnlDetails:pnlDaily:radioGroup",
                                                              value:
                                                                "everyDay?",
                                                              checked:
                                                                "checked",
                                                            },
                                                            domProps: {
                                                              checked: _vm._q(
                                                                _vm.cronOptions
                                                                  .daily.radio,
                                                                "everyDay?"
                                                              ),
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  _vm
                                                                    .cronOptions
                                                                    .daily,
                                                                  "radio",
                                                                  "everyDay?"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "Everyday"
                                                                )
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-inline mt-3",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-check-input",
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .cronOptions
                                                                    .daily
                                                                    .radio,
                                                                expression:
                                                                  "cronOptions.daily.radio",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-check-input mb-1",
                                                            attrs: {
                                                              "data-role":
                                                                "radio",
                                                              type: "radio",
                                                              name: "pnlDetails:pnlDaily:radioGroup",
                                                              value:
                                                                "weekdays?",
                                                            },
                                                            domProps: {
                                                              checked: _vm._q(
                                                                _vm.cronOptions
                                                                  .daily.radio,
                                                                "weekdays?"
                                                              ),
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  _vm
                                                                    .cronOptions
                                                                    .daily,
                                                                  "radio",
                                                                  "weekdays?"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "Every weekday"
                                                                )
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-inline mt-4",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t("Starts at")
                                                          ) +
                                                          " : "
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-inline",
                                                        },
                                                        [
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .cronOptions
                                                                      .daily.at
                                                                      .hour,
                                                                  expression:
                                                                    "cronOptions.daily.at.hour",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control mr-1 ml-1 select w-auto",
                                                              staticStyle: {
                                                                display:
                                                                  "inline",
                                                              },
                                                              attrs: {
                                                                name: "pnlDetails:pnlDaily:pnlTime:hourPart",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal =
                                                                      Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                    _vm.$set(
                                                                      _vm
                                                                        .cronOptions
                                                                        .daily
                                                                        .at,
                                                                      "hour",
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            _vm._l(
                                                              24,
                                                              function (n) {
                                                                return _c(
                                                                  "option",
                                                                  {
                                                                    key: n - 1,
                                                                    domProps: {
                                                                      value:
                                                                        n - 1,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          (
                                                                            n -
                                                                            1
                                                                          )
                                                                            .toString()
                                                                            .padStart(
                                                                              2,
                                                                              "0"
                                                                            )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                          _vm._v(" : "),
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .cronOptions
                                                                      .daily.at
                                                                      .minute,
                                                                  expression:
                                                                    "cronOptions.daily.at.minute",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control ml-1 select w-auto",
                                                              staticStyle: {
                                                                display:
                                                                  "inline",
                                                              },
                                                              attrs: {
                                                                name: "pnlDetails:pnlDaily:pnlTime:minutePart",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal =
                                                                      Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                    _vm.$set(
                                                                      _vm
                                                                        .cronOptions
                                                                        .daily
                                                                        .at,
                                                                      "minute",
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            _vm._l(
                                                              60,
                                                              function (n) {
                                                                return _c(
                                                                  "option",
                                                                  {
                                                                    key: n - 1,
                                                                    domProps: {
                                                                      value:
                                                                        n - 1,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          (
                                                                            n -
                                                                            1
                                                                          )
                                                                            .toString()
                                                                            .padStart(
                                                                              2,
                                                                              "0"
                                                                            )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "p-4",
                                              attrs: { id: "weekly" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "card-body",
                                                  attrs: { id: "idb" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "row" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "col-3",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-inline",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "form-check-label",
                                                                },
                                                                [
                                                                  _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .cronOptions
                                                                              .weekly
                                                                              .days,
                                                                          expression:
                                                                            "cronOptions.weekly.days",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-check-input",
                                                                    attrs: {
                                                                      "data-role":
                                                                        "checkbox",
                                                                      "data-caption":
                                                                        _vm.$t(
                                                                          "Monday"
                                                                        ),
                                                                      type: "checkbox",
                                                                      name: "pnlDetails:pnlWeekly:pnlDaysSelected",
                                                                      value:
                                                                        "Mon",
                                                                    },
                                                                    domProps: {
                                                                      checked:
                                                                        Array.isArray(
                                                                          _vm
                                                                            .cronOptions
                                                                            .weekly
                                                                            .days
                                                                        )
                                                                          ? _vm._i(
                                                                              _vm
                                                                                .cronOptions
                                                                                .weekly
                                                                                .days,
                                                                              "Mon"
                                                                            ) >
                                                                            -1
                                                                          : _vm
                                                                              .cronOptions
                                                                              .weekly
                                                                              .days,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$a =
                                                                              _vm
                                                                                .cronOptions
                                                                                .weekly
                                                                                .days,
                                                                            $$el =
                                                                              $event.target,
                                                                            $$c =
                                                                              $$el.checked
                                                                                ? true
                                                                                : false
                                                                          if (
                                                                            Array.isArray(
                                                                              $$a
                                                                            )
                                                                          ) {
                                                                            var $$v =
                                                                                "Mon",
                                                                              $$i =
                                                                                _vm._i(
                                                                                  $$a,
                                                                                  $$v
                                                                                )
                                                                            if (
                                                                              $$el.checked
                                                                            ) {
                                                                              $$i <
                                                                                0 &&
                                                                                _vm.$set(
                                                                                  _vm
                                                                                    .cronOptions
                                                                                    .weekly,
                                                                                  "days",
                                                                                  $$a.concat(
                                                                                    [
                                                                                      $$v,
                                                                                    ]
                                                                                  )
                                                                                )
                                                                            } else {
                                                                              $$i >
                                                                                -1 &&
                                                                                _vm.$set(
                                                                                  _vm
                                                                                    .cronOptions
                                                                                    .weekly,
                                                                                  "days",
                                                                                  $$a
                                                                                    .slice(
                                                                                      0,
                                                                                      $$i
                                                                                    )
                                                                                    .concat(
                                                                                      $$a.slice(
                                                                                        $$i +
                                                                                          1
                                                                                      )
                                                                                    )
                                                                                )
                                                                            }
                                                                          } else {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .cronOptions
                                                                                .weekly,
                                                                              "days",
                                                                              $$c
                                                                            )
                                                                          }
                                                                        },
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "col-3",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-inline",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "form-check-label",
                                                                },
                                                                [
                                                                  _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .cronOptions
                                                                              .weekly
                                                                              .days,
                                                                          expression:
                                                                            "cronOptions.weekly.days",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-check-input ml-4",
                                                                    attrs: {
                                                                      "data-role":
                                                                        "checkbox",
                                                                      "data-caption":
                                                                        _vm.$t(
                                                                          "Tuesday"
                                                                        ),
                                                                      type: "checkbox",
                                                                      name: "pnlDetails:pnlWeekly:pnlDaysSelected",
                                                                      value:
                                                                        "Tue",
                                                                    },
                                                                    domProps: {
                                                                      checked:
                                                                        Array.isArray(
                                                                          _vm
                                                                            .cronOptions
                                                                            .weekly
                                                                            .days
                                                                        )
                                                                          ? _vm._i(
                                                                              _vm
                                                                                .cronOptions
                                                                                .weekly
                                                                                .days,
                                                                              "Tue"
                                                                            ) >
                                                                            -1
                                                                          : _vm
                                                                              .cronOptions
                                                                              .weekly
                                                                              .days,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$a =
                                                                              _vm
                                                                                .cronOptions
                                                                                .weekly
                                                                                .days,
                                                                            $$el =
                                                                              $event.target,
                                                                            $$c =
                                                                              $$el.checked
                                                                                ? true
                                                                                : false
                                                                          if (
                                                                            Array.isArray(
                                                                              $$a
                                                                            )
                                                                          ) {
                                                                            var $$v =
                                                                                "Tue",
                                                                              $$i =
                                                                                _vm._i(
                                                                                  $$a,
                                                                                  $$v
                                                                                )
                                                                            if (
                                                                              $$el.checked
                                                                            ) {
                                                                              $$i <
                                                                                0 &&
                                                                                _vm.$set(
                                                                                  _vm
                                                                                    .cronOptions
                                                                                    .weekly,
                                                                                  "days",
                                                                                  $$a.concat(
                                                                                    [
                                                                                      $$v,
                                                                                    ]
                                                                                  )
                                                                                )
                                                                            } else {
                                                                              $$i >
                                                                                -1 &&
                                                                                _vm.$set(
                                                                                  _vm
                                                                                    .cronOptions
                                                                                    .weekly,
                                                                                  "days",
                                                                                  $$a
                                                                                    .slice(
                                                                                      0,
                                                                                      $$i
                                                                                    )
                                                                                    .concat(
                                                                                      $$a.slice(
                                                                                        $$i +
                                                                                          1
                                                                                      )
                                                                                    )
                                                                                )
                                                                            }
                                                                          } else {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .cronOptions
                                                                                .weekly,
                                                                              "days",
                                                                              $$c
                                                                            )
                                                                          }
                                                                        },
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "col-3",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-inline",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "form-check-label",
                                                                },
                                                                [
                                                                  _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .cronOptions
                                                                              .weekly
                                                                              .days,
                                                                          expression:
                                                                            "cronOptions.weekly.days",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-check-input ml-4",
                                                                    attrs: {
                                                                      "data-role":
                                                                        "checkbox",
                                                                      "data-caption":
                                                                        _vm.$t(
                                                                          "Wednesday"
                                                                        ),
                                                                      type: "checkbox",
                                                                      name: "pnlDetails:pnlWeekly:pnlDaysSelected",
                                                                      value:
                                                                        "Wed",
                                                                    },
                                                                    domProps: {
                                                                      checked:
                                                                        Array.isArray(
                                                                          _vm
                                                                            .cronOptions
                                                                            .weekly
                                                                            .days
                                                                        )
                                                                          ? _vm._i(
                                                                              _vm
                                                                                .cronOptions
                                                                                .weekly
                                                                                .days,
                                                                              "Wed"
                                                                            ) >
                                                                            -1
                                                                          : _vm
                                                                              .cronOptions
                                                                              .weekly
                                                                              .days,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$a =
                                                                              _vm
                                                                                .cronOptions
                                                                                .weekly
                                                                                .days,
                                                                            $$el =
                                                                              $event.target,
                                                                            $$c =
                                                                              $$el.checked
                                                                                ? true
                                                                                : false
                                                                          if (
                                                                            Array.isArray(
                                                                              $$a
                                                                            )
                                                                          ) {
                                                                            var $$v =
                                                                                "Wed",
                                                                              $$i =
                                                                                _vm._i(
                                                                                  $$a,
                                                                                  $$v
                                                                                )
                                                                            if (
                                                                              $$el.checked
                                                                            ) {
                                                                              $$i <
                                                                                0 &&
                                                                                _vm.$set(
                                                                                  _vm
                                                                                    .cronOptions
                                                                                    .weekly,
                                                                                  "days",
                                                                                  $$a.concat(
                                                                                    [
                                                                                      $$v,
                                                                                    ]
                                                                                  )
                                                                                )
                                                                            } else {
                                                                              $$i >
                                                                                -1 &&
                                                                                _vm.$set(
                                                                                  _vm
                                                                                    .cronOptions
                                                                                    .weekly,
                                                                                  "days",
                                                                                  $$a
                                                                                    .slice(
                                                                                      0,
                                                                                      $$i
                                                                                    )
                                                                                    .concat(
                                                                                      $$a.slice(
                                                                                        $$i +
                                                                                          1
                                                                                      )
                                                                                    )
                                                                                )
                                                                            }
                                                                          } else {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .cronOptions
                                                                                .weekly,
                                                                              "days",
                                                                              $$c
                                                                            )
                                                                          }
                                                                        },
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-auto",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-inline",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "form-check-label",
                                                                },
                                                                [
                                                                  _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .cronOptions
                                                                              .weekly
                                                                              .days,
                                                                          expression:
                                                                            "cronOptions.weekly.days",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-check-input ml-4",
                                                                    attrs: {
                                                                      "data-role":
                                                                        "checkbox",
                                                                      "data-caption":
                                                                        _vm.$t(
                                                                          "Thursday"
                                                                        ),
                                                                      type: "checkbox",
                                                                      name: "pnlDetails:pnlWeekly:pnlDaysSelected",
                                                                      value:
                                                                        "Thu",
                                                                    },
                                                                    domProps: {
                                                                      checked:
                                                                        Array.isArray(
                                                                          _vm
                                                                            .cronOptions
                                                                            .weekly
                                                                            .days
                                                                        )
                                                                          ? _vm._i(
                                                                              _vm
                                                                                .cronOptions
                                                                                .weekly
                                                                                .days,
                                                                              "Thu"
                                                                            ) >
                                                                            -1
                                                                          : _vm
                                                                              .cronOptions
                                                                              .weekly
                                                                              .days,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$a =
                                                                              _vm
                                                                                .cronOptions
                                                                                .weekly
                                                                                .days,
                                                                            $$el =
                                                                              $event.target,
                                                                            $$c =
                                                                              $$el.checked
                                                                                ? true
                                                                                : false
                                                                          if (
                                                                            Array.isArray(
                                                                              $$a
                                                                            )
                                                                          ) {
                                                                            var $$v =
                                                                                "Thu",
                                                                              $$i =
                                                                                _vm._i(
                                                                                  $$a,
                                                                                  $$v
                                                                                )
                                                                            if (
                                                                              $$el.checked
                                                                            ) {
                                                                              $$i <
                                                                                0 &&
                                                                                _vm.$set(
                                                                                  _vm
                                                                                    .cronOptions
                                                                                    .weekly,
                                                                                  "days",
                                                                                  $$a.concat(
                                                                                    [
                                                                                      $$v,
                                                                                    ]
                                                                                  )
                                                                                )
                                                                            } else {
                                                                              $$i >
                                                                                -1 &&
                                                                                _vm.$set(
                                                                                  _vm
                                                                                    .cronOptions
                                                                                    .weekly,
                                                                                  "days",
                                                                                  $$a
                                                                                    .slice(
                                                                                      0,
                                                                                      $$i
                                                                                    )
                                                                                    .concat(
                                                                                      $$a.slice(
                                                                                        $$i +
                                                                                          1
                                                                                      )
                                                                                    )
                                                                                )
                                                                            }
                                                                          } else {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .cronOptions
                                                                                .weekly,
                                                                              "days",
                                                                              $$c
                                                                            )
                                                                          }
                                                                        },
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "row mt-2" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "col-3",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-inline",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "form-check-label",
                                                                },
                                                                [
                                                                  _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .cronOptions
                                                                              .weekly
                                                                              .days,
                                                                          expression:
                                                                            "cronOptions.weekly.days",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-check-input",
                                                                    attrs: {
                                                                      "data-role":
                                                                        "checkbox",
                                                                      "data-caption":
                                                                        _vm.$t(
                                                                          "Friday"
                                                                        ),
                                                                      type: "checkbox",
                                                                      name: "pnlDetails:pnlWeekly:pnlDaysSelected",
                                                                      value:
                                                                        "Fri",
                                                                    },
                                                                    domProps: {
                                                                      checked:
                                                                        Array.isArray(
                                                                          _vm
                                                                            .cronOptions
                                                                            .weekly
                                                                            .days
                                                                        )
                                                                          ? _vm._i(
                                                                              _vm
                                                                                .cronOptions
                                                                                .weekly
                                                                                .days,
                                                                              "Fri"
                                                                            ) >
                                                                            -1
                                                                          : _vm
                                                                              .cronOptions
                                                                              .weekly
                                                                              .days,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$a =
                                                                              _vm
                                                                                .cronOptions
                                                                                .weekly
                                                                                .days,
                                                                            $$el =
                                                                              $event.target,
                                                                            $$c =
                                                                              $$el.checked
                                                                                ? true
                                                                                : false
                                                                          if (
                                                                            Array.isArray(
                                                                              $$a
                                                                            )
                                                                          ) {
                                                                            var $$v =
                                                                                "Fri",
                                                                              $$i =
                                                                                _vm._i(
                                                                                  $$a,
                                                                                  $$v
                                                                                )
                                                                            if (
                                                                              $$el.checked
                                                                            ) {
                                                                              $$i <
                                                                                0 &&
                                                                                _vm.$set(
                                                                                  _vm
                                                                                    .cronOptions
                                                                                    .weekly,
                                                                                  "days",
                                                                                  $$a.concat(
                                                                                    [
                                                                                      $$v,
                                                                                    ]
                                                                                  )
                                                                                )
                                                                            } else {
                                                                              $$i >
                                                                                -1 &&
                                                                                _vm.$set(
                                                                                  _vm
                                                                                    .cronOptions
                                                                                    .weekly,
                                                                                  "days",
                                                                                  $$a
                                                                                    .slice(
                                                                                      0,
                                                                                      $$i
                                                                                    )
                                                                                    .concat(
                                                                                      $$a.slice(
                                                                                        $$i +
                                                                                          1
                                                                                      )
                                                                                    )
                                                                                )
                                                                            }
                                                                          } else {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .cronOptions
                                                                                .weekly,
                                                                              "days",
                                                                              $$c
                                                                            )
                                                                          }
                                                                        },
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "col-3",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-inline",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "form-check-label",
                                                                },
                                                                [
                                                                  _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .cronOptions
                                                                              .weekly
                                                                              .days,
                                                                          expression:
                                                                            "cronOptions.weekly.days",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-check-input ml-4",
                                                                    attrs: {
                                                                      "data-role":
                                                                        "checkbox",
                                                                      "data-caption":
                                                                        _vm.$t(
                                                                          "Saturday"
                                                                        ),
                                                                      type: "checkbox",
                                                                      name: "pnlDetails:pnlWeekly:pnlDaysSelected",
                                                                      value:
                                                                        "Sat",
                                                                    },
                                                                    domProps: {
                                                                      checked:
                                                                        Array.isArray(
                                                                          _vm
                                                                            .cronOptions
                                                                            .weekly
                                                                            .days
                                                                        )
                                                                          ? _vm._i(
                                                                              _vm
                                                                                .cronOptions
                                                                                .weekly
                                                                                .days,
                                                                              "Sat"
                                                                            ) >
                                                                            -1
                                                                          : _vm
                                                                              .cronOptions
                                                                              .weekly
                                                                              .days,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$a =
                                                                              _vm
                                                                                .cronOptions
                                                                                .weekly
                                                                                .days,
                                                                            $$el =
                                                                              $event.target,
                                                                            $$c =
                                                                              $$el.checked
                                                                                ? true
                                                                                : false
                                                                          if (
                                                                            Array.isArray(
                                                                              $$a
                                                                            )
                                                                          ) {
                                                                            var $$v =
                                                                                "Sat",
                                                                              $$i =
                                                                                _vm._i(
                                                                                  $$a,
                                                                                  $$v
                                                                                )
                                                                            if (
                                                                              $$el.checked
                                                                            ) {
                                                                              $$i <
                                                                                0 &&
                                                                                _vm.$set(
                                                                                  _vm
                                                                                    .cronOptions
                                                                                    .weekly,
                                                                                  "days",
                                                                                  $$a.concat(
                                                                                    [
                                                                                      $$v,
                                                                                    ]
                                                                                  )
                                                                                )
                                                                            } else {
                                                                              $$i >
                                                                                -1 &&
                                                                                _vm.$set(
                                                                                  _vm
                                                                                    .cronOptions
                                                                                    .weekly,
                                                                                  "days",
                                                                                  $$a
                                                                                    .slice(
                                                                                      0,
                                                                                      $$i
                                                                                    )
                                                                                    .concat(
                                                                                      $$a.slice(
                                                                                        $$i +
                                                                                          1
                                                                                      )
                                                                                    )
                                                                                )
                                                                            }
                                                                          } else {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .cronOptions
                                                                                .weekly,
                                                                              "days",
                                                                              $$c
                                                                            )
                                                                          }
                                                                        },
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "col-2",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-inline",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "form-check-label",
                                                                },
                                                                [
                                                                  _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .cronOptions
                                                                              .weekly
                                                                              .days,
                                                                          expression:
                                                                            "cronOptions.weekly.days",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-check-input ml-4",
                                                                    attrs: {
                                                                      "data-role":
                                                                        "checkbox",
                                                                      "data-caption":
                                                                        _vm.$t(
                                                                          "Sunday"
                                                                        ),
                                                                      type: "checkbox",
                                                                      name: "pnlDetails:pnlWeekly:pnlDaysSelected",
                                                                      value:
                                                                        "Sun",
                                                                    },
                                                                    domProps: {
                                                                      checked:
                                                                        Array.isArray(
                                                                          _vm
                                                                            .cronOptions
                                                                            .weekly
                                                                            .days
                                                                        )
                                                                          ? _vm._i(
                                                                              _vm
                                                                                .cronOptions
                                                                                .weekly
                                                                                .days,
                                                                              "Sun"
                                                                            ) >
                                                                            -1
                                                                          : _vm
                                                                              .cronOptions
                                                                              .weekly
                                                                              .days,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$a =
                                                                              _vm
                                                                                .cronOptions
                                                                                .weekly
                                                                                .days,
                                                                            $$el =
                                                                              $event.target,
                                                                            $$c =
                                                                              $$el.checked
                                                                                ? true
                                                                                : false
                                                                          if (
                                                                            Array.isArray(
                                                                              $$a
                                                                            )
                                                                          ) {
                                                                            var $$v =
                                                                                "Sun",
                                                                              $$i =
                                                                                _vm._i(
                                                                                  $$a,
                                                                                  $$v
                                                                                )
                                                                            if (
                                                                              $$el.checked
                                                                            ) {
                                                                              $$i <
                                                                                0 &&
                                                                                _vm.$set(
                                                                                  _vm
                                                                                    .cronOptions
                                                                                    .weekly,
                                                                                  "days",
                                                                                  $$a.concat(
                                                                                    [
                                                                                      $$v,
                                                                                    ]
                                                                                  )
                                                                                )
                                                                            } else {
                                                                              $$i >
                                                                                -1 &&
                                                                                _vm.$set(
                                                                                  _vm
                                                                                    .cronOptions
                                                                                    .weekly,
                                                                                  "days",
                                                                                  $$a
                                                                                    .slice(
                                                                                      0,
                                                                                      $$i
                                                                                    )
                                                                                    .concat(
                                                                                      $$a.slice(
                                                                                        $$i +
                                                                                          1
                                                                                      )
                                                                                    )
                                                                                )
                                                                            }
                                                                          } else {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .cronOptions
                                                                                .weekly,
                                                                              "days",
                                                                              $$c
                                                                            )
                                                                          }
                                                                        },
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-inline mt-2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t("Starts at")
                                                          ) +
                                                          " : "
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-inline",
                                                        },
                                                        [
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .cronOptions
                                                                      .weekly.at
                                                                      .hour,
                                                                  expression:
                                                                    "cronOptions.weekly.at.hour",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control mr-1 ml-1 select w-auto inline",
                                                              staticStyle: {
                                                                display:
                                                                  "inline",
                                                              },
                                                              attrs: {
                                                                name: "pnlDetails:pnlWeekly:pnlTime:hourPart",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal =
                                                                      Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                    _vm.$set(
                                                                      _vm
                                                                        .cronOptions
                                                                        .weekly
                                                                        .at,
                                                                      "hour",
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            _vm._l(
                                                              24,
                                                              function (n) {
                                                                return _c(
                                                                  "option",
                                                                  {
                                                                    key: n - 1,
                                                                    domProps: {
                                                                      value:
                                                                        n - 1,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          (
                                                                            n -
                                                                            1
                                                                          )
                                                                            .toString()
                                                                            .padStart(
                                                                              2,
                                                                              "0"
                                                                            )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                          _vm._v(" : "),
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .cronOptions
                                                                      .weekly.at
                                                                      .minute,
                                                                  expression:
                                                                    "cronOptions.weekly.at.minute",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control ml-1 select w-auto",
                                                              staticStyle: {
                                                                display:
                                                                  "inline",
                                                              },
                                                              attrs: {
                                                                name: "pnlDetails:pnlWeekly:pnlTime:minutePart",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal =
                                                                      Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                    _vm.$set(
                                                                      _vm
                                                                        .cronOptions
                                                                        .weekly
                                                                        .at,
                                                                      "minute",
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            _vm._l(
                                                              60,
                                                              function (n) {
                                                                return _c(
                                                                  "option",
                                                                  {
                                                                    key: n - 1,
                                                                    domProps: {
                                                                      value:
                                                                        n - 1,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          (
                                                                            n -
                                                                            1
                                                                          )
                                                                            .toString()
                                                                            .padStart(
                                                                              2,
                                                                              "0"
                                                                            )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "p-4",
                                              attrs: { id: "montly" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "card-body",
                                                  attrs: { id: "idb" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-inline",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-check-label",
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .cronOptions
                                                                    .monthly
                                                                    .radio,
                                                                expression:
                                                                  "cronOptions.monthly.radio",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-check-input mb-1",
                                                            attrs: {
                                                              "data-role":
                                                                "radio",
                                                              type: "radio",
                                                              name: "pnlDetails:pnlMonthly:radioGroup",
                                                              value: "fixed",
                                                              checked:
                                                                "checked",
                                                            },
                                                            domProps: {
                                                              checked: _vm._q(
                                                                _vm.cronOptions
                                                                  .monthly
                                                                  .radio,
                                                                "fixed"
                                                              ),
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  _vm
                                                                    .cronOptions
                                                                    .monthly,
                                                                  "radio",
                                                                  "fixed"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t("Day")
                                                              ) +
                                                              " "
                                                          ),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .cronOptions
                                                                    .monthly
                                                                    .day,
                                                                expression:
                                                                  "cronOptions.monthly.day",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control ml-1 mr-1 input w-auto text-center",
                                                            staticStyle: {
                                                              display: "inline",
                                                            },
                                                            attrs: {
                                                              type: "text",
                                                              size: "2",
                                                              name: "pnlDetails:pnlMonthly:radioGroup:txtDay",
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm.cronOptions
                                                                  .monthly.day,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.$set(
                                                                  _vm
                                                                    .cronOptions
                                                                    .monthly,
                                                                  "day",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                            },
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "of every"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .cronOptions
                                                                      .monthly
                                                                      .ofEvery,
                                                                  expression:
                                                                    "cronOptions.monthly.ofEvery",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control ml-1 mr-1 select w-auto",
                                                              staticStyle: {
                                                                display:
                                                                  "inline",
                                                              },
                                                              attrs: {
                                                                name: "pnlDetails:pnlMonthly:radioGroup:ddMonth",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal =
                                                                      Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                    _vm.$set(
                                                                      _vm
                                                                        .cronOptions
                                                                        .monthly,
                                                                      "ofEvery",
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "1",
                                                                  },
                                                                },
                                                                [_vm._v("1")]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "2",
                                                                  },
                                                                },
                                                                [_vm._v("2")]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "3",
                                                                  },
                                                                },
                                                                [_vm._v("3")]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "4",
                                                                  },
                                                                },
                                                                [_vm._v("4")]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "6",
                                                                  },
                                                                },
                                                                [_vm._v("6")]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "month(s)"
                                                                )
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-inline mt-3",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-check-label",
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .cronOptions
                                                                    .monthly
                                                                    .radio,
                                                                expression:
                                                                  "cronOptions.monthly.radio",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-check-input mb-1",
                                                            attrs: {
                                                              "data-role":
                                                                "radio",
                                                              type: "radio",
                                                              name: "pnlDetails:pnlMonthly:radioGroup",
                                                              value:
                                                                "recurrent",
                                                            },
                                                            domProps: {
                                                              checked: _vm._q(
                                                                _vm.cronOptions
                                                                  .monthly
                                                                  .radio,
                                                                "recurrent"
                                                              ),
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  _vm
                                                                    .cronOptions
                                                                    .monthly,
                                                                  "radio",
                                                                  "recurrent"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t("The")
                                                              ) +
                                                              " "
                                                          ),
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .cronOptions
                                                                      .monthly
                                                                      .ddRank,
                                                                  expression:
                                                                    "cronOptions.monthly.ddRank",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control ml-1 mr-1 select w-auto",
                                                              staticStyle: {
                                                                display:
                                                                  "inline",
                                                              },
                                                              attrs: {
                                                                name: "pnlDetails:pnlMonthly:radioGroup:ddRank",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal =
                                                                      Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                    _vm.$set(
                                                                      _vm
                                                                        .cronOptions
                                                                        .monthly,
                                                                      "ddRank",
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "first",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "First"
                                                                      )
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "second",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Second"
                                                                      )
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "third",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Third"
                                                                      )
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "fourth",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Fourth"
                                                                      )
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .cronOptions
                                                                      .monthly
                                                                      .ddDay,
                                                                  expression:
                                                                    "cronOptions.monthly.ddDay",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control mr-1 select w-auto",
                                                              staticStyle: {
                                                                display:
                                                                  "inline",
                                                              },
                                                              attrs: {
                                                                name: "pnlDetails:pnlMonthly:radioGroup:ddDay",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal =
                                                                      Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                    _vm.$set(
                                                                      _vm
                                                                        .cronOptions
                                                                        .monthly,
                                                                      "ddDay",
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "mon",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Monday"
                                                                      )
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "tue",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Tuesday"
                                                                      )
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "wed",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Wednesday"
                                                                      )
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "thu",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Thursday"
                                                                      )
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "fri",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Friday"
                                                                      )
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "sat",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Saturday"
                                                                      )
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "sun",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Sunday"
                                                                      )
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "of every"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .cronOptions
                                                                      .monthly
                                                                      .ddMonth,
                                                                  expression:
                                                                    "cronOptions.monthly.ddMonth",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control ml-1 mr-1 select w-auto",
                                                              staticStyle: {
                                                                display:
                                                                  "inline",
                                                              },
                                                              attrs: {
                                                                name: "pnlDetails:pnlMonthly:radioGroup:ddMonth",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal =
                                                                      Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                    _vm.$set(
                                                                      _vm
                                                                        .cronOptions
                                                                        .monthly,
                                                                      "ddMonth",
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "1",
                                                                  },
                                                                },
                                                                [_vm._v("1")]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "2",
                                                                  },
                                                                },
                                                                [_vm._v("2")]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "3",
                                                                  },
                                                                },
                                                                [_vm._v("3")]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "4",
                                                                  },
                                                                },
                                                                [_vm._v("4")]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "6",
                                                                  },
                                                                },
                                                                [_vm._v("6")]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "month(s)"
                                                                )
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-inline mt-2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t("Starts at")
                                                          ) +
                                                          ": "
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-inline",
                                                        },
                                                        [
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .cronOptions
                                                                      .monthly
                                                                      .at.hour,
                                                                  expression:
                                                                    "cronOptions.monthly.at.hour",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control mr-1 ml-1 select w-auto",
                                                              staticStyle: {
                                                                display:
                                                                  "inline",
                                                              },
                                                              attrs: {
                                                                name: "pnlDetails:pnlMonthly:pnlTime:hourPart",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal =
                                                                      Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                    _vm.$set(
                                                                      _vm
                                                                        .cronOptions
                                                                        .monthly
                                                                        .at,
                                                                      "hour",
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            _vm._l(
                                                              24,
                                                              function (n) {
                                                                return _c(
                                                                  "option",
                                                                  {
                                                                    key: n - 1,
                                                                    domProps: {
                                                                      value:
                                                                        n - 1,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          (
                                                                            n -
                                                                            1
                                                                          )
                                                                            .toString()
                                                                            .padStart(
                                                                              2,
                                                                              "0"
                                                                            )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                          _vm._v(" : "),
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .cronOptions
                                                                      .monthly
                                                                      .at
                                                                      .minute,
                                                                  expression:
                                                                    "cronOptions.monthly.at.minute",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control ml-1 select w-auto",
                                                              staticStyle: {
                                                                display:
                                                                  "inline",
                                                              },
                                                              attrs: {
                                                                name: "pnlDetails:pnlMonthly:pnlTime:minutePart",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal =
                                                                      Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                    _vm.$set(
                                                                      _vm
                                                                        .cronOptions
                                                                        .monthly
                                                                        .at,
                                                                      "minute",
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            _vm._l(
                                                              60,
                                                              function (n) {
                                                                return _c(
                                                                  "option",
                                                                  {
                                                                    key: n - 1,
                                                                    domProps: {
                                                                      value:
                                                                        n - 1,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          (
                                                                            n -
                                                                            1
                                                                          )
                                                                            .toString()
                                                                            .padStart(
                                                                              2,
                                                                              "0"
                                                                            )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "p-4",
                                              attrs: { id: "yearly" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "card-body",
                                                  attrs: { id: "idb" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-inline",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-check-label mr-1",
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .cronOptions
                                                                    .yearly
                                                                    .radio,
                                                                expression:
                                                                  "cronOptions.yearly.radio",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-check-input mb-1",
                                                            attrs: {
                                                              "data-role":
                                                                "radio",
                                                              "data-caption":
                                                                _vm.$t("Every"),
                                                              type: "radio",
                                                              name: "pnlDetails:pnlYearly:radioGroup",
                                                              value: "fixed",
                                                              checked:
                                                                "checked",
                                                            },
                                                            domProps: {
                                                              checked: _vm._q(
                                                                _vm.cronOptions
                                                                  .yearly.radio,
                                                                "fixed"
                                                              ),
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  _vm
                                                                    .cronOptions
                                                                    .yearly,
                                                                  "radio",
                                                                  "fixed"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.cronOptions
                                                                  .yearly
                                                                  .ofEvery,
                                                              expression:
                                                                "cronOptions.yearly.ofEvery",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control select w-auto",
                                                          staticStyle: {
                                                            display: "inline",
                                                          },
                                                          attrs: {
                                                            name: "pnlDetails:pnlYearly:radioGroup:ddMonth",
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              var $$selectedVal =
                                                                Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      var val =
                                                                        "_value" in
                                                                        o
                                                                          ? o._value
                                                                          : o.value
                                                                      return val
                                                                    }
                                                                  )
                                                              _vm.$set(
                                                                _vm.cronOptions
                                                                  .yearly,
                                                                "ofEvery",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "1",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "January"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "2",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "February"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "3",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "March"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "4",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "April"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "5",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t("May")
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "6",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t("June")
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "7",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t("July")
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "8",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "August"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "9",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "September"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "10",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "October"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "11",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "November"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "12",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "December"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.cronOptions
                                                                .yearly.day,
                                                            expression:
                                                              "cronOptions.yearly.day",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control ml-1 mr-1 input w-auto text-center",
                                                        staticStyle: {
                                                          display:
                                                            "inline-grid",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          size: "2",
                                                          name: "pnlDetails:pnlYearly:radioGroup:txtDay",
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm.cronOptions
                                                              .yearly.day,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              _vm.cronOptions
                                                                .yearly,
                                                              "day",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-inline mt-3",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-check-label",
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .cronOptions
                                                                    .yearly
                                                                    .radio,
                                                                expression:
                                                                  "cronOptions.yearly.radio",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-check-input mb-1",
                                                            attrs: {
                                                              "data-role":
                                                                "radio",
                                                              "data-caption":
                                                                _vm.$t("The"),
                                                              type: "radio",
                                                              name: "pnlDetails:pnlYearly:radioGroup",
                                                              value:
                                                                "recurrent",
                                                            },
                                                            domProps: {
                                                              checked: _vm._q(
                                                                _vm.cronOptions
                                                                  .yearly.radio,
                                                                "recurrent"
                                                              ),
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  _vm
                                                                    .cronOptions
                                                                    .yearly,
                                                                  "radio",
                                                                  "recurrent"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .cronOptions
                                                                      .yearly
                                                                      .ddRank,
                                                                  expression:
                                                                    "cronOptions.yearly.ddRank",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control ml-1 select w-auto",
                                                              staticStyle: {
                                                                display:
                                                                  "inline",
                                                              },
                                                              attrs: {
                                                                name: "pnlDetails:pnlYearly:radioGroup:ddRank",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal =
                                                                      Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                    _vm.$set(
                                                                      _vm
                                                                        .cronOptions
                                                                        .yearly,
                                                                      "ddRank",
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "first",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "First"
                                                                      )
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "second",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Second"
                                                                      )
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "third",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Third"
                                                                      )
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "fourth",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Fourth"
                                                                      )
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .cronOptions
                                                                      .yearly
                                                                      .ddDay,
                                                                  expression:
                                                                    "cronOptions.yearly.ddDay",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control ml-1 mr-1 select w-auto",
                                                              staticStyle: {
                                                                display:
                                                                  "inline",
                                                              },
                                                              attrs: {
                                                                name: "pnlDetails:pnlYearly:radioGroup:ddDay",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal =
                                                                      Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                    _vm.$set(
                                                                      _vm
                                                                        .cronOptions
                                                                        .yearly,
                                                                      "ddDay",
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "mon",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Monday"
                                                                      )
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "tue",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Tuesday"
                                                                      )
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "wed",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Wednesday"
                                                                      )
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "thu",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Thursday"
                                                                      )
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "fri",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Friday"
                                                                      )
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "sat",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Saturday"
                                                                      )
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "sun",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Sunday"
                                                                      )
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t("of")
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.cronOptions
                                                                  .yearly
                                                                  .ddMonth,
                                                              expression:
                                                                "cronOptions.yearly.ddMonth",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control ml-1 select w-auto",
                                                          staticStyle: {
                                                            display: "inline",
                                                          },
                                                          attrs: {
                                                            name: "pnlDetails:pnlYearly:radioGroup:ddMonth",
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              var $$selectedVal =
                                                                Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      var val =
                                                                        "_value" in
                                                                        o
                                                                          ? o._value
                                                                          : o.value
                                                                      return val
                                                                    }
                                                                  )
                                                              _vm.$set(
                                                                _vm.cronOptions
                                                                  .yearly,
                                                                "ddMonth",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "1",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "January"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "2",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "February"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "3",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "March"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "4",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "April"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "5",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t("May")
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "6",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t("June")
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "7",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t("July")
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "8",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "August"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "9",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "September"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "10",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "October"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "11",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "November"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "12",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "December"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-inline mt-2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t("Starts at")
                                                          ) +
                                                          " : "
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-inline",
                                                        },
                                                        [
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .cronOptions
                                                                      .yearly.at
                                                                      .hour,
                                                                  expression:
                                                                    "cronOptions.yearly.at.hour",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control mr-1 ml-1 select w-auto",
                                                              staticStyle: {
                                                                display:
                                                                  "inline",
                                                              },
                                                              attrs: {
                                                                name: "pnlDetails:pnlYearly:pnlTime:hourPart",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal =
                                                                      Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                    _vm.$set(
                                                                      _vm
                                                                        .cronOptions
                                                                        .yearly
                                                                        .at,
                                                                      "hour",
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            _vm._l(
                                                              24,
                                                              function (n) {
                                                                return _c(
                                                                  "option",
                                                                  {
                                                                    key: n - 1,
                                                                    domProps: {
                                                                      value:
                                                                        n - 1,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          (
                                                                            n -
                                                                            1
                                                                          )
                                                                            .toString()
                                                                            .padStart(
                                                                              2,
                                                                              "0"
                                                                            )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                          _vm._v(" : "),
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .cronOptions
                                                                      .yearly.at
                                                                      .minute,
                                                                  expression:
                                                                    "cronOptions.yearly.at.minute",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control ml-1 select w-auto",
                                                              staticStyle: {
                                                                display:
                                                                  "inline",
                                                              },
                                                              attrs: {
                                                                name: "pnlDetails:pnlYearly:pnlTime:minutePart",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal =
                                                                      Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                    _vm.$set(
                                                                      _vm
                                                                        .cronOptions
                                                                        .yearly
                                                                        .at,
                                                                      "minute",
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            _vm._l(
                                                              60,
                                                              function (n) {
                                                                return _c(
                                                                  "option",
                                                                  {
                                                                    key: n - 1,
                                                                    domProps: {
                                                                      value:
                                                                        n - 1,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          (
                                                                            n -
                                                                            1
                                                                          )
                                                                            .toString()
                                                                            .padStart(
                                                                              2,
                                                                              "0"
                                                                            )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "row p-2" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "button secondary mr-3",
                                        on: {
                                          click: function ($event) {
                                            return _vm.refreshSched()
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("Add Scheduling")))]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "p-4" }, [
                                    _vm.backupJob.scheduling.length != 0
                                      ? _c("h6", { staticClass: "text-bold" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("Selected Schedulations")
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _c(
                                      "ul",
                                      { staticClass: "listview" },
                                      _vm._l(
                                        _vm.backupJob.scheduling,
                                        function (sched, index) {
                                          return _c(
                                            "li",
                                            {
                                              key: index,
                                              staticClass:
                                                "bg-dark fg-white mr-4 mt-4 p-4 rounded",
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "mif-alarm",
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.cronstrue.toString(
                                                      sched,
                                                      {
                                                        locale:
                                                          _vm.$i18n.locale,
                                                        dayOfWeekStartIndexZero: false,
                                                      }
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c("a", {
                                                staticClass:
                                                  "second-action mif-cross-light",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeSched(
                                                      index
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("div", { attrs: { id: "result" } }, [
                    _vm.backupJob.destinations.length != 0
                      ? _c("section", { staticClass: "overflow" }, [
                          _c("div", { staticClass: "page-content p-4" }, [
                            _c("div", { staticClass: "m-5 w-100" }, [
                              _c("div", { staticClass: "d-flex flex-row" }, [
                                _c("div", { staticClass: "mt-4 col-4 m-0" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row d-flex flex-align-center flex-justify-between mb-4 ml-0",
                                    },
                                    [
                                      _c("h1", [
                                        _vm._v(
                                          _vm._s(_vm.$t("Backup Summary"))
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "Choose a name for this backup job"
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.backupJob.name,
                                        expression: "backupJob.name",
                                      },
                                    ],
                                    staticClass: "input rounded border mt-2",
                                    attrs: {
                                      required: "",
                                      type: "text",
                                      maxlength: "100",
                                      disabled: [
                                        _vm.SAVEBACKUP_STARTED,
                                        _vm.SAVEBACKUP_SAVED,
                                      ].includes(_vm.saveBackupResult),
                                    },
                                    domProps: { value: _vm.backupJob.name },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.backupJob,
                                          "name",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-8 d-flex flex-align-center flex-justify-center flex-column m-0",
                                  },
                                  [
                                    [
                                      _vm.SAVEBACKUP_NOTSTARTED,
                                      _vm.SAVEBACKUP_NOTSAVED,
                                    ].includes(_vm.saveBackupResult)
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "button primary p-6 mt-2",
                                            attrs: {
                                              disabled:
                                                _vm.backupJob.sources.length ==
                                                  0 ||
                                                _vm.backupJob.destinations
                                                  .length == 0 ||
                                                _vm.backupJob.name == "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.sendBackupJob()
                                              },
                                            },
                                          },
                                          [
                                            _c("h3", [
                                              _vm._v(
                                                _vm._s(_vm.$t("Save backup"))
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.saveBackupResult ==
                                    _vm.SAVEBACKUP_NOTSAVED
                                      ? _c(
                                          "div",
                                          { staticClass: "fg-red mt-2" },
                                          [
                                            _c("p", [
                                              _c("span", {
                                                staticClass: "mif-cross mr-2",
                                              }),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("Backup not saved")
                                                  )
                                                ),
                                              ]),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.saveBackupResult ==
                                    _vm.SAVEBACKUP_STARTED
                                      ? _c("div", { staticClass: "w-fit" }, [
                                          _c("span", {
                                            staticClass:
                                              "mif-spinner2 fg-primary ani-spin",
                                          }),
                                        ])
                                      : _vm._e(),
                                    _vm.saveBackupResult == _vm.SAVEBACKUP_SAVED
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "fg-green d-flex fg-white flex-align-center flex-column flex-justify-center text-center mt-4",
                                          },
                                          [
                                            _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "d-flex flex-align-center flex-column text-bold text-center",
                                              },
                                              [
                                                _c("span", {
                                                  staticClass:
                                                    "mif-checkmark mif-6x mr-2",
                                                }),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("Backup saved")
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass:
                                                  "button text-center alert float-right mt-4",
                                                attrs: {
                                                  to:
                                                    "/dashboard/computer/" +
                                                    _vm.pc.id,
                                                },
                                              },
                                              [
                                                _c("h6", [
                                                  _c("span", {
                                                    staticClass:
                                                      "mif-keyboard-return mr-2",
                                                  }),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "Return to {0}",
                                                          { 0: _vm.pc.name }
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-justify-around mt-8",
                                },
                                [
                                  _vm.backupJob.sources.length == 1
                                    ? _c(
                                        "div",
                                        { staticClass: "panel p-6 m-3" },
                                        [
                                          _c(
                                            "h4",
                                            { staticClass: "fg-cobalt mb-4" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("Source")) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.backupJob.sources[0]
                                                      .type_name
                                                  )
                                              ),
                                            ]
                                          ),
                                          _c("SourceView", {
                                            staticClass: "mt-4",
                                            attrs: {
                                              source: _vm.backupJob.sources[0],
                                            },
                                          }),
                                          !["", "-2", -2].includes(
                                            _vm.backupJob.sources[0]
                                              .id_service_account
                                          )
                                            ? _c(
                                                "h5",
                                                {
                                                  staticClass: "mt-4 fg-cobalt",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "Source - Service account"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          !["", "-2", -2].includes(
                                            _vm.backupJob.sources[0]
                                              .id_service_account
                                          )
                                            ? _c("ObjectView", {
                                                staticClass: "mt-4",
                                                attrs: {
                                                  obj: _vm.$session.getServiceAccountWithID(
                                                    _vm.backupJob.sources[0]
                                                      .id_service_account
                                                  ),
                                                  withCopyButton: false,
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "h5",
                                            { staticClass: "mt-4 fg-cobalt" },
                                            [_vm._v(_vm._s(_vm.$t("Options")))]
                                          ),
                                          _c("ObjectView", {
                                            staticClass: "mt-4",
                                            attrs: {
                                              obj: _vm.backupJob.sources[0]
                                                .options,
                                              withCopyButton: false,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.backupJob.destinations.length == 1
                                    ? _c(
                                        "div",
                                        { staticClass: "panel p-6 m-3" },
                                        [
                                          _c(
                                            "h4",
                                            { staticClass: "fg-cobalt mb-4" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("Destination")) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.backupJob
                                                      .destinations[0].type_name
                                                  )
                                              ),
                                            ]
                                          ),
                                          _c("ObjectView", {
                                            attrs: {
                                              obj: _vm.backupJob
                                                .destinations[0],
                                              withCopyButton: false,
                                            },
                                          }),
                                          !["", "-2", -2].includes(
                                            _vm.backupJob.destinations[0]
                                              .id_repository
                                          )
                                            ? _c(
                                                "h5",
                                                {
                                                  staticClass: "mt-4 fg-cobalt",
                                                },
                                                [
                                                  _c("span", {
                                                    staticClass:
                                                      "mif-folder-open mr-2",
                                                  }),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "Destination - Repository"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                          !["", "-2", -2].includes(
                                            _vm.backupJob.destinations[0]
                                              .id_repository
                                          )
                                            ? _c("ObjectView", {
                                                staticClass: "mt-4",
                                                attrs: {
                                                  obj: _vm.$session.getRepositoryWithID(
                                                    _vm.backupJob
                                                      .destinations[0]
                                                      .id_repository
                                                  ),
                                                  withCopyButton: false,
                                                },
                                              })
                                            : _vm._e(),
                                          !["", "-2", -2].includes(
                                            _vm.backupJob.destinations[0]
                                              .id_service_account
                                          )
                                            ? _c(
                                                "h5",
                                                {
                                                  staticClass: "mt-4 fg-cobalt",
                                                },
                                                [
                                                  _c("span", {
                                                    staticClass: "mif-key mr-2",
                                                  }),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "Destination - Service account"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                          !["", "-2", -2].includes(
                                            _vm.backupJob.destinations[0]
                                              .id_service_account
                                          )
                                            ? _c("ObjectView", {
                                                staticClass: "mt-4",
                                                attrs: {
                                                  obj: _vm.$session.getServiceAccountWithID(
                                                    _vm.backupJob
                                                      .destinations[0]
                                                      .id_service_account
                                                  ),
                                                  withCopyButton: false,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.backupJob.id_scheduling_policy != "" ||
                                  _vm.backupJob.scheduling.length != 0
                                    ? _c(
                                        "div",
                                        { staticClass: "panel p-6 m-3" },
                                        [
                                          _c(
                                            "h4",
                                            { staticClass: "fg-cobalt" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("Pianificazione"))
                                              ),
                                            ]
                                          ),
                                          _vm.backupJob.id_scheduling_policy ==
                                          ""
                                            ? _c(
                                                "ul",
                                                { staticClass: "simple-list" },
                                                _vm._l(
                                                  _vm.backupJob.scheduling,
                                                  function (schedulation) {
                                                    return _c("li", [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.cronstrue.toString(
                                                              schedulation,
                                                              {
                                                                locale:
                                                                  _vm.$i18n
                                                                    .locale,
                                                                dayOfWeekStartIndexZero: false,
                                                              }
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ])
                                                  }
                                                ),
                                                0
                                              )
                                            : _c("span", [
                                                _c("label", [
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$session
                                                          .getPolicies()
                                                          .find(
                                                            (value) =>
                                                              value.id ==
                                                              _vm.backupJob
                                                                .id_scheduling_policy
                                                          ).name
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                                _c("br"),
                                                _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.cronstrue.toString(
                                                          _vm.$session
                                                            .getPolicies()
                                                            .find(
                                                              (value) =>
                                                                value.id ==
                                                                _vm.backupJob
                                                                  .id_scheduling_policy
                                                            ).policy
                                                            .scheduling[0],
                                                          {
                                                            locale:
                                                              _vm.$i18n.locale,
                                                            dayOfWeekStartIndexZero: false,
                                                          }
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }