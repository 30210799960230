<template>
    <div class="w-100 overflow">
        <Navigation pageName="sessions" />
            <!-- PANNELLO SESSIONI-->
            <div id="all_sessions" class="p-5 w-100 mt-4" >
                
                <div class="d-flex flex-justify-start ">
                    <form @submit.prevent="getSessions()" class="inline-form">

                                <select class=" pl-4 select  text-small" v-model="filters.id_group">
                                    <option value="">{{ $t("All Groups") }}</option>
                                    <option v-for="group in groups" :value="group.id_group" v-bind:key="group.id_group">{{ group.name_group }}
                                    </option>
                                    
                                </select>
                            <select class="pl-4 select text-small" v-model="filters.id_user">
                                    <option value="">{{ $t("All Users") }}</option>
                                    <option v-for="user in users" :value="user.id" v-bind:key="user.id">{{ user.firstname }} {{ user.lastname }} ({{ user.username  }})  </option>   
                                </select>
                            <input type="date" class="date select  p-4  text-small text-center" v-model="filters.dt_inf" min="2018-01-01" :max="Date.now() | moment('YYYY-MM-DD')" >
                            <input type="date" class="date select   p-4  text-small text-center" v-model="filters.dt_sup" min="2018-01-01" :max="Date.now() | moment('YYYY-MM-DD')" >
                        
                            <button @click="getSessions()" class="button primary">{{$t("Search")}} </button>
                        
                    </form>
                </div>
                <div>
                    <div v-show="loading" class="h-25 m-0 p-0 w-100 z-2">
                        <span class="ani-spin mif-5x mif-spinner2 mt-13-minus pos-center"></span>
                    </div>
                    
                    <NoDataAvailable v-if="sessions.length == 0" :message="$t('No Sessions available')" />

                    <table v-if="sessions.length!=0" id="session-list" class="border bg-white compact table w-100 mt-4">
                        <thead class="mb-0">
                            <tr class="mb-3">
                                
                                <th class="text-left no-wrap" style="vertical-align: left;">{{ $t("From Device") }}</th>
                                <th class="text-left no-wrap" style="vertical-align: middle;white-space:nowrap;">{{ $t("From Device ID") }}</th>
                                <th class="text-left no-wrap" style="vertical-align: middle;">{{ $t("To Device") }}</th>
                                <th class="text-left  no-wrap" style="vertical-align: middle;white-space:nowrap;">{{ $t("To Device ID") }}</th>
                                <th class="text-center no-wrap" style="vertical-align: middle;">{{ $t("Organization") }}</th>
                                <th class="text-right no-wrap" style="vertical-align: middle;">{{ $t("Started at") }}</th>
                                <th class="text-right no-wrap" style="vertical-align: middle;">{{ $t("Ended at") }}</th>
                                <th class="text-center no-wrap" style="vertical-align: middle;">{{ $t("Duration") }}</th>
                                <th class="text-center no-wrap" style="vertical-align: middle;">{{ $t("Notes") }}</th>
                                <th class="text-center no-wrap" style="vertical-align: middle;"></th>
                            </tr>
                        </thead>

                        <tbody>
                            
                            <tr class="pt-10" v-if="!sessions">
                                <td class="p-6" colspan="7" data-role="activity" data-type="metro" data-style="dark"></td>
                            </tr>
                            <!--inizio codice vero-->
                            <template   v-for="(session,index) in sessions">
                                <tr v-if="shouldBeAddedAfter(session) && index != 0 && sessionrow!=0"><td colspan="11" class="text-bold bg-light border border-top-none"> {{$t("Sessions")}} : {{ sessionrow }}</td></tr>
                                <tr v-if="shouldBeAddedBefore(session)" class="bg-primary "><td colspan="11" class="fg-white text-bold"> {{ session.name_computer_host }} ({{ session.custom_name }})</td></tr>        
                                <tr>
                                    <td class="no-wrap" >
                                        {{session.firstname + " "+ session.lastname+ " (" + session.name_computer_control +")"}}
                                    </td>
                                    <td>{{session.id_remote_control}}</td>
                                    <!-- <td class="text-left c-pointer" @click="openBackupView(session.id)">{{
                                            session.firstname + " "+ session.lastname+ " (" + session.name_computer_control +")"}}
                                        </td> -->
                                        <td class="text-center">
                                            {{ session.name_computer_host }}
                                        </td>
                                        <td class="text-center">
                                            {{ session.id_remote_host }}
                                        </td>
                                    
                                        <td class="text-center">
                                            {{ session.organization_name }}
                                        </td>
                                        <td class="text-right no-wrap"><label v-if="session.dt_start != '' && session.dt_start != null">{{formatDate(session.dt_start) }}</label></td>
                                        <td class="text-right no-wrap"><label v-if="session.dt_end != '' && session.dt_end != null">{{  formatDate(session.dt_end)  }}</label></td>
                                        <td class="text-right"><label class="text-bold" v-if="session.dt_end != '' && session.dt_end != null">{{ diffDate(session.dt_start, session.dt_end) }}</label></td>
                                    
                                        <td class="text-center" >
                                            <input v-if="session.notes[0]" type="text" data-role="input" v-model="session.notes[0].note">
                                            <input v-else type="text" data-role="input" v-model="session.notedesc">
                                        </td>
                                        <td>
                                            <button class="button small" @click="saveNote(session)">{{ $t("Save")}}</button>
                                        </td>

                                </tr>
                                
                            </template>
                            <tr v-if="sessionrow != 0"><td colspan="11"> <div class="text-bold" >{{$t("Sessions")}} : {{ sessionrow }}</div></td></tr>
                        
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- FINE PANNELLO SESSIONI -->
    </div>
    
</template>
<script>

import Navigation from './utils/Navigation.vue';
import NoDataAvailable from "./utils/NoDataAvailable.vue";
import {  formatDate, diffDate, fromNow } from '../../public/assets/js/utilitiesmodule';
import { PC_MESSAGES } from '../../public/assets/js/messages';
export default {
    name: "Sessions",
    components:{
        Navigation,
        NoDataAvailable
    },
    
    data() { 
    return { 
        groups: null,
        filteredgroups:null,     
        filters: {
            firstline: true,
            lastline: false,
            sessionrow: 0,
            first_dt_start: 0,
            total_session_inarow: "",
            headergroupname: "",
            numrows: 25,
            offset: 0,
            lastresult: '',
            dt_inf:  this.$moment().subtract(10, 'days').format('YYYY-MM-DD'),
            dt_sup:  this.$moment().format('YYYY-MM-DD'),
            id_group: '',
            id_host: '',
            id_user: '',
            include_stats:"0",
        },
        sessions: [],
        users:null,
        groups:null,
        loading:false,
            } 
    },
    created() {
        this.$session.setRoomPC( null);
        this.$root.socket("SETPC", null);  
        //this.$store.commit('SET_LAYOUT', 'nav-layout')
        this.groups = this.$session.getGroups();
        this.users = this.$session.getUsers();
       /*  this.$root.$on("finish", message => {
            var mss = message.split("|");
            self.$root.toast(this.$t(mss[0]), 3000, mss[1]);
        }); */
    }, 
    mounted: function () {
        this.headergroupname = "";
        this.sessionrow = 0;
        this.getSessions();
       
        
    },
    methods:{

        saveNote(session) {

            let self = this;
            const note = {
                id_session: session.id,
                note: session['notes'].length == 0 ? session.notedesc : session['notes'][0].note,
                id: session['notes'].length == 0 ? null : session['notes'][0].id,
                hide_customer: 0
            }

            self.$api.saveNote(note).then((value) => {
                if (value) {
                    self.$root.toast(this.$t(PC_MESSAGES.NOTE_SAVED), 5000, "success")
                }
            });
            /*   if ($note["id"] == "") {
                  $response = $http -> post($this -> endpoint.'/api/notes', [
                      'id_session'=> $note["session_id"],
                      'note'=> $note["note"],
                      'hide_customer'=> 0
                  ]);
              } else {
                  $response = $http -> put($this -> endpoint.'/api/notes', [
                      'note'=> $note["note"],
                      'id'=> $note["id"]
                  ]);
              } */
        },

        runMethodWithDelay(callback, delay) {
            setTimeout(callback, delay);
        },

        openMenu(){
            Metro.getPlugin('#remotemenu', 'dropdown').open();
        },

         //metodo per controllare se devo aggiungere o meno la riga iniziale...
        shouldBeAddedBefore(session) {
            if (this.headergroupname == "" || session.name_computer_control != this.headergroupname) {
                this.headergroupname = session.name_computer_control;
                this.sessionrow = 1;
                this.total_session_inarow = session.total_time;
                return true;
            }
            if (session.name_computer_control == this.headergroupname) {
                this.total_session_inarow += session.total_time;
                this.sessionrow += 1;
                return false;
            }

        },
        shouldBeAddedAfter(session) {
            
            if ( this.headergroupname != "" && session.name_computer_control != this.headergroupname ) {
                this.sessionsrow=0;
                return true;
            }
            return false;

        },
        formatDate(date) {
            if(date==null || date =="")return ""
            return formatDate(date, this.$moment);
        },
        fromNow(date) {
                if(date==null || date =="")return ""
                return fromNow(date, this.$moment);
            },
        diffDate(datestart, dateend) {
            return diffDate(datestart, dateend, this.$moment);
        },
         getSessions() {
                let self = this;
                self.loading = true;
                this.$api.getSessions(self.filters).then(res => {
                    self.sessions = res;
                    self.loading = false;
                });
            },
               

     
        
    
        
    
        
    }
}



</script>
