import api from "./api";
import { isValid, createErrorObject, cookieManager, GetAllPaths, GetFoldersNames, FileFolderEntityType } from "../../public/assets/js/utilitiesmodule";
import { ClientTreeNode } from "../models/TreeNode";
import { ERROR } from "../../public/assets/js/messages";
//DOCUMENTAZIONE PARAMETRI QUERY : https://developers.google.com/drive/api/guides/search-files?hl=it
//L'applicazione deve essere pubblicata e deve avere i permessi
//https://console.cloud.google.com/welcome?hl=it&project=cogent-scion-373609
export class GoogleDriveAuthentication {
    constructor(clientID, clientSecret) {
        this.clientID = clientID;
        this.clientSecret = clientSecret;
    }

    async getAuthCode() {

        var params = [
            "scope=https%3A//www.googleapis.com/auth/drive", //drive.metadata.readonly",
            "access_type=offline",
            "include_granted_scopes=true",
            "response_type=code",
            "state=state_parameter_passthrough_value",
            "redirect_uri=http%3A%2F%2Flocalhost%3A8080",
            "client_id=" + this.clientID
        ];

        cookieManager.deleteCookie("authCode");
        var url = "https://accounts.google.com/o/oauth2/v2/auth?" + params.join("&");
        window.open(url);

        var authCode = await cookieManager.waitAndGetCookie("authCode");

        return authCode;

    }

    async getTokensWithAuthorizationCode(authCode) {


        if (!isValid(authCode))
            authCode = await this.getAuthCode();

        var response = await api.post("https://oauth2.googleapis.com/token",
            JSON.stringify({
                code: authCode,
                redirect_uri: "http://localhost:8080",
                scope: "openid", //"https://www.googleapis.com/auth/drive",
                grant_type: "authorization_code",
                client_id: this.clientID,
                client_secret: this.clientSecret
            })
        ).catch(e => {
            console.log(e);
            return {
                status: 400
            };
        });

        console.log(response);

        if (response.status != 200) {
            console.log(ERROR);
            return null;
        }

        return {
            accessToken: response.data.access_token,
            refreshToken: response.data.refresh_token
        };

    }

    async getAccessTokenWithRefreshToken(refreshToken) {
        var response = await api.post("https://www.googleapis.com/oauth2/v4/token", //"https://oauth2.googleapis.com/token",
            JSON.stringify({
                refresh_token: refreshToken,
                //redirect_uri: "http://localhost:8080",
                //scope: "https://www.googleapis.com/auth/drive",
                grant_type: "refresh_token",
                client_id: this.clientID,
                client_secret: this.clientSecret
            })
        );

        console.log(response);

        if (response.status != 200) {
            console.log(ERROR);
            return null;
        }

        return response.data.access_token;
    }
}

export class GoogleDriveClient {
    constructor(accessToken) {
        this.accessToken = accessToken;
    }

    async getRootNodes() {
        var nextContinuationToken = null;
        var response;
        var nodes = [];
        //https://developers.google.com/drive/api/v3/reference/files/list
        var params = _getDefaultParams();
        params.push("q=%28%27root%27%20in%20parents%29"); //Aggiungo una query che mi permette di cercare i children della root
        do {
            response = await createRequest(params, this.accessToken, "", nextContinuationToken);
            if (response == null)
                return null;
            nextContinuationToken = response.nextContinuationToken;
            if (response.status == 200)
                nodes = nodes.concat(response.nodes);
        } while (nextContinuationToken != null);

        return nodes;
    }

    async getNodesFrom(resource) {

        if (!isValid(resource.resID))
            resource.resID = await _getIDFromPath(resource.path, this.accessToken);
        var idPath = resource.resID;
        var nextContinuationToken = null;
        var response;
        var nodes = [];

        var params = _getDefaultParams();
        do {
            //Aggiungo una query che mi permette di cercare i children della risorsa con in idPath
            params.push(("q=('" + idPath + "' in parents)").replace("(", "%28").replace(")", "%29").replace("'", "%27").replace(" ", "%20").replace("/", "%2F").replace(".", "%2C"));

            response = await createRequest(params, this.accessToken, resource.path, nextContinuationToken);
            if (response == null)
                return null;

            nextContinuationToken = response.nextContinuationToken;
            if (response.status == 200)
                nodes = nodes.concat(response.nodes);
        } while (nextContinuationToken != null);

        return nodes;
    }
}

async function createRequest(params, accessToken, parentPath, nextPageToken) {

    if (nextPageToken != null)
        params.push("pageToken=" + nextPageToken);

    var response = await api.get(
            "https://www.googleapis.com/drive/v3/files?" + params.join("&"), {
                headers: {
                    "Authorization": "Bearer " + accessToken
                }
            }
        )
        .then(res => {
            console.log(res);
            var resources = res.data.files.map(r => new ClientTreeNode(
                null,
                r.id,
                parentPath == "" ? r.name : parentPath + "/" + r.name,
                r.mimeType != "application/vnd.google-apps.folder" ?
                FileFolderEntityType.Cloud_File :
                FileFolderEntityType.Cloud_Folder
            ));

            return { status: 200, nodes: resources, nextContinuationToken: isValid(res.data.nextPageToken) ? res.data.nextPageToken : null };
        })
        .catch(e => {
            console.log(e);
            e = createErrorObject(e);
            console.log(e);

            //L'utente deve abilitare Google Drive API
            if (
                isValid(e.message) &&
                e.message.startsWith("Access Not Configured. Drive API has not been used in project")
            )
                return {
                    status: 400,
                    url: e.data.extendedHelp
                };
            else return null;
        });

    //https://console.developers.google.com/apis/api/drive.googleapis.com/overview?project=24613014036

    console.log(response);
    console.log(typeof(response));

    return response;
}

async function _getIDFromPath(path, accessToken) {
    var folders = GetAllPaths(path);
    var foldersNames = GetFoldersNames(path);
    var parentID = "root";
    var children = [];
    var params;

    for (var i = 0; i < foldersNames.length; i++) {
        params = _getDefaultParams();
        //Aggiungo una query che mi permette di cercare all'interno di parentID la risorsa con il nome foldersNames[i]
        params.push("q=('" + parentID + "' in parents and name = '" + foldersNames[i] + "')".replace("(", "%28").replace(")", "%29").replace("'", "%27").replace(" ", "%20").replace("/", "%2F").replace(".", "%2C"));

        children = await createRequest(params, accessToken, parentID == "root" ? "" : folders[i - 1], null);
        if (children == null)
            return null;

        parentID = children.nodes.find(r => r.path == folders[i]).resID;
    }

    return parentID;

}

//https://howto.caspio.com/tech-tips-and-articles/using-special-characters-in-query-string/
function _getDefaultParams() {
    return [
        "corpora=user",
        "fields=nextPageToken%2cfiles%28id%2cname%2cmimeType%29",
        "includeItemsFromAllDrives=false",
        "supportsAllDrives=true",
        "includeDeleted=false",
    ];
}