var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog center h-50 p-5",
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.$root.$emit("CLOSESAREPOVIEW")
        },
      },
    },
    [
      _c("div", [
        _c("span", {
          staticClass: "button square closer",
          on: {
            click: function ($event) {
              return _vm.$root.$emit("CLOSESAREPOVIEW")
            },
          },
        }),
      ]),
      _c("div", { staticClass: "dialog-content p-5 overflow h-100" }, [
        _vm.isValid(_vm.repository)
          ? _c(
              "div",
              [
                _c("h3", { staticClass: "mt-4 fg-cobalt" }, [
                  _vm._v(_vm._s(_vm.$t("Repository"))),
                ]),
                _c("ObjectView", {
                  staticClass: "mt-4",
                  attrs: { obj: _vm.repository, withCopyButton: true },
                }),
                !_vm.isValid(_vm.serviceAccount)
                  ? _c("div", [
                      _c("span", { staticClass: "mr-2 mif-warning fg-red" }),
                      _c("span", { staticClass: "fg-red" }, [
                        _vm._v(_vm._s(_vm.$t("Service account not found"))),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.isValid(_vm.serviceAccount)
          ? _c(
              "div",
              [
                _c(
                  "h3",
                  {
                    staticClass: "fg-cobalt",
                    class: _vm.isValid(_vm.repository) ? "mt-4" : "",
                  },
                  [_vm._v(_vm._s(_vm.$t("Service account")))]
                ),
                _c("ObjectView", {
                  staticClass: "mt-4",
                  attrs: { obj: _vm.serviceAccount, withCopyButton: true },
                }),
                _c("h4", { staticClass: "mt-4 fg-cobalt" }, [
                  _vm._v(_vm._s(_vm.$t("Service account - Options"))),
                ]),
                _c("ObjectView", {
                  staticClass: "mt-4",
                  attrs: {
                    obj: _vm.serviceAccount.options,
                    withCopyButton: true,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }