/*****************************STRINGS*****************************************************************************************************/
//Trasforma la stringa nel seguente modo:
// - la mette tutta in lowercase
// - mette in uppercase la prima lettera e le parole che devono essere in maiuscole, definite nell'array uppercaseStrings
String.prototype.titolize = function() {
    var arr = this.split(" ")

    for (var i = 0; i < arr.length; i++) {
        if (uppercaseStrings.includes(arr[i].toUpperCase()))
            arr[i] = arr[i].toUpperCase();
        else if (i == 0)
            arr[0] = arr[0].charAt(0).toUpperCase() + arr[0].substring(1).toLowerCase();
        else
            arr[i] = arr[i].toLowerCase();
    }
    return arr.join(" ");
}

var uppercaseStrings = ["CPU", "SSL", "POP", "PC", "ESXI", "FTP", "OS", "TFA", "URL", "2FA", "ID", "RAM", "RCT", "CBT", "SSPI", "POP3", "I/O", "MB", "KB", "GB", "TB", "B"];

String.prototype.contains = function(str) {
    return this.indexOf(str) != -1;
}

String.prototype.lastPartOfPath = function() {
    if (this.contains('/'))
        return this.split('/').at(-1);

    if (this.contains('\\'))
        return this.split('\\').at(-1);

    return this;
}

import VueRouter from 'vue-router';
import api from '../../../src/router/api';
VueRouter.prototype.removeHash = function() {
    var currentURL = this.history.current.path;
    var originalURL = currentURL.split("#")[0];
    this.replace({ path: originalURL }).catch((err) => {
        // Ignora l'errore NavigationDuplicated
        if (err.name !== 'NavigationDuplicated') {
            console.error(err);
        }
    });;
}


/*****************************SESSION - SERVICEACCOUNTS*****************************************************************************************************/
//import Vue from 'vue';
export function installSessionPrototypes(Vue) {
    Vue.prototype.$api = api;
    Vue.prototype.$session.getServiceAccounts = function() {
        if (!this.has("SERVICEACCOUNTS"))
            this.set("SERVICEACCOUNTS", []);
        return this.get("SERVICEACCOUNTS");
    }

    Vue.prototype.$session.setServiceAccounts = function(list) {
        this.set("SERVICEACCOUNTS", list);
    }

    Vue.prototype.$session.getServiceAccountWithID = function(id) {
        var serviceAccounts = this.getServiceAccounts();
        var sa = serviceAccounts.find(sa => sa.id == id)
        if (sa == undefined)
            return null;
        return sa;
    }

    Vue.prototype.$session.addServiceAccount = function(sa) {
        var serviceAccounts = this.getServiceAccounts();
        serviceAccounts.push(sa);
        this.set("SERVICEACCOUNTS", serviceAccounts);
    }

    Vue.prototype.$session.editServiceAccount = function(sa) {
        var serviceAccounts = this.getServiceAccounts();

        var saIndex = serviceAccounts.findIndex(s => s.id == sa.id);
        if (saIndex == -1)
            serviceAccounts.push(sa);
        else
            serviceAccounts[saIndex] = sa;
        this.set("SERVICEACCOUNTS", serviceAccounts);

    }

    Vue.prototype.$session.deleteServiceAccount = function(idSA) {
        var serviceAccounts = this.getServiceAccounts();
        var saIndex = serviceAccounts.findIndex(s => s.id == idSA);
        if (saIndex == -1)
            return;
        serviceAccounts.splice(saIndex, 1);
        this.set("SERVICEACCOUNTS", serviceAccounts);

    }

    /*****************************SESSION - REPOSITORIES*****************************************************************************************************/
    Vue.prototype.$session.getRepositories = function() {
        if (!this.has("REPOSITORIES"))
            this.set("REPOSITORIES", []);
        return this.get("REPOSITORIES");
    }

    Vue.prototype.$session.setRepositories = function(list) {
        this.set("REPOSITORIES", list);
    }

    Vue.prototype.$session.getRepositoryWithID = function(id) {
        var repositories = this.getRepositories();

        var repo = repositories.find(r => r.id == id)
        if (repo == undefined)
            return null;
        return repo;
    }

    Vue.prototype.$session.addRepository = function(repo) {
        var repositories = this.getRepositories();

        repositories.push(repo);
        this.set("REPOSITORIES", repositories);
    }

    Vue.prototype.$session.editRepository = function(repo) {
        var repositories = this.getRepositories();

        var repoIndex = repositories.findIndex(r => r.id == repo.id);
        if (repoIndex == -1)
            repositories.push(repo);
        else
            repositories[repoIndex] = repo;
        this.set("REPOSITORIES", repositories);

    }

    Vue.prototype.$session.deleteRepository = function(idRepo) {
        var repositories = this.getRepositories();

        var repoIndex = repositories.findIndex(r => r.id == idRepo);
        if (repoIndex == -1)
            return;
        repositories.splice(repoIndex, 1);
        this.set("REPOSITORIES", repositories);

    }

    /*****************************SESSION - COMPUTERS*****************************************************************************************************/
    Vue.prototype.$session.getPCList = function() {
        if (!this.has("COMPUTERS"))
            return [];
        return this.get("COMPUTERS");
    }

    Vue.prototype.$session.setPCList = function(list) {
        this.set("COMPUTERS", list);
    }

    Vue.prototype.$session.getPCWithID = function(idPC) {
        if (!this.has("COMPUTERS"))
            return null;

        //mappa {id :pc, ...}
        var pcs = this.get("COMPUTERS");
        var pc = pcs.find(x => x.id == idPC);
        if (pc == undefined)
            return null;
        return pc;
    }

    Vue.prototype.$session.getPCsConnections = function() {
        if (!this.has("PCSCONNECTIONS"))
            return [];
        return this.get("PCSCONNECTIONS");
    }

    //{
    //    idPC : true/false
    //    ...
    //}
    Vue.prototype.$session.setPCsConnections = function(idPC_isConnected) {
        this.set("PCSCONNECTIONS", idPC_isConnected);
        var roomPC = this.getRoomPC();
        if (roomPC == null)
            this.set("ONEISCONNECTED", Object.values(idPC_isConnected).some(isConnectedValue => isConnectedValue));
        else
            this.set("ONEISCONNECTED", idPC_isConnected[roomPC.id])
    }

    Vue.prototype.$session.isConnectedToSocket = function(idPC) {
        if (!this.has("PCSCONNECTIONS"))
            return false;
        return this.getPCsConnections()[idPC];
    }

    Vue.prototype.$session.getAtLeastOnePCConnectedToSocket = function() {
        if (!this.has("ONEISCONNECTED"))
            return false;
        return this.get("ONEISCONNECTED");
    }

    Vue.prototype.$session.setAtLeastOnePCConnectedToSocket = function(value) {
        this.set("ONEISCONNECTED", value);
    }

    Vue.prototype.$session.noPCConnectedToSocket = function() {
        return !this.getAtLeastOnePCConnectedToSocket();
    }

    /*****************************SESSION - GROUPS*****************************************************************************************************/
    Vue.prototype.$session.getGroups = function() {
        if (!this.has("GROUPS"))
            return [];
        return this.get("GROUPS");
    }
    Vue.prototype.$session.setGroups = function(groups) {
            this.set("GROUPS", groups);
        }
        /*****************************SESSION - POLICIES*****************************************************************************************************/
    Vue.prototype.$session.getPolicies = function() {
        if (!this.has("POLICIES"))
            return [];
        return this.get("POLICIES");
    }

    Vue.prototype.$session.setPolicies = function(policies) {
        this.set("POLICIES", policies);
    }

    /*****************************SESSION - USERS*****************************************************************************************************/
    Vue.prototype.$session.getUsers = function() {
        if (!this.has("USERS"))
            return [];
        return this.get("USERS");
    }

    Vue.prototype.$session.setUsers = function(users) {
        this.set("USERS", users);
    }

    /*****************************SESSION - ROLES*****************************************************************************************************/
    Vue.prototype.$session.getRoles = function() {
        if (!this.has("ROLES"))
            return [];
        return this.get("ROLES");
    }

    Vue.prototype.$session.setRoles = function(roles) {
        this.set("ROLES", roles);
    }

    /*****************************SESSION - POLICY_TYPES*****************************************************************************************************/
    Vue.prototype.$session.getPolicyTypes = function() {
        if (!this.has("POLICY_TYPES"))
            return [];
        return this.get("POLICY_TYPES");
    }

    Vue.prototype.$session.setPolicyTypes = function(policyTypes) {
        this.set("POLICY_TYPES", policyTypes);
    }

    /*****************************SESSION - TYPES*****************************************************************************************************/
    Vue.prototype.$session.getTypes = function() {
        if (!this.has("TYPES"))
            return [];
        return this.get("TYPES");
    }

    Vue.prototype.$session.setTypes = function(types) {
        this.set("TYPES", types);
    }

    /*****************************SESSION - LICENSES*****************************************************************************************************/
    Vue.prototype.$session.getLicenses = function() {
        if (!this.has("LICENSES"))
            return null;
        return this.get("LICENSES");
    }

    Vue.prototype.$session.setLicenses = function(licenses) {
        this.set("LICENSES", licenses);
    }

    Vue.prototype.$session.getLicense = function() {
        if (!this.has("LICENSE"))
            return null;
        return this.get("LICENSE");
    }

    Vue.prototype.$session.setLicense = function(license) {
        this.set("LICENSE", license);
    }

    /*****************************SESSION - CONNECTION STATES REMOTE*****************************************************************************************************/
    Vue.prototype.$session.getRemoteConnectionStates = function() {
        if (!this.has("REMOTECONNECTIONSTATES"))
            return null;
        return this.get("REMOTECONNECTIONSTATES");
    }

    Vue.prototype.$session.setRemoteConnectionStates = function(connectionStates) {
        this.set("REMOTECONNECTIONSTATES", connectionStates);
    }

    /*****************************SESSION - SINGOLI DATI*****************************************************************************************************/
    Vue.prototype.$session.getRoomPC = function() {
        if (!this.has("ROOMPC"))
            return null;
        return this.get("ROOMPC");
    }

    Vue.prototype.$session.setRoomPC = function(pc) {
        this.set("ROOMPC", pc);
    }

    Vue.prototype.$session.getAuth = function() {
        if (!this.has("AUTH"))
            return null;
        return this.get("AUTH");
    }

    Vue.prototype.$session.setAuth = function(auth) {
        this.set("AUTH", auth);
    }

    Vue.prototype.$session.getLastRemoteVersion = function() {
        if (!this.has("LASTREMOTEVERSION"))
            return null;
        return this.get("LASTREMOTEVERSION");
    }

    Vue.prototype.$session.setLastRemoteVersion = function(version) {
        this.set("LASTREMOTEVERSION", version);
    }



}