var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _vm.lastresult == _vm.$root.ResultValue.OK
      ? _c("span", {
          staticClass: "fg-green text-bold mif-checkmark mif-2x",
          attrs: { title: _vm.$t("Ok") },
        })
      : _vm._e(),
    _vm.lastresult == _vm.$root.ResultValue.SomeWarnings
      ? _c("span", {
          staticClass: "fg-orange text-bold mif-warning mif-2x",
          attrs: { title: _vm.$t("Some Warnings") },
        })
      : _vm._e(),
    _vm.lastresult == _vm.$root.ResultValue.Error
      ? _c("span", {
          staticClass: "fg-red text-bold mif-cross mif-2x",
          attrs: { title: _vm.$t("Error") },
        })
      : _vm._e(),
    _vm.lastresult == _vm.$root.ResultValue.Running
      ? _c("span", {
          staticClass: "fg-black mif-spinner ani-spin",
          attrs: { title: _vm.$t("Running") },
        })
      : _vm._e(),
    _vm.lastresult == _vm.$root.ResultValue.Aborted
      ? _c("span", {
          staticClass: "fg-red text-bold mif-checkmark",
          attrs: { title: _vm.$t("Aborted by user") },
        })
      : _vm._e(),
    _vm.lastresult == _vm.$root.ResultValue.NeverExecuted
      ? _c("span", { attrs: { title: _vm.$t("Never executed") } })
      : _vm._e(),
    _vm.lastresult == _vm.$root.ResultValue.TerminatedAbnormally
      ? _c("span", {
          staticClass: "fg-violet text-bold mif-cross-light",
          attrs: { title: _vm.$t("Abnormally terminated") },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }