<template>
    <div v-on:keyup.esc="$root.$emit('CLOSERESTOREDIALOG')" class="dialog light w-100-sm w-100-md w-75-lg  w-50-xl"
        v-cloak>

        <DialogTitle :title="$t('Configure ESXI Restore')" :defaultTitle="$t('Config ESXI restore')"
            :subTitle="$t('ESXi Backups')" @closeDialog="$root.$emit('CLOSERESTOREDIALOG')" />

        <div class="dialog-content p-4">

            <!-- SELEZIONE DELL'ACCOUNT PER RESTORE-->
            <div v-show="steps[0]" class="container-fluid">

                <!------------------------------SCELTA DEL SERVICE ACCOUNT--------------------------------------------------------------->

                <!--Questa pagina deve implementare il metodo "sendSATestResult"-->
                <SARepoSelect :type=source.type :idSA=source.id_service_account :idRepo=null
                    @sendSATestResult="sendSATestResult" />

            </div>

            <!--VISUALIZZA SOLO SPINNER CONNESSIONE E AL FINE CONNECTION SUCCESSFULLY-->
            <div v-show="steps[0] && (typeof source.id_service_account) == 'string'" class="container-fluid">
                <div class="row mt-4" v-if="source.esxiSource">
                    <span class="mif-done mif-lg fg-green"></span><span>{{ $t('Connected successfully') }}</span>
                    <span class="small-text"> ({{ source.esxiSource.url }}-{{ source.esxiSource.hostVersion }})</span>
                </div>

                <span v-if="source.esxiSource == null" class="mt-4 mif-spinner2 ani-spin mr-1"></span><span
                    v-if="source.esxiSource == null">{{ $t('Connection in progress, please wait') }}.</span>
            </div>

            <!--SCELTA PERCORSI-->
            <div v-show="steps[1]" class="grid p-10">
                <div class="row h6 text-bold mb-4">
                    <div class="cell-12">{{ $t('Choosing folder containing backup') }}</div>
                </div>
                <NoDataAvailable v-if="source.folderSource.paths.length == 0" :message="$t('No item selected')" />
                <!--VISUALIZZAZIONE DEGLI ELEMENTI-->
                <div v-else class="overflow mt-4 h-vh-33">
                    <div class="cell">
                        <table class="table striped d-table-lg text-small">
                            <tbody>
                                <tr v-for="res in source.folderSource.paths" v-bind:key="res.id">
                                    <td class="d-flex">
                                        <span :class="getMetroIcon(res.type, res.path)" class="px-2" />
                                        <span v-if="source.options.recreateCompletePath" class="flex-self-center">{{
                                            res.path }}</span>
                                        <span v-else class="flex-self-center">{{ (res.path.indexOf('/') != -1 ?
                                            res.path.split('/') : res.path.split('\\')).at(-1) }}</span>
                                    </td>
                                    <td><span class='mif-bin' @click="$root.$emit('uncheckNode', res)" /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div v-show="steps[2]" class="container-fluid">

                <div v-if="listbackup.length < 1" data-role="activity" data-type="metro" data-style="dark"></div>

                <div v-if="listbackup.length > 0" id="esxiBackupList" class="pb-4 w-100" style="overflow: scroll;">

                    <table data-role="sorter" data-sort-dir="desc" class="table striped text-small "
                        data-show-search="true" data-show-rows-steps="false" data-search-min-length="3"
                        data-search-threshold="300">

                        <thead>
                            <tr>
                                <td>Sel</td>
                                <td data-name="dt_start_utc" data-sortable="true" data-format="int">{{ $t('Date') }}
                                </td>
                                <td data-name="dt_end_utc" data-sortable="true">{{ $t('Number') }}</td>
                                <td data-name="backup_size" data-sortable="true">{{ $t('Size') }}</td>
                                <td data-name="copied_files" data-sortable="true" data-sort-dir="desc">{{ $t('Type') }}
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(lb) in listbackup" :key="lb.dirName" track-by="log.id">
                                <td>
                                    <input class="ml-5" name="r1" type='radio' data-role='radio'
                                        @click="putBackupInEsxiRestoreModel(lb)">
                                </td>
                                <td>{{ convertDate(lb.timeStamp) }}</td>
                                <td>{{ lb.dirName }}</td>
                                <td>{{ lb.dirSize | prettyBytes }}</td>
                                <td>{{ backupTypeToName(lb.type, lb.dirName) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>


            </div>

            <div v-show="steps[3]" class="container-fluid">

                <div v-if="dataStoreList.length == 0" data-role="activity" data-type="metro" data-style="dark"></div>

                <div v-if="dataStoreList.length > 0" class="row mt-4"><b>{{ $t('Select a Datastore') }}:</b></div>

                <div class="row">

                    <select data-role="select" data-filter="false" v-if="dataStoreList.length > 0" v-model="dataStore">
                        <option v-for="(datastore, index) in dataStoreList"
                            v-bind:key="datastore.datastore.name + '_' + index" :value="datastore">
                            [{{ datastore.datacentername }}]/[{{ datastore.resourcename }}] {{
                            datastore.datastore.name
                            }}
                            [{{ datastore.datastore.freeSpace | prettyBytes }}/{{ datastore.datastore.capacity |
                            prettyBytes
                            }}]
                        </option>
                    </select>

                </div>
                <div class="row mt-4" v-if="dataStore">
                    <label><b>{{ $t('Virtual machine name (the name must not already exist)') }}:</b></label>
                </div>
                <div class="row" v-if="dataStore">
                    <input type="text" class="w-50" data-role="textbox" v-model="suggestedRestoredVmName" />
                </div>
                <!--div class="row mt-4" v-if="dataStore">
                                <input type="checkbox" data-role="checkbox" data-caption="Port (CBT)" v-model="portchech"/>
                            </div-->
                <div class="row mt-4" v-if="dataStore">
                    <label><b>{{ $t('Port (CBT)') }}:</b></label>
                </div>
                <div class="row" v-if="dataStore">
                    <input type="number" minlength="2" maxlength="5" placeholder="Port" v-model.number="port" />
                </div>
            </div>

            <!--SUMMARY END BEFORE START RESTORE -->
            <div v-show="steps[4]" class="container-fluid">

                <div v-if="dataStoreList.length > 0" class="row mt-4 mb-2"><b>{{ $t('Summary') }}:</b></div>

                <div class="row mb-2">
                    <div v-if="source.esxiSource != null" class="cell-3">{{ $t('Server') }}</div>
                    <div v-if="source.esxiSource != null" class="cell">{{ source.esxiSource.url }}-{{
                        source.esxiSource.hostVersion }}</div>
                </div>

                <div class="row mb-2">
                    <div v-if="source.folderSource != null && source.folderSource.paths.length > 0" class="cell-3">{{
                        $t('Backup path') }}</div>
                    <div v-if="source.folderSource != null && source.folderSource.paths.length > 0" class="cell">{{
                        source.folderSource.paths[0].path }}</div>
                </div>

                <div class="row mb-2">
                    <div class="cell-3">{{ $t('Selected backup') }}</div>
                    <div class="cell">{{ convertDate(selBackupInfo.date) }} {{ selBackupInfo.number }} {{
                        selBackupInfo.dimensione | prettyBytes }} {{ selBackupInfo.tipo }}</div>
                </div>
                <div class="row mb-2" v-if="dataStore">
                    <div class="cell-3">{{ $t('Destination datastore') }}</div>
                    <div class="cell">[{{ dataStore.datacentername }}]/[{{ dataStore.resourcename }}] {{
                        dataStore.datastore.name
                        }}
                        [{{ dataStore.datastore.freeSpace | prettyBytes }}/{{ dataStore.datastore.capacity |
                        prettyBytes
                        }}]</div>
                </div>
                <div class="row mb-2">
                    <div class="cell-3">{{ $t('Name of the target VM') }}</div>
                    <div class="cell">{{ suggestedRestoredVmName }}</div>
                </div>

            </div>

            <div v-if="steps[1] && restore" class="container-fluid">

                <div class="row mt-4 ml-2">
                    <div class="cell-8">
                        <label>{{ restore.vmname }}</label>
                        <br />
                        <!--  <span class='badge inline bg-black fg-white m-2 p-1 w-33'>{{backup.scheduling==null || backup.scheduling==''?"On Demand":"Queued"}}</span> -->
                        <span class='badge inline bg-cobalt fg-white p-1 mt-2 mr-1'>{{
                            restore.type_name
                            }}</span>
                        <span class='badge inline bg-cobalt fg-white p-1 mt-2 mr-1'>{{
                            restore.datacenter
                            }}</span>
                        <span class='badge inline bg-cobalt fg-white p-1 mt-2 mr-1'>n. {{
                            restore.backupnumber
                            }}</span>
                        <span class='badge inline bg-cobalt fg-white p-1 mt-2 mr-1'>{{
                            restore.datastore + '-' + restore.host
                            }}</span>
                    </div>

                    <div class="cell-8">
                        <label v-if="restore.dt_start_utc != '' && restore.dt_start_utc != null">{{
                            restore.dt_start_utc.substr(0, 8) + "T" + restore.dt_start_utc.substr(8) |
                            utcAsLocal | moment("MM/DD/YY hh:mm A") }}</label>
                    </div>
                    <div class="cell-8">
                        <RestoreLastResult :lastresult="restore.lastresult" />
                    </div>
                    <div class="cell-6">
                        <div v-show="restore.progress != null && restore.progress != 0">
                            <div class="clear">
                                <small v-if="restore.current_operation.length <= 50" class="place-left" data-role="hint"
                                    data-hint-position="top" :data-hint-text="restore.current_operation">{{
                                    restore.current_operation
                                    }}</small>
                                <small v-else-if="restore.current_operation <= 100" class="place-left" data-role="hint"
                                    data-hint-position="top" :data-hint-text="restore.current_operation">{{
                                    restore.current_operation.substr(0, 50) + "..."
                                    }}</small>
                                <small v-else class="place-left" data-role="hint" data-hint-position="top"
                                    :data-hint-text="restore.current_operation">{{
                                    restore.current_operation.substr(0,
                                    50) + "..." + restore.current_operation.substr(-50, 50)
                                    }}</small>
                            </div>
                            <div data-role="progress" data-type="line" :data-value="restore.progress" data-small="true"
                                data-cls-bar="bg-cobalt" data-role-progress="true" class="progress small">
                                <div class="bar ribbed-cobalt" :style="{ width: restore.progress + '%' }">
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="cell-2">
                        <small v-if="restore.isRunning" class="place-right text-bold enlarge-1">{{
                            restore.progress
                            }}%</small>
                    </div>
                </div>

            </div>

        </div>

        <div class="dialog-actions">
            <div class="ml-auto">
                <button class="button error float-right" @click="$root.$emit('CLOSERESTOREDIALOG')">{{ $t('Cancel')}}</button>
                <button class="button" :class="{ 'disabled': steps[0] }" @click="back">
                    <span class="mif-arrow-left"></span>{{ $t('Prev') }}</button>
                <button class="button" :class="{ 'disabled': (steps[0] && source.esxiSource == null) || (steps[1] && this.source.folderSource.paths.count == 0) || (steps[2] && this.bkpDirSelected == '') || (steps[4]) || (steps[5]) }"
                    @click="next">{{ $t('Next') }} <span class="mif-arrow-right ml-2"> </span> </button>
                <button class="button primary float-right" v-on:click="toggleFileSystemSidebar()"
                    v-show="steps[1]">{{$t('Select items') }}</button>
                <button class="button primary float-right" v-show="steps[4]" @click="beginRestore">
                    <span class="mif-checkmark pr-2"></span>{{$t('Restore') }}
                </button>
            </div>
        </div>

    </div>
</template>
<script>
import DialogTitle from "../../utils/DialogTitle";
import NoDataAvailable from "../../utils/NoDataAvailable.vue";
import moment from 'moment'
import RestoreLastResult from "../../utils/RestoreLastResult";
import SARepoSelect from "../../utils/SARepoSelect";
import { syncronizeArray, waitSeconds, getMetroIcon} from "../../../../public/assets/js/utilitiesmodule"


export default {
    name: "ESXiManualRestore",
    props: {
        source: {
            type: Object,
            required: true
        }
    },
    components: {
        "DialogTitle": DialogTitle,
        "NoDataAvailable": NoDataAvailable,
        "RestoreLastResult": RestoreLastResult,
        SARepoSelect
    },
    filters: {
   
    },

    data() {
        return {
            steps: [1, 0, 0, 0, 0, 0],

            drives: [],
            driveSelected: 'C:\\',

            go: false,
            justOneGuid: '',

            virtualMachines: [],
            wait: true,
            waitvm: true,
            bkpDirSelected: '',
            checkedNames: [],
            id_service_account: -2,

            restores: null, // sochet [GT - 06/06/2023]
            restore: null,

            EsxiBackupDetails: {
                path: ''
            },

            // array con lista delle cartelle dei backup effattuati
            listbackup: [],

            ESXiRestoreModel:
            {
                Id: '',
                JobId: '', // questo lo manda l'interfaccia client, da recuperare dal job del backup
                LogId: '', // questo lo manda l'interfaccia client
                esxiAccount: null,
                ImpersonationUsername: '',
                ImpersonationPassword: '',
                NetworkUsername: '',
                NetworkPassword: '',
                Command: 'restore',
                UseVDDK: true,
                VDDKDllConfigFile: '',
                BackupNumber: 0,
                VirtualMachines: '',// "ESXi8»192.168.0.121»datastore1»Ubuntu20_restored_24173739",                    
                VmFolderPath: '',// @"D:\VM_TEST_MAREK\Ubuntu20\",
                //ExternalDllLogFile = Path.Combine(currentJobLogPath, DateTime.Now.ToString("yyyyMMddHHmmss") + "_Restore-ESXI.log"),// @"G:\IPERIUS_DEV_FILES\ESXiRestore\20230224173805\Restore-01896-ESXi.log",
                //LogFilePath = currentJobLogPath,
            },
            job: {},
            logs: [],

            destination: {
                id: null,
                type: 4,
                type_name: "ESXi",
                port: 21,
                id_service_account: -2,
                id_repository: -2,
                region: "",
                options: {
                    signatureversion: 2,
                    connectionType: 'ftps'
                },
                icon: "assets/img/icons/disk.png",
                path: "",
            },

            ESXiVmPowerState: [
                "OFF", "ON", "SUSPENDED"
            ],

            dataStoreList: [],
            dataStore: null,
            esxiselected: null,

            folder: null,

            connection: null,

            pc_isconnected: '',
            portchech: null,
            port: null,
            virtualMachineName: '',
            suggestedRestoredVmName: '',

            selBackupInfo: {
                date: '',
                number: '',
                dimensione: 0,
                tipo: ''
            }



        }
    },

    methods: {
        async sendSATestResult(testResult) {
            let self = this;
            this.source.id_service_account = testResult.serviceAccount.id;
            var subSource = await this.$root.socket("GetVirtualMachinesESXi", this.source.id_service_account);
            self.source.driveImgSource = null;
            self.source.exchangeSource = null;
            self.source.dbSource = null;

            //Se la richiesta non è andata a buon fine, ritorno alla prima pagina
            if (subSource == null || subSource.dataCenters == null) {
                self.step = 0;
                self.source.id_service_account = -2;
                self.wait = false;
                return;
            }

            if (this.source.id == null) {
                this.source.esxiSource = subSource;
                self.source.enableCompression = false;
                self.source.options.backupType = "F";
                self.source.options.backupMode = "STD";
                self.source.options.replicaName = "-Replica";
                self.source.options.nCopies = 1;

                //carico su dataStoreList la lista parsata dei data store in modo da usarla quando mi serve 
                //self.dataStoreList = self.extractDatastoreList(subSource);
                self.dataStoreList = self.parseDataStoreList(subSource);

                self.wait = false;
                return;
            }

            self.dataStoreList = self.parseDataStoreList(subSource);

            var newDatacenters = subSource.dataCenters;
            var oldDatacenters = this.source.esxiSource.dataCenters;

            var syncronizationResult = syncronizeArray(4, oldDatacenters, newDatacenters);

            this.source.esxiSource.dataCenters = syncronizationResult.finalArray;
            //carico su dataStoreList la lista parsata dei data store in modo da usarla quando mi serve 
            syncronizationResult.messages.forEach(async messageObj => {
                self.$root.toast(this.$t(messageObj.message, messageObj.args), 3000, "warning");
                await waitSeconds(3);
            });
        },

        //valorizza l'array con le virtual machine incluse nel singolo backup
        populateVMArray() {
            let self = this;
            self.waitvm = true;
            var dc, cl, ds, vm; // , idsToClose = [];

            // svuoto array
            self.virtualMachines.splice(0);

            console.log('[ESXiDestinationRestore.populateVMArray] - self.job.backupSources: ' + self.job.sources);


            if (self.job.sources)
                if (self.job.sources.length > 0)
                    for (var idc = 0; idc < self.job.sources[0].esxiSource.DataCenters.length; idc++) { // ciclo datacenters
                        dc = self.job.sources[0].esxiSource.DataCenters[idc];
                        for (var icl = 0; icl < dc.ComputerResourceList.length; icl++) { // ciclo gli host che ospitano i datastore
                            cl = dc.ComputerResourceList[icl];
                            for (var ids = 0; ids < cl.Datastores.length; ids++) { // ciclo datastore 
                                ds = cl.Datastores[ids];
                                for (var ivm = 0; ivm < ds.VirtualMachines.length; ivm++) { // ciclo virtual machines
                                    vm = ds.VirtualMachines[ivm];
                                    vm.selectedRestore = false;
                                    if (!vm.Selected) {
                                        ds.VirtualMachines.splice(ivm, 1); // rimuove dall'array la vm non selezionata
                                        ivm--;
                                    } else {
                                        //idsToClose.push(vm.name.replace(" ", ""));
                                        self.virtualMachines.push(vm);
                                    }
                                }
                                if (ds.VirtualMachines.length == 0) {
                                    cl.Datastores.splice(ids, 1); // se non ho trovato nessuna virtual machines elimino il datastore dall'array
                                    ids--;
                                }
                            }
                            if (cl.Datastores.length == 0) {
                                dc.ComputerResourceList.splice(icl, 1); // se non ho trovato nessun datastore elimino l'host dall'array
                                icl--;
                            }
                        }
                        if (dc.ComputerResourceList.length == 0) {
                            self.job.sources[0].esxiSource.DataCenters.splice(idc, 1); // se non ho trovato nessun host elimino il datacenter dall'array
                            idc--;
                        }
                    } // first for


            setTimeout(function () {
                //var treeview = window.Metro.get$el("#esxiTreeview").data("treeview");
                //console.log(treeview)
                /*idsToClose.forEach(id => {
                    console.log(treeview);
                    console.log(treeview.toggleNode);
                    console.log(id);
                    console.log($("#" + id));

                    treeview.toggleNode($("#" + id)); // espande tutti i nodi 

                });*/
                self.waitvm = false;

            }, 1000);

        },
       
        convertDate(date) {
            date = this.$moment(date.substr(0, 8) + "T" + date.substr(8), "YYYYMMDD HH:mm:ss");
            return this.$moment.utc(date).format("ddd DD/MM/YYYY HH:mm:ss");
        },
        backupTypeToName(tipo, dirname) {


            if (dirname == '001') return this.$t('Complete (Full)');

            switch (tipo.toLowerCase().trim()) {
                case "f":
                    return this.$t('Complete (Full)');
                case "i":
                    return this.$t('Incremental');
                case "d":
                    return this.$t('Differential');
                default:
                    return '';
            }

        },

        /*********************METODI***********************************************/
        putBackupInEsxiRestoreModel(lb) {

            let self = this;
            self.bkpDirSelected = lb.dirname;

            self.selBackupInfo.date = lb.timeStamp;
            self.selBackupInfo.number = lb.dirName;
            self.selBackupInfo.dimensione = lb.dirSize;
            self.selBackupInfo.tipo = self.backupTypeToName(self.EsxiBackupDetails.ibiconfig.type, lb.dirName);

        },

        /* SUBSCRIBE METODO SOCKET PER RESTORE EFFETTIVO*/
        async beginRestore() {
            var next = true;
            let self = this;

            self.go = confirm(this.$t("Restore the selected backup?"));


            if (self.go) {

                // faccio vedere la sezione del progress
                this.steps.forEach((step, index) => {
                    if (step == 1) {
                        if (next) {
                            this.$set(this.steps, index + 1, true)
                            this.$set(this.steps, index, false)
                            next = false;

                        }
                    }
                });

                self.justOneGuid = self.uuidv4();

                self.ESXiRestoreModel.Id = self.justOneGuid;
                self.ESXiRestoreModel.ID_LOG = '';
                self.ESXiRestoreModel.ID_JOB = '';
                self.ESXiRestoreModel.id_service_account = self.source.id_service_account;
                self.ESXiRestoreModel.Command = 'Restore';
                self.ESXiRestoreModel.BackupNumber = parseInt(self.selBackupInfo.number);
                self.ESXiRestoreModel.VirtualMachines = self.dataStore.datacentername + '»' + self.dataStore.resourcename + '»' + self.dataStore.datastore.name + '»' + self.suggestedRestoredVmName
                self.ESXiRestoreModel.BackupFolderPath = self.EsxiBackupDetails.path;
                self.ESXiRestoreModel.backuptype = self.EsxiBackupDetails.ibiconfig.type;
                self.ESXiRestoreModel.NetworkUsername = '';
                self.ESXiRestoreModel.NetworkPassword = '';
                self.ESXiRestoreModel.ImpersonationUsername = '';
                self.ESXiRestoreModel.ImpersonationPassword = '';
                self.ESXiRestoreModel.UseVDDK = true,
                    self.ESXiRestoreModel.VDDKDllConfigFile = '',

                    self.ESXiRestoreModel.type = self.source.type;
                self.ESXiRestoreModel.type_name = self.source.type_name;


                console.log("[ESXIManualRestore.beginRestore]");

                this.$root.socket("RESTOREVIRTUALMACHINE", self.ESXiRestoreModel, self.source.id_service_account);

                // invoca agent per dare start ad invio delle informazioni sul restore
                self.idPC = await this.$root.getPC().id;
                //###DA INSERIRE IN OGNI COMPONENTE CHE NECESSITA DI CHIAMATE SOCKET###
                // "checkSocket" avvia la connessione socket se ferma
                // "socketEventConnected" viene emesso quando la connessione socket è attiva, quindi nella sua callback si possono avviare tutte le istruzioni che riguardano le chiamate socket
                // "socketEventDisconnected" viene emesso quando la connessione socket non è attiva
                this.$root.$on("socketEventConnected", () => {
                    self.$root.socketConnected = true;
                    self.pc_isconnected = true;
                    self.$root.socket("subscribe_pc", self.idPC).then(() => 
                        self.$root.socket("STARTSENDINFO")
                    );
                });
                this.$root.$on("socketEventDisconnected", () =>{
                    self.$root.socketConnected = false;
                    self.pc_isconnected = false;
                });
                this.$root.checkSocket();
                // -------------------------------------------------------------------- 


                // subscrive per ricevere il progress del restore ---------------------
                this.$root.$on("addRestoreToPage", restores => {
                    // TODO
                    console.log('Restore in popup');
                    console.log(restores);
                    //self.restores = restores;

                    self.restore = restores.find(obj => {
                        return obj.id == self.justOneGuid
                    })

                });
                // --------------------------------------------------------------------
            }
        },


        uuidv4() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
                .replace(/[xy]/g, function (c) {
                    const r = Math.random() * 16 | 0,
                        v = c == 'x' ? r : (r & 0x3 | 0x8);
                    return v.toString(16);
                });
        },


        toggleFileSystemSidebar() {

            var config = {
                type: this.$root.PlatformsTypesEnum.FileFolder, // sempre numero 8 per selezionare file/folder
                includeFiles: false,
                multipleChoice: false,
                client: null
            };

            this.$root.$emit(
                "toggleFileSystemSidebar",
                this.source.folderSource.paths,
                config
            );
        },

        formatdatefrom(format, value) {
            if (value) {
                return moment(String(value)).format(format)
            }
        },

        next() {
            var next = true;
            let self = this;

            // nel caso in cui ci trovi al primo step e gli oggetti necessari a valorizzare gli input da form sono nulli ripeto la connessione e il retrieve degli
            // oggetti da esxi
            if (self.steps[0]) {
                if (self.source == null || self.source.esxiSource == null || self.source.esxiSource.dataCenters == null || self.dataStoreList.length == 0) {
                    self.source.esxiSource == null
                    self.setESXiSource();
                }
            }

            // una volta selezionato il path locale chiedo all'agent tramite socket la configurazione del file .ibi contenuto nella directory destinazione del backup 
            if (self.source.folderSource) {
                if (self.steps[1] && self.source.folderSource.paths.length > 0) {
                    self.getEsxiBackupConfigurationFromFileSystem(self.source.folderSource.paths[0].path);
                }
            }

            // devo valorizzare il campo suggerito per la nuova vm da ripristinare
            if (self.steps[2]) {
                // es: Ubuntu20_restored_23173655
                self.suggestedRestoredVmName = self.EsxiBackupDetails.ibiconfig.vmName + '_restored_' + moment().format('YYYYMMDDhhmmss');
            }

            this.steps.forEach((step, index) => {
                if (step == 1) {
                    if (next) {
                        this.$set(this.steps, index + 1, true)
                        this.$set(this.steps, index, false)
                        next = false;

                    }
                }
            });

        },

        back() {
            var next = true;
            this.steps.forEach((step, index) => {
                if (step == 1) {
                    if (next) {
                        this.$set(this.steps, index - 1, true)
                        this.$set(this.steps, index, false)
                        next = false;

                    }
                }
            });

        },
      

        // popola un array con gli attribiti dei datastore presenti sul vmware vcenter
        parseDataStoreList(esxiObj) {

            //console.log(esxiObj);
            let dataStoreListArray = [];

            if (esxiObj.dataCenters != null) {
                if (esxiObj.dataCenters.length > 0) {
                    esxiObj.dataCenters.forEach(datacenter => {
                        this.datacentername = datacenter.name;
                        datacenter.computerResourceList.forEach(resource => {
                            this.resourcename = resource.name;
                            resource.datastores.forEach(datastore => {
                                var temp = {
                                    "datacentername": this.datacentername,
                                    "resourcename": this.resourcename,
                                    "datastore": datastore
                                }
                                dataStoreListArray.push(temp);

                            })
                        })
                    });
                }
            }

            return dataStoreListArray;

        },

        // chiamata socket per leggere la configurazione di un backup dal suo percorso
        // come backup software win
        async getEsxiBackupConfigurationFromFileSystem(path) {

            let self = this;

            if (path != '') {
                self.EsxiBackupDetails.path = path;
                var esxiBkpConfig = await this.$root.socket("ESXI_CHECKPATHANDGETBACKUPDETAILS", self.EsxiBackupDetails);
                if (esxiBkpConfig != null) {
                    self.EsxiBackupDetails = esxiBkpConfig;
                    self.listbackup = self.EsxiBackupDetails.ibiconfig.listBackup;
                }

                if (self.listbackup.length < 1)
                    alert(self.$t('No valid backups found'), self.$t('Validity check of backup files'), 'warning');


            }

        }


    },

    getMetroIcon(type, path) {
        return getMetroIcon(type, path);
    }

}
</script>