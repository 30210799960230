import { GetAllPaths, getParentOf, getSeparator, FileFolderEntityType, getMetroIcon, PlatformsTypesEnum, isValid } from "../../public/assets/js/utilitiesmodule";

export class ClientTreeNode {

    constructor(nodeID, resID, path, type) {
        this.nodeID = nodeID;
        this.resID = resID;
        this.path = path;
        this.type = type; // FileFolderEntityType: ...\iperius-enterprise-agent\IperiusEWP\BackupSources\FileFolder\Core\Utils\Enums.cs

        var slash = getSeparator(path);

        this.nParts = path.split(slash).length;
        this.children = new ClientTreeNodeMethods().isFile(type) ? null : [];
        this.visible = true;

        return this;
    }
}

export class ClientTreeNodeMethods {
    /**********************RICERCA*****************************************************/
    findNodeWithPath(startNode, path) {
        if (startNode.path == path) return startNode;
        var child = startNode.children.find(res =>
            path == res.path ||
            path.startsWith(res.path + "/") ||
            path.startsWith(res.path + "\\") ||
            (path.startsWith(res.path) && res.path.endsWith("/")) ||
            (path.startsWith(res.path) && res.path.endsWith("\\"))
        );
        return this.findNodeWithPath(child, path);
    }

    findNodeWithId(startNode, id) {
        if (startNode.nodeID == id) return startNode;

        var indexes = id.split("-");
        if (indexes.length == 1)
            return startNode;

        indexes.shift(); //rimuove il primo elemento
        indexes = indexes.map(i => parseInt(i));
        //ogni indice indica la posizione del figlio da prendere
        var currentNode = startNode;
        indexes.forEach(i => currentNode = currentNode.children[i]);
        return currentNode;
    }

    toSimpleResource(node) {
        return {
            path: node.path,
            type: node.type
        }
    }

    toString(node) {
        return node.path + "?" + node.type;
    }

    getParent(node) {
        var id = node.nodeID.lastIndexOf("-") == 4 //node-{numero con una cifra}
            ?
            "" :
            node.nodeID.substr(0, node.nodeID.lastIndexOf("-"));

        var parentPath = getParentOf(node.path);

        return new ClientTreeNode(id, null, parentPath, false);
    }

    hasParent(node) {
        return node.nodeID != "node" && node.nodeID.lastIndexOf("-") != 4;
    }

    getTemplate(node, checked) {
        var shortPath = this.getShortPath(node);
        var icon = getMetroIcon(node.type, node.path);
        var template =
            "<div class='d-flex flex-align-center'>" +
            "<input type='checkbox' data-role='checkbox' id='checkbox-" + node.nodeID + "'" +
            (checked ? 'checked ' : '') +
            ">" +
            "<label><span class='" + icon + " mx-1'/><span class='mx-1'>" + shortPath + "</span></label>" +
            "</div>";

        return template;
    }

    getTemplateForSingleChoice(node, checked) {
        var shortPath = this.getShortPath(node);
        var icon = getMetroIcon(node.type, node.path);
        var template =
            "<div class='d-flex flex-align-center'>" +
            "<input name='node' type='radio' data-role='radio' id='checkbox-" + node.nodeID + "'" + (checked ? 'checked ' : '') + ">" +
            "<label><span class='" + icon + " mx-1'/><span class='mx-1'>" + shortPath + "</span></label>" +
            "</div>";

        return template;
    }

    getShortPath(node) {
        var shortPath = "";
        if (node.path.length == 3 && node.path.indexOf("\\") != -1) shortPath = node.path;
        else if (node.path.indexOf("\\") != -1) shortPath = node.path.split("\\").pop();
        else if (node.path.indexOf("/") != -1) shortPath = node.path.split("/").pop();
        else if (node.path.indexOf("?") != -1) shortPath = node.path.split("?")[0];
        else shortPath = node.path;

        return shortPath;
    }



    insertParentsInto(node, inputNodes, platformType) {
        if (!isValid(inputNodes))
            inputNodes = [];
        var parentsPaths = GetAllPaths(node.path);
        parentsPaths.splice(-1, 1);
        parentsPaths.forEach(p => {
            if (inputNodes.findIndex(n => n.path == p) == -1)
                inputNodes.push(new ClientTreeNode(null, null, p, this.getParentType(node, platformType)));
        });

        return inputNodes;
    }

    getParentType(node, platformType) {
        if ([PlatformsTypesEnum.FileFolder, PlatformsTypesEnum.Network].includes(platformType))
            return FileFolderEntityType.FN_Folder;
        if (platformType == 2)
            return FileFolderEntityType.FTP_Folder;
        //childpath è la prima cartella
        if (node.path.indexOf("/") == -1) {
            return platformType == PlatformsTypesEnum.Microsoft_SharePoint ?
                FileFolderEntityType.SP_Site_All :
                FileFolderEntityType.Cloud_Root;
        }
        var nFolders = node.path.split("/").length;
        if (nFolders == 2)
            switch (platformType) {
                case PlatformsTypesEnum.Microsoft_SharePoint:
                    return FileFolderEntityType.SP_Site;
                case PlatformsTypesEnum.Cloud_AmazonS3Storage:
                case PlatformsTypesEnum.Cloud_S3CompatibleStorage:
                case PlatformsTypesEnum.Cloud_IperiusS3Storage:
                case PlatformsTypesEnum.Cloud_AzureBlob:
                case PlatformsTypesEnum.Cloud_AzureFile:
                    return FileFolderEntityType.Cloud_Bucket;
                default:
                    return FileFolderEntityType.Cloud_Folder;
            }


        if (nFolders == 3 && platformType == PlatformsTypesEnum.Microsoft_SharePoint)
            return FileFolderEntityType.SP_Library;


        return platformType == PlatformsTypesEnum.Microsoft_SharePoint ?
            FileFolderEntityType.SP_Folder :
            platformType == PlatformsTypesEnum.FTP ?
            FileFolderEntityType.FTP_Folder :
            FileFolderEntityType.Cloud_Folder;
    }

    isFile(node_or_type) {

        var type = typeof node_or_type == 'number' ? node_or_type : node.type
        switch (type) {
            case FileFolderEntityType.FN_File:
                return true;
            case FileFolderEntityType.Cloud_File: //CLOUD_FILE
                return true;
            case FileFolderEntityType.SP_File: //SP_FILE
                return true;
            case FileFolderEntityType.FTP_File: //FTP_FILE
                return true;
            default:
                return false;

        }
    }
}

export class FileSystemSidebarConfig {
    constructor(type, includeFiles, multipleChoice, client, rootPath, oneLevel, tree) {
        this.type = type;
        this.includeFiles = includeFiles;
        this.multipleChoice = multipleChoice;
        this.client = client;
        this.rootPath = rootPath;
        this.oneLevel = oneLevel;
        this.tree = tree;
    }

    toString() {
        return JSON.stringify(this)
    }
}