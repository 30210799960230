<template>
    <div class="dialog  light w-100-sm w-100-md w-75-lg  w-50-xl h-75 draggable-item" id="policy_dialog"
        v-if="policy.refreshID != 0">
        <div class="dialog-title">
            {{ policy.id == null ? $t('Create New Policy') : policy.name }}
            <span class="button square closer" @click="$root.$emit('CLOSEPOLICYVIEWDIALOG')"></span>
        </div>
        <div class="dialog-content overflow">
            <div id="profile">

                <div class="form-group">
                    <label>{{ $t("Name") }}*</label>
                    <input data-role="input" class="input" required v-model="policy.name" type="text"
                        data-prepend="<span class='mif-folder'></span>" />
                    <small class="text-muted">{{ $t("Required") }}</small>
                </div>
                <div class="d-flex flex-align-center row ">
                    <div class="form-group cell-5">
                        <label>{{ $t("Type") }}*</label>
                        <select v-if="policy.id == null" class="input w-auto" data-filter="false" id="typeselect"
                            v-model="policy.id_type" @change="formatTree">
                            <option v-for="policytypes in $session.getPolicyTypes()" :value="policytypes.id"
                                :key="policytypes.id"> {{ $t(policytypes.name) }} </option>
                        </select>
                        <label class="text-bold" v-else><br>{{ $t(policy.name_type) }}<br></label>
                        <small class="text-muted">{{ $t("Required") }}</small>
                    </div>
                    <div class="form-group cell-3">
                        <input data-role="checkbox" type="checkbox" :data-caption="$t('Enable Email Notifications')"
                            v-model="policy.enabled_email" /><br>
                    </div>
                    <div class="form-group cell-3">
                        <input data-role="checkbox" type="checkbox" :data-caption="$t('Enable Mobile Notifications')"
                            v-model="policy.enabled_push" /><br>
                    </div>
                </div>

                <div class="form-group" v-show="policy.id_type != 1">
                    <label>{{ $t("Devices") }}</label>
                    <select v-if="computers" class=" input" data-role="select"
                        data-cls-selected-item="bg-light border pr-2 rounded" data-filter="false" multiple
                        id="computerselect" name="ids_computers">
                        <option :selected="policy.computers.find(elem => elem.id == pc.id)" v-for="pc in computers"
                            :value="pc.id" :key="pc.id" data-template="<span class='mif-laptop icon'></span> $1">
                            {{ pc.name }} </option>
                    </select>
                </div>
                <div v-show="policy.id_type == 3">
                    <div class="form-group">
                        <label>{{ $t("Threshold DURATION") }}*</label>
                        <input type="number" min="0" v-model.number="policy.policy.threshold_duration"
                            class="input w-auto">
                        <small class="muted">*{{ $t("in minutes") }}</small>
                    </div>
                    <div class="form-group ">
                        <div>
                            <label>{{ $t("Threshold CPU") }}%</label>
                            <input type="number" id="sliderCPU" v-model.number="policy.policy.threshold_cpu"
                                class="ultra-thin cycle-marker" data-hint-always="true" data-cls-complete="bg-cobalt"
                                data-cls-hint="bg-cobalt custom-marker "
                                data-cls-marker="bg-cobalt border-50 custom-marker" data-role="slider" data-hint="true"
                                data-hint-position="top" :data-value="policy.policy.threshold_cpu" data-max="100"
                                data-min="0">
                        </div>
                    </div>
                    <div class="form-group ">
                        <div>
                            <label>{{ $t("Threshold RAM") }}%</label>
                            <input type="number" id="sliderRAM" v-model.number="policy.policy.threshold_ram"
                                class="ultra-thin cycle-marker" data-hint-always="true" data-cls-complete="bg-cobalt"
                                data-cls-hint="bg-cobalt custom-marker"
                                data-cls-marker="bg-cobalt border-50 custom-marker" data-role="slider" data-hint="true"
                                data-hint-position="top" :data-value="policy.policy.threshold_ram" data-max="100"
                                data-min="0">
                        </div>
                    </div>
                    <div class="form-group ">
                        <div>
                            <label>{{ $t("Threshold DISK") }}%</label>
                            <input type="number" id="sliderDISK" v-model.number="policy.policy.threshold_disk"
                                class="ultra-thin cycle-marker" data-hint-always="true" data-cls-complete="bg-cobalt"
                                data-cls-hint="bg-cobalt custom-marker"
                                data-cls-marker="bg-cobalt border-50 custom-marker" data-role="slider" data-hint="true"
                                data-hint-position="top" :data-value="policy.policy.threshold_disk" data-max="100"
                                data-min="0">
                        </div>
                    </div>
                </div>
                <div v-show="policy.id_type == 2">
                    <div class="form-group">
                        <label>{{ $t("Update time") }}</label>
                        <input data-role="input" class="input w-auto"
                            :value="policy.policy.update_time != null ? cronstrue.toString(policy.policy.update_time, { locale: $i18n.locale, dayOfWeekStartIndexZero: false }) : ''"
                            type="text" @click="$root.$emit('OPENSCHEDULATIONVIEWDIALOG', '')"
                            data-prepend="<span class='mif-calendar'></span>" />
                    </div>



                    <div class="form-group mt-5">
                        <label class="text-bold">{{ $t('OS Patch Management') }}</label>
                        <ul data-role="treeview" class="treeview p-0" id="patchview">

                            <li>
                                <input class="ml-3 text-bold" data-style="1" type="checkbox" data-role="checkbox"
                                    :data-caption="$t('Security Updates')" :title="$t('Security Updates')"
                                    value='["0fa1201d-4330-4fa8-8ae9-b877473b6441","e0789628-ce08-4437-be74-2495b842f43b","28bc880e-0592-4cbf-8f95-c79b17911d5f"]'>
                                <ul>
                                    <li>
                                        <input class="ml-3" data-style="1" type="checkbox" data-role="checkbox"
                                            value="0fa1201d-4330-4fa8-8ae9-b877473b6441"
                                            :checked="policy.policy.included_categories?.includes('0fa1201d-4330-4fa8-8ae9-b877473b6441')"
                                            :data-caption="patchCategories['0fa1201d-4330-4fa8-8ae9-b877473b6441']"
                                            :title="patchCategories['0fa1201d-4330-4fa8-8ae9-b877473b6441']">
                                    </li>
                                    <li>
                                        <input class="ml-3" data-style="1" type="checkbox" data-role="checkbox"
                                            value="e0789628-ce08-4437-be74-2495b842f43b"
                                            :checked="policy.policy.included_categories?.includes('e0789628-ce08-4437-be74-2495b842f43b')"
                                            :data-caption="patchCategories['e0789628-ce08-4437-be74-2495b842f43b']"
                                            :title="patchCategories['e0789628-ce08-4437-be74-2495b842f43b']">
                                    </li>
                                    <li>
                                        <input class="ml-3" data-style="1" type="checkbox" data-role="checkbox"
                                            value="28bc880e-0592-4cbf-8f95-c79b17911d5f"
                                            :checked="policy.policy.included_categories?.includes('28bc880e-0592-4cbf-8f95-c79b17911d5f')"
                                            :data-caption="patchCategories['28bc880e-0592-4cbf-8f95-c79b17911d5f']"
                                            :title="patchCategories['28bc880e-0592-4cbf-8f95-c79b17911d5f']">
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <input class="ml-3 text-bold" data-style="1" type="checkbox" data-role="checkbox"
                                    :data-caption="$t('Service packs')" :title="$t('Service packs')"
                                    value='["68c5b0a3-d1a6-4553-ae49-01d3a7827828","b54e7d24-7add-428f-8b75-90a396fa584f","cd5ffd1e-e932-4e3a-bf74-18bf0b1bbd83"]'>
                                <ul>
                                    <li>
                                        <input class="ml-3" data-style="1" type="checkbox" data-role="checkbox"
                                            value="68c5b0a3-d1a6-4553-ae49-01d3a7827828"
                                            :checked="policy.policy.included_categories?.includes('68c5b0a3-d1a6-4553-ae49-01d3a7827828')"
                                            :data-caption="patchCategories['68c5b0a3-d1a6-4553-ae49-01d3a7827828']"
                                            :title="patchCategories['68c5b0a3-d1a6-4553-ae49-01d3a7827828']">
                                    </li>
                                    <li>
                                        <input class="ml-3" data-style="1" type="checkbox" data-role="checkbox"
                                            value="b54e7d24-7add-428f-8b75-90a396fa584f"
                                            :checked="policy.policy.included_categories?.includes('b54e7d24-7add-428f-8b75-90a396fa584f')"
                                            :data-caption="patchCategories['b54e7d24-7add-428f-8b75-90a396fa584f']"
                                            :title="patchCategories['b54e7d24-7add-428f-8b75-90a396fa584f']">
                                    </li>
                                    <li>
                                        <input class="ml-3" data-style="1" type="checkbox" data-role="checkbox"
                                            value="cd5ffd1e-e932-4e3a-bf74-18bf0b1bbd83"
                                            :checked="policy.policy.included_categories?.includes('cd5ffd1e-e932-4e3a-bf74-18bf0b1bbd83')"
                                            :data-caption="patchCategories['cd5ffd1e-e932-4e3a-bf74-18bf0b1bbd83']"
                                            :title="patchCategories['cd5ffd1e-e932-4e3a-bf74-18bf0b1bbd83']">
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <input class="ml-3 text-bold" data-style="1" type="checkbox" data-role="checkbox"
                                    :data-caption="$t('Drivers and Tools')" :title="$t('Drivers and Tools')"
                                    value='["ebfc1fc5-71a4-4f7b-9aca-3b9a503104a0","b4832bd8-e735-4761-8daf-37f882276dab"]'>
                                <ul>
                                    <li>
                                        <input class="ml-3" data-style="1" type="checkbox" data-role="checkbox"
                                            value="ebfc1fc5-71a4-4f7b-9aca-3b9a503104a0"
                                            :checked="policy.policy.included_categories?.includes('ebfc1fc5-71a4-4f7b-9aca-3b9a503104a0')"
                                            :data-caption="patchCategories['ebfc1fc5-71a4-4f7b-9aca-3b9a503104a0']"
                                            :title="patchCategories['ebfc1fc5-71a4-4f7b-9aca-3b9a503104a0']">
                                    </li>
                                    <li>
                                        <input class="ml-3" data-style="1" type="checkbox" data-role="checkbox"
                                            value="b4832bd8-e735-4761-8daf-37f882276dab"
                                            :checked="policy.policy.included_categories?.includes('b4832bd8-e735-4761-8daf-37f882276dab')"
                                            :data-caption="patchCategories['b4832bd8-e735-4761-8daf-37f882276dab']"
                                            :title="patchCategories['b4832bd8-e735-4761-8daf-37f882276dab']">
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <input class="ml-3 text-bold" data-style="1" type="checkbox" data-role="checkbox"
                                    value="e6cf1350-c01b-414d-a61f-263d14d133b4"
                                    :checked="policy.policy.included_categories?.includes('e6cf1350-c01b-414d-a61f-263d14d133b4')"
                                    :data-caption="patchCategories['e6cf1350-c01b-414d-a61f-263d14d133b4']"
                                    :title="patchCategories['e6cf1350-c01b-414d-a61f-263d14d133b4']">
                            </li>
                        </ul>
                    </div>

                    <div class="form-group  mt-3">
                        <input data-role="checkbox" type="checkbox" :data-caption="$t('Reboot auto')"
                            v-model="policy.policy.reboot_auto" /><br>
                    </div>


                    <div class="row ml-3 d-flex">

                        <div class="form-group cell-3">
                            <label class="no-wrap">{{ $t("Reboot time from") }}</label>
                            <input :disabled="!policy.policy.reboot_auto" class="input w-auto"
                                v-model="policy.policy.reboot_time_inf" type="time" />
                        </div>
                        <div class="form-group cell-3">
                            <label class="no-wrap">{{ $t("Reboot time to") }}</label>
                            <input :disabled="!policy.policy.reboot_auto" class="input w-auto"
                                v-model="policy.policy.reboot_time_sup" type="time" />
                        </div>
                    </div>
                </div>
                <div v-show="policy.id_type == 1">
                    <div class="form-group">
                        <label>{{ $t("Scheduling") }}</label>
                        <input class="input" v-if="policy.refreshID != 0" :key="policy.refreshID"
                            :value="policy.policy.scheduling != null && policy.policy.scheduling.length > 0 && policy.policy.scheduling[0] != '' ? cronstrue.toString(policy.policy.scheduling[0], { locale: $i18n.locale, dayOfWeekStartIndexZero: false }) : ''"
                            type="text" @click="$root.$emit('OPENSCHEDULATIONVIEWDIALOG', '')" />
                    </div>

                    <div class="form-group  mt-3">
                        <input data-role="checkbox" type="checkbox" :data-caption="$t('Enable Misfire Alert')"  v-model="policy.policy.misfire_alert" /><br>
                    </div>

                    <div class="form-group" v-if="backupTree">
                        <label>{{ $t('Select Backups') }}</label>
                        <ul data-role="treeview" id="treeview">
                            <li data-icon="" data-caption="" v-for="computer in backupTree.computers">
                                <input :key="computer.id" name="computer" :value="computer.id" type="checkbox"
                                    data-role="checkbox" :data-caption="computer.name" :title="computer.name">
                                <ul v-if="computer.jobs.size != 0">
                                    <li data-icon="" data-caption="" v-for="backup in computer.jobs">
                                        <input :key="backup.id" name="backup" :value="backup.id" type="checkbox"
                                            data-role="checkbox" :data-caption="backup.name" :title="backup.name"
                                            v-model="policy.jobs">
                                    </li>

                                </ul>
                            </li>


                        </ul>
                    </div>

                </div>


            </div>
        </div>
        <div class="dialog-actions">
            <button class="button primary" @click="save">{{ policy.id == "" ? $t('Create') : $t("Save") }}</button>
            <button v-if="policy.id != null" class="button error ml-2" @click="deletePolicy(policy)">{{
                $t("Delete") }}</button>
            <button class="button" @click="$root.$emit('CLOSEPOLICYVIEWDIALOG')">{{ $t("Cancel") }}</button>
        </div>
    </div>
</template>
<script>

import patchCategories from "../../../components/utils/patchCategories";
import $ from 'jquery';
import { draggableMixin } from '../../../mixins/draggable-mixin'

export default {

    name: "PolicyDialog",

    mixins: [draggableMixin],
    data() {
        return {
            cronstrue: require('cronstrue/i18n'),
            patchCategories: patchCategories,
            backupTree: null,
            jobsSelected: []
        }
    },
    props: {

        policy: {
            type: Object,
            required: true
        },

        computers: {
            type: Array,
            required: true
        },



    },

    mounted() {

        let self=this;
        self.cronstrue=require('cronstrue/i18n');
        this.$api.getBackupTree().then(tree => {
            self.backupTree=tree;
            self.runMethodWithDelay(self.formatTree, 350);
        });
        this.policy.refreshID=1;
        this.$root.$on("CLOSESCHEDULATIONVIEWDIALOG", (schedulationString) => this.policy.policy.scheduling[0]=schedulationString);

    },
    methods: {
        runMethodWithDelay(callback, delay) {
            setTimeout(callback, delay);
        },

        formatTree() {
            let self=this;

            var sliderCPU=Metro.getPlugin('#sliderCPU', 'slider');
            if (sliderCPU!=undefined) {
                sliderCPU.options.onChangeValue=function(val, percent, slider) {
                    self.policy.policy.threshold_cpu=parseInt(val);
                }
                var sliderRAM=Metro.getPlugin('#sliderRAM', 'slider');
                sliderRAM.options.onChangeValue=function(val, percent, slider) {
                    self.policy.policy.threshold_ram=parseInt(val);
                }
                var sliderDISK=Metro.getPlugin('#sliderDISK', 'slider');
                sliderDISK.options.onChangeValue=function(val, percent, slider) {
                    self.policy.policy.threshold_disk=parseInt(val);
                }
            }


            var patchview=Metro.getPlugin('#patchview', 'treeview');
            if (patchview!=undefined) {

                patchview.options.onCheckClick=function(state, check, node, tree) {
                    if (typeof check.value==='string'&&check.value.startsWith('[')&&check.value.endsWith(']')) {

                        var arraytoPush=JSON.parse(check.value);

                        if (state) {
                            arraytoPush.forEach(value => self.policy.policy.included_categories.push(value));
                        } else {
                            arraytoPush.forEach((value) => {
                                const index=self.policy.policy.included_categories.indexOf(value);
                                if (index!==-1) { // Controlla che il valore esista
                                    self.policy.policy.included_categories.splice(index, 1);
                                }
                            });
                        }



                    } else {
                        const index=self.policy.policy.included_categories.indexOf(check.value);
                        if (index!==-1) { // Controlla che il valore esista
                            self.policy.policy.included_categories.splice(index, 1);
                        } else {
                            self.policy.policy.included_categories.push(check.value);
                        }


                    }

                    console.log(state);
                    console.log(check.value);
                    console.log(check.name);
                    console.log(node);
                }
            }

            var treeview=Metro.getPlugin('#treeview', 'treeview');
            if (treeview!=undefined) {

                treeview.options.onCheckClick=function(state, check, node, tree) {


                    /*  if (check.name == "computer"){
                         if (!state) { // Controlla che il valore esista
                             const index = self.policy.jobs.computers.indexOf(check.value);
                             self.policy.jobs.computers.splice(index, 1);
                         } else {
                             self.policy.jobs.computers.push(check.value);
                         }
                     }
                     if (check.name == "backup"){
 
                         if (!state) { // Controlla che il valore esista
                             const index = self.policy.jobs.jobs.indexOf(check.value);
                             self.policy.jobs.jobs.splice(index, 1);
                         } else {
                             self.policy.jobs.jobs.push(check.value);
                         }
                     } */

                    console.log(state);
                    console.log(check.value);
                    console.log(check.name);
                    console.log(node);
                }
                treeview.collapseAll();
            }
        },

        async save() {
            let self=this;
            if (self.policy.name==null||self.policy.name==""||self.policy.id_type=="") {
                Metro.dialog.create({
                    title: this.$t("Error on saving policy"),
                    content: this.$t("*Policy name,type are required"),
                    closeButton: false,
                    clsDialog: "dialog error pos-center ",
                    clsContent: "p-10",
                    closeAction: true,
                    actions: [
                    {
                        caption: this.$t("Close"),
                        cls: "js-dialog-close alert "
                    }
                    ],
                    
                });
                return;
            }
            self.policy.computers=window.Metro.getPlugin($("#computerselect"), 'select').val();

            var newPolicy=self.policy.id==null
            ? await self.$api.createPolicy(self.policy)
            : await self.$api.editPolicy(self.policy);
            if (newPolicy==null) {
                return;
            }
            this.$root.$emit("refreshPolicies");
            this.$root.$emit('CLOSEPOLICYVIEWDIALOG');
            window.location.reload();

        },

        async deletePolicy(policy) {
            //Personalizzo la dialog da mostrare
            var dialogID=Math.floor(100000+Math.random()*900000).toString();
            var dialogTitle=this.$t("Delete Policy");
            var dialogContent=this.$t('deleteConfirmation', { 0: policy.name });

            var okText=this.$t("Yes");
            var okClass="mr-2 primary";
            var okResult=true;

            var cancelText=this.$t("No");
            var cancelClass="alert";
            var cancelResult=false;

            let self=this;
            this.$root.$on(dialogID, async ok => {
                if (ok) {


                    var ok = await self.$api.deletePolicy( policy.id);

                     if (ok) {
                        this.$root.$emit("refreshPolicies");
                        this.$root.$emit('CLOSEPOLICYVIEWDIALOG');  
                    }
                    
                }
                
                self.$root.$off(dialogID);

            });

            //Avvio la richiesta che mi risponderà con un $emit
            this.$root.openDialogActions(dialogID, dialogTitle, dialogContent, okText, okClass, okResult, cancelText, cancelClass, cancelResult)
        }
    }
}
</script>