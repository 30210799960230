<template>
    <div id="foldersource" v-on:keyup.esc="$root.$emit('CLOSESOURCEDIALOG')"
        class="dialog light w-100-sm w-100-md w-75-lg w-50-xl h-75">

        <DialogTitle :title="$t('Configure Source')" :defaultTitle="$t('Configure Source')"
            :subTitle="$t(source.type_name)" @closeDialog="$root.$emit('CLOSESOURCEDIALOG')" />


        <!--SCELTA PERCORSI-->
        <div v-if="step == 0" class="dialog-content">
            <SelectedResourcesLayout :buttonText="$t('Choosing files and folders')" :singleItem="false"
                :isDisabled="false" :list="source.folderSource.paths" :heightPercentage="55" :canCancel="true"
                @clickFunction="toggleFileSystemSidebar" />

        </div>

        <!--FILTRI-->
        <div class="dialog-content overflow" v-show="step == 1">


            <!--FILTRI - ESTENSIONI-->
            <div class="form-group">
                <h6 class="bg-light px-2 py-2 text-bold">
                    <span class="mif-filter icon mr-1" />
                    <span>{{ $t('Filter by extensions') }} ({{ $t('Optional') }})</span>
                </h6>


                <div class="cell-12 d-flex flex-align-center">
                    <input type="checkbox" data-role="switch" data-material="false"
                        v-model="source.options.extensionsFilterAction" :true-value="1" :false-value="0" />
                    <label v-if="source.options.extensionsFilterAction == 0"> {{ $t('Exclude specified extensions') }}
                    </label>
                    <label v-else>{{ $t('Include only specified extensions') }}</label>
                </div>


                <div class="cell-6 d-flex">
                    <input id="taginput" type="text" data-role="tag-input" data-tag-separator=";" data-class="rounded"
                        class="rounded">
                </div>
            </div>
            <br>
            <!--FILTRI - DIMENSIONE FILE-->
            <div class="form-group">
                <h6 class="bg-light px-2 py-2 text-bold">
                    <span class="mif-filter icon mr-1" />
                    <span>{{ $t('Filter by file size') }} ({{ $t('Optional') }})</span>
                </h6>

                <input type="checkbox" v-model="source.options.useFileSizeFilter"
                    :data-caption="$t('Use file size filter')" data-role="checkbox" />

                <div class="w-100 d-flex flex-align-center">
                    <select v-model="source.options.fileSizeFilterAction" :disabled="!source.options.useFileSizeFilter"
                        class="input input-small mr-2 h-100">
                        <option v-for="(value, index) in $root.selectValues.fileSizeFilterAction" :value="index"
                            v-bind:key="index">{{
                                $t(value)
                            }}</option>

                    </select>

                    <select v-model="source.options.fileSizeFilterCompare" :disabled="!source.options.useFileSizeFilter"
                        class="input input-small mr-2 h-100 w-75">
                        <option v-for="(value, index) in $root.selectValues.fileSizeFilterCompare" :value="index"
                            v-bind:key="index">{{
                                $t(value)
                            }}</option>
                    </select>

                    <input type="number" v-model="source.options.fileSize" :disabled="!source.options.useFileSizeFilter"
                        class="input input-small mr-2" style="width: 100px;" />
                    <span> MB</span>
                </div>
            </div>
        </div>

        <!--ESCLUSIONI-->
        <div class="dialog-content overflow" v-if="step == 2">

            
            <div class="form-group overflow" v-if="source.folderSource.paths.length != 0">
                <h6 class="bg-light px-2 py-2 text-bold">
                    <span>{{ $t('Exclusions') }} ({{ $t('Optional') }})</span>
                </h6>
                <hr>
                <!--VISUALIZZAZIONE DEGLI ELEMENTI SELEZIONATI-->
                {{ $t("Click on an item to select subfolders or files to exclude") }}
                <table class="striped table text-small w-100 table-compact">
                    <thead>
                        <th></th>
                        <th class="w-10"></th>
                    </thead>
                    <tbody>
                        <tr v-for="res in source.folderSource.paths" @click="openFileSystemSidebarFrom(res)"
                            v-bind:key="res.id">
                            <td class="d-flex">
                                <span :class="getMetroIcon(res.type, res.path)" class="px-2" />
                                <span v-if="source.options.recreateCompletePath" class="flex-self-center">{{ res.path}}</span>
                                <span v-else class="flex-self-center">
                                    {{ (res.path.indexOf('/') != -1 ? res.path.split('/') : res.path.split('\\')).at(-1) }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="form-group overflow">
                <NoDataAvailable v-if="source.options.excludedPaths.length == 0"
                    :message="$t('No excluded path selected')" />
                <div v-else>
                    <label>{{ $t("Paths to exclude selected:") }}</label>
                    <div>
                        <table class="striped table text-small w-100 table-compact">
                            <thead>
                                <th></th>
                                <th class="w-10"></th>
                            </thead>
                            <tbody>
                                <tr v-for="(res, i) in source.options.excludedPaths" :title="res.path"
                                    v-bind:key="res.id">
                                    <td class="d-flex">
                                        <span :class="getMetroIcon(res.type, res.path)" class="px-2" />
                                        <span lass="flex-self-center">{{ cutText(res.path, 50) }}</span>
                                    </td>
                                    <td><span class='mif-bin' @click="$root.$emit('uncheckNode', res)" /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            <div class="form-group text-small" v-if="step == 2">
                <input data-role="checkbox" type="checkbox"
                    :data-caption="$t('Do not include any subfolders (only files in the root folder will be copied)')"
                    data-validate="required" v-model="source.options.excludeSubFolders"
                    data-prepend="<span class='mif-folder'></span>" /><br>
            </div>

        </div>
        <!--BACKUP-->
        <div class="dialog-content overflow" v-show="step == 3">
            
            <div class="form-group">
                <h6 class="text-bold">
                    <span>{{ $t('Backup') }}</span>
                </h6>
                <hr>

                <label>{{ $t("Type") }}:</label>
                <select v-model="source.options.backupType" class="select" data-filter="false">
                    <option v-for="key in Object.keys($root.selectValues.backupTypes)" :value="key" v-bind:key="key">
                        {{ $t($root.selectValues.backupTypes[key]) }}</option>
                </select>

                <label>{{ $t("Number of copies") }}:</label>
                <input class="w-25" type="number" v-model="source.options.nCopies"
                    :min="['F', 'U'].includes(source.options.backupType) ? 1 : 0" max="365" data-role="input" />
            </div>

            <div class="form-group">
                <input type="checkbox" v-model="source.options.recreateCompletePath" data-role="checkbox"
                    :data-caption="$t('Recreate the full path of the items')" />
            </div>
        </div>

        <!--COMPRESSIONE ZIP-->
        <div class="dialog-content overflow p-4" v-if="step == 4" :disabled="source.options.backupType == 'U'">

            <h6 class="text-bold">
                <span class="mif-file-zip icon mr-1" />
                <span>{{ $t('Zip compression') }} ({{ $t('Optional') }})</span>
            </h6>
            <div class="form-group">
                <input type="checkbox" v-model="source.options.useArchiveCompression" data-role="checkbox"
                    :data-caption="$t('Enable zip compression')" />
                <br />
            </div>

            <div class="form-group d-flex flex-justify-between" :disabled="!source.options.useArchiveCompression">
                <div>
                    <label>{{ $t("Archive type") }}</label>
                    <select class="input" v-model="source.options.archiveType">
                        <option v-for="(value, index) in $root.selectValues.archiveTypes" :value="index"
                            v-bind:key="index">
                            {{ $t(value) }}
                        </option>
                    </select>
                </div>
                <!--
                09/09/2024 : Al momento si considera solo il percorso completo (source.options.pathsInArchive = 1) all'interno del file zip.
                Se si vorranno implementare altre modalità, basta attivare questo div
                -->
                <div class="d-none">
                    <label>{{ $t("Paths in archive") }}</label>
                    <select class="input" v-model="source.options.pathsInArchive">
                        <option v-for="(value, index) in $root.selectValues.pathsInArchive" :value="index"
                            v-bind:key="index">{{ $t(value) }}
                        </option>
                    </select>
                </div>
            </div>


            <div class="form-group" :disabled="!source.options.useArchiveCompression">
                <input type="checkbox" v-model="source.options.useArchiveFileCustomName" data-role="checkbox" />
                <label>{{ $t("Give the zip file a custom name") }}</label>
                <br />
                <input type="text" class="input" v-model="source.options.archiveFileCustomName"
                    :disabled="!source.options.useArchiveFileCustomName" required />
            </div>

            <div class="form-group" :disabled="!source.options.useArchiveCompression">
                <input type="checkbox" v-model="source.options.useArchivePassword" data-role="checkbox"
                    :data-caption="$t('Protect zip files using password')" />
            </div>
            <div :disabled="!source.options.useArchiveCompression || !source.options.useArchivePassword"
                class="form-group">
                <label>{{ $t("Password") }}:</label>
                <input data-role="input" type="password" v-model="source.options.archivePassword"
                    :placeholder="$t('Enter Password')" data-prepend="<span class='mif-lock'></span>"
                    :disabled="!source.options.useArchiveCompression || !source.options.useArchivePassword" />
                <small class="text-muted">{{ $t("Required") }}</small>
                <br />
                <label>{{ $t("Protection type") }}:</label>
                <br />
                <select class="input" v-model="source.options.protectionType"
                    :disabled="!source.options.useArchiveCompression || !source.options.useArchivePassword">
                    <option v-for="(value, index) in $root.selectValues.protectionTypes" :value="index"
                        v-bind:key="index">{{ $t(value) }}
                    </option>
                </select>

            </div>
        </div>

        <div class="dialog-actions">
            <!--div class="cell-4 text-small flex-left" v-if="step==2" style="margin-left:10%">
                    <input type="checkbox" v-model="source.options.excludeSubFolders" />
                    <label>  {{ $t("Do not include any subfolders (only files in the root folder will be copied)")}}</label>
                </div-->

            <!--input type="button" v-on:click="toggleFileSystemSidebar" :value="$t('Select items')" class="button primary"
                v-if="step == 0" /-->

            <button class="button alert" @click="$root.$emit('CLOSESOURCEDIALOG')">{{ $t("Cancel") }}</button>

            <!--input type="button" v-on:click="setStep(step - 1)" :value="$t('Prev')" class="button primary"
                :disabled="step == 0" /-->

            <button class="button" :class="{ 'disabled': step == 0 }" @click="setStep(step - 1)">
                <span class="mif-arrow-left"></span>{{ $t("Prev") }}</button>


            <!--input v-if="!showSaveButton" type="button" v-on:click="setStep(step + 1)" :value="$t('Next')"
                class="button primary" :disabled="step == 4 || source.folderSource.paths.length == 0" /-->

            <button class="button" v-if="!showSaveButton"
                :class="{ 'disabled': step == 4 || source.folderSource.paths.length == 0 }"
                @click="setStep(step + 1)">{{ $t("Next") }}<span class="mif-arrow-right"> </span> </button>

            <!--input type="button" v-on:click="saveSource" :value="$t('Save')" class="button primary" v-if="showSaveButton" /-->

            <button class="button primary ml-2" v-if="showSaveButton" @click="saveSource">{{ $t("Save") }}</button>

        </div>
    </div>
</template>
<script>
import DialogTitle from "../../utils/DialogTitle";
import NoDataAvailable from "../../utils/NoDataAvailable.vue";
import SelectedResourcesLayout from "../../utils/SelectedResourcesLayout.vue";
import { cutText, getMetroIcon } from "../../../../public/assets/js/utilitiesmodule";
import { ClientTreeNodeMethods } from "../../../models/TreeNode";

export default {
    name: "FolderSource",
    props: {
        source: Object,
    },
    components: {
        "DialogTitle": DialogTitle,
        "NoDataAvailable": NoDataAvailable,
        SelectedResourcesLayout
    },
    created: function() {
        console.log(this.$root.selectValues);
        console.log(this.$root.selectValues.fileSizeFilterAction);
    },
    data() {
        return {
            step: 0,
            drives: [],
            driveSelected: 'C:\\',
        }
    },
    computed: {

        showSaveButton() {
            return (this.step==3&&this.source.options.backupType=='U')||this.step==4
        }
    },

    methods: {
        sendSATestResult(testResult) {
            this.testResult=testResult;
            this.source.id_service_account=testResult.serviceAccount.id;
        },


        setStep(nStep) {
            if (nStep!=0&&this.step==0&&this.source.folderSource.paths.length==0)
                alert("Seleziona almeno un percorso.");
            else this.step=nStep;

            if (this.step==1) {

                let self=this;
                setTimeout(function() {
                    var taginput=window.Metro.get$el("#taginput").data("taginput").options;
                    taginput.onBeforeTagAdd=(val, values) => self.onBeforeTagAdd('', val, values);
                    taginput.onTagAdd=(tag, val, values) => self.onTagAdd(tag, val, values)

                }, 1000);
            }

            this.$root.$emit("closeFileSystemSidebar");
        },

        toggleFileSystemSidebar() {

            var config={
                type: this.$root.PlatformsTypesEnum.FileFolder,
                includeFiles: true,
                multipleChoice: true,
                client: null
            };

            this.$root.$emit(
                "toggleFileSystemSidebar",
                this.source.folderSource.paths,
                config
            );
        },

        openFileSystemSidebarFrom(res) {
            if (new ClientTreeNodeMethods().isFile(res)) return;

            var config={
                type: this.$root.PlatformsTypesEnum.FileFolder,
                includeFiles: true,
                multipleChoice: true,
                client: null,
                rootPath: res.path
            };

            this.$root.$emit(
                "toggleFileSystemSidebar",
                this.source.options.excludedPaths,
                config
            );

        },

        saveSource() {
            let self=this;
            //I paths sono nodi ma devo salvare solamente la struttura {path:'', type:''}
            self.source.folderSource.paths=self.source.folderSource.paths.map(n => {
                return {
                    path: n.path,
                    type: n.type
                }
            });

            if (self.source.options.excludedPaths.length==0)
                self.source.options.excludedPaths="";
            else //paths?type(FileFolderEntityType)|paths?type(FileFolderEntityType)| ....
                self.source.options.excludedPaths=self.source.options.excludedPaths.map(r => r.path+"?"+r.type).join("|");

            this.$root.$emit("saveBackupSource", this.source);
        },
        //---------------------------------TAG INPUT------------------------------------------------------------------
        onBeforeTagAdd(tag, val) {

            console.log("check tag "+val);

            var valToArray=val.toLowerCase().split("");

            //Caso 1 : var è composta da sole lettere
            if (valToArray.every(c => 97<=c.charCodeAt(0)&&c.charCodeAt(0)<=122))
                return true;

            //Caso 2 : var ha come unico simbolo un punto all'inizio
            if (valToArray.filter(c => c.charCodeAt(0)<97||c.charCodeAt(0)>122).length==1&&val.startsWith("."))
                return true;

            //Caso 3 : Sono presenti simboli non ammessi
            this.$root.toast(this.$t(EXTENSION_NOT_VALID), 3000, "warning");
            return false;
        },
        onTagAdd(tag, val, values) {
            val=val.toLowerCase();
            if (val.startsWith(".")) return;
            values.pop();
            val="."+val;
            $(".tag .title").last().html(val);
            values.push(val);
            let self=this;
            self.source.options.specifiedExtensions=values.join(";");
        },


        cutText(text, range) {
            return cutText(text, range);
        },


        getMetroIcon(type, path) {
            return getMetroIcon(type, path);
        }
    }
}
</script>