var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    {
      staticClass:
        "bd-primary border border-3 border-left-none border-right-none border-top-none w-100",
      attrs: { "data-role": "appbar", "data-expand-point": "xl" },
    },
    [
      _c(
        "div",
        {
          staticClass: "app-bar-container w-100 flex-justify-between",
          staticStyle: { "user-select": "none" },
        },
        [
          _c(
            "div",
            [
              _c("router-link", { attrs: { to: "/dashboard" } }, [
                _c("img", {
                  staticClass: "ml-5 mt-1",
                  attrs: {
                    src: "/assets/img/brand/iperius_one_v5.png",
                    height: "100",
                    alt: "Iperius One",
                  },
                }),
              ]),
            ],
            1
          ),
          _c("ul", { staticClass: "bg-transparent h-menu" }, [
            _vm.user
              ? _c("li", [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-toggle app-bar-item",
                      attrs: { href: "#" },
                    },
                    [
                      _c("label", { staticClass: "pl-4" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$root.checkAuth()?.firstname +
                              " " +
                              _vm.$root.checkAuth()?.lastname +
                              " (" +
                              _vm.$root.checkAuth()?.username +
                              ")"
                          )
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "ul",
                    {
                      staticClass: "d-menu border w-100 bg-white",
                      attrs: { id: "userdropdown", "data-role": "dropdown" },
                    },
                    [
                      _c(
                        "li",
                        { staticClass: "text-right" },
                        [
                          _c("router-link", { attrs: { to: "/licenses" } }, [
                            _vm._v(" " + _vm._s(_vm.$t("My Licenses")) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c("li", { staticClass: "text-right" }, [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.$parent.$parent.logout()
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "mif-bin" }),
                            _vm._v(" " + _vm._s(_vm.$t("Logout"))),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _c("li", [_c("locale-changer")], 1),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }