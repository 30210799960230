import Vue from 'vue';
import VueI18n from "vue-i18n";
import getBrowserLocale from "../utils/getBrowserLocale";
import dateTimeFormats from "../utils/dateTimeFormats"


function loadLocaleMessages () {
    const locales = require.context('./locale', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    console.log( getBrowserLocale({ countryCodeOnly: true }))
    locales.keys().forEach(key => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i)
      if (matched && matched.length > 1) {
        const locale = matched[1]
        messages[locale] = locales(key)
      }
    })
    return messages
  }

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: localStorage.getItem('lang') ?? getBrowserLocale({ countryCodeOnly: true }) ,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(), // set locale messages
  dateTimeFormats,
  silentTranslationWarn: true,
});

export default i18n;