var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.group
    ? _c(
        "div",
        {
          staticClass:
            "dialog light w-100-sm w-100-md w-75-lg w-50-xl h-75 draggable-item",
          attrs: { id: "group_dialog" },
        },
        [
          _c("div", { staticClass: "dialog-title" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.isNew ? _vm.$t("Create New Group") : _vm.group.name
                ) +
                " "
            ),
            _c("span", {
              staticClass: "button square closer",
              on: {
                click: function ($event) {
                  return _vm.$root.$emit("CLOSEGROUPVIEWDIALOG")
                },
              },
            }),
          ]),
          _c("div", { staticClass: "dialog-content overflow" }, [
            _c("div", { attrs: { id: "profile" } }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("Name")) + "*")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.group.name,
                      expression: "group.name",
                    },
                  ],
                  staticClass: "input",
                  attrs: {
                    "data-role": "input",
                    required: "",
                    type: "text",
                    "data-prepend": "<span class='mif-folder'></span>",
                  },
                  domProps: { value: _vm.group.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.group, "name", $event.target.value)
                    },
                  },
                }),
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v(_vm._s(_vm.$t("Required"))),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("Users")) + "*")]),
                _c(
                  "select",
                  {
                    staticClass: "input",
                    attrs: {
                      "data-role": "select",
                      "data-cls-selected-item": "bg-light border pr-2 rounded",
                      "data-filter": "false",
                      multiple: "",
                      id: "userselect",
                      name: "ids_users",
                      required: "",
                    },
                  },
                  _vm._l(_vm.users, function (user) {
                    return _c(
                      "option",
                      {
                        key: user.id,
                        attrs: {
                          "data-template":
                            "<span class='mif-user icon'></span> $1",
                        },
                        domProps: {
                          selected:
                            _vm.group.users.find(
                              (elem) =>
                                elem.id == user.id || elem.id_user == user.id
                            ) || user.id == _vm.$session.getAuth()?.id_user,
                          value: user.id,
                        },
                      },
                      [_vm._v(" " + _vm._s(user.username) + " ")]
                    )
                  }),
                  0
                ),
                _c("small", [
                  _vm._v(_vm._s(_vm.$t("Users that can view this group"))),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("Devices")))]),
                _vm.computers
                  ? _c(
                      "select",
                      {
                        staticClass: "input",
                        attrs: {
                          "data-role": "select",
                          "data-cls-selected-item":
                            "bg-light border pr-2 rounded",
                          "data-filter": "false",
                          multiple: "",
                          id: "computerselect",
                          name: "ids_computers",
                        },
                      },
                      _vm._l(_vm.computers, function (pc) {
                        return _c(
                          "option",
                          {
                            key: pc.id,
                            attrs: {
                              "data-template":
                                "<span class='mif-laptop icon'></span> $1",
                            },
                            domProps: {
                              selected: _vm.group.computers.find(
                                (elem) => elem.id == pc.id
                              ),
                              value: pc.id,
                            },
                          },
                          [_vm._v(" " + _vm._s(pc.name) + " ")]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _c("small", [
                  _vm._v(_vm._s(_vm.$t("Computers in this group"))),
                ]),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "form-group col-6" }, [
                  _c("div", [
                    _c("label", [_vm._v(_vm._s(_vm.$t("Email")))]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.group.email,
                          expression: "group.email",
                        },
                      ],
                      staticClass: "input",
                      attrs: {
                        "data-role": "input",
                        type: "email",
                        "data-prepend": "<span class='mif-mail'></span>",
                      },
                      domProps: { value: _vm.group.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.group, "email", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _c("div", [
                    _c("label", [_vm._v(_vm._s(_vm.$t("Phone Number")))]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.group.phone_number,
                          expression: "group.phone_number",
                        },
                      ],
                      staticClass: "input",
                      attrs: {
                        "data-role": "input",
                        type: "number",
                        "data-prepend": "<span class='mif-phone'></span>",
                      },
                      domProps: { value: _vm.group.phone_number },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.group,
                            "phone_number",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "form-group col-6" }, [
                  _c("div", [
                    _c("label", [_vm._v(_vm._s(_vm.$t("Address")))]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.group.address,
                          expression: "group.address",
                        },
                      ],
                      staticClass: "input",
                      attrs: {
                        "data-role": "input",
                        type: "text",
                        "data-prepend": "<span class='mif-home'></span>",
                      },
                      domProps: { value: _vm.group.address },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.group, "address", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group col-6" }, [
                  _c("div", {}, [
                    _c("label", [_vm._v(_vm._s(_vm.$t("Note")))]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.group.notes,
                          expression: "group.notes",
                        },
                      ],
                      staticClass: "input small",
                      attrs: {
                        "data-role": "input",
                        maxlength: "255",
                        type: "text",
                        "data-prepend": "<span class='mif-folder'></span>",
                      },
                      domProps: { value: _vm.group.notes },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.group, "notes", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "dialog-actions" }, [
            _c(
              "button",
              {
                staticClass: "button primary",
                attrs: { disabled: !_vm.enableSave },
                on: { click: _vm.save },
              },
              [_vm._v(_vm._s(_vm.isNew ? _vm.$t("Create") : _vm.$t("Save")))]
            ),
            _vm.group.name != "Shared Computers" &&
            !_vm.isNew &&
            _vm.group.attribute != 3
              ? _c(
                  "button",
                  {
                    staticClass: "button error ml-2",
                    on: { click: _vm.deleteGroup },
                  },
                  [_vm._v(_vm._s(_vm.$t("Delete")))]
                )
              : _vm._e(),
            _c(
              "button",
              {
                staticClass: "button",
                on: {
                  click: function ($event) {
                    return _vm.$root.$emit("CLOSEGROUPVIEWDIALOG")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("Cancel")))]
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }