//const qs = require('querystring')

export default {
    name: "login2FA",
    data() {
        return {
            TFAcode: null,
            TFAcode_recovery: null,
            RememberMe: false,
            RememberMachine: false
        };
    },
    props: {
        msg: String
    },
    created: function() {
        this.$store.commit('SET_LAYOUT', 'simple-layout')
    },
    components: {

    },
    methods: {

        async loginTFA() {
            let self = this;

            var authObj = await this.$api.loginTFA(self.TFAcode, self.RememberMachine);

            if (authObj == null)
                return;

            if (localStorage.getItem("AUTH")) {
                localStorage.setItem("AUTH", JSON.stringify(authObj))
            } else {
                self.$session.setAuth(authObj)
            }
            self.$router.push("/dashboard");
        },

        //???????????????????????????????????????????????
        async loginTFArecovery() {
            let self = this;

            var authObj = await this.$api.loginTFARecovery("LOGINTFARECOVERY", self.TFAcode_recovery);

            if (authObj == null)
                return;

            if (localStorage.getItem("AUTH")) {
                localStorage.setItem("AUTH", JSON.stringify(authObj))
            } else {
                self.$session.setAuth(authObj)
            }
            self.$router.push("/dashboard");
        }
    }
}