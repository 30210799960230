<template>
    <div v-on:keyup.esc="$root.$emit('CLOSERESTOREDIALOG')"
        class="dialog overflow w-100-sm w-100-md w-75-lg  w-50-xl" v-cloak>

        <DialogTitle :title="$t('Restore of database backup')" :defaultTitle="$t('Restore of database backup')"
        :subTitle="$t('SQLServer restore')" @closeDialog="$root.$emit('CLOSERESTOREDIALOG')" />

        <div class="dialog-content p-4">
            <!-- SELEZIONE DEI BACKUP E DEL SINGOLO RESTORE RELATO AL JOB-->
            <div class="row" v-show="steps[0]">

                <div v-if="wait" data-role="activity" data-type="metro" data-style="dark"></div>
                <div id="dblogslist" class="border bd-light p-4 w-100" style="overflow-y: scroll;overflow-x: hidden;">

                    <!--table data-role="sorter" data-sort-dir="desc" class="table striped text-small " v-if="!wait"
                        data-show-search="true" data-show-rows-steps="false" data-search-min-length="3"
                        data-search-threshold="300">

                        <thead>
                            <tr>
                                <td style="text-align: center;">Sel</td>
                                <td data-name="dt_start_utc" data-sortable="true" data-format="int">{{ $t('Start Date')
                                    }}</td>
                                <td data-name="dt_end_utc" data-sortable="true">{{ $t('End Date') }}</td>
                                <td data-name="backup_size" data-sortable="true">{{ $t('Backup Size') }}</td>
                                <td data-name="copied_files" data-sortable="true" data-sort-dir="desc">{{ $t('Copied files') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(log, index) in logs" :key="log.id" track-by="log.id">
                                <td>
                                    <input class="ml-5" name="r1" type='radio' data-role='radio'
                                        @click="getJobConfiguration(log, index)">
                                </td>
                                <td>{{ convertDate(log.dt_start_utc) }}</td>
                                <td>{{ convertDate(log.dt_end_utc) }}</td>
                                <td>{{ log.backup_size | prettyBytes }}</td>
                                <td>{{ log.copied_files }}</td>
                            </tr>
                        </tbody>
                    </table-->
                    <ul v-if="logs.length>0" data-role="listview" data-view="content" data-select-node="true">

                        <li v-for="(forlog) in logs" :key="forlog.id" class="mb-2" data-icon="<span class='mif-stack'>"
                            :data-caption="convertDate(forlog.dt_start_utc)"
                            :data-content="`<span class='text-muted'>${convertDate(forlog.dt_end_utc)} - ${prettyBytes(forlog.backup_size)} - ${forlog.copied_files} ${$t('files')}</span>`"
                            @click="getJobConfiguration(forlog)">
                        </li> 
                                    
                    </ul>      
                    <p v-else>{{ $t('No Logs available') }}</p> 
                </div>


            </div>
            <!-- SELEZIONE DELLA MAILBOXES PER RESTORE-->
            <div class="row" v-show="steps[1]">
                <div v-if="waitvm" data-role="activity" data-type="metro" data-style="dark"></div>
                <div id="dblist" class="border bd-light p-4 w-100" style="overflow-y: scroll;overflow-x: hidden;">

                    <!--table data-role="sorter" data-sort-dir="desc" class="table striped text-small" v-if="!waitvm"
                        data-show-search="true" data-show-rows-steps="false" data-search-min-length="3"
                        data-search-threshold="300">

                        <thead>
                            <tr>

                                <td></td>
                                <td data-name="name" data-sortable="true" data-format="int">{{ $t('Database Name') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(db, index) in databasesinbackup" :key="db.Name" track-by="mb.Id">
                                <td>
                                    <input class="ml-5" name="r2" type='radio' @click="setDbSelected(db, index)"
                                        data-role='radio'>
                                </td>
                                <td>{{ db.Name }}</td>
                            </tr>
                        </tbody>
                    </table-->
                    <ul v-if="databasesinbackup.length>0" data-role="listview" data-view="content" data-select-node="true">

                        <li v-for="(db, index) in databasesinbackup" :key="db.Name" data-icon="<span class='mif-server'>"
                            :data-caption="db.Name"
                            :data-content="`<span class='text-muted'>${$t('Database Name')}</span>`"
                            @click="setDbSelected(db, index)">
                        </li> 
                                
                    </ul>      
                    <p v-else>{{ $t('No Backup available') }}</p>     


                </div>
            </div>

            <div class="row mt-4" v-if="steps[2] && databasestorestore.length > 0">
                <div class="row mt-4 ml-2">
                    <div class="cell-12">
                        <p>{{ $t("Backup file:") }}</p>
                    </div>
                    <div class="cell-8">
                        <span>{{ job.destinations[0].path + '\\' }} {{ dbnametorestore + '.bak' }} </span>
                    </div>
                    <div class="cell-4">
                        <button class="button primary ml-2" v-on:click="toggleFileSystemSidebar()">{{ $t('Select path')}}</button>
                    </div>
                    <div class="cell-8">
                        <input type="radio" data-role="radio" id="NewDatabase" value="NewDatabase" v-model="picked"
                            :data-caption="$t('Make the restore creating a new database with the following name')" />
                    </div>
                    <div class="cell-8">
                        <input type="text" data-role="input" v-model="userDbName"
                            :class="{ 'disabled': picked != 'NewDatabase' }">
                    </div>
                    <div class="cell-8">
                        <input type="radio" data-role="radio" id="OverwriteDatabase" value="OverwriteDatabase"
                            v-model="picked" :data-caption="$t('Make the restore overwriting an existing database')" />
                    </div>
                    <div class="cell-8">
                        <select data-role="select" data-filter="false" :disabled="picked != 'OverwriteDatabase'" v-model="selectedDB">
                            <option v-for="db in job.sources[0].dbSource.Databases" :key="db.Name" :value="db">{{db.Name }}</option>
                        </select>
                    </div>
                </div>

            </div>

            <div v-if="steps[3] && restore" class="container-fluid">

                <div class="row mt-4 ml-2">
                    <div class="cell-8">
                        <label>{{ $t("Database") }}: <b>{{ restore.restorename }}</b></label>
                    </div>
                    <div class="cell-8">
                        <label v-if="restore.dt_start_utc != '' && restore.dt_start_utc != null">{{ $t("Start") }}: <b>{{
                            formatDate(restore.dt_start_utc) }}</b></label>
                    </div>
                    <div class="cell-8">
                        <RestoreLastResult :lastresult="restore.lastresult" />
                    </div>
                    <div class="cell-6">
                        <div v-show="restore.progress != null && restore.progress != 0">
                            <div class="clear pb-3">
                                <small class="place-left" data-role="hint"
                                    data-hint-position="top" :data-hint-text="restore.current_operation">{{restore.current_operation}}</small>
                                <!--small v-else-if="restore.current_operation <= 100" class="place-left" data-role="hint"
                                    data-hint-position="top" :data-hint-text="restore.current_operation">{{restore.current_operation.substr(0, 50) + "..."}}</small>
                                <small v-else class="place-left" data-role="hint" data-hint-position="top"
                                    :data-hint-text="restore.current_operation">{{restore.current_operation.substr(0,50) + "..." + restore.current_operation.substr(-50, 50)}}</small-->
                            </div>
                            <div data-role="progress" data-type="line" :data-value="restore.progress" data-small="true"
                                data-cls-bar="bg-cobalt" data-role-progress="true" class="progress small">
                                <div class="bar ribbed-cobalt" :style="{ width: restore.progress + '%' }">
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="cell-2">
                        <small v-if="restore.isRunning" class="place-right text-bold enlarge-1">{{restore.progress}} %</small>
                    </div>
                </div>

            </div>

        </div>
        <div class="dialog-actions">
             <div class="ml-auto">
                <button class="button error float-right ml-2" @click="$root.$emit('CLOSERESTOREDIALOG')">{{ $t('Close')}}</button>
                <button class="button" :class="{ 'disabled': steps[0] }" @click="back"><span class="mif-arrow-left"></span> {{ $t('Prev') }}</button>
                <button class="button" :class="{'disabled': (steps[0] && jobselected == '') ||(steps[1] && (!databasestorestore.length > 0)) || (steps[2]) || steps[3]}" @click="next">{{ $t('Next') }} <span class="mif-arrow-right"> </span> </button>
                <button class="button primary float-right" v-show="(steps[2] && job.destinations[0].path != '') && ((picked == 'NewDatabase' && userDbName != '') || (picked == 'OverwriteDatabase' && selectedDB != null))" @click="beginRestore"><span class="mif-checkmark pr-2"></span>{{ $t('Restore') }}</button>
            </div>
        </div>

    </div>
</template>
<script>
import DialogTitle from "../../utils/DialogTitle";
import { prettyBytes,formatDate } from '../../../../public/assets/js/utilitiesmodule';
import api from "../../../router/api";
import $ from 'jquery'

export default {
    name: "DatabaseDestinationRestore",
    props: {
        idPC: String,
        idJob: {
            type: String,
            required: true
        },
    },
    components: {
        "DialogTitle": DialogTitle,
    },
    filters: {
        prettyBytes: function (bytes, precision) {
            return prettyBytes(bytes, precision);
        }
    },

    data() {
        return {
            steps: [1, 0, 0, 0],

            wait: true,
            waitvm: true,
            jobselected: '',
            checkedNames: [],
            id_service_account: -2,

            job: {},
            logs: [],

            go: false,
            justOneGuid: '',

            restores: null, // socket [GT - 06/06/2023]
            restore: null,

            DatabaseRestoreModel: {
                Id: '',
                JobId: '',
                Version: '',
                IdServiceAccount: '',
                DatabaseToRestorePath: '',
                CreateNewDatabase: false,
                DatabaseToCreateName: '',
                OverWriteExistingDatabase: false,
                DatabaseToOverwriteName: '',
                type: 6,
                type_name: 'Database',
            },

            databasesinbackup: [],
            databasestorestore: [],

            dbnametorestore: '',
            picked: 'NewDatabase',
            customCopyFilePath: [],
            userDbName: '',
            selectedDB: null,

            source: {},
            destination: {},


            folder: null,

            connection: null,

            pc_isconnected: '',
            portchech: null,
            port: null,
        }
    },

    created: function () {
        this.getLogsList();
    },

    methods: {

        // primo metodo ad essere eseguito
        // mostra tutte le esecuzioni con relativa configurazione
        async getLogsList() {
            let self = this;

            await this.$api.getBackupLogs(self.idJob).then(response => {
                self.logs = response;
                self.wait = false;
            });

        },

        formatDate(date) {
            if(date==null || date =="")return ""
            return formatDate(date, this.$moment);
        },

        async setDbSelected(db) {

            let self = this;
            if (self.databasestorestore.find(f => f.Name == db.Name) == null) {
                self.databasestorestore.push(db);
            }

            self.dbnametorestore = db.Name;

        },

        async getJobConfiguration(log) {

            let self = this;
            self.jobselected = ''; // svuoto la variabile che attiva pulsante next

            self.selectedlog = log;

            await this.$api.getBackupLogConfiguration(log.id).then(response => {

                console.log('[ExchangeDestinationRestore.getJobConfiguration] - api get backupconfigurations');
                console.log('[ExchangeDestinationRestore.getJobConfiguration] - ' + response);

                self.job = {}

                if (!self.job) {
                    self.job = response;
                }
                else {
                    self.job = Object.assign(self.job, response)
                }

                if (self.job.options) {
                    self.options = self.job.options
                }

                //valorizza l'array con le caselle di posta incluse nel singolo backup
                self.populateDBArray();

                self.jobselected = log.id; // attiva il pulsante next           

            });


        },

        getBackup() {

            let self = this;
            api
                .get('/jobs/' + self.idJob + "?fview=true")
                .then(response => {

                    console.log("api get jobs idbackup");
                    console.log(response.data);

                    if (!self.job) self.job = response.data;
                    else self.job = Object.assign(self.job, response.data);

                    if (self.job.options) self.options = self.job.options;

                });
        },

        //valorizza l'array con i database inclusi nel singolo backup
        populateDBArray() {
            let self = this;
            self.waitvm = true;
            var sc, db; // , idsToClose = [];

            //job.sources.0.exchangeSource.Mailboxes

            // svuoto array
            self.databasesinbackup.splice(0);

            if (self.job.sources)
                if (self.job.sources.length > 0)
                    for (var s = 0; s < self.job.sources.length; s++) { // ciclo sources del backup exchange
                        sc = self.job.sources[s]; // singola source
                        if (sc.dbSource)
                            for (var es = 0; es < sc.dbSource.Databases.length; es++) { // ciclo gli host che ospitano i datastore
                                db = sc.dbSource.Databases[es];
                                if (db.Selected)
                                    self.databasesinbackup.push(db);
                            }
                    }

            setTimeout(function () {
                //var treeview = window.Metro.get$el("#esxiTreeview").data("treeview");
                //console.log(treeview)
                /*idsToClose.forEach(id => {
                    console.log(treeview);
                    console.log(treeview.toggleNode);
                    console.log(id);
                    console.log($("#" + id));

                    treeview.toggleNode($("#" + id)); // espande tutti i nodi 

                });*/
                self.waitvm = false;

            }, 1000);

        },
        
        convertDate(date) {
            date = this.$moment(date.substr(0, 8) + "T" + date.substr(8), "YYYYMMDD HH:mm:ss");
            return this.$moment.utc(date).format("ddd DD/MM/YYYY HH:mm:ss");
        },

        prettyBytes: function (bytes, precision) {
            return prettyBytes(bytes, precision);
        },
       
        toggle(id) {

            console.log($('#' + id).css('display'));
            console.log($('#' + id));
            if ($('#' + id).css('display') == 'none')
                $('#' + id).slideDown(250);
            else
                $('#' + id).slideUp(250);
        },

        /*********************************UTILS**************************************/
        setIdServiceAccount() {
            this.$root.$emit('setIdServiceAccount', this.id_service_account);
            if ((typeof this.id_service_account) == 'string')
                this.connection.invoke("GetVirtualMachinesESXi", this.id_service_account);
        },

        next() {
            var next = true;


            this.steps.forEach((step, index) => {
                if (step == 1) {
                    if (next) {
                        this.$set(this.steps, index + 1, true)
                        this.$set(this.steps, index, false)
                        next = false;

                    }
                }
            });

        },
        back() {
            var next = true;
            this.steps.forEach((step, index) => {
                if (step == 1) {
                    if (next) {
                        this.$set(this.steps, index - 1, true)
                        this.$set(this.steps, index, false)
                        next = false;

                    }
                }
            });

        },
        


        async beginRestore() {
            var next = true;
            let self = this;

            self.go = confirm(this.$t("Restore the selected backup?"));


            if (self.go) {

                // faccio vedere la sezione del progress
                this.steps.forEach((step, index) => {
                    if (step == 1) {
                        if (next) {
                            this.$set(this.steps, index + 1, true)
                            this.$set(this.steps, index, false)
                            next = false;

                        }
                    }
                });

                self.justOneGuid = self.uuidv4();

                self.DatabaseRestoreModel.Id = self.justOneGuid;
                self.DatabaseRestoreModel.JobId = self.job.id;
                self.DatabaseRestoreModel.IdServiceAccount = self.job.sources[0].id_service_account;
                self.DatabaseRestoreModel.Version = '',
                    self.DatabaseRestoreModel.DatabaseToRestorePath = self.job.destinations[0].path + '\\' + self.dbnametorestore + '.bak';//self.customCopyFilePath[0].path;
                if (self.picked == 'NewDatabase') {
                    self.DatabaseRestoreModel.CreateNewDatabase = true;
                    self.DatabaseRestoreModel.DatabaseToCreateName = self.userDbName
                    self.DatabaseRestoreModel.backupName = self.userDbName;
                } else //OverwriteDatabase
                {
                    self.DatabaseRestoreModel.OverWriteExistingDatabase = true;
                    self.DatabaseRestoreModel.DatabaseToOverwriteName = self.selectedDB.name;
                    self.DatabaseRestoreModel.backupName = self.selectedDB.name;
                }

                self.DatabaseRestoreModel.type = self.job.sources[0].type;
                self.DatabaseRestoreModel.type_name = self.job.sources[0].type_name;


                console.log("[DatabaseRestore.beginRestore]");
                // runexchangerestore
                this.$root.socket("RUNDATABASERESTORE", self.DatabaseRestoreModel);

                // invoca agent per dare start ad invio delle informazioni sul restore
                self.idPC = this.$session.getRoomPC().id;
                //###DA INSERIRE IN OGNI COMPONENTE CHE NECESSITA DI CHIAMATE SOCKET###
                // "checkSocket" avvia la connessione socket se ferma
                // "socketEventConnected" viene emesso quando la connessione socket è attiva, quindi nella sua callback si possono avviare tutte le istruzioni che riguardano le chiamate socket
                // "socketEventDisconnected" viene emesso quando la connessione socket non è attiva
                this.$root.$on("socketEventConnected", () => {
                    self.$root.socketConnected = true;
                    self.pc_isconnected = true;
                    self.$root.socket("subscribe_pc", self.idPC).then(() => 
                        self.$root.socket("STARTSENDINFO")
                    );
                });
                this.$root.$on("socketEventDisconnected", () =>{
                    self.$root.socketConnected = false;
                    self.pc_isconnected = false;
                });
                //this.$root.checkSocket();


                // -------------------------------------------------------------------- 


                // subscrive per ricevere il progress del restore ---------------------
                this.$root.$on("addRestoreToPage", restores => {
                    // TODO
                    console.log('Restore in popup');
                    if (restores){
                        console.log(restores);
                        self.restore = restores[self.justOneGuid];
                    }
                    //self.restores = restores;                   

                });
                // --------------------------------------------------------------------
            }
        },

        uuidv4() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
                .replace(/[xy]/g, function (c) {
                    const r = Math.random() * 16 | 0,
                        v = c == 'x' ? r : (r & 0x3 | 0x8);
                    return v.toString(16);
                });
        },

        beforeDestroy: function () {
            this.$root.$off("socketEventConnected");
            this.$root.$off("socketEventDisconnected");
            if (this.connection != null) this.connection.stop();
        },
    },
}
</script>