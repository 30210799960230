<template>
<div class="w-100 h-100 overflow">

        <!-- charms -->
        <div data-role="charms" id="notifications-statsbackup" data-position="left"
            data-cls-charms="bd-gray bg-white border">
            <div class="row p-3">
                <h3 class="text-left fg-black">{{ $t("Notifications") }}</h3>
                <div class="cell d-flex flex-justify-end">
                    <button type="submit" class="button primary">{{ $t("Save") }}</button>
                    <button onclick="$('#notifications-statsbackup').data('charms').toggle()" class="button bg-white"><span class="icon mif mif-close mif-bin"> </span></button>
                </div>
            </div>                       
        </div>

        <Navigation pageName="remotestats" />
    
        <!-- spinner -->
        <div v-if="loading" class="h-100 m-0 op-dark-hi p-0 pos-absolute pos-center w-100 z-2">
           <span class="ani-spin fg-white mif-5x mif-spinner2 mt-13-minus pos-center"></span>      
        </div>

        <!-- graph container -->
        <div  class="w-100 p-3 mt-5" style="overflow-y: auto;">
            <div class="row">
                <div class="col-lg-12 col-sm-12 bg-transparent">
                  
                          <!--sezione filtri--> 
                          <form class="inline-form" @submit.prevent="getStats()">
                          <div class="d-flex flex-justify-left pb-6 pr-6">
                              
                              <select class="pl-4 select text-small mr-2 pl-3 pr-3 w-auto" v-model="filters.id_group">
                                  <option value="">{{ $t("All Groups") }}</option>
                                  <option v-for="group in groups" :value="group.id_group" v-bind:key="group.id_group">{{ group.name_group }} </option>
                              </select>
                              <select class="pl-4 select text-small mr-2 pl-3 pr-3 w-auto" v-model="filters.id_user">
                                  <option value="">{{ $t("All Users") }}</option>
                                  <option v-for="user in users" :value="user.id" v-bind:key="user.id">{{ user.username  }}  </option>   
                                </select>
                                
                                <input type="date" v-model="filters.dt_inf" :max="Date.now()| moment('YYYY-MM-DD')" class=" pl-4 select text-small mr-2 pl-3 pr-3   "  >
                                <input type="date" v-model="filters.dt_sup" :max="Date.now()| moment('YYYY-MM-DD')" class=" pl-4 select text-small mr-2 pl-3 pr-3  "  >
                                <input type="button" @click="getStats" :value="$t('filter')" class="button primary">
                          </div>
                          </form>       
                          
                          <!--sezione grafici-->
                          
                          <div class="info-box-content">
                          <div class="panel scroll p-4 mb-3">
    
                          <div class="overflow">

                            <div>
                                <div class="row">
                                    <div class="cell-12">
                                        <label class="mr-1">{{ $t("Number of Sessions per")}}</label>
                                        <select  class="d-inline-flex select text-small mr-2 pl-3 pr-3 w-auto" v-model="selected" @change="onSelBarUnoChange()">
                                        <option disabled value="">{{ $t("Please select one")}}</option>
                                        <option value="1">{{ $t("Month")}}</option>
                                        <option value="2" selected>{{ $t("Day")}}</option>
                                        </select>
                                        <!--div :class="item"  v-for="(item, index) in graph" :id="index"></div-->    
                                        
                                        <div id="chart1">
                                            <apexchart type="bar" height="380" :options="chartOptions" :series="seriesBar"></apexchart>
                                        </div>     
                                    </div>
                                    <div class="cell-12">
                                        <label class="mr-1">{{ $t("Duration of Sessions per")}}</label>

                                        <select  class="d-inline-flex select text-small mr-2 pl-3 pr-3 w-auto" v-model="selectedLine" @change="onSelLineUnoChange()">
                                        <option disabled value="">{{ $t("Please select one")}}</option>
                                        <option value="1">{{ $t("Month")}}</option>
                                        <option value="2" selected>{{ $t("Day")}}</option>
                                        </select>
                                       
                                        
                                        <div id="chart3">
                                            <apexchart type="area" height="380" :options="chartOptionsLine" :series="seriesLine"></apexchart>
                                        </div>     
                                    </div>
                                    
                                </div>  
                                <div class="row">
                                    
                                    <div class="cell-6 cell-lg-5 cell-xl-4">
                                        <label>{{ $t("% of Sessions per Operator")}}</label>
                                                                               
                                        <div id="chart2">
                                            <apexchart type="donut" height="380" :options="chartOptionsPie" :series="seriesPie"></apexchart>
                                        </div>    
                                    </div>
                                    <div class="cell-6 cell-lg-5 cell-xl-4">
                                        <label>{{ $t("% of Session Duration per Operator")}}</label>
                                                                               
                                        <div id="chart4">
                                            <apexchart type="donut" height="380" :options="chartOptionsPieDown" :series="seriesPieDown"></apexchart>
                                        </div>    
                                    </div>
                                </div>  
                                <div class="row">                                  
                                    <div class="cell-12">
                                        <label>{{ $t("Number and Duration of incoming sessions per device")}}</label>
                                                                               
                                        <div id="chart5">
                                            <apexchart type="bar" height="400" :options="chartOptionsBarBottom" :series="chartSeriesBarBottom"></apexchart>
                                        </div>    
                                    </div>
                                </div>         
                                <div class="row">                                  
                                    <div class="cell-12">
                                        <label>{{ $t("Number and Duration of incoming sessions per group")}}</label>
                                                                               
                                        <div id="chart6">
                                            <apexchart type="bar" height="400" :options="chartOptionsBarBottomGRP" :series="chartSeriesBarBottomGRP"></apexchart>
                                        </div>    
                                    </div>
                                </div>   
                                <div class="row">                                  
                                    <div class="cell-12">
                                        <label>{{ $t("Number and Duration of incoming sessions per operator")}}</label>
                                                                               
                                        <div id="chart7">
                                            <apexchart type="bar" height="400" :options="chartOptionsBarBottomUSR" :series="chartSeriesBarBottomUSR"></apexchart>
                                        </div>    
                                    </div>
                                </div>                              
                            </div>

                        
                        
                        
                        </div>

                    </div> 
                </div>
            </div>
        </div>  
    </div>



</div>



</template>
<script>
import Navigation from "../utils/Navigation";
import VueApexCharts from 'vue-apexcharts';
 export default {
    name:"StatsRemote",
    components: {           
            Navigation,    
            apexchart: VueApexCharts
        },
    props: {
          
       title :{
           type : String,
           required:false
       },
      
   },
   data() {
     return {
       size: 200,
       loading:false,
       groups:this.$session.getGroups(),
       users:this.$session.getUsers(),
       filters: {
               numrows: 25,
               offset: 0,
               lastresult: '',
               dt_inf:  this.$moment().subtract(10, 'days').format('YYYY-MM-DD'),
               dt_sup:  this.$moment().format('YYYY-MM-DD'),
               id_group: '',
               id_host: '',
               id_user: '',

           },
     
        maxDate: new Date().toISOString().split('T')[0], // Imposta la data massima come oggi in formato YYYY-MM-DD

        sessions: [],
        filteredSessions: [],
        stats_for_date: [],
        stats_for_date_tml: [],
        stats_for_date_line: [],
        stats_for_date_tmp_line: [],
        selected:"2",  
        selectedLine: "2",
        selectedBarBottom: "2",
          // series e config per first chart "BAR"
        seriesBar: [{
                data: [0, 0, 0, 0, 0],                
              }],
        chartOptions: {
                chart: {
                  type: 'bar',
                  height: 380
                },
                plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth: '20%',
                      endingShape: 'rounded'
                    },
                  },
                
                dataLabels: {
                    enabled: false
                  },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                  categories: ['', '', '', '', ''],
                },
                yaxis: {
                  labels: {
                    show: true
                  }
                },
                title: {
                    text: '',
                    align: 'center',
                    floating: true
                },
                /*subtitle: {
                    text: 'Count the number of sessions for months',
                    align: 'center',
                },*/
                tooltip: {
                  theme: 'dark',
                  x: {
                    show: false
                  },
                  y: {
                    title: {
                      formatter: function () {
                        return ''
                      }
                    }
                  }
                }
              },

        // "PIE" series e config for first pie chart
        seriesPie: [0, 0, 0, 0, 0],
        chartOptionsPie: {
              chart: {
                width: 380,
                type: 'donut',
              },
              
              labels: ['', '', '', '', ''],            
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 400
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              }]
            },
            
        // "PIE" series e config for second pie chart 
        seriesPieDown: [0, 0, 0, 0, 0],
            chartOptionsPieDown: {
            chart: {
                width: 380,
                type: 'donut',
            },
           
            labels: ['', '', '', '', ''],
            responsive: [{
                breakpoint: 480,
                options: {
                chart: {
                    width: 400
                },
                legend: {
                    position: 'bottom'
                }
                }
            }]
            },                         

        // series e config per third chart "LINE"
        seriesLine: [{
                name: this.$t("Total time"),
                data: [0, 0, 0, 0, 0]
            }],
            chartOptionsLine: {
              chart: {
                height: 350,
                type: 'area',
                zoom: {
                  enabled: false
                }
              },
              
              dataLabels: {
                enabled: false
              },
              fill: {
                colors: ['#0366d6'], // Colore dell'area sotto la linea
                opacity: 0.3 // Opacità dell'area
              },
              stroke: {
                curve: 'smooth', // Tipo di curva della linea
                colors: ['#0366d6'], // Colore della linea
                width: 2 // Spessore della linea
              },
              title: {
                text: '',
                align: 'center'
              },
              tooltip: {
                custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                  var timeInSeconds = series[seriesIndex][dataPointIndex];
                  var formattedTime = this.formatTime(timeInSeconds);
                  return '<div class="custom-tooltip">' +
                    '<span>' + w.globals.labels[dataPointIndex] + ': ' + formattedTime + '</span>' +
                    '</div>';
                }
              },
            /*subtitle: {
                text: 'Count the number of sessions for months',
                align: 'center',
            },*/
              grid: {
                row: {
                  colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                  opacity: 0.5
                },
              },
              xaxis: {
                categories: ['', '', '', '', ''],
              }
            },

            // FIRST CHART BAR BOTTOM
            chartOptionsBarBottom: {
                chart: {
                  type: "bar",
                  height: 400,
                  zoom: {
                    enabled: true
                  }
                },    
               
                plotOptions: {
                    bar: {
                    horizontal: false,
                    columnWidth: '20%' // Imposta la larghezza delle colonne al 20%
                    }
                },            
                xaxis: {
                  categories: [], // Elenco degli id_remote_host
                  /*labels: {
                    formatter: function(value) {
                      // Abbrevia il testo delle categorie
                      return value.substring(0, 3); // Mostra solo i primi 3 caratteri
                    }
                  }*/
                  labels: {
                    style: {
                      fontSize: '8px' // Imposta la dimensione del font a 10 pixel
                    }
                  }
                },
         
                  toolbar: {
                    show: true,
                    tools: {
                    download: true,
                   
                    }
                },
                yaxis: [
                  {
                    title: {
                      text: this.$t("Session number"),
                    },
                  },
                  {
                    opposite: true,
                    title: {
                      text: this.$t("Total time (min)"),
                    },
                  },
                ],
              },
            chartSeriesBarBottom: [
                {
                  name: this.$t('Session number'),
                  data: [], // Numero di connessioni per ciascun id_remote_host
                },
                {
                  name: this.$t("Total time (min)"),
                  data: [], // Tempo totale delle connessioni per ciascun id_remote_host
                },
            ],

               // LAST CHART BAR BOTTOM
            chartOptionsBarBottomGRP: {
                chart: {
                type: "bar",
                height: 400,
                
                },
             
                plotOptions: {
                    bar: {
                    horizontal: false,
                    columnWidth: '20%' // Imposta la larghezza delle colonne al 20%
                    }
                },
                xaxis: {
                categories: [], // Elenco degli id_remote_host
                /*labels: {
                    formatter: function(value) {
                    // Abbrevia il testo delle categorie
                    return value.substring(0, 3); // Mostra solo i primi 3 caratteri
                    }
                }*/
                labels: {
                    style: {
                    fontSize: '8px' // Imposta la dimensione del font a 10 pixel
                    }
                }
                },
                /*tooltip: {
                    enabled: true,
                    y: {
                    formatter: function(value) {
                        return value + ' connessioni'; // Personalizza il formato del valore del tooltip
                    }
                    }
                },*/
                toolbar: {
                    tools: {
                      download: true, // Abilita il download
                   
                    }
                },
                yaxis: [
                {
                    title: {
                    text: this.$t("Session number"),
                    },
                },
                {
                    opposite: true,
                    title: {
                    text: this.$t("Total time (min)"),
                    },
                },
                ],
            },
            chartSeriesBarBottomGRP: [
                {
                name: this.$t("Session number"),
                data: [], // Numero di connessioni per ciascun id_remote_host
                },
                {
                name: this.$t("Total time (min)"),
                data: [], // Tempo totale delle connessioni per ciascun id_remote_host
                },
            ],  

            // LAST CHART BAR BOTTOM
            chartOptionsBarBottomUSR: {
                chart: {
                type: "bar",
                height: 400,
               
                },
                //colors: ['#0366d6'],
                plotOptions: {
                    bar: {
                    horizontal: false,
                    columnWidth: '20%' // Imposta la larghezza delle colonne al 20%
                    }
                },
                xaxis: {
                categories: [], // Elenco degli id_remote_host
                /*labels: {
                    formatter: function(value) {
                    // Abbrevia il testo delle categorie
                    return value.substring(0, 3); // Mostra solo i primi 3 caratteri
                    }
                }*/
                labels: {
                    style: {
                    fontSize: '8px' // Imposta la dimensione del font a 10 pixel
                    }
                }
                },
                /*tooltip: {
                    enabled: true,
                    y: {
                    formatter: function(value) {
                        return value + ' connessioni'; // Personalizza il formato del valore del tooltip
                    }
                    }
                },*/
                toolbar: {
                    tools: {
                      download: true, // Abilita il download
                    }
                },
               
                yaxis: [
                {
                    title: {
                    text: this.$t("Session number"),
                    },
                },
                {
                    opposite: true,
                    title: {
                    text: this.$t("Total time (min)"),
                    },
                },
                ],
            },
            chartSeriesBarBottomUSR: [
                {
                name: this.$t("Session number"),
                data: [], // Numero di connessioni per ciascun id_remote_host
                },
                {
                name: this.$t("Total time (min)"),
                data: [], // Tempo totale delle connessioni per ciascun id_remote_host
                },
            ],



      }
    },
   created() {
    this.$session.setRoomPC( null);
    this.$root.socket("SETPC", null);  
       this.getStats();
     
   },
 
   methods:{


     // questa function formatta le tooltips del grafico di tipo Area
     formatTime(seconds) {
            var hours = Math.floor(seconds / 3600);
            var minutes = Math.floor((seconds % 3600) / 60);
            var remainingSeconds = seconds % 60;
            return hours + 'h ' + minutes + 'm ' + remainingSeconds + 's';
        },

        // Funzione per generare colori varianti dello stesso colore base
        generateBlueShades(baseColor, numberOfShades) {
            const colors = [];
            for (let i = 0; i < numberOfShades; i++) {
            const lightness = i * (100 / numberOfShades); // Calcola la luminosità
            const color = `hsl(${baseColor}, 100%, ${lightness}%)`; // Imposta la tonalità del blu a 210 (blu) e varia solo la luminosità
            colors.push(color);
            }
            return colors;
        },    

        getStats() {
               let self = this;

               self.sessions = [];
               self.stats_for_date_tmp = {};
               self.stats_for_date = {};
               self.stats_for_date_tmp_line = {};
               self.stats_for_date_line = {};            
               self.loading = true;
               self.filteredSessions=[];

               const baseColor = '210';

               // Numero di tonalità desiderate
               const numberOfShades = 5;


               self.loading = true;
               //self.fullStats = await this.$api.getStatsFromLicense(self.filters.dt_inf,self.filters.dt_sup);
               //self.sessions =  await this.$api.getRemoteStats( self.filters);
               
               this.$api.getSessions(self.filters)
                 .then(function(response) {
                    if (response){
                     // statistiche per giorno 
                     
                     if (response.stats_per_date) {
                        self.stats_for_date_tmp = response.stats_per_date;
                        self.stats_for_date_tmp_line = response.stats_per_date;

                        // BAR REPORTS -----------------------------------------------------------------------
                        if (self.selected=='1'){
                            self.stats_for_date = self.raggruppaPerMese(self.stats_for_date_tmp)
                            // creazione di due array separati per le etichette (mesi) e i dati (count)
                            const etichetteMesi = Object.keys(self.stats_for_date).sort();
                            self.aggiornaCategorieColumnChart(etichetteMesi);
                            const datiCount = etichetteMesi.map(mese => self.stats_for_date[mese].count);
                            //self.series[0].data = datiCount
                            self.seriesBar = [{
                                data: datiCount
                            }];
                        }else{
                            // creazione di due array separati per le etichette (mesi) e i dati (count)
                            const etichetteGiorni = Object.keys(self.stats_for_date_tmp).sort();
                            self.aggiornaCategorieColumnChart(etichetteGiorni);
                            // Ottenere i dati per il conteggio per ogni giorno
                            const datiCount = Object.values(self.stats_for_date_tmp).map(day => day.count);
                            //self.series[0].data = datiCount
                            self.seriesBar = [{
                                data: datiCount
                            }];
                        }                      
                        // ---------------------------------------------------------------------------------------------    
                        
                        // LINE REPORTS --------------------------------------------------------------------------------
                        if (self.selectedLine=='1'){
                            self.stats_for_date_line = self.raggruppaPerMese(self.stats_for_date_tmp_line)
                            // creazione di due array separati per le etichette (mesi) e i dati (count)
                            const etichetteMesiLine = Object.keys(self.stats_for_date_line).sort();
                            self.aggiornaCategorieLineChart(etichetteMesiLine);
                            const datiTimeLine = etichetteMesiLine.map(mese => self.stats_for_date_line[mese].total_time);

                            self.seriesLine = [{
                                data: datiTimeLine
                            }];
                        }else{
                            // creazione di due array separati per le etichette (mesi) e i dati (count)
                            const etichetteGiorniLine = Object.keys(self.stats_for_date_tmp_line).sort();
                            self.aggiornaCategorieLineChart(etichetteGiorniLine);
                            // Ottenere i dati per il conteggio per ogni giorno
                            const datiTimeline = Object.values(self.stats_for_date_tmp_line).map(day => day.total_time);
                            self.seriesLine = [{
                                data: datiTimeline
                            }];

                        }
                        // ---------------------------------------------------------------------------------------------                        
                      }
                        // PIE REPORTS (grafico a torta a destra) -------------------------------------------------------------------------------
                      if (response.stats_per_user){
                        // Calcola la somma totale di conteggio e tempo
                        const totalCount = Object.values(response.stats_per_user).reduce((acc, user) => acc + user.count, 0);
                        const totalTime = Object.values(response.stats_per_user).reduce((acc, user) => acc + user.total_time, 0);

                        // Calcola la percentuale di conteggio e tempo per ciascun utente
                        const userData = Object.keys(response.stats_per_user).map(userId => {
                            const user = response.stats_per_user[userId];
                            return {
                                userId: userId,
                                detailcountuser: user.count,
                                detailusertime: user.total_time,
                                countPercentage: +(Math.round(((user.count / totalCount) * 100) + "e+2") + "e-2"),
                                //timePercentage: +(Math.round(((user.total_time / totalCount) * 100) + "e+2") + "e-2")
                                timePercentage: Math.round((user.total_time / totalTime) * 100)
                            };
                        });    

                        // Genera colori varianti su base blu
                        const colors = self.generateBlueShades(baseColor, userData.length);      

                        // il grafico a torta in alto mostra le percentuali relativie al numero di connessioni / sul totale, per ogni utente
                        self.chartOptionsPie = {
                            labels: userData.map(user => user.userId.split('|')[1]),
                            colors: colors
                          };
                        self.seriesPie = userData.map(user => user.detailcountuser );
                        
                        // il grafico a torta in basso mostra le percentuali relativie al tempo di connessioni / sul tempo totale, per ogni utente
                        self.chartOptionsPieDown = {
                            labels: userData.map(user => user.userId.split('|')[1]),
                            colors: colors
                          };
                        self.seriesPieDown = userData.map(user => Math.round(user.detailusertime / 60) );
                        // -----------------------------------------------------------------------------------------------------------------------

                        // FIRST CHART BAR BOTTOM
                        // mostra il totale delle connessione e il totale dei minuti di sessione in base al host remoto
                        const sessions = response.sessions; 
                        const groupedData = {}; 

                        // Calcola il numero di connessioni e il tempo totale per ogni gruppo
                        for (const session of sessions) {
                        //const key = `${session.id_remote_host}-${session.id_user}`;
                        const key = `${session.id_remote_host}`;
                        if (!groupedData[key]) {
                            groupedData[key] = {
                            id_remote_host: session.id_remote_host + ' ('+ session.custom_name +')',
                            numConnections: 0,
                            totalTime: 0,
                            };
                        }
                        groupedData[key].numConnections++;
                        groupedData[key].totalTime += session.total_time;
                        }

                        // Prepara i dati per ApexCharts
                        const categories = [];
                        const numConnections = [];
                        const totalTimebb = [];

                        for (const key in groupedData) {
                            const group = groupedData[key];
                            categories.push(group.id_remote_host);
                            numConnections.push(group.numConnections);
                            totalTimebb.push(Math.round(group.totalTime/60));
                        }

                        // Aggiorna i dati del grafico
                        self.aggiornaCategorieBarBottomChart(categories);
                        self.chartSeriesBarBottom[0].data = numConnections;
                        self.chartSeriesBarBottom[1].data = totalTimebb; 
                        // -----------------------------------------------------------------------------

                        // SECOND CHART BAR AT BOTTOM
                        // mostra il totale delle connessione e il totale dei minut di sessione in base al gruppo
                        const groupedDataGRP = {}; 

                        // Calcola il numero di connessioni e il tempo totale per ogni gruppo
                        for (const sessionGRP of sessions) {
                        const key = `${sessionGRP.group_id}`;
                        if (!groupedDataGRP[key]) {
                            groupedDataGRP[key] = {
                            groupname: sessionGRP.group_name,
                            numConnections: 0,
                            totalTime: 0,
                            };
                        }
                        groupedDataGRP[key].numConnections++;
                        groupedDataGRP[key].totalTime += sessionGRP.total_time;
                        }

                        // Prepara i dati per ApexCharts
                        const categoriesGRP = [];
                        const numConnectionsGRP = [];
                        const totalTimebbGRP = [];

                        for (const key in groupedDataGRP) {
                            const groupGRP = groupedDataGRP[key];
                            categoriesGRP.push(groupGRP.groupname);
                            //categories.push(group.id_user);
                            numConnectionsGRP.push(groupGRP.numConnections);
                            totalTimebbGRP.push(Math.round(groupGRP.totalTime/60));
                        }

                        // Aggiorna i dati del grafico
                        self.aggiornaCategorieBarBottomChartGRP(categoriesGRP);

                        self.chartSeriesBarBottomGRP[0].data = numConnectionsGRP;
                        self.chartSeriesBarBottomGRP[1].data = totalTimebbGRP;    
                        // -----------------------------------------------------------------------------

                        // THIRD CHART BAR BOTTOM
                        // mostra il totale delle connessione e il totale dei minut di sessione in base al'operatore
                        const groupedDataUSR = {}; 

                        // Calcola il numero di connessioni e il tempo totale per ogni gruppo
                        for (const sessionUSR of sessions) {
                            const key = `${sessionUSR.id_user}`;
                            if (!groupedDataUSR[key]) {
                                groupedDataUSR[key] = {
                                userfullname: sessionUSR.firstname + ' ' + sessionUSR.lastname,                            
                                numConnections: 0,
                                totalTime: 0,
                                };
                            }
                            groupedDataUSR[key].numConnections++;
                            groupedDataUSR[key].totalTime += sessionUSR.total_time;
                        }

                        // Prepara i dati per ApexCharts
                        const categoriesUSR = [];
                        const numConnectionsUSR = [];
                        const totalTimebbUSR = [];

                        for (const key in groupedDataUSR) {
                            const groupUSR = groupedDataUSR[key];
                            categoriesUSR.push(groupUSR.userfullname);
                            numConnectionsUSR.push(groupUSR.numConnections);
                            totalTimebbUSR.push(Math.round(groupUSR.totalTime/60));
                        }

                        // Aggiorna i dati del grafico
                        self.aggiornaCategorieBarBottomChartUSR(categoriesUSR);

                        self.chartSeriesBarBottomUSR[0].data = numConnectionsUSR;
                        self.chartSeriesBarBottomUSR[1].data = totalTimebbUSR;    
                        // -----------------------------------------------------------------------------                        
                      }


                }})
                .catch(e => {
                    //self.errors.push(e)
                    //self.toastr["info"](self.errors);
                    app.loading = false;
                })

           

               //if (self.sessions.sessions.length != 0)  self.statsPerDateChart(self.sessions.stats_per_date);
               //if (self.sessions.sessions.length != 0)  self.statsPerUserChart(self.sessions.stats_per_user);
               //if (self.sessions.sessions.length != 0)  self.statsPerUserCake(self.sessions.stats_per_user);
               //if (self.fullStats.dataStats.length != 0)  self.dataBackupChart(self.fullStats.dataStats);

               self.loading = false;
               //if (self.fullStats!=null && self.fullStats.nOfServers + self.fullStats.nOfWorkstations != 0)  self.computerTypeChart();
               //if (self.fullStats.nPcDanger + self.fullStats.nPcOk + self.fullStats.nPcWarning != 0)  self.computerHealtChart();
           },

       // METODI PER I REPORT CHART ###########################################################################
        // eseguita al change della combo month/day del grafico a colonne verticali
        onSelBarUnoChange() {

            var self = this;

            // BAR REPORTS -----------------------------------------------------------------------
            if (self.selected=='1'){
                self.stats_for_date = self.raggruppaPerMese(self.stats_for_date_tmp)
                // creazione di due array separati per le etichette (mesi) e i dati (count)
                const etichetteMesi = Object.keys(self.stats_for_date).sort();
                self.aggiornaCategorieColumnChart(etichetteMesi);
                const datiCount = etichetteMesi.map(mese => self.stats_for_date[mese].count);
                //app.series[0].data = datiCount
                self.seriesBar = [{
                    data: datiCount
                }];
            }
            if (self.selected=='2'){
                // creazione di due array separati per le etichette (mesi) e i dati (count)
                const etichetteGiorni = Object.keys(self.stats_for_date_tmp).sort();
                self.aggiornaCategorieColumnChart(etichetteGiorni);
                // Ottenere i dati per il conteggio per ogni giorno
                const datiCount = Object.values(self.stats_for_date_tmp).map(day => day.count);
                //app.series[0].data = datiCount
                self.seriesBar = [{
                    data: datiCount
                }];
            }
            },
            // eseguita al change della combo month/day del grafico di tipo area
        onSelLineUnoChange() {

                var self = this;

                // LINE REPORTS --------------------------------------------------------------------------------
                if (self.selectedLine=='1'){
                    self.stats_for_date_line = self.raggruppaPerMese(app.stats_for_date_tmp_line)
                    // creazione di due array separati per le etichette (mesi) e i dati (count)
                    const etichetteMesiLine = Object.keys(self.stats_for_date_line).sort();
                    self.aggiornaCategorieLineChart(etichetteMesiLine,self.chartOptionsLine);
                    const datiTimeLine = etichetteMesiLine.map(mese => self.stats_for_date_line[mese].total_time);
                    self.seriesLine = [{
                        data: datiTimeLine
                    }];
                }
                if (self.selectedLine=='2'){
                    // creazione di due array separati per le etichette (mesi) e i dati (count)
                    const etichetteGiorniLine = Object.keys(self.stats_for_date_tmp_line).sort();
                    self.aggiornaCategorieLineChart(etichetteGiorniLine,self.chartOptionsLine);
                    // Ottenere i dati per il conteggio per ogni giorno
                    const datiTimeline = Object.values(self.stats_for_date_tmp_line).map(day => day.total_time);
                    self.seriesLine = [{
                        data: datiTimeline
                    }];
                }
                // ---------------------------------------------------------------------------------------------
            },
          

            aggiornaCategorieColumnChart(categorieNuove) {


                let self = this;

                self.chartOptions = {
                ...self.chartOptions,
                xaxis: { ...self.chartOptions.xaxis, categories: categorieNuove }
                };
            },

            aggiornaCategorieLineChart(categorieNuove) {

                let self = this;

                self.chartOptionsLine = {
                ...self.chartOptionsLine,
                xaxis: { ...self.chartOptionsLine.xaxis, categories: categorieNuove }
                };
            },  

            aggiornaCategorieBarBottomChart(categorieNuove) {

                let self = this;


                self.chartOptionsBarBottom = {
                ...self.chartOptionsBarBottom,
                xaxis: { ...self.chartOptionsBarBottom.xaxis, categories: categorieNuove }
                };
            },          

            aggiornaCategorieBarBottomChartUSR(categorieNuove) {

                let self = this;

                self.chartOptionsBarBottomUSR = {
                ...self.chartOptionsBarBottomUSR,
                xaxis: { ...self.chartOptionsBarBottomUSR.xaxis, categories: categorieNuove }
                };
            },   

            aggiornaCategorieBarBottomChartGRP(categorieNuove) {

                let self = this;

                self.chartOptionsBarBottomGRP = {
                ...self.chartOptionsBarBottomGRP,
                xaxis: { ...self.chartOptionsBarBottomGRP.xaxis, categories: categorieNuove }
                };
            },           

            // raggruppa i risultati (raggruppati per giorni) in mesi delle statistiche inviati da api  
            raggruppaPerMese(stats) {
                const risultatiPerMese = {};

                Object.entries(stats).forEach(([data, {count, total_time}]) => {
                    const mese = data.substr(0, 7); // Estrae l'anno e il mese dalla data
                    
                    if (!risultatiPerMese[mese]) {
                        risultatiPerMese[mese] = { count: 0, total_time: 0 };               
                    }
                    
                    risultatiPerMese[mese].count += count;
                    risultatiPerMese[mese].total_time += total_time;
                });

                return risultatiPerMese;
            },        
            // #######################################################################################################

         
           
   }
 }
</script> 