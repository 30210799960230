var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.friendlyObj
    ? _c(
        "div",
        _vm._l(Object.keys(_vm.friendlyObj), function (prop) {
          return _c("div", { staticClass: "d-flex" }, [
            _c("span", { staticClass: "cell-4 d-flex flex-align-center p-0" }, [
              _c("strong", [_vm._v(_vm._s(_vm.$t(prop)))]),
            ]),
            _c("span", { staticClass: "cell-7 d-flex flex-align-center p-0" }, [
              _vm._v(_vm._s(_vm.friendlyObj[prop])),
            ]),
            _vm.withCopyButton
              ? _c("span", { staticClass: "cell-1 p-0" }, [
                  _c("button", {
                    staticClass: "mif-copy button bg-transparent ml-auto",
                    attrs: { title: _vm.$t("Click to copy to clipboard") },
                    on: {
                      click: function ($event) {
                        return _vm.copy(_vm.friendlyObj[prop])
                      },
                    },
                  }),
                ])
              : _vm._e(),
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }