<template>
    <div class="dialog-title">
        <span class="caption "> 
            <label >{{ title == undefined || title == null || title == "" ? defaultTitle : title }} ></label>
            
            <label v-if="subTitle!=''" class="p-2">{{ subTitle }}</label>
        </span>
        <span class="button square closer" @click="closeDialog"></span>
    </div>
</template>

<script>
export default {
    name: "DialogTitle",
    props: {
        title: String,
        defaultTitle: String,
        subTitle: String
    },
    methods:{
        closeDialog(){
            this.$emit('closeDialog');
        }
    }
    
}
</script>