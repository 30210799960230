var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.pc
    ? _c(
        "div",
        {
          key: _vm.$route.params.idPC,
          staticClass: "w-100 overflow",
          staticStyle: { height: "100%" },
        },
        [
          _vm.terminal.toggleTerminal
            ? _c(
                "div",
                {
                  attrs: {
                    id: "terminalwindow",
                    "data-cls-window": "z-10",
                    "data-role": "window",
                    "data-title": _vm.$t("Command Prompt"),
                    "data-resizable": "true",
                    "data-draggable": "true",
                    "data-place": "top-center",
                    "data-btn-max": "false",
                    "data-btn-min": "false",
                    "data-shadow": "true",
                    "data-width": "500",
                    "data-close-action": "Metro.actions.HIDE",
                    "data-cls-caption": "bg-light fg-dark pl-2 text-bold ",
                    "data-height": "400",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.terminal.terminalLogged,
                          expression: "terminal.terminalLogged",
                        },
                      ],
                    },
                    [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.messageToTerminal()
                            },
                          },
                        },
                        [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.terminal.terminalText,
                                expression: "terminal.terminalText",
                              },
                            ],
                            staticClass:
                              "bg-black border-visible-none fg-white w-100 p-4",
                            staticStyle: {
                              "font-family": "system-ui",
                              height: "calc(100% - 35px)",
                              "font-size": "14px",
                            },
                            attrs: { id: "textarea", readonly: "" },
                            domProps: { value: _vm.terminal.terminalText },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.terminal,
                                  "terminalText",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.terminal.terminalInput,
                                expression: "terminal.terminalInput",
                              },
                            ],
                            attrs: {
                              type: "text",
                              "data-role": "input",
                              "data-clear-button": "false",
                              "data-history": "true",
                              "data-prepend": ">",
                              "data-prevent-submit": "true",
                              "data-on-search-button-click":
                                _vm.messageToTerminal,
                              "data-search-button-icon":
                                "<span class='mif-keyboard-return'></span>",
                            },
                            domProps: { value: _vm.terminal.terminalInput },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.terminal,
                                  "terminalInput",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  !_vm.terminal.terminalLogged
                    ? _c("div", [
                        _c(
                          "form",
                          {
                            staticClass: "form p-5",
                            on: {
                              subtmit: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "prevent()",
                                    undefined,
                                    $event.key,
                                    undefined
                                  )
                                )
                                  return null
                                return _vm.loginTerminal.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._m(0),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "cell-sm-12" }, [
                                _c("label", { staticClass: "mt-1 no-wrap" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("Computer username")) + ":"
                                  ),
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.terminal.username,
                                      expression: "terminal.username",
                                    },
                                  ],
                                  staticClass: "input bg-light",
                                  attrs: { type: "text" },
                                  domProps: { value: _vm.terminal.username },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.terminal,
                                        "username",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "cell-sm-12" }, [
                                _c("label", { staticClass: "mt-1" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("Computer password")) + ":"
                                  ),
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.terminal.password,
                                      expression: "terminal.password",
                                    },
                                  ],
                                  staticClass: "input bg-light",
                                  attrs: {
                                    "data-validate": "required",
                                    "data-role": "input",
                                    type: "password",
                                    placeholder: _vm.$t("Password"),
                                  },
                                  domProps: { value: _vm.terminal.password },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.terminal,
                                        "password",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _c(
                                  "span",
                                  { staticClass: "invalid_feedback" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("Please enter a valid password")
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "row text-center" }, [
                              _c("div", { staticClass: "cell-sm-12 pt-5" }, [
                                _c("input", {
                                  staticClass: "button primary",
                                  attrs: {
                                    type: "button",
                                    value: _vm.$t("Login"),
                                  },
                                  on: { click: _vm.loginTerminal },
                                }),
                              ]),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              attrs: {
                "data-role": "charms",
                "data-on-open": (_vm.settings_opened = true),
                "data-on-close": (_vm.settings_opened = false),
                id: "notifications-pc",
                "data-position": "right",
                "data-cls-charms": "mt-1 bg-white",
              },
            },
            [
              _c("div", { staticClass: "row p-3" }, [
                _c("h3", { staticClass: "text-left fg-black" }, [
                  _c("span", { staticClass: "mif-notifications" }),
                  _vm._v(" " + _vm._s(_vm.$t("Notifications"))),
                ]),
                _vm._m(1),
              ]),
              _c("div", { staticClass: "mt-4 col-lg-12 col-md-12" }, [
                _c("form", [
                  _c("table", { staticClass: "table compact" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("td"),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.$t("EMAIL"))),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.$t("PUSH")) + " "),
                        ]),
                      ]),
                    ]),
                    _c("tbody", [
                      _c("tr", [
                        _c("td", [
                          _vm._v(_vm._s(_vm.$t("Backup Notifications"))),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "row mb-2" }, [
                            _c("input", {
                              attrs: {
                                type: "checkbox",
                                "data-caption-position": "left",
                                "data-role": "switch",
                                "data-on": "on",
                              },
                              domProps: {
                                checked:
                                  _vm.pc.notifications[1].enabled_email == "1",
                              },
                            }),
                          ]),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "row mb-2" }, [
                            _c("input", {
                              attrs: {
                                type: "checkbox",
                                "data-caption-position": "left",
                                "data-role": "switch",
                                "data-on": "on",
                              },
                              domProps: {
                                checked:
                                  _vm.pc.notifications[1].enabled_push == "1",
                              },
                            }),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", [
                          _vm._v(_vm._s(_vm.$t("System Notifications"))),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "row mb-2" }, [
                            _c("input", {
                              attrs: {
                                type: "checkbox",
                                "data-caption-position": "left",
                                "data-role": "switch",
                                "data-on": "on",
                              },
                              domProps: {
                                checked:
                                  _vm.pc.notifications[0].enabled_email == "1",
                              },
                            }),
                          ]),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "row mb-2" }, [
                            _c("input", {
                              attrs: {
                                type: "checkbox",
                                "data-caption-position": "left",
                                "data-role": "switch",
                                "data-on": "on",
                              },
                              domProps: {
                                checked:
                                  _vm.pc.notifications[0].enabled_push == "1",
                              },
                            }),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", [
                          _vm._v(" " + _vm._s(_vm.$t("Backup Reports")) + " "),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "row mb-2" }, [
                            _c("input", {
                              attrs: {
                                type: "checkbox",
                                "data-caption-position": "left",
                                "data-role": "switch",
                                "data-on": "on",
                              },
                              domProps: {
                                checked:
                                  _vm.pc.notifications[3].enabled_email == "1",
                              },
                            }),
                          ]),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "row mb-2" }, [
                            _c("input", {
                              attrs: {
                                type: "checkbox",
                                "data-caption-position": "left",
                                "data-role": "switch",
                                "data-on": "on",
                              },
                              domProps: {
                                checked:
                                  _vm.pc.notifications[3].enabled_push == "1",
                              },
                            }),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.$t("Disconnect Notifications")) + " "
                          ),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "row mb-2" }, [
                            _c("input", {
                              attrs: {
                                type: "checkbox",
                                "data-caption-position": "left",
                                "data-role": "switch",
                                "data-on": "on",
                              },
                              domProps: {
                                checked:
                                  _vm.pc.notifications[2].enabled_email == "1",
                              },
                            }),
                          ]),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "row mb-2" }, [
                            _c("input", {
                              attrs: {
                                type: "checkbox",
                                "data-caption-position": "left",
                                "data-role": "switch",
                                "data-on": "on",
                              },
                              domProps: {
                                checked:
                                  _vm.pc.notifications[2].enabled_push == "1",
                              },
                            }),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm.pc != null
            ? _c("Navigation", {
                attrs: { pageName: "computer", data: { pc: _vm.pc } },
              })
            : _vm._e(),
          _c("div", { key: _vm.computerKey }, [
            _c("div", { staticClass: "overflow flex-align-left" }, [
              _vm.pc
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-sm-12 col-md-12 col-lg-12 col-xl-12 pl-4 p-2",
                    },
                    [
                      _c(
                        "ul",
                        {
                          staticClass:
                            "bg-transparent text-small pl-2 tabs-material",
                          staticStyle: { "z-index": "1" },
                          attrs: {
                            "data-role": "materialtabs",
                            id: "backups_tabs",
                            "data-expand": "true",
                            "data-on-tab": "console.log('tab', arguments)",
                            "data-on-tab-close":
                              "console.log('close', arguments)",
                            "data-on-tab-open":
                              "console.log('open', arguments)",
                            "data-update-uri": "true",
                          },
                        },
                        [
                          _c(
                            "li",
                            {
                              staticClass:
                                "d-flex flex-align-center mt-0 pt-0 active pb-3",
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#sysinfo" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openTab(1)
                                    },
                                  },
                                },
                                [
                                  _c("br"),
                                  _vm.pc.ram_alert != 0 ||
                                  _vm.pc.av_alert != 0 ||
                                  _vm.pc.disk_alert != 0
                                    ? _c("span", {
                                        staticClass: "mif-warning fg-red",
                                      })
                                    : _vm._e(),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("Overview"))),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass:
                                "d-flex flex-align-center mt-0 pt-0 active pb-3",
                              attrs: { disabled: _vm.socket_backups == null },
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#backups" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openTab(2)
                                    },
                                  },
                                },
                                [
                                  _vm.socket_backups == null
                                    ? _c("span", {
                                        staticClass:
                                          "mif-spinner2 fg-primary ani-spin",
                                      })
                                    : _vm._e(),
                                  _c("br"),
                                  _vm.is_global_running.jobs == true
                                    ? _c(
                                        "span",
                                        { staticClass: "fg-cobalt ani-flash" },
                                        [_vm._v(" ● ")]
                                      )
                                    : _vm._e(),
                                  _c("span", [
                                    _vm._v(" " + _vm._s(_vm.$t("Backup"))),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass:
                                "d-flex flex-align-center mt-0 pt-0 active pb-3",
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#restores" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openTab(3)
                                    },
                                  },
                                },
                                [
                                  _vm.restores == null
                                    ? _c("span", {
                                        staticClass:
                                          "mif-spinner2 fg-primary ani-spin",
                                      })
                                    : _vm._e(),
                                  _c("br"),
                                  _vm.is_global_running.restores == true
                                    ? _c(
                                        "span",
                                        { staticClass: "fg-cobalt ani-flash" },
                                        [_vm._v(" ● ")]
                                      )
                                    : _vm._e(),
                                  _c("span", [
                                    _vm._v(" " + _vm._s(_vm.$t("Restore"))),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass:
                                "d-flex flex-align-center mt-0 pt-0 active pb-3",
                              attrs: {
                                disabled: _vm.processesList.length == 0,
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#taskmanager" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openTab(4)
                                    },
                                  },
                                },
                                [
                                  _vm.processesList.length == 0
                                    ? _c("span", {
                                        staticClass:
                                          "mif-spinner2 fg-primary ani-spin",
                                      })
                                    : _vm._e(),
                                  _c("br"),
                                  _c("span", [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("Task Manager"))
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass:
                                "d-flex flex-align-center mt-0 pt-0 active pb-3",
                              attrs: { disabled: _vm.filesystem == null },
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#filemanager" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openTab(5)
                                    },
                                  },
                                },
                                [
                                  _vm.filesystem == null
                                    ? _c("span", {
                                        staticClass:
                                          "mif-spinner2 fg-primary ani-spin",
                                      })
                                    : _vm._e(),
                                  _c("br"),
                                  _c("span", [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("File Explorer"))
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass:
                                "d-flex flex-align-center mt-0 pt-0 active pb-3",
                              attrs: { disabled: _vm.eventlogs == null },
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#eventlogs" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openTab(6)
                                    },
                                  },
                                },
                                [
                                  _vm.eventlogs == null
                                    ? _c("span", {
                                        staticClass:
                                          "mif-spinner2 fg-primary ani-spin fg-primary",
                                      })
                                    : _vm._e(),
                                  _c("br"),
                                  _c("span", [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("Event Viewer"))
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass:
                                "d-flex flex-align-center mt-0 pt-0 active pb-3",
                              attrs: { disabled: _vm.patches == null },
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#patches" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openTab(7)
                                    },
                                  },
                                },
                                [
                                  _vm.patches == null
                                    ? _c("span", {
                                        staticClass:
                                          "mif-spinner2 fg-primary ani-spin fg-primary",
                                      })
                                    : _vm._e(),
                                  _c("br"),
                                  _vm.pc.system_info[92].info_value == "True"
                                    ? _c(
                                        "span",
                                        { staticClass: "fg-red ani-flash" },
                                        [_vm._v(" ● ")]
                                      )
                                    : _vm._e(),
                                  _c("span", [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("Patches & Updates"))
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "w-100 active p-3",
                  staticStyle: { height: "75vh", overflow: "auto" },
                  attrs: { id: "backups" },
                },
                [
                  _c(
                    "div",
                    [
                      _vm.socket_backups == null ||
                      Object.keys(_vm.socket_backups).length == 0
                        ? _c("NoDataAvailable", {
                            attrs: { message: _vm.$t("No backups available") },
                          })
                        : [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row pt-4 pb-4 d-flex flex-justify-start",
                              },
                              [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.filters.type,
                                        expression: "filters.type",
                                      },
                                    ],
                                    staticClass: "select text-small w-auto",
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.filters,
                                          "type",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("option", { attrs: { value: "-1" } }, [
                                      _vm._v(_vm._s(_vm.$t("All types"))),
                                    ]),
                                    _vm._l(
                                      _vm.filters.typeList,
                                      function (value, id, index) {
                                        return _c(
                                          "option",
                                          { key: id, domProps: { value: id } },
                                          [_vm._v(_vm._s(value.name) + " ")]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.filters.lastresult,
                                        expression: "filters.lastresult",
                                      },
                                    ],
                                    staticClass:
                                      "ml-4 select text-small w-auto",
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.filters,
                                          "lastresult",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("option", { attrs: { value: "-1" } }, [
                                      _vm._v(_vm._s(_vm.$t("All results"))),
                                    ]),
                                    _vm._l(
                                      _vm.filters.resultList,
                                      function (v, k) {
                                        return _c(
                                          "option",
                                          { key: v, domProps: { value: v } },
                                          [_vm._v(_vm._s(_vm.$t(k)) + " ")]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                            _c(
                              "table",
                              {
                                staticClass:
                                  "overflow bg-white border table table-sm striped compact p-2",
                                attrs: { id: "backup-list" },
                              },
                              [
                                _c("thead", { staticClass: "mb-0" }, [
                                  _c("tr", { staticClass: "mb-3" }, [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-center",
                                        staticStyle: {
                                          "vertical-align": "middle",
                                          width: "10px",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("Last Result")))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-left pl-4",
                                        staticStyle: {
                                          "vertical-align": "middle",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("Backup name")))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-left",
                                        staticStyle: {
                                          "vertical-align": "middle",
                                          width: "50px",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("Type")))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-center",
                                        staticStyle: {
                                          "vertical-align": "middle",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("Started")) +
                                            " / " +
                                            _vm._s(_vm.$t("Ended")) +
                                            " / " +
                                            _vm._s(_vm.$t("Duration"))
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-center",
                                        staticStyle: {
                                          "vertical-align": "middle",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("Data")) + " ")]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-center no-wrap",
                                        staticStyle: {
                                          "vertical-align": "middle",
                                          width: "50px",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("Schedulations")))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-center",
                                        staticStyle: {
                                          "vertical-align": "middle",
                                          width: "50px",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("Next run")))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-center",
                                        staticStyle: {
                                          "vertical-align": "middle",
                                          width: "200px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("Current operation")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-center",
                                        staticStyle: {
                                          "vertical-align": "middle",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("Saved Schedulations"))
                                        ),
                                      ]
                                    ),
                                    _c("td", {
                                      staticClass: "text-center no-wrap",
                                      staticStyle: {
                                        "vertical-align": "middle",
                                      },
                                    }),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(
                                    _vm.socket_backups,
                                    function (value, id, index) {
                                      return (value.type == _vm.filters.type ||
                                        _vm.filters.type == -1) &&
                                        (value.lastresult ==
                                          _vm.filters.lastresult ||
                                          _vm.filters.lastresult == -1)
                                        ? _c(
                                            "tr",
                                            {
                                              key: id + "-" + index,
                                              attrs: { "track-by": id },
                                            },
                                            [
                                              _c(
                                                "td",
                                                { staticClass: "text-center" },
                                                [
                                                  _c("BackupLastResult", {
                                                    attrs: {
                                                      isrunning:
                                                        value.isRunning,
                                                      lastresult:
                                                        value.lastresult,
                                                      progress: value.progress,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "overflow",
                                                  staticStyle: {
                                                    "min-width": "200px",
                                                    "max-width": "350px",
                                                  },
                                                  attrs: { title: value.name },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.jobAction(
                                                        "see",
                                                        value
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "text-bold button flat-button",
                                                    },
                                                    [_vm._v(_vm._s(value.name))]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: { width: "50" },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "badge inline bg-cobalt fg-white p-1 mr-1",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          value.type_name.toUpperCase()
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-right no-wrap",
                                                },
                                                [
                                                  value.dt_start_utc != "" &&
                                                  value.dt_start_utc != null
                                                    ? _c("label", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.formatDate(
                                                              value.dt_start_utc
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _c("br"),
                                                  !value.isRunning &&
                                                  value.dt_end_utc != "" &&
                                                  value.dt_end_utc != null
                                                    ? _c("label", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.formatDate(
                                                              value.dt_end_utc
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _c("br"),
                                                  !value.isRunning &&
                                                  value.dt_end_utc != "" &&
                                                  value.dt_end_utc != null
                                                    ? _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "text-bold",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.diffDate(
                                                                value.dt_start_utc,
                                                                value.dt_end_utc
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-center" },
                                                [
                                                  value.lastresult !=
                                                  _vm.$root.ResultValue
                                                    .NeverExecuted
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "prettyBytes"
                                                            )(
                                                              value.processed_data
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _vm.jobPoliciesList[value.id] ==
                                              null
                                                ? _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                    },
                                                    [
                                                      value.scheduling !=
                                                        null &&
                                                      value.scheduling != ""
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "mini",
                                                              attrs: {
                                                                title:
                                                                  value.scheduling !=
                                                                    null &&
                                                                  value.scheduling !=
                                                                    ""
                                                                    ? _vm.$t(
                                                                        "Scheduled"
                                                                      )
                                                                    : _vm.$t(
                                                                        "Not Scheduled"
                                                                      ),
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.cronstrue.toString(
                                                                      value.scheduling,
                                                                      {
                                                                        locale:
                                                                          _vm
                                                                            .$i18n
                                                                            .locale,
                                                                        dayOfWeekStartIndexZero: false,
                                                                        verbose: true,
                                                                      }
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.jobPoliciesList[value.id] !=
                                              null
                                                ? _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mini" },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.cronstrue.toString(
                                                                  _vm
                                                                    .jobPoliciesList[
                                                                    value.id
                                                                  ][0].policy
                                                                    .scheduling[0],
                                                                  {
                                                                    locale:
                                                                      _vm.$i18n
                                                                        .locale,
                                                                    dayOfWeekStartIndexZero: false,
                                                                    verbose: true,
                                                                  }
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.jobPoliciesList[value.id] ==
                                              null
                                                ? _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                    },
                                                    [
                                                      value.scheduling !=
                                                        null &&
                                                      value.scheduling != ""
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "mini",
                                                              attrs: {
                                                                title:
                                                                  value.scheduling !=
                                                                    null &&
                                                                  value.scheduling !=
                                                                    ""
                                                                    ? _vm.$t(
                                                                        "Scheduled"
                                                                      )
                                                                    : _vm.$t(
                                                                        "Not Scheduled"
                                                                      ),
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.nextSched(
                                                                      value.scheduling
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.jobPoliciesList[value.id] !=
                                              null
                                                ? _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mini" },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.nextSched(
                                                                  _vm
                                                                    .jobPoliciesList[
                                                                    value.id
                                                                  ][0].policy
                                                                    .scheduling[0]
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "max-width": "200px",
                                                  },
                                                },
                                                [
                                                  value.isRunning
                                                    ? _c("div", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "place-left text-truncate pl-3",
                                                            attrs: {
                                                              title:
                                                                value.current_operation,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                value.current_operation
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  value.last_error_desc &&
                                                  value.last_error_desc != ""
                                                    ? _c("div", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "place-left text-truncate pl-3",
                                                            attrs: {
                                                              title:
                                                                value.last_error_desc,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                value.last_error_desc
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _c("td", [
                                                _vm.jobPoliciesList[value.id] !=
                                                null
                                                  ? _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "d-flex flex-justify-center flex-wrap text-center button flat-button",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.openDialogPolicy(
                                                              _vm
                                                                .jobPoliciesList[
                                                                value.id
                                                              ][0].id
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("label", {}, [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm
                                                                .jobPoliciesList[
                                                                value.id
                                                              ][0].name
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "no-wrap pos-relative",
                                                },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "button dropdown-toggle",
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "mif-menu text-bold",
                                                      }),
                                                    ]
                                                  ),
                                                  _c(
                                                    "ul",
                                                    {
                                                      staticClass:
                                                        "d-menu border drop-left bg-white",
                                                      attrs: {
                                                        "data-role": "dropdown",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "li",
                                                        {
                                                          staticClass:
                                                            "bg-lightcobalt fg-white",
                                                        },
                                                        [
                                                          value.isRunning
                                                            ? _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href: "#",
                                                                    title:
                                                                      _vm.$t(
                                                                        "Stop job"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.jobAction(
                                                                          "stop",
                                                                          value
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "mif-stop icon",
                                                                  }),
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Stop"
                                                                      )
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href: "#",
                                                                    title:
                                                                      _vm.$t(
                                                                        "Run Job"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.jobAction(
                                                                          "run",
                                                                          value
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "mif-play icon",
                                                                  }),
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Run"
                                                                      )
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              ),
                                                        ]
                                                      ),
                                                      _c("li", [
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href: "#",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.jobAction(
                                                                  "restore",
                                                                  value
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("span", {
                                                              staticClass:
                                                                "mif-undo icon",
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "Restore"
                                                                  )
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _c("li", {
                                                        staticClass: "divider",
                                                      }),
                                                      _c("li", [
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href: "#",
                                                              title:
                                                                _vm.$t(
                                                                  "Job details"
                                                                ),
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.jobAction(
                                                                  "see",
                                                                  value
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("span", {
                                                              staticClass:
                                                                "mif-eye icon",
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t("View")
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _c("li", [
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href: "#",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.jobAction(
                                                                  "edit",
                                                                  value
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("span", {
                                                              staticClass:
                                                                "mif-pencil icon",
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t("Edit")
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _c("li", [
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href: "#",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.jobAction(
                                                                  "duplicate",
                                                                  value
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("span", {
                                                              staticClass:
                                                                "mif-files-empty icon",
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "Duplicate"
                                                                  )
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _c("li", {
                                                        staticClass: "divider",
                                                      }),
                                                      _c("li", [
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href: "#",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.jobAction(
                                                                  "delete",
                                                                  value
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("span", {
                                                              staticClass:
                                                                "mif-bin icon",
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "Delete"
                                                                  )
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _c("li", {
                                                        staticClass: "divider",
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e()
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ],
                    ],
                    2
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "w-100 p-3",
                  staticStyle: { "overflow-y": "auto" },
                  attrs: { id: "restores" },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { height: "75vh" } },
                    [
                      _vm.restores == null ||
                      Object.keys(_vm.restores).length == 0
                        ? _c("NoDataAvailable", {
                            attrs: { message: _vm.$t("No restore available") },
                          })
                        : _c(
                            "table",
                            {
                              staticClass:
                                "bg-white border striped compact table w-100",
                              attrs: { id: "restore-list" },
                            },
                            [
                              _c("thead", { staticClass: "mb-0" }, [
                                _c("tr", { staticClass: "mb-3" }, [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-center",
                                      staticStyle: {
                                        "vertical-align": "middle",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("State")))]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-center",
                                      staticStyle: {
                                        "vertical-align": "middle",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("Name")))]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-center",
                                      staticStyle: {
                                        "vertical-align": "middle",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("Type")))]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-center",
                                      staticStyle: {
                                        "vertical-align": "middle",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("Started at")))]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-left",
                                      staticStyle: {
                                        "vertical-align": "middle",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("Last operation")) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-center",
                                      staticStyle: {
                                        "vertical-align": "middle",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("Progress")))]
                                  ),
                                  _c("td"),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                _vm._l(_vm.restores, function (restjob) {
                                  return _c(
                                    "tr",
                                    {
                                      key: restjob.id,
                                      attrs: { "track-by": restjob.id },
                                    },
                                    [
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "fadeIn text-center w-10",
                                        },
                                        [
                                          _c("RestoreLastResult", {
                                            attrs: {
                                              lastresult: restjob.lastresult,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      restjob.type !=
                                      _vm.$root.PlatformsTypesEnum.ESXi
                                        ? _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [
                                              _c(
                                                "label",
                                                { staticClass: "text-bold" },
                                                [
                                                  _vm._v(
                                                    _vm._s(restjob.restorename)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _c("td", [
                                            _c("label", [
                                              _vm._v(_vm._s(restjob.vmname)),
                                            ]),
                                            _c("br"),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "badge inline bg-cobalt fg-white p-1 mr-1",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(restjob.type_name)
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "badge inline bg-cobalt fg-white p-1 mr-1",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(restjob.datacenter)
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "badge inline bg-cobalt fg-white p-1 mr-1",
                                              },
                                              [
                                                _vm._v(
                                                  "n. " +
                                                    _vm._s(restjob.backupnumber)
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "badge inline bg-cobalt fg-white p-1 mr-1",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    restjob.datastore +
                                                      "-" +
                                                      restjob.host
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "badge inline bg-cobalt fg-white p-1 mr-1",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    restjob.datastore +
                                                      "-" +
                                                      restjob.host
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]),
                                      _c("td", { staticClass: "text-center" }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "badge inline bg-cobalt fg-white p-1",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                restjob.type_name.toUpperCase()
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "td",
                                        { staticClass: "text-center no-wrap" },
                                        [
                                          restjob.dt_start_utc != "" &&
                                          restjob.dt_start_utc != null
                                            ? _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatDate(
                                                      restjob.dt_start_utc
                                                    )
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                          !restjob.isRunning &&
                                          restjob.dt_end_utc != "" &&
                                          restjob.dt_end_utc != null
                                            ? _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatDate(
                                                      restjob.dt_end_utc
                                                    )
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                          _c("br"),
                                          !restjob.isRunning &&
                                          restjob.dt_end_utc != "" &&
                                          restjob.dt_end_utc != null
                                            ? _c(
                                                "label",
                                                { staticClass: "text-bold" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.diffDate(
                                                        restjob.dt_start_utc,
                                                        restjob.dt_end_utc
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        { staticStyle: { width: "200px" } },
                                        [
                                          restjob.lastresult ==
                                          _vm.$root.ResultValue.Running
                                            ? _c(
                                                "div",
                                                { staticClass: "clear" },
                                                [
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "place-left",
                                                      attrs: {
                                                        "data-role": "hint",
                                                        "data-hint-position":
                                                          "top",
                                                        "data-hint-text":
                                                          restjob.current_operation,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          restjob.current_operation
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c("td", { staticClass: "text-center" }, [
                                        restjob.lastresult ==
                                        _vm.$root.ResultValue.Running
                                          ? _c("label", [
                                              _vm._v(
                                                _vm._s(restjob.progress) + " %"
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]),
                                      _c(
                                        "td",
                                        { staticClass: "no-wrap pos-relative" },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "button dropdown-toggle border-radius",
                                            },
                                            [
                                              _c("span", {
                                                staticClass:
                                                  "mif-menu text-bold",
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "ul",
                                            {
                                              staticClass: "d-menu drop-left",
                                              attrs: {
                                                "data-role": "dropdown",
                                              },
                                            },
                                            [
                                              _c(
                                                "li",
                                                {
                                                  staticClass:
                                                    "bg-lightcobalt fg-white",
                                                  attrs: {
                                                    disabled:
                                                      !restjob.isRunning,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "#",
                                                        title:
                                                          _vm.$t(
                                                            "Stop restore"
                                                          ),
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.restoreAction(
                                                            "stop",
                                                            restjob.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "mif-stop icon",
                                                      }),
                                                      _vm._v(
                                                        _vm._s(_vm.$t("Stop")) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "li",
                                                {
                                                  attrs: {
                                                    disabled: restjob.isRunning,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: { href: "#" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.restoreAction(
                                                            "delete",
                                                            restjob.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "mif-bin icon",
                                                      }),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t("Delete")
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "w-100 p-3",
                  staticStyle: { height: "75vh", "overflow-y": "auto" },
                  attrs: { id: "sysinfo" },
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "info-box-content cell-4" }, [
                      _c("div", { staticClass: "panel p-4" }, [
                        _c(
                          "div",
                          { staticClass: "row d-flex flex-justify-between" },
                          [
                            _c("h3", {}, [
                              _c("span", {
                                class: {
                                  "mif-windows": _vm.pc.device.includes("PC"),
                                  "mif-apple":
                                    _vm.pc.device.includes("MAC") ||
                                    _vm.pc.device.includes("IOS"),
                                },
                              }),
                              _vm._v(" " + _vm._s(_vm.pc.pc_name)),
                            ]),
                          ]
                        ),
                        _c("hr"),
                        _c("div", { staticClass: "row" }, [
                          _c("div", [
                            _c("span", {
                              attrs: { title: _vm.pc.os_version_full },
                            }),
                            _vm._v(" " + _vm._s(_vm.pc.os_version_full)),
                          ]),
                        ]),
                        _c("div", { staticClass: "p-2" }, [
                          _c("label", [
                            _vm._v(
                              _vm._s(_vm.$t("Agent Version")) +
                                ": " +
                                _vm._s(_vm.system.agentVersion)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _c("label", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    _vm.pc.system_info[30].info_description
                                  )
                                ) +
                                ": " +
                                _vm._s(_vm.pc.system_info[30].info_value)
                            ),
                          ]),
                          _c("br"),
                          _c("label", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    _vm.pc.system_info[30].info_description
                                  )
                                ) +
                                ": " +
                                _vm._s(_vm.pc.system_info[30].info_value)
                            ),
                          ]),
                          _c("br"),
                          _c("label", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("LAN IP")) +
                                ": " +
                                _vm._s(_vm.pc.ipaddress_lan)
                            ),
                          ]),
                          _c("br"),
                          _c("label", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("Public IP")) +
                                ": " +
                                _vm._s(_vm.pc.ipaddress_public)
                            ),
                          ]),
                          _c("br"),
                          _c("label", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("Last Connection")) +
                                ": " +
                                _vm._s(_vm.formatDate(_vm.pc.last_login_time))
                            ),
                          ]),
                          _c("br"),
                        ]),
                        _c("hr"),
                        _c("form", { staticClass: "form" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "cell-sm-12" }, [
                              _c("label", { staticClass: "mt-1 no-wrap" }, [
                                _vm._v(_vm._s(_vm.$t("Custom name")) + ":"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.pc.name,
                                    expression: "pc.name",
                                  },
                                ],
                                staticClass: "input bg-light",
                                attrs: { type: "text" },
                                domProps: { value: _vm.pc.name },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.pc,
                                      "name",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "cell-sm-12" }, [
                              _c("label", { staticClass: "mt-1" }, [
                                _vm._v(_vm._s(_vm.$t("Organization")) + ":"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.pc.organization_name,
                                    expression: "pc.organization_name",
                                  },
                                ],
                                staticClass:
                                  "input input-small input-material bg-light",
                                attrs: { type: "text" },
                                domProps: { value: _vm.pc.organization_name },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.pc,
                                      "organization_name",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "cell-sm-12" }, [
                              _c("label", { staticClass: "mt-1" }, [
                                _vm._v(_vm._s(_vm.$t("Description")) + ":"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.pc.description,
                                    expression: "pc.description",
                                  },
                                ],
                                staticClass:
                                  "input input-small input-material bg-light",
                                attrs: { type: "text" },
                                domProps: { value: _vm.pc.description },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.pc,
                                      "description",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "cell-sm-12" }, [
                              _c("label", { staticClass: "ml-3" }, [
                                _vm._v(_vm._s(_vm.$t("Groups"))),
                              ]),
                              _c(
                                "select",
                                {
                                  staticClass: "bg-light",
                                  attrs: {
                                    "data-role": "select",
                                    "data-filter": "false",
                                    multiple: "",
                                    "data-cls-selected-item":
                                      "bg-light border pr-2 rounded",
                                    id: "groupselect",
                                    name: "ids_groups",
                                  },
                                },
                                _vm._l(
                                  _vm.$session.getGroups(),
                                  function (group) {
                                    return _c(
                                      "option",
                                      {
                                        key: group.id_group,
                                        domProps: {
                                          selected: _vm.pc.groups.find(
                                            (elem) => elem.id == group.id_group
                                          ),
                                          value: group.id_group,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(group.name_group) + " "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                          ]),
                        ]),
                        _c("br"),
                        _c("div", { staticClass: "d-flex flex-justify-end" }, [
                          _c("div", { staticClass: "" }, [
                            _c(
                              "a",
                              {
                                staticClass: "button m-1 primary",
                                on: { click: _vm.editComputer },
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("Update")) + " ")]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "info-box-content cell-4" }, [
                      _c(
                        "div",
                        { staticClass: "panel p-4" },
                        [
                          _c("h3", {}, [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "24",
                                  height: "24",
                                  fill: "none",
                                  viewBox: "0 0 24 24",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M21 18.251a2.249 2.249 0 0 0-2.25-2.249H5.25a2.249 2.249 0 1 0 0 4.498h13.5A2.249 2.249 0 0 0 21 18.251Zm-4-6.5a2.249 2.249 0 0 0-2.25-2.249h-9.5a2.25 2.25 0 1 0 0 4.498h9.5A2.249 2.249 0 0 0 17 11.751Zm-4-6.5a2.25 2.25 0 0 0-2.25-2.25l-5.5.001a2.25 2.25 0 0 0 0 4.498h5.5A2.25 2.25 0 0 0 13 5.251Z",
                                    fill: "#212121",
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" " + _vm._s(_vm.$t("Performance")) + " "),
                          ]),
                          _c("hr"),
                          _c("div", { staticClass: "cell-lg-12 cell-sm-12" }, [
                            _c("div", { staticClass: "content" }, [
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-small pb-2 d-flex flex-justify-between",
                                  },
                                  [
                                    _c("span", [_vm._v(_vm._s(_vm.$t("RAM")))]),
                                    _c("span", [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("prettyBytes")(
                                              parseInt(
                                                _vm.system.usedRAM * 1024 * 1024
                                              )
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(
                                        "/" +
                                          _vm._s(
                                            _vm._f("prettyBytes")(
                                              parseInt(
                                                _vm.system.totalRAM *
                                                  1024 *
                                                  1024
                                              )
                                            )
                                          )
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "progress small bg-gray",
                                  attrs: {
                                    "data-role": "progress",
                                    "data-value":
                                      (parseInt(_vm.system.used) /
                                        parseInt(_vm.system.totalRAM)) *
                                      100,
                                    "data-small": "true",
                                    "data-cls-bar": "bg-cobalt",
                                    "data-cls-back": "bg-darkGray",
                                    "data-role-progress": "true",
                                  },
                                },
                                [
                                  _c("div", {
                                    staticClass: "bar",
                                    class: {
                                      "bg-red":
                                        parseInt(_vm.system.availableRAM) >
                                        (parseInt(_vm.system.totalRAM) / 100) *
                                          70,
                                      "ribbed-cobalt":
                                        parseInt(_vm.system.availableRAM) <=
                                        (parseInt(_vm.system.totalRAM) / 100) *
                                          70,
                                    },
                                    style: {
                                      width:
                                        (parseInt(_vm.system.usedRAM) /
                                          parseInt(_vm.system.totalRAM)) *
                                          100 +
                                        "%",
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "cell-lg-12 cell-sm-12" }, [
                            _c("div", { staticClass: "content" }, [
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-small pb-2 d-flex flex-justify-between",
                                  },
                                  [
                                    _c("span", [_vm._v(_vm._s(_vm.$t("CPU")))]),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.system.CPU) + "%"),
                                    ]),
                                  ]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "progress small bg-gray",
                                  attrs: {
                                    "data-role": "progress",
                                    "data-value": parseInt(_vm.system.CPU),
                                    "data-small": "true",
                                    "data-cls-bar": "bg-white",
                                    "data-cls-back": "bg-darkGray",
                                    "data-role-progress": "true",
                                  },
                                },
                                [
                                  _c("div", {
                                    staticClass: "bar",
                                    class: {
                                      "bg-red": parseInt(_vm.system.CPU) > 85,
                                      "ribbed-cobalt":
                                        parseInt(_vm.system.CPU) <= 85,
                                    },
                                    style: { width: _vm.system.CPU + "%" },
                                  }),
                                ]
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "cell-lg-12 cell-sm-12" }, [
                            _c("div", { staticClass: "content" }, [
                              _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "text-small pt-2 pb-2" },
                                  [_vm._v(_vm._s(_vm.$t("Disk").toUpperCase()))]
                                ),
                                _c("div", { staticClass: "text-small" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("Read")) +
                                      ": " +
                                      _vm._s(_vm.system.readDiskspeed) +
                                      " KB/s "
                                  ),
                                ]),
                                _c("div", { staticClass: "text-small" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("Write")) +
                                      ": " +
                                      _vm._s(_vm.system.writeDiskspeed) +
                                      " KB/s "
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                          _vm.pc != null && _vm.pc.security_status
                            ? _c(
                                "div",
                                { staticClass: "cell-lg-12 cell-sm-12" },
                                [
                                  _c("div", { staticClass: "content" }, [
                                    _c("div", { staticClass: "pb-2" }, [
                                      _c(
                                        "div",
                                        { staticClass: "text-small pb-2" },
                                        [
                                          _c("b", [
                                            _vm._v(_vm._s(_vm.$t("Security"))),
                                          ]),
                                        ]
                                      ),
                                      _c("div", { staticClass: "text-small" }, [
                                        _c(
                                          "span",
                                          { staticClass: "text-upper" },
                                          [_vm._v(_vm._s(_vm.$t("Antivirus")))]
                                        ),
                                        _vm._v(": "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.pc.security_status
                                                .antivirusList
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("p", { staticClass: "mt-1" }, [
                                        _c(
                                          "span",
                                          { staticClass: "text-small" },
                                          [_vm._v(_vm._s(_vm.$t("Firewall")))]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "ml-1",
                                            class: {
                                              "fg-green":
                                                _vm.pc.security_status
                                                  .windowsFirewallEnabled,
                                            },
                                          },
                                          [_vm._v("●")]
                                        ),
                                      ]),
                                      _c("p", { staticClass: "mt-1" }, [
                                        _c(
                                          "span",
                                          { staticClass: "text-small" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("Windows Defender"))
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "ml-1 fg-red",
                                            class: {
                                              "fg-green":
                                                _vm.pc.security_status
                                                  .windowsDefenderEnabled,
                                            },
                                          },
                                          [_vm._v("●")]
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _c("hr"),
                          _vm._l(_vm.disks, function (disk, i) {
                            return _c(
                              "div",
                              { key: disk.caption, staticClass: "pt-4" },
                              [
                                _c("label", [
                                  _c("span", {
                                    staticClass: "mif-drive2 mr-1",
                                  }),
                                  _c("span", [
                                    _vm._v(" " + _vm._s(disk.caption) + " "),
                                  ]),
                                ]),
                                _vm._l(
                                  disk.partitions,
                                  function (partition, j) {
                                    return _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              partition.volumeDriveLetter !=
                                              "None",
                                            expression:
                                              "partition.volumeDriveLetter != 'None'",
                                          },
                                        ],
                                        key: i + "-" + j,
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "clear p-1" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "place-left text-small",
                                              },
                                              [
                                                partition.volumeIsWinVolume
                                                  ? _c("span", {
                                                      staticClass:
                                                        "mif-windows mr-2",
                                                    })
                                                  : _vm._e(),
                                                partition.volumeLabel
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          partition.volumeLabel
                                                        )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                partition.volumeDriveLetter
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "(" +
                                                          _vm._s(
                                                            partition.volumeDriveLetter
                                                          ) +
                                                          ")"
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "place-right text-small",
                                              },
                                              [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("prettyBytes")(
                                                        partition.volumeNotfreeSpace
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(
                                                  "/" +
                                                    _vm._s(
                                                      _vm._f("prettyBytes")(
                                                        partition.volumeCapacity
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "progress small",
                                            attrs: {
                                              "data-role": "progress",
                                              "data-value":
                                                partition.volumeSpacePercent,
                                              "data-cls-bar": "bg-dark",
                                              "data-role-progress": "true",
                                            },
                                          },
                                          [
                                            _c("div", {
                                              staticClass: "bar",
                                              class: {
                                                "bg-red":
                                                  partition.volumeSpacePercent >
                                                  85,
                                                "bg-cobalt":
                                                  partition.volumeSpacePercent <=
                                                  85,
                                              },
                                              style: {
                                                width:
                                                  partition.volumeSpacePercent +
                                                  "%",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                              ],
                              2
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                    _c("div", { staticClass: "info-box-content cell-4" }, [
                      _c("div", { staticClass: "panel p-4" }, [
                        _c(
                          "h3",
                          {
                            staticClass:
                              "d-flex flex-justify-between flex-align-center",
                          },
                          [
                            _c("label", {}, [
                              _c("span", { staticClass: "mif-security" }),
                              _vm._v(" " + _vm._s(_vm.$t("Security"))),
                            ]),
                          ]
                        ),
                        _c("hr"),
                        _c("div", { staticClass: "cell-lg-12 cell-sm-12" }, [
                          _c("div", { staticClass: "content" }, [
                            _c("label", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      _vm.pc.system_info[20].info_description
                                    )
                                  ) +
                                  ":"
                              ),
                            ]),
                            _vm.pc.system_info[20].info_value.length == 0
                              ? _c("span", { staticClass: "ml-1 fg-red" }, [
                                  _vm._v("●"),
                                ])
                              : _vm._e(),
                            _c(
                              "ul",
                              { staticClass: "listview" },
                              _vm._l(
                                _vm.pc.system_info[20].info_value,
                                function (ivalue) {
                                  return _c("li", [
                                    _c("label", [
                                      _vm._v(" " + _vm._s(ivalue.Name) + ": "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "ml-1",
                                          class: {
                                            "fg-green": ivalue.Enabled,
                                            "fg-red": !ivalue.Enabled,
                                          },
                                        },
                                        [_vm._v("●")]
                                      ),
                                    ]),
                                  ])
                                }
                              ),
                              0
                            ),
                            _c("br"),
                            _c("label", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      _vm.pc.system_info[21].info_description
                                    )
                                  ) +
                                  ": "
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "ml-1",
                                  class: {
                                    "fg-green":
                                      _vm.pc.system_info[21].info_value ==
                                      "True",
                                    "fg-red":
                                      _vm.pc.system_info[21].info_value !=
                                      "True",
                                  },
                                },
                                [_vm._v("●")]
                              ),
                            ]),
                            _c("br"),
                            _c("label", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      _vm.pc.system_info[22].info_description
                                    )
                                  ) +
                                  ": "
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "ml-1",
                                  class: {
                                    "fg-green":
                                      _vm.pc.system_info[22].info_value ==
                                      "True",
                                    "fg-red":
                                      _vm.pc.system_info[22].info_value !=
                                      "True",
                                  },
                                },
                                [_vm._v("●")]
                              ),
                            ]),
                            _c("br"),
                            _c("label", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      _vm.pc.system_info[23].info_description
                                    )
                                  ) +
                                  ": "
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "ml-1",
                                  class: {
                                    "fg-green":
                                      _vm.pc.system_info[23].info_value ==
                                      "True",
                                    "fg-red":
                                      _vm.pc.system_info[23].info_value !=
                                      "True",
                                  },
                                },
                                [_vm._v("●")]
                              ),
                            ]),
                            _c("br"),
                            _c("label", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      _vm.pc.system_info[24].info_description
                                    )
                                  ) +
                                  ":"
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "ml-1",
                                  class: {
                                    "fg-green":
                                      _vm.pc.system_info[24].info_value ==
                                      "True",
                                    "fg-red":
                                      _vm.pc.system_info[24].info_value !=
                                      "True",
                                  },
                                },
                                [_vm._v("●")]
                              ),
                            ]),
                            _c("br"),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "panel p-4 mt-3" }, [
                        _c(
                          "h3",
                          {
                            staticClass:
                              "d-flex flex-justify-between flex-align-center",
                          },
                          [
                            _c("label", [
                              _c("span", { staticClass: "mif-post_add" }),
                              _vm._v(" " + _vm._s(_vm.$t("Policies"))),
                            ]),
                            _c(
                              "router-link",
                              {
                                staticClass: "button flat-button large",
                                attrs: {
                                  title: _vm.$t("Add"),
                                  to: "/policies",
                                },
                              },
                              [_c("span", { staticClass: "mif-add" })]
                            ),
                          ],
                          1
                        ),
                        _c("hr"),
                        _c("div", { staticClass: "cell-lg-12 cell-sm-12" }, [
                          _vm.policiesList.length == 0
                            ? _c(
                                "div",
                                { staticClass: "content" },
                                [
                                  _c("NoDataAvailable", {
                                    attrs: {
                                      message: _vm.$t("No policies available"),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.policiesList.length != 0
                            ? _c("div", { staticClass: "content" }, [
                                _c(
                                  "div",
                                  {
                                    attrs: {
                                      "data-role": "accordion",
                                      "data-one-frame": "true",
                                      "data-show-active": "false",
                                    },
                                  },
                                  _vm._l(
                                    _vm.policiesList,
                                    function (policy, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: policy.id,
                                          staticClass: "frame",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "heading" },
                                            [
                                              _vm._v(" " + _vm._s(policy.name)),
                                              _c("br"),
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "text-mute text-small",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$session.getPolicyTypes(
                                                        "POLICY_TYPES"
                                                      )[policy.id_type - 1].name
                                                    ) + " "
                                                  ),
                                                  _c("span", {
                                                    staticClass:
                                                      "mif-email fg-gray",
                                                    class: {
                                                      "fg-dark":
                                                        policy.enabled_email ==
                                                        true,
                                                    },
                                                  }),
                                                  _c("span", {
                                                    staticClass:
                                                      "mif-mobile fg-gray",
                                                    class: {
                                                      "fg-dark":
                                                        policy.enabled_push ==
                                                        true,
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "content p-2" },
                                            [
                                              policy.id_type == 3
                                                ? _c(
                                                    "div",
                                                    { staticClass: "p-2 row" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "cell-12",
                                                        },
                                                        [
                                                          _c(
                                                            "h6",
                                                            {
                                                              staticClass:
                                                                "pt-1 text-small",
                                                            },
                                                            [
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "Thresholds"
                                                                    )
                                                                  ) + ":"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c("p", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm
                                                                  .$t(
                                                                    "duration"
                                                                  )
                                                                  .toUpperCase()
                                                              ) +
                                                                ": " +
                                                                _vm._s(
                                                                  _vm.secondsToFormattedString(
                                                                    policy
                                                                      .policy
                                                                      .threshold_duration
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                          _c("hr"),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "cell-4 text-center text-bold",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t("CPU")
                                                              ) + ": "
                                                            ),
                                                          ]),
                                                          _c("div", {
                                                            staticClass:
                                                              "text-center",
                                                            attrs: {
                                                              id: "donut3",
                                                              "data-role":
                                                                "donut",
                                                              "data-size": "80",
                                                              "data-stroke-width":
                                                                "2",
                                                              "data-value":
                                                                policy.policy
                                                                  .threshold_cpu,
                                                              "data-stroke":
                                                                "#f5f5f5",
                                                              "data-fill":
                                                                "#0e72cf",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "cell-4 text-center text-bold",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t("DISK")
                                                              ) + ":"
                                                            ),
                                                          ]),
                                                          _c("div", {
                                                            attrs: {
                                                              id: "donut4",
                                                              "data-role":
                                                                "donut",
                                                              "data-size": "80",
                                                              "data-stroke-width":
                                                                "2",
                                                              "data-value":
                                                                policy.policy
                                                                  .threshold_disk,
                                                              "data-stroke":
                                                                "#f5f5f5",
                                                              "data-fill":
                                                                "#0e72cf",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "cell-4 text-center text-bold",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t("RAM")
                                                              ) + ":"
                                                            ),
                                                          ]),
                                                          _c("div", {
                                                            attrs: {
                                                              id: "donut5",
                                                              "data-role":
                                                                "donut",
                                                              "data-size": "80",
                                                              "data-stroke-width":
                                                                "2",
                                                              "data-value":
                                                                policy.policy
                                                                  .threshold_ram,
                                                              "data-stroke":
                                                                "#f5f5f5",
                                                              "data-fill":
                                                                "#0e72cf",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              policy.id_type == 2
                                                ? _c(
                                                    "div",
                                                    { staticClass: "p-2" },
                                                    [
                                                      _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "Reboot Auto"
                                                            )
                                                          ) + ": "
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "fg-red",
                                                            class: {
                                                              "fg-green":
                                                                policy.policy
                                                                  .reboot_auto ==
                                                                true,
                                                            },
                                                          },
                                                          [_vm._v(" ● ")]
                                                        ),
                                                      ]),
                                                      _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "Reboot Time"
                                                            )
                                                          ) +
                                                            ": " +
                                                            _vm._s(
                                                              _vm.nextSched(
                                                                policy.policy
                                                                  .reboot_time
                                                              )
                                                            )
                                                        ),
                                                      ]),
                                                      _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "Update Time"
                                                            )
                                                          ) +
                                                            ": " +
                                                            _vm._s(
                                                              _vm.nextSched(
                                                                policy.policy
                                                                  .update_time
                                                              )
                                                            )
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _c("br"),
                              ])
                            : _vm._e(),
                          _c("br"),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "w-100 p-3",
                  staticStyle: { height: "75vh", "overflow-y": "auto" },
                  attrs: { id: "eventlogs" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "pt-4 pb-4 d-flex flex-justify-start" },
                    [
                      _c("div", { staticClass: "col-auto m-0" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.eventlogs.type,
                                expression: "eventlogs.type",
                              },
                            ],
                            staticClass: "select text-small w-auto",
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.eventlogs,
                                    "type",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function ($event) {
                                  return _vm.refreshEventLogs()
                                },
                              ],
                            },
                          },
                          [
                            _c("option", { attrs: { value: "1" } }, [
                              _vm._v(_vm._s(_vm.$t("Errors"))),
                            ]),
                            _c("option", { attrs: { value: "2" } }, [
                              _vm._v(_vm._s(_vm.$t("Warnings"))),
                            ]),
                            _c("option", { attrs: { value: "4" } }, [
                              _vm._v(_vm._s(_vm.$t("Info"))),
                            ]),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "col-auto m-0" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.eventlogs.level,
                                expression: "eventlogs.level",
                              },
                            ],
                            staticClass: "pl-4 select text-small w-auto",
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.eventlogs,
                                    "level",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function ($event) {
                                  return _vm.refreshEventLogs()
                                },
                              ],
                            },
                          },
                          [
                            _c("option", { attrs: { value: "System" } }, [
                              _vm._v(_vm._s(_vm.$t("System"))),
                            ]),
                            _c("option", { attrs: { value: "Application" } }, [
                              _vm._v(_vm._s(_vm.$t("Application"))),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _vm.eventlogs.list
                    ? _c(
                        "table",
                        {
                          staticClass:
                            "bg-white border table compact striped pt-4",
                        },
                        [
                          _c("thead", [
                            _c("tr", [
                              _c(
                                "th",
                                {
                                  staticClass: "text-center",
                                  staticStyle: { width: "100px" },
                                },
                                [_vm._v(_vm._s(_vm.$t("Source")))]
                              ),
                              _c("th", [_vm._v(_vm._s(_vm.$t("Message")))]),
                              _c("th", { staticStyle: { width: "150px" } }, [
                                _vm._v(_vm._s(_vm.$t("Created at"))),
                              ]),
                            ]),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.eventlogs.list, function (event, index) {
                              return _c(
                                "tr",
                                { key: index, attrs: { "track-by": index } },
                                [
                                  _c("td", { staticClass: "text-center" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "badge inline bg-cobalt fg-white p-1 mr-1",
                                      },
                                      [_vm._v(_vm._s(event.source))]
                                    ),
                                  ]),
                                  _c("td", [_vm._v(_vm._s(event.message))]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatDate(event.timeGenerated)
                                      )
                                    ),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "w-100 p-3",
                  staticStyle: { height: "75vh", "overflow-y": "auto" },
                  attrs: { id: "taskmanager" },
                },
                [
                  _c("div", { staticClass: "cell-12" }, [
                    _c("div", { staticClass: "pb-4 d-flex flex-justify-end" }, [
                      _c(
                        "a",
                        {
                          staticClass: "ml-2 button secondary",
                          attrs: { href: "#" },
                          on: { click: _vm.openTerminal },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Open Terminal")))]
                      ),
                    ]),
                    _vm.processesList.length != 0
                      ? _c(
                          "table",
                          {
                            staticClass:
                              "bg-white border table striped compact overflow",
                            attrs: {
                              "data-show-activity": "false",
                              "data-table-search-title": _vm.$t("Search Tasks"),
                              "data-cls-search":
                                "w-100-sm w-25-xl w-25-lg w-50-md",
                              "data-show-rows-steps": "false",
                              "data-show-pagination":
                                _vm.processesList.length > 20
                                  ? "true"
                                  : "false",
                              "data-cell-wrapper": "true",
                              "data-role": "table",
                              "data-rows": "20",
                            },
                          },
                          [
                            _c("thead", [
                              _c("tr", [
                                _c(
                                  "th",
                                  {
                                    attrs: {
                                      "data-cls-column": "no-wrap p-1",
                                      "data-name": "pid",
                                      "data-sortable": "true",
                                      "data-format": "int",
                                    },
                                  },
                                  [_vm._v("PID")]
                                ),
                                _c(
                                  "th",
                                  {
                                    attrs: {
                                      "data-cls-column": "no-wrap p-1",
                                      "data-name": "name",
                                      "data-sortable": "true",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Name")))]
                                ),
                                _c(
                                  "th",
                                  {
                                    attrs: {
                                      "data-cls-column": "no-wrap p-1",
                                      "data-name": "username",
                                      "data-sortable": "true",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Username")) + " ")]
                                ),
                                _c(
                                  "th",
                                  {
                                    attrs: {
                                      "data-cls-column": "no-wrap p-1",
                                      "data-name": "ram",
                                      "data-sortable": "true",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Memory")) + " ")]
                                ),
                                _c("th", { staticStyle: { width: "10px" } }),
                              ]),
                            ]),
                            _c(
                              "tbody",
                              _vm._l(
                                _vm.processesList,
                                function (process, index) {
                                  return _c(
                                    "tr",
                                    {
                                      key: index,
                                      attrs: { "track-by": index },
                                    },
                                    [
                                      _c("td", {}, [
                                        _vm._v(_vm._s(process.pid)),
                                      ]),
                                      _c("td", {}, [
                                        _vm._v(_vm._s(process.name)),
                                      ]),
                                      _c("td", {}, [
                                        _vm._v(_vm._s(process.username)),
                                      ]),
                                      _c("td", {}, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("prettyBytes")(process.ram)
                                          )
                                        ),
                                      ]),
                                      _c("td", {}, [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                "#killprocess_" + process.pid,
                                            },
                                          },
                                          [
                                            _c("span", {
                                              staticClass:
                                                "mif mif-cross-light fg-red",
                                            }),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "p-3 w-100 pt-4",
                  staticStyle: { height: "75vh", "overflow-y": "auto" },
                  attrs: { id: "patches" },
                },
                [
                  _c("div", { staticClass: "overflow" }, [
                    _vm.patches != null
                      ? _c(
                          "table",
                          {
                            staticClass:
                              "bg-white border table striped compact overflow",
                            attrs: {
                              "data-check": false,
                              "data-check-name": "chkBox",
                              "data-locale": "it-IT",
                              "data-table-search-title": _vm.$t("Search Patch"),
                              "data-cls-search":
                                "w-100-sm w-25-xl w-25-lg w-50-md",
                              "data-show-activity": "true",
                              "data-cell-wrapper": "false",
                              "data-role": "table",
                              "data-rows": "20",
                              "data-show-rows-steps": "false",
                              "data-show-pagination":
                                _vm.patches.length > 20 ? "true" : "false",
                              "data-horizontal-scroll": "true",
                            },
                          },
                          [
                            _c("thead", [
                              _c("tr", [
                                _c(
                                  "th",
                                  {
                                    staticClass: "text-center",
                                    attrs: {
                                      "data-name": "status",
                                      "data-sortable": "true",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Status")))]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticClass: "text-center",
                                    attrs: {
                                      "data-name": "severity",
                                      "data-sortable": "true",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Severity")))]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticClass: "w-25",
                                    attrs: {
                                      "data-name": "title",
                                      "data-sortable": "true",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Title")))]
                                ),
                                _c(
                                  "th",
                                  {
                                    attrs: {
                                      "data-name": "description",
                                      "data-sortable": "true",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Description")))]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticClass: "text-center w-10",
                                    attrs: {
                                      "data-name": "lastupdate",
                                      "data-sortable": "true",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Last Update")) + " ")]
                                ),
                                _c("th", { staticClass: "w-10 text-center" }, [
                                  _vm._v(_vm._s(_vm.$t("Download Status"))),
                                ]),
                                _c("th", { staticClass: "w-10 text-center" }, [
                                  _vm._v(_vm._s(_vm.$t("Installation Status"))),
                                ]),
                                _c("th"),
                              ]),
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_vm.patches, function (patch) {
                                return _c(
                                  "tr",
                                  {
                                    key: patch.id,
                                    attrs: { "track-by": patch.id },
                                  },
                                  [
                                    _c("td", { staticClass: "text-right" }, [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "badge inline bg-cobalt fg-white p-1 mr-1",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              patch.isInstalled
                                                ? _vm
                                                    .$t("Installed")
                                                    .toUpperCase()
                                                : _vm
                                                    .$t("Not Installed")
                                                    .toUpperCase()
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("td", { staticClass: "text-right" }, [
                                      patch.severity != null
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "badge inline bg-cobalt fg-white p-1 mr-1",
                                              class: {
                                                "bg-red":
                                                  patch.severity.toUpperCase() ==
                                                  "CRITICAL",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm
                                                    .$t(patch.severity)
                                                    .toUpperCase()
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _c("td", [_vm._v(_vm._s(patch.title))]),
                                    _c("td", [
                                      _vm._v(_vm._s(patch.description)),
                                    ]),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatDate(
                                            patch.lastDeploymentChangeTime
                                          )
                                        )
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "(" +
                                          _vm._s(
                                            _vm.fromNow(
                                              patch.lastDeploymentChangeTime
                                            )
                                          ) +
                                          ")"
                                      ),
                                    ]),
                                    _c(
                                      "td",
                                      { staticClass: "text-center m-5" },
                                      [
                                        patch.downloadProgress != 0 &&
                                        !patch.isInstalled
                                          ? _c("div", {
                                              attrs: {
                                                id: patch.id + "d",
                                                "data-role": "donut",
                                                "data-value":
                                                  patch.downloadProgress,
                                                "data-size": "20",
                                                "data-hole": "0",
                                                "data-stroke": "transparent",
                                                "data-background":
                                                  "transparent",
                                                "data-fill": "#0052ff",
                                                "data-animate": "10",
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "text-center m-5" },
                                      [
                                        patch.installProgress != 0 &&
                                        !patch.isInstalled
                                          ? _c("div", {
                                              attrs: {
                                                id: patch.id + "d",
                                                "data-role": "donut",
                                                "data-value":
                                                  patch.installProgress,
                                                "data-size": "20",
                                                "data-hole": "0",
                                                "data-stroke": "transparent",
                                                "data-background":
                                                  "transparent",
                                                "data-fill": "#0052ff",
                                                "data-animate": "10",
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    ),
                                    _c("td", { staticClass: "text-center" }, [
                                      !patch.isInstalled && !patch.isInstalling
                                        ? _c(
                                            "a",
                                            {
                                              staticClass:
                                                "button button-sm mini fg-white bg-dark",
                                              attrs: {
                                                title: _vm.$t("Install"),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.sendInstallMessage(
                                                    patch.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "mif mif-play",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "m-3 w-100 pr-4",
                  staticStyle: { height: "75vh", "overflow-y": "auto" },
                  attrs: { id: "filemanager" },
                },
                [
                  _c("div", { staticClass: "w-100" }, [
                    _vm.filesystem
                      ? _c("div", { staticClass: "pos-relative grid" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-justify-between flex-align-center pt-4",
                            },
                            [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.filesystem.driveSelected,
                                      expression: "filesystem.driveSelected",
                                    },
                                  ],
                                  staticClass: "select w-10 m-1",
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.filesystem,
                                          "driveSelected",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      function ($event) {
                                        return _vm.changeDrive(
                                          _vm.filesystem.driveSelected
                                        )
                                      },
                                    ],
                                  },
                                },
                                _vm._l(_vm.filesystem.drives, function (drive) {
                                  return _c(
                                    "option",
                                    { key: drive, domProps: { value: drive } },
                                    [_vm._v(_vm._s(drive))]
                                  )
                                }),
                                0
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "button p-2 secondary",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$root.$emit(
                                        "OPENCREATEFOLDERDIALOG",
                                        _vm.filesystem.directorySelected
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Create Folder")) + " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "table",
                            {
                              staticClass:
                                "bg-white border table striped compact overflow",
                            },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.$t("File Name"))),
                                  ]),
                                  _c("td", { staticClass: "text-center" }, [
                                    _vm._v(_vm._s(_vm.$t("File Size"))),
                                  ]),
                                  _c("td", { staticClass: "text-right" }, [
                                    _vm._v(_vm._s(_vm.$t("Date Modified"))),
                                  ]),
                                  _c("td", { staticClass: "text-right" }, [
                                    _vm._v(_vm._s(_vm.$t("Date Created"))),
                                  ]),
                                  _c("td", { staticStyle: { width: "80px" } }),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                [
                                  _c(
                                    "tr",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.filesystem.directorySelected.split(
                                              "\\"
                                            )[1] != "",
                                          expression:
                                            "filesystem.directorySelected.split('\\\\')[1] != ''",
                                        },
                                      ],
                                    },
                                    [
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "bg-lightgray d-flex flex-align-center p-2 c-pointer",
                                          attrs: {
                                            title:
                                              _vm.filesystem.directorySelected,
                                          },
                                          on: {
                                            dblclick: function ($event) {
                                              return _vm.navigate("up")
                                            },
                                          },
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "mif-backspace mif-2x",
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-bold no-user-select ml-3",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.filesystem.directorySelected
                                                      .split("\\")
                                                      .at(-1)
                                                  ) +
                                                  "/"
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("td", { staticClass: "bg-lightgray" }),
                                      _c("td", { staticClass: "bg-lightgray" }),
                                      _c("td", { staticClass: "bg-lightgray" }),
                                      _c("td", { staticClass: "bg-lightgray" }),
                                    ]
                                  ),
                                  _vm.filesystem.items == null
                                    ? _c("tr", [_vm._m(2)])
                                    : _vm._e(),
                                  _vm._l(_vm.filesystem.items, function (item) {
                                    return _c("tr", { key: item.path }, [
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "c-pointer no-user-select",
                                          on: {
                                            dblclick: function ($event) {
                                              item.type ==
                                              _vm.$root.FileFolderEntityType
                                                .FN_Folder
                                                ? _vm.navigate(item.path)
                                                : null
                                            },
                                          },
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "m-1",
                                            class: {
                                              "mif-folder fg-dark":
                                                item.type ==
                                                _vm.$root.FileFolderEntityType
                                                  .FN_Folder,
                                              "mif-file-empty":
                                                item.type ==
                                                _vm.$root.FileFolderEntityType
                                                  .FN_File,
                                            },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "c-pointer no-user-select",
                                              attrs: { title: item.path },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.path.split("\\").at(-1)
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("td", { staticClass: "text-center" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("prettyBytes")(
                                                item.type ==
                                                  _vm.$root.FileFolderEntityType
                                                    .FN_Folder
                                                  ? ""
                                                  : item.size
                                              )
                                            )
                                        ),
                                      ]),
                                      _c("td", { staticClass: "text-right" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatDate(
                                              item.modificationDate
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("td", { staticClass: "text-right" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatDate(item.creationDate)
                                          )
                                        ),
                                      ]),
                                      _c("td", { staticClass: "no-wrap" }, [
                                        _c(
                                          "div",
                                          { staticClass: "pos-relative" },
                                          [
                                            _vm._m(3, true),
                                            _c(
                                              "ul",
                                              {
                                                staticClass:
                                                  "d-menu border drop-left bg-white",
                                                attrs: {
                                                  "data-role": "dropdown",
                                                },
                                              },
                                              [
                                                _c(
                                                  "li",
                                                  {
                                                    staticClass:
                                                      "bg-lightcobalt fg-white",
                                                  },
                                                  [
                                                    item.type ==
                                                    _vm.$root
                                                      .FileFolderEntityType
                                                      .FN_Folder
                                                      ? _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href: "#",
                                                              title:
                                                                _vm.$t("Open"),
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.navigate(
                                                                  item.path
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("span", {
                                                              staticClass:
                                                                "mif-folder-open icon mr-2",
                                                            }),
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t("Open")
                                                              ) + " "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c("li", [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "#",
                                                        title: _vm.$t("Rename"),
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.openRenameItemDialog(
                                                            item.path,
                                                            item.type
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "mif-sign-pen icon icon mr-2",
                                                      }),
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("Rename")
                                                        ) + " "
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _c("li", [
                                                  item.type ==
                                                  _vm.$root.FileFolderEntityType
                                                    .FN_File
                                                    ? _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            href: "#",
                                                            title:
                                                              _vm.$t(
                                                                "Download"
                                                              ),
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.alert(
                                                                "Item download not implemented"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("span", {
                                                            staticClass:
                                                              "mif-file-download icon icon mr-2",
                                                          }),
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("Download")
                                                            ) + " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ])
                                  }),
                                ],
                                2
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row text-center" }, [
      _c("div", { staticClass: "cell-12" }, [
        _c("img", {
          attrs: { src: "/assets/img/icons/prompt.svg", width: "50" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "cell d-flex flex-justify-end" }, [
      _c(
        "button",
        {
          staticClass: "button flat-button",
          attrs: {
            onclick: "window.Metro.charms.toggle('#notifications-pc');",
          },
        },
        [_c("span", { staticClass: "icon mif mif-cross-light" })]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", { staticClass: "text-center", attrs: { colspan: "4" } }, [
      _c("span", { staticClass: "mif-spinner2 mif-2x ani-spin" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("button", { staticClass: "button dropdown-toggle" }, [
      _c("span", { staticClass: "mif-menu text-bold" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }