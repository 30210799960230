<template>
    <div v-on:keyup.esc="$root.$emit('CLOSESOURCEDIALOG')"
        class="dialog light w-100-sm w-100-md w-75-lg w-50-xl h-75" v-cloak>

        <DialogTitle :title="$t('Configure Source')" :defaultTitle="$t('Configure Source')" :subTitle="source.type_name"
            @closeDialog="$root.$emit('CLOSESOURCEDIALOG')" />
            <div v-if="step == 0" class="dialog-content p-4">
                <!------------------------------SCELTA DEL SERVICE ACCOUNT--------------------------------------------------------------->
                
                <!--Questa pagina deve implementare sendSATestResult-->
                <SARepoSelect :type=source.type :idSA=source.id_service_account :idRepo=null @sendSATestResult="sendSATestResult"/>
            </div>
            <div v-if="step == 1" class="dialog-content p-4 overflow">

                <!--Se il recupero delle macchine virtuali fallisce-->
                <NoDataAvailable v-if="source.id_service_account != -2 && source.dbSource == null"
                    :message="$t('No Database available')" />
                <!--Se il recupero delle macchine virtuali va a buon fine-->
                <div v-else-if="source.dbSource != null" class="form-group mt-5">
                    <h6>{{ $t("Backup the following databases") }}:</h6>
                    <div data-role="treeview" v-if="source.dbSource.databases.length != 0">
                        <ul class="mt-4">
                            <li>
                                <span class="mif-server fs-5" style="margin-right: 8px;font-size: 16px;"></span>
                                <span>
                                    <input :value="true" type="checkbox" data-role="checkbox" v-model="instanceSelected"
                                    :data-caption="source.dbSource.serverName + ' (SQL Server ' + source.dbSource.serverVersion + ' - ' + $session.getServiceAccountWithID(source.id_service_account).username +  ')'"></span>
                                <ul>
                                    <li data-collapsed="true" :data-caption="$t('System databases')">                                                                   
                                    <ul v-show="showSystemDatabases">
                                        <li v-for="db in sortedSystemDatabases" :key="db.name" data-collapsed="true" style="display: flex; align-items: center;">
                                            <span class="mif-database" style="margin-right: 8px;"></span>
                                            <input type="checkbox" data-role="checkbox" v-model="db.selected" :data-caption="db.name" @change="updateSystemDatabasesSelection" />                                    
                                        </li>
                                    </ul>
                                    </li>                                        
                                    <li v-for="db in sortedUserDatabases" :key="db.name" data-collapsed="true" style="display: flex; align-items: center;">
                                        <span class="mif-database" style="margin-right: 8px;"></span>
                                        <input type="checkbox" data-role="checkbox" v-model="db.selected" :data-caption="db.name" />
                                    </li>
                                </ul>
                            </li>
                        </ul>                     
                    </div>
                </div>
            </div>
            <div v-if="step == 2" class="dialog-content p-4">
                <div class="form-group">
                    <h6>{{ $t("Options") }}:</h6>
                </div>

                <div class="form-group">
                    <input v-model="source.options.verifyBackup" type="checkbox" data-role="checkbox"
                        :data-caption="$t('Verify Backup')">
                </div>
                <div class="form-group">
                    <input v-model="source.options.useNativeComp" type="checkbox" data-role="checkbox"
                        :data-caption="$t('Enable Native Compression if supported')">
                </div>
                <div class="form-group">
                    <input v-model="source.options.useCopyOnly" type="checkbox" data-role="checkbox"
                        :data-caption="$t('Use copy-only mode')">
                </div>
                <div class="form-group">
                    <input v-model="source.options.enableAppend" type="checkbox" data-role="checkbox"
                        :data-caption="$t('Append text to the backup file name')">
                </div>
                <div class="form-group">
                    <input :disabled="!source.options.enableAppend" v-model.lazy="source.options.appendTextBkp" type="text"
                        data-role="input">
                    <label class="text-small text-muted">{{ $t("If blank, default name will be used") }}</label>
                </div>
                <hr>
                <div class="form-group">
                    <h6>{{ $t("Additional Options") }}:</h6>
                </div>

                <div class="form-group">
                    <input v-model="source.options.backupLog" type="checkbox" data-role="checkbox"
                        :data-caption="$t('Backup the transaction log')">
                </div>
                <div class="form-group">
                    <input :disabled="!source.options.backupLog" v-model="source.options.shrinkLog" type="checkbox"
                        data-role="checkbox" :data-caption="$t('Shrink the transaction log after the backup')">
                </div>
                <div class="form-group">
                    <input :disabled="!source.options.backupLog" v-model="source.options.alterDbRecModel" type="checkbox"
                        data-role="checkbox" :data-caption="$t('Alter the database recovery mode to SIMPLE if necessary')">
                </div>
                <!--div class="form-group">
                    <h6>{{ $t("Compression") }}:</h6>
                </div>
                <div class="form-group">
                    <input v-model="source.options.singleComp" type="checkbox" data-role="checkbox"
                        :data-caption="$t('Create .zip file for each single database')">
                </div>
                <div class="form-group">
                    <input v-model="source.enableCompression" type="checkbox" data-role="checkbox"
                        :data-caption="$t('Enable Compression')">
                </div>
                <div class="form-group ">
                    <p>{{ $t("Archive File Custom Name") }} </p>
                    <input :disabled="!source.enableCompression" v-model.lazy="source.options.archiveFileCustomName" type="text"
                        data-role="input">
                    <label class="text-small text-muted">{{ $t("If blank, default name will be used") }}</label>
                </div>
                <div class="form-group">
                    <input :disabled="!source.enableCompression" v-model="source.options.useArchivePassword" type="checkbox"
                        data-role="checkbox" :data-caption="$t('Protect zip with Password')">
                </div-->
                <div v-show="source.enableCompression && source.options.useArchivePassword">
                    <div class="form-group ">
                        <p>{{ $t("Archive Password") }}</p>
                        <input data-role="input" class="metro-input" type="password" v-model="source.options.archivePassword"
                            :placeholder="$t('Enter Password')" data-prepend="<span class='mif-lock'></span>" />
                        <small class="text-muted">{{ $t("Required") }}</small>

                    </div>
                </div>
        </div>
        <div class="dialog-actions">
            <div class="ml-auto">
                <button class="button alert" @click="$root.$emit('CLOSESOURCEDIALOG')">{{ $t("Cancel") }}</button>
                <button class="button" :class="{ 'disabled': step == 0 }" @click="back"><span class="mif-arrow-left"></span> {{ $t("Prev") }}</button>
                <button class="button" v-if="step < 2" :class="{ 'disabled': step == 2 || source.dbSource == null || !isAnySelected }" @click="next">{{$t("Next") }}<span class="mif-arrow-right" /></button> 
                <button class="button primary" v-if="step == 2" @click="saveSource">{{ $t("Save") }}</button>
            </div>
        </div>
    </div>
</template>
<script>
import DialogTitle from "../../utils/DialogTitle";
import SARepoSelect from "../../utils/SARepoSelect";
import NoDataAvailable from "../../utils/NoDataAvailable.vue";
import { DATABASE_MESSAGES } from "../../../../public/assets/js/messages";
import { isValid, syncronizeArray, waitSeconds } from "../../../../public/assets/js/utilitiesmodule";

export default {
    name: "DatabaseSource",
    props: {
        source: {
            type: Object,
            required: true,
        }
    },
    components: {
        "DialogTitle": DialogTitle,
        NoDataAvailable,
        SARepoSelect
    },
    data() {
        return {
            step: 0,
            serverSelected: false,
            systemDatabasesSelected: false,
            showSystemDatabases: false,
            instanceSelected:false,
        }
    },
    computed: {
        sortedSystemDatabases() {
            return this.source.dbSource.databases.filter(db => ["master", "model", "msdb", "tempdb"].includes(db.name)).sort((a, b) => a.name.localeCompare(b.name));
        },
        sortedUserDatabases() {
            return this.source.dbSource.databases.filter(db => !["master", "model", "msdb", "tempdb"].includes(db.name)).sort((a, b) => a.name.localeCompare(b.name));
        },
        isAnySelected() {
            return this.serverSelected || this.systemDatabasesSelected || this.source.dbSource.databases.some(db => db.selected);
        }
    },
    created: async function () {
        let self = this;
        
            if (self.source.type == 60 && self.getServiceAccountIdLength() > 2){
                
                self.step = 1;

                await this.$root.socket("GetDatabaseList", self.source.id_service_account)
                 .then(response => {
                    var dbSource_new = response;
                    var dbSource_old = self.source.dbSource;

                    var syncronizationResult = syncronizeArray(this.source.type, dbSource_old.databases, dbSource_new.databases);

                    self.source.dbSource.databases = syncronizationResult.finalArray;

                    syncronizationResult.messages.forEach(async messageObj => {
                        self.$root.toast(this.$t(messageObj.message, messageObj.args), 3000, "warning");
                        await waitSeconds(3);
                });           
                })
                .catch(error => {
                    // Gestisci l'errore qui
                    console.error(error);
                    self.$root.toast(this.$t(messageObj.message), 3000, "warning");
                });

                if (dbSource_new == null) {
                    self.step = 0;
                    return;
                }

            }
           
    },
    methods: {

        getServiceAccountIdLength() {
            let self = this;
            return self.source.id_service_account !== undefined ? String(self.source.id_service_account).length : 0;
        },      

        toggleSystemDatabases() {
            this.showSystemDatabases = !this.showSystemDatabases;
        },
        selectAllSystemDatabases() {
            this.systemDatabasesSelected = !this.systemDatabasesSelected;
            this.instanceSelected =  this.systemDatabasesSelected;
            this.sortedSystemDatabases.forEach(db => {
                db.selected = this.systemDatabasesSelected;
            });
        },
        updateSystemDatabasesSelection() {
            this.systemDatabasesSelected = this.sortedSystemDatabases.every(db => db.selected);
        },

        async sendSATestResult(testResult) {
            let self = this;
            self.source.id_service_account = testResult.serviceAccount.id;

            var dbSource_new = testResult.list;
            if (dbSource_new == null) return;

            //Se sto creando una sorgente, assegno la sottosorgente
            if (!isValid(this.source.id)) {
                self.source.dbSource = dbSource_new;
                self.step = 1;
                return;
            }

            //Se sto editando source, rifaccio il list dei database per confrontare 
            //il list che avevo fatto al momento della creazione di tale sorgente, con l'attuale list
            var dbSource_old = self.source.dbSource;


            //test
            //oldDatabases.pop(); // l'ultimo db dei nuovi risulterà creato
            //newDatabases.splice(0, 1); // il primo db dei vecchi risulterà cancellato

            var syncronizationResult = syncronizeArray(this.source.type, dbSource_old.databases, dbSource_new.databases);

            self.source.dbSource.databases = syncronizationResult.finalArray;

            syncronizationResult.messages.forEach(async messageObj => {
                self.$root.toast(this.$t(messageObj.message, messageObj.args), 3000, "warning");
                await waitSeconds(3);
            });
        },

        next() {
            this.step += 1;
        },
        back() {
            this.step -= 1;
        },
        saveSource() {
            this.$root.$emit("saveBackupSource", this.source);
        },

        syncronizeArray(oldArray, newArray) {
            var array = newArray.map(d => { d.old = false; return d }).concat(oldArray.map(d => { d.old = true; return d }));
            var comparator = "name";
            array.sort((a, b) => {
                if (a[comparator] < b[comparator])
                    return -1;
                if (a[comparator] > b[comparator])
                    return 1;
                if (a.old)
                    return 1;
                return -1;
            });



            var a, b;
            for (var i = 0; i < array.length - 1; i++) {
                a = array[i];
                b = array[i + 1];
                console.log(a[comparator] + " " + b[comparator]);

                //Caso 1 : Trovo lo stesso oggetto:
                if (a[comparator] == b[comparator]) {
                    if (a.selected) {
                        b.selected = true;

                    } else if (a.indeterminate) {
                        b.indeterminate = true;
                    }
                    i++;// ho trovato una coppia -> analizzo la prossima coppia
                    newArray.push(b);
                    continue;
                }

                //Caso 2 : Trovo 2 elementi e a è old, è stato cancellato
                if (a.old) {
                    this.$root.toast(this.$t(DATABASE_MESSAGES.NOT_FOUND, {0 : a[comparator]}), [], 3000, "warning");

                    continue;
                }

                //Caso 3: trovo 2 guid diversi e a non è old, a è un disco nuovo
                this.$root.toast(this.$t(DATABASE_MESSAGES.FOUND_NEW, {0 : a[comparator]}), 3000, "warning");

                newArray.push(a);
            }

            return newArray;

        },

    },

}
</script>