<template>
    <div class="dialog light center text-small rounded w-100-sm w-100-md w-75-lg  w-50-xl draggable-item" v-cloak>

        <DialogTitle :title="serviceAccount.id == null ? $t('Add Service Account') : serviceAccount.name"
            :defaultTitle="serviceAccount.id == null ? $t('Add Service Account') : $t('Edit/Test Service Account')"
            :subTitle="serviceAccount.type_name" @closeDialog="$root.$emit('CLOSESERVICEACCOUNTDIALOG')" />

        <div class="dialog-content overflow">

            <!--------------------------FORM--------------------------------------------->
            <div class="form-group">
                <label>{{ $t("Name") }}</label>
                <input data-role="input" type="text" v-model="serviceAccount.name" maxlength="50"
                    :placeholder="$t('Enter friendly name')" data-prepend="<span class='mif-pencil'></span>" />
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>
            <div class="form-group">
                <label>{{ $t("Username") }}</label>
                <input data-role="input" v-model="serviceAccount.username" type="text" required
                    data-prepend="<span class='mif-user'></span>" autocomplete="off"/>
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>
            <div class="form-group">
                <label>{{ $t("Password") }}</label>
                <input data-role="input" class="metro-input" type="password" v-model="serviceAccount.password"
                    :placeholder="$t('Enter Password')" data-prepend="<span class='mif-lock'></span>" autocomplete=false/>
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>
            <div class="form-group">
                <label>{{ $t("Path") }}</label>
                <input data-role="input" type="text" data-validate="required" v-model="serviceAccount.options.path">
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>

            <!-------------------------------SCELTA PC----------------------------------------->
            <div v-if="$session.getRoomPC() == null" class="form-group">
                <label>
                    <span>{{ $t("Select PC to Test Service Account") }}</span>
                </label>
                <select class="select cell-9" v-model="testPC" :disabled="$session.getPCList().length == 0">
                    <option :data-append="pc.note" v-for="pc in $session.getPCList()" :value="pc"
                       :disabled="!$root.isConnected(pc.id)"> {{ pc.name }}
                    </option>
                </select>
            </div>
        </div>

        <!-------------------------------TEST ACCOUNT----------------------------------------->
        <div class="dialog-actions">
            <div v-if="tested == TEST_WAIT"><span class="mif-spinner2 ani-spin" /></div>
            <button class="button alert mr-2" @click="$root.$emit('CLOSESERVICEACCOUNTDIALOG')">{{ $t("Cancel")
                }}</button>
            <button class="button primary mr-2" @click="test" :disabled="!enableTest"> {{ $t("Test account") }}
            </button>
            <button class="button primary" :disabled="!enableSave" @click="$root.saveServiceAccount(serviceAccount)"> {{ $t("Save") }}
            </button>
        </div>
    </div>
</template>

<script>
import DialogTitle from "../../utils/DialogTitle.vue";
import { isValid } from "../../../../public/assets/js/utilitiesmodule";

import { draggableMixin } from '../../../mixins/draggable-mixin'


export default {

    mixins: [draggableMixin],
    name: "NetworkServiceAccount",
    components: {
        "DialogTitle": DialogTitle,
    },
    props: {
        serviceAccount: Object,
    },
    data() {
        return {
            testPC: null,

            // -1 : avviato
            // 0 : non avviato o fallito
            // 1 : ok
            tested: 0,
            TEST_NOTOK : 0,
            TEST_WAIT : -1,
            TEST_OK : 1,
        }
    },

    beforeDestroy() {
        this.$root.$off("socketEventConnected");
        this.$root.$off("socketEventDisconnected");
        if(isValid(this.serviceAccount.options.accountSessionId))
            this.$root.socket("disconnectnetwork", this.serviceAccount.options.accountSessionId);
        
    },
    computed: {
        allInputCompleted() {
            
            return this.serviceAccount.name != "" && (this.$session.getRoomPC() != null || this.testPC != null) && 
                this.serviceAccount.username != "" &&
                this.serviceAccount.password != "";
        },
        enableTest() {
            return this.allInputCompleted && this.tested == this.TEST_NOTOK;
        },
        enableSave() {
            return this.allInputCompleted && (this.tested == this.TEST_OK || this.tested== this.TEST_NOTOK && this.serviceAccount.id != null);
        }

    },
    created(){
        if(this.serviceAccount.options.lastTestPC !=null)
            this.testPC = this.$session.getPCList().find(pc => pc.id == this.serviceAccount.options.lastTestPC);
        },
    methods: {
        async test() {
            let self = this;
            self.tested = -1; // test in corso
            var testResult = await this.$root.testServiceAccount(self.serviceAccount, self.testPC);
            self.tested = testResult.okNumber;
            self.serviceAccount.options.accountSessionId = testResult.serviceAccount.options.accountSessionId;
            self.serviceAccount.options.lastTestPC = testResult.serviceAccount.options.lastTestPC;
            self.serviceAccount.options.path = isValid(testResult.serviceAccount.options.path) ? testResult.serviceAccount.options.path : "";



        },

        /**************************SALVATAGGIO SERVICE ACCOUNT********************************/
    },
}

</script>