var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "w-100 h-100 overflow" },
    [
      _c("Navigation", { attrs: { pageName: "licenses" } }),
      _vm.licenses == null
        ? _c("div", { staticClass: "d-flex flex-justify-center p-10" }, [
            _c("span", {
              staticClass: "mif-spinner2 fg-primary ani-spin mif-3x",
            }),
          ])
        : _vm._e(),
      _c("div", { staticClass: "p-10" }, [
        _vm.licenseDetails
          ? _c("div", [
              _c("h3", [_vm._v(_vm._s(_vm.$t("License detail")))]),
              _c("div", { staticClass: "pt-2" }, [
                _c("p", [
                  _c("strong", [_vm._v("ID:")]),
                  _vm._v(" " + _vm._s(_vm.licenseDetails.id)),
                ]),
                _c("p", [
                  _c("strong", [_vm._v("Assegnatario:")]),
                  _vm._v(" " + _vm._s(_vm.licenseDetails.assignee)),
                ]),
              ]),
            ])
          : _vm._e(),
        _c("br"),
        _c("h3", [_vm._v(_vm._s(_vm.$t("Licenses List Backup")))]),
        _vm.licenses != null
          ? _c(
              "table",
              { staticClass: "table condensed striped bg-white mt-2" },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("Name")))]),
                    _c("th", { staticClass: "text-right" }, [
                      _vm._v(_vm._s(_vm.$t("Description"))),
                    ]),
                    _c("th", { staticClass: "text-right" }, [
                      _vm._v(_vm._s(_vm.$t("Unit"))),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.licenses.backup, function (license, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [_vm._v(_vm._s(license.nome_licenza))]),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(license.description)),
                      ]),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(license.units)),
                      ]),
                    ])
                  }),
                  0
                ),
              ]
            )
          : _c("p", [_vm._v(_vm._s(_vm.$t("No license found")))]),
        _c("br"),
        _c("h3", [_vm._v(_vm._s(_vm.$t("Licenses List Remote")))]),
        _vm.licenses != null
          ? _c(
              "table",
              { staticClass: "table condensed striped bg-white mt-2" },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("Name")))]),
                    _c("th", { staticClass: "text-right" }, [
                      _vm._v(_vm._s(_vm.$t("Description"))),
                    ]),
                    _c("th", { staticClass: "text-right" }, [
                      _vm._v(_vm._s(_vm.$t("Max Control connections"))),
                    ]),
                    _c("th", { staticClass: "text-right" }, [
                      _vm._v(_vm._s(_vm.$t("Max Host list"))),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.licenses.remote, function (license, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [_vm._v(_vm._s(license.nome_licenza))]),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(license.description)),
                      ]),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(license.max_control_connections)),
                      ]),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(license.max_host_list)),
                      ]),
                    ])
                  }),
                  0
                ),
              ]
            )
          : _c("p", [_vm._v(_vm._s(_vm.$t("No license found")))]),
        _c("br"),
        _c("h3", [_vm._v(_vm._s(_vm.$t("Licenses List Monitoring")))]),
        _vm.licenses != null
          ? _c(
              "table",
              { staticClass: "table condensed striped bg-white mt-2" },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("Name")))]),
                    _c("th", { staticClass: "text-right" }, [
                      _vm._v(_vm._s(_vm.$t("Description"))),
                    ]),
                    _c("th", { staticClass: "text-right" }, [
                      _vm._v(_vm._s(_vm.$t("Unit"))),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.licenses.monitoring, function (license, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [_vm._v(_vm._s(license.nome_licenza))]),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(license.description)),
                      ]),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(license.units)),
                      ]),
                    ])
                  }),
                  0
                ),
              ]
            )
          : _c("p", [_vm._v(_vm._s(_vm.$t("No license found")))]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }