export const extensionsFilterAction = [
    "Exclude specified extensions",
    "Include only specified extensions"
];

export const fileSizeFilterAction = [
    "Exclude files whose size is",
    "Only include files whose size is"
];

export const fileSizeFilterCompare = [
    "greater than",
    "greater or equal to ",
    "less than",
    "less or equal to",
    "equal to"
];

export const backupTypes = {
    "F": "Create a full backup every time and always copy all files",
    "U": "Create a full backup first, then update it with new or changed files",
    "D": "Keep a full backup and multiple differential copies",
    "I": "Keep a full backup and multiple incremental copies"
};

export const driveImageBackupTypes = [{
        text: "Full backups only (make a new Full from scratch each time)",
        character: "F",
        value: 0
    },
    {
        text: "One full backup (Full) updated each time with differences",
        character: "F",
        value: 1
    },
    {
        text: "One full backup (Full) + Incremental",
        character: "I",
        value: 2
    },
    {
        text: "One full Backup (Full) + Differentials",
        character: "D",
        value: 3
    }
];

export const protectionTypes = [
    "Standard protection (compatible with all zip archiver software)",
    "Encryption of the entire zip file (the file will not be openable without first decrypting it)",
    "Use both protections (standard + whole file encryption)"
];

export const pathsInArchive = [
    "No path",
    "Complete path",
    "Full path including drive (recommended)",
    "Relative path"
];

export const archiveTypes = ["Zip", "7Zip"];


export const selectValues = {
    extensionsFilterAction: extensionsFilterAction,
    fileSizeFilterAction: fileSizeFilterAction,
    fileSizeFilterCompare: fileSizeFilterCompare,
    backupTypes: backupTypes,
    protectionTypes: protectionTypes,
    pathsInArchive: pathsInArchive,
    driveImageBackupTypes: driveImageBackupTypes,
    archiveTypes: archiveTypes
}