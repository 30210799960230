<template>
    <div class="form-group">
        <div>

            <!--Se devo selezionare un elemento singolo, mostro un input text-->
            <div v-if="singleItem" class="mb-4">
                <label>{{$t(buttonText) }}</label>
                <div class="input" @click="$emit('clickFunction')" :disabled="isDisabled"
                    :title="list.length == 1 ? list[0].path : ''">
                    <input v-if="list.length == 1" type="text" readonly data-role="input" data-validate="required"
                        data-role-input="true" :value="list[0].path">
                    <input v-else type="text" data-role="input" data-validate="required"
                        data-role-input="true">
                    <div class="button-group">
                        <button class="button" type="button" :disabled="isDisabled">
                            <span class="mif-folder" />
                        </button>
                    </div>
                </div>
            </div>

            <input v-if="!singleItem" type="button" @click="$emit('clickFunction')" :value="$t(buttonText)"
                class="button primary mb-2" :disabled="isDisabled" />
        </div>

        <div v-if="!singleItem && list.length>0" class="overflow mt-2" :style="'max-height:' + height + 'px'">

            <table class="table striped compact table-compact">
                <tbody>
                    <tr  v-for="res in list" v-bind:key="res.id" :title="res.path">
                        <td class="d-flex flex-align-baseline"><span :class="getClass(res)" />  {{ res.path.split("?")[0] }}</td>
                        <td><a href="#" class="button flat-button" v-if="canCancel" @click="removeNode(res)"><span
                                    class='mif-bin'></span></a></td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>

</template>

<script>
import { getMetroIcon, getSeparator } from '../../../public/assets/js/utilitiesmodule';
import { ClientTreeNodeMethods } from '../../models/TreeNode';

export default {
    name: "SelectedResourcesLayout",
    props: {
        buttonText: String,
        singleItem: Boolean,
        isDisabled: Boolean,
        list: {
            type: Array,
            required: true
        },
        heightPercentage: {
            type: Number,
            required: true
        },
        canCancel: {
            type: Boolean,
            required: true
        }
    },
    data() { return { 
        height: 0, 
        iconClass: '',
        nodeMethods : null
    } },
    computed: {
/*         valueToShow : function(){
            if(this.list.length == 1)
            return this.list[0].path;
        return "";

        } */
    },
    mounted: function () {
        this.height = this.$parent.$el.clientHeight * this.heightPercentage / 100;
        this.nodeMethods = new ClientTreeNodeMethods();
    },
    methods: {

        getClass(node) {
            var classString = "px-2 " + getMetroIcon(node.type, node.path);
            if (!this.compact)
                classString += " mif-2x py-3";
            return classString;
        },

        getSeparator(path){
            return getSeparator(path);
        },

        getShortPath(node){
            return new ClientTreeNodeMethods().getShortPath(node);
        }
    }
}
</script>