import BackupLastResult from "../components/utils/BackupLastResult";
import RestoreLastResult from "../components/utils/RestoreLastResult.vue";
import VueContext from 'vue-context';
import 'vue-context/dist/css/vue-context.css';
import {
    prettyBytes,
    platformsTypes,
    ResultValue,
    formatDate,
    secondsToFormattedString,
    diffDate,
    distinctBy,
    fromNow,
    getValueOrDefault,
} from "../../public/assets/js/utilitiesmodule";
import NoDataAvailable from "../components/utils/NoDataAvailable";
import TruncatedText from "../components/utils/TruncatedText";
import Navigation from "../components/utils/Navigation";
import mBadge from "../components/utils/mBadge";
import { DefaultObjectBuilder } from '../models/DefaulfObjectBuilder';
import vueContext from 'vue-context/src/js/vue-context';
import { createBackups } from '../../public/assets/js/test';
import { ClientTreeNode } from '../models/TreeNode';
import PCRemoteLink from '../components/utils/PCRemoteLink.vue';
import PCBackupLink from '../components/utils/PCBackupLink.vue';
import StatsCollector from '../components/stats/StatsCollector.vue'
import { PC_MESSAGES } from '../../public/assets/js/messages';
import WebRTCSession from '../services/WebRTCSession';

export default {
    components: {
        BackupLastResult,
        RestoreLastResult,
        NoDataAvailable,
        TruncatedText,
        Navigation,
        VueContext,
        mBadge,
        vueContext,
        "PCRemoteLink": PCRemoteLink,
        "PCBackupLink": PCBackupLink,
        StatsCollector
    },
    name: "Computer",
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Computer',
        // all titles will be injected into this template
        titleTemplate: '%s | Iperius ONE',
    },
    watch: {
        '$route': {
            handler: function(to) {
                //Si vuole terminare un processo
                if (to.hash != "" && to.hash.startsWith("#killprocess_")) {
                    var pid = to.hash.replace("#killprocess_", "");
                    this.killApp(pid);
                }

            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            remoteSessionActive: false,
            remoteControlSession: null,
            tabOpened: 1,
            connectionState: 'disconnected',
            computerKey: 0,
            terminal: {
                toggleTerminal: false,
                terminalText: "",
                terminalLogged: false,
                terminalInput: "",
                username: "Administrator",
                password: ""

            },
            remote: {
                id: "",
                pw: "",
                webSocket: null,
                peerConnection: null,
                dataChannel: null,
                remoteStream: null,
                localCanvas: null,
            },

            loading: false,
            // [TG - apertura restore da menu sx singolo job]
            restoreType: -1,
            selectedJobId: "",
            settings_opened: false,
            serviceAccount: null,
            selectedGroups: [],
            repository: null,
            is_global_running: {
                jobs: false,
                patches: false,
                restores: false,
            },
            platformTypes: null,

            // PANNELLO PRINCIPALE
            pc: null,
            fullStats: null,
            patches: null,
            patchInstalled: "",
            policiesList: [],
            jobPoliciesList: [],
            api_backups: null,
            socket_backups: null,
            restores: null, // socket [GT - 06/06/2023]
            cronstrue: null,
            cronParser: null,

            //PANNELLO DX
            system: { // socket realtime
                CPU: 0,
                usedRAM: 0,
                availableRAM: 0,
                totalRAM: 0,
                readDiskspeed: 0,
                writeDiskspeed: 0,
                diskTransfers: 0,
                agentVersion: '',
            },
            disks: null, //socket
            processesList: [], // socket realtime
            filesystem: null,
            eventlogs: {
                list: [], //socket
                type: 1,
                level: "System"
            },

            //PANNELLO DINAMICO LOGS
            backupJobLogs: null,

            //Recuperati tramite API
            selectedgroup: null,
            windowWidth: 0,
            openAfterRefresh: false,
            selectedIndex: -1,
            log_visible: false,

            runAppPath: "",
            antivirusString: '',

            filters: {
                typeList: [],
                type: -1,
                resultList: [],
                lastresult: -1
            },

            //TODO Prendere quelli di giancarlo
            id: "",
            backupIDForView: null,
            dataRetrieved: false,

            //Si riferisce alla classe ExploreFSOptions dell'agent
            exploreFSOptions: {
                includeFiles: true,
                includeHiddenFiles: false,
                includeInaccessibleFiles: false,
                includeSystemFiles: false,
                includeDetails: true
            },

            jobIDsOrder: [], //Conterrà gli id dei job in ordine in base ai parametri stabiliti nell'agent (attualmente sono ordine in base all'ultimo avvio)

            //fsClickedNode: null
        }
    },
    props: {
        idPC: String,
    },

    beforeDestroy: async function() {
        this.pc = null; // Pulizia dei dati
        this.backupJobLogs = null;
        this.backupJob = null;
        this.api_backups = [];
        this.$root.$off("socketEventConnected");
        this.$root.$off("socketEventDisconnected");
        this.$root.$off("addRealTimeSystemDataToPage");
        this.$root.$off("addRealTimeProcessesToPage");
        this.$root.$off("addWUToPage");
        this.$root.$off("addRestoreToPage");
        this.$root.$off("realtimeEvents");
        this.$root.$off("cancel");
        this.$root.$off("DeletedJob");
        this.$root.$off("runCMD");
        this.$root.$off("closeCMD");
        //this.$root.newPage("computer", "destroy");
        this.$session.set("REALTIMEJOBS", []);
        console.log("stop = " + await this.$root.socket("STOPSENDINFO"));
        console.log("unsubscribe = " + await this.$root.socket("unsubscribe_pc", this.idPC));
    },
    created: function() {
        //alert("CREATED COMPUTER.JS");
        //this.$root.newPage("computer", "created");
        //this.platformsTypes = platformsTypes;
        //this.createAndTestBackups();


    },
    mounted: async function() {

        //this.$root.newPage("computer", "mounted");
        /*  if (!this.$root.loadPage("computer"))
        return; */
        let self = this;

        self.cronstrue = require('cronstrue/i18n');
        self.cronParser = require('cron-parser');

        //Step 1 : Richiedo i dati per il pannello principale con le chiamate API
        //popolo i valori dei filtri
        this.filters.typeList = platformsTypes;
        this.filters.resultList = ResultValue;

        self.pc = await this.$api.getPC(self.idPC);
        if (self.pc.is_protected) {
            self.pc.custom_password2 = self.pc.custom_password;
        }
        self.$session.setRoomPC(self.pc);
        /*  if (self.pc != null && self.pc.security_status) {
             self.antivirusString = self.pc.security_status.antivirusList.map(x => x.name + "(" + (x.enabled ? "enabled" : "not enabled") + ")").join(", ");
         } */

        this.$api.getJobPoliciesList(self.idPC).then((list) => {
            self.jobPoliciesList = list;
        });

        this.$api.getPCPoliciesList(self.idPC).then((list) => {
            self.policiesList = list;
        });

        //###DA INSERIRE IN OGNI COMPONENTE CHE NECESSITA DI CHIAMATE SOCKET###
        // "checkSocket" avvia la connessione socket se ferma
        // "socketEventConnected" viene emesso quando la connessione socket è attiva, quindi nella sua callback si possono avviare tutte le istruzioni che riguardano le chiamate socket
        // "socketEventDisconnected" viene emesso quando la connessione socket non è attiva
        this.$root.$on("socketEventConnected", () => {
            self.$root.socketConnected = true;
            self.$root.checkConnectionTimerLight();
            self.retrieveDataFromSocket();
        });
        //"socketEventDisconnected" viene emesso se la connessione socket non è attiva
        this.$root.$on("socketEventDisconnected", () => {
            self.$root.socketConnected = false;
            self.setDefaultData();
        });
        this.$root.checkSocket();

        //####################################################################

        //UTILITIES
        this.$root.$on("cancel", () => this.reset());
        this.$root.$on("DeletedJob", idBackup => {
            //Documentazione : https://www.w3schools.com/howto/howto_js_remove_property_object.asp
            delete self.socket_backups[idBackup];
        });

        // ----------------------------------------------------------------------------

        //INIZIO DELLE CALLBACKS PER LA CONNESSIONE SOCKET
        this.$root.$on("addBackupsToPage", backups_received => {

            self.api_backups = null;
            if (self.socket_backups == null) {
                self.socket_backups = backups_received;
            } else {
                for (let [id, job] of Object.entries(backups_received)) {
                    self.socket_backups[id] = job;
                }
            }

            if (self.backupIDForView != null && backups_received[self.backupIDForView] != undefined) {
                this.$root.$emit("sendRealtimeJob", backups_received[self.backupIDForView]);
            } else {
                self.backupIDForView = null;
            }

            /*PROVA FUNZIONANTE PER ORDINARE I BACKUP DA QUELLO AVVIATO PIU' RECENTEMENTE A QUELLI MAI AVVIATI
            self.socket_backups = Object.entries(self.socket_backups)
                .sort(([, a], [, b]) => self.orderJobs(a, b))
                .reduce((r, [k, v]) => ({...r, [k]: v }), {});*/
        });

        this.$root.$on("addIDsOrder", idsOrder => {
            self.jobIDsOrder = idsOrder;
        })

        this.$root.$on("addWUToPage", wu_received => {
            if (wu_received == null) self.patches = null;
            else {
                self.patches = [];
                self.patches = wu_received;
            }


        });


        this.$root.$on("runCMD", event => {
            self.terminal.terminalText += "\n" + event;
            self.terminal.terminalInput = "";
            var content = document.getElementById('textarea');
            content.scrollTop = content.scrollHeight;
        });

        this.$root.$on("closeCMD", event => {
            self.terminal.terminalText = "";
            self.terminal.terminalInput = "";
            var content = document.getElementById('textarea');
            content.scrollTop = content.scrollHeight;
            self.terminal.terminalLogged = false;
        });


        // da completare per valorizzare lista restore in corso cu computer.js
        this.$root.$on("addRestoreToPage", async restores => {
            if (self.restores == null) {
                self.restores = restores;
            } else {
                for (let [id, job] of Object.entries(restores)) {
                    //Restore in corso
                    //if (job.isRunning) {
                    self.restores[id] = job;
                    //} 

                    // [TG - 29/07/2024 - commentata per non far eliminare i restore dalla lista, almeno nella sessione corrente, finche l'agent non viene riavviato]
                    //restore finito
                    //else if (self.restores[id] != undefined) {
                    //    delete self.restores[id];
                    //}
                }
            }
            /*var n = self.restores == null ? 0 : self.restores.length;
            self.restores = restores;
          /*   if (restores.length == 0)
                return;
            await waitSeconds(1);
            if (self.restores == null || restores.length == n + 1)
                setTimeout(function() {
                    if (isValid(document.getElementById('restoresShortcut')))
                        document.getElementById('restoresShortcut').click();
                }, 2000);*/
        });

        this.$root.$on("realtimeEvents", event => {
            var mss = event.split("|");
            this.$root.toast(this.$t(mss[0]), 3000, mss[1]);
        });

        this.$root.$on("createitemfeedback", async itemCreated => {
            if (!itemCreated)
                return;
            var requestData = {
                nodeID: "",
                path: this.filesystem.directorySelected,
                options: this.exploreFSOptions
            };
            self.GetExploreFsCallback(await this.$root.socket("GetExploreFs", requestData));
        });

        this.$root.$on("renameitemfeedback", async itemRenamed => {
            if (!itemRenamed)
                return;
            var requestData = {
                nodeID: "",
                path: this.filesystem.directorySelected,
                options: this.exploreFSOptions
            };
            self.GetExploreFsCallback(await this.$root.socket("GetExploreFs", requestData));
        });

        this.$root.$on("deleteitemfeedback", async itemDeleted => {
            if (!itemDeleted)
                return;
            var requestData = {
                nodeID: "",
                path: this.filesystem.directorySelected,
                options: this.exploreFSOptions
            };
            self.GetExploreFsCallback(await this.$root.socket("GetExploreFs", requestData));
        });

        this.$root.$on("addRealTimeSystemDataToPage", rt_systemData => self.addRealTimeSystemDataToPage_Callback(rt_systemData));
        this.$root.$on("addRealTimeProcessesToPage", rt_processesList => {
            self.processesList.splice(0, self.processesList.length);
            self.processesList.push(...getValueOrDefault(rt_processesList, []));
            //self.processesList = ;
        });

    },

    filters: {
        prettyBytes: function(bytes, precision) {
            return prettyBytes(bytes, precision);
        },
    },
    methods: {

        openTab(index) {
            /* var tabs = $("#backups_tabs").data("materialtabs");
            tabs.open(index); */
            this.tabOpened = index;
        },

        reloadComponent() {
            this.componentKey += 1;
        },
        // openRightClickMenu(filesystemNode) {
        //     this.fsClickedNode = filesystemNode;
        //     // if (filesystemNode.type == this.$root.FileFolderEntityType.FN_Folder)
        //     //     this.filesystem.directorySelected = filesystemNode.path;
        //     this.$refs.menu.open();
        // },

        // fsAction(action) {

        //     switch (action) {
        //         case "OPEN":
        //             this.navigate(this.fsClickedNode.path);
        //             break;
        //         case "RENAME":
        //             this.openRenameItemDialog(this.fsClickedNode.path, this.fsClickedNode.type)
        //             break;
        //         case "DOWNLOAD":
        //             alert(`Item download not implemented!`);
        //             break;
        //     }
        // },

        async openDialogPolicy(id_policy) {
            var policy = await this.$api.getPolicy(id_policy);
            this.$root.$emit("OPENPOLICYVIEWDIALOG", policy, this.$session.getPCList());
        },



        handleWebSocketMessage(message) {
            // Handle incoming messages from the WebSocket
            const data = JSON.parse(message.data);
            console.log(data);
        },
        handleRemoteStream(stream) {
            this.remoteVideoElement.srcObject = stream;
        },
        handleConnectionStateChange(state) {
            this.connectionState = state;
        },
        startCall() {
            // Crea un'offerta per iniziare la chiamata
            this.remoteControlSession.createOffer();
        },
        endCall() {
            if (this.remoteControlSession) {
                this.remoteControlSession.closeConnection();
                this.connectionState = 'Disconnected';
                this.remoteVideoElement.srcObject = null;
            }
        },
        async initializeWebRTC() {
            try {
                // Step 1: Create WebRTC session
                this.remoteControlSession = new WebRTCSession(
                    "https://global.iperius-rs.com:443/socket/websocket",
                    this.handleRemoteStream,
                    this.handleConnectionStateChange
                );

                // Step 2: Set up signaling
                await this.setupSignaling();

                // Step 3: Create offer
                await this.remoteControlSession.createOffer();

                // Step 4: Set up mouse and keyboard listeners for remote video control
                this.setupMouseAndKeyboardListeners();
                this.connectionState = 'connected';
            } catch (error) {
                console.error('Error initiating remote control:', error);
                this.connectionState = 'disconnected';
            }
        },



        async setupSignaling() {
            // Here you should set up signaling using your server connection, either WebSockets or other signaling protocol
            // Implement the logic to send offers, answers, and ICE candidates between peers
            // These methods replicate the signaling logic found in your connected_controller.dart

            // Example pseudo-code for handling signaling
            this.remoteControlSession.on('icecandidate', (candidate) => {
                this.remoteControlSession.handleIceCandidate(candidate);
            });

            this.remoteControlSession.on('offer', async(offer) => {
                // Send the offer to the signaling server, to be forwarded to the remote peer
                this.remoteControlSession.handleOffer(offer);
            });

            // Add methods for receiving answers and ice candidates from the signaling server
        },

        /*  setupMouseAndKeyboardListeners() {
             const remoteVideo = document.getElementById('remoteVideo');
             if (!remoteVideo) {
                 console.error('Remote video element not found');
                 return;
             }

             // Mouse event listeners
             remoteVideo.addEventListener('mousemove', (event) => {
                 this.remoteControlSession.sendMouseData(event.offsetX, event.offsetY);
             });
             remoteVideo.addEventListener('click', (event) => {
                 this.remoteControlSession.sendMouseClickData(event.button);
             });

             // Keyboard event listeners
             window.addEventListener('keydown', (event) => {
                 this.remoteControlSession.sendKeyboardData(event.key, 'keydown');
             });
             window.addEventListener('keyup', (event) => {
                 this.remoteControlSession.sendKeyboardData(event.key, 'keyup');
             });
         }, */

        closeRemoteControlConnection() {
            if (this.remoteControlSession) {
                this.remoteControlSession.closeConnection();
                this.remoteControlSession = null;
                this.connectionState = 'disconnected';
            }
        },

        /***********************PC*********************************************************************************/
        addRealTimeSystemDataToPage_Callback(rt_systemData) {
            this.system.CPU = getValueOrDefault(rt_systemData.CPU, 0);
            this.system.usedRAM = getValueOrDefault(rt_systemData.usedRAM, 0);
            this.system.availableRAM = getValueOrDefault(rt_systemData.availableRAM, 0);
            this.system.totalRAM = getValueOrDefault(rt_systemData.totalRAM, 0);
            this.system.readDiskspeed = getValueOrDefault(rt_systemData.readDiskspeed, 0);
            this.system.writeDiskspeed = getValueOrDefault(rt_systemData.writeDiskspeed, 0);
            this.system.diskTransfers = getValueOrDefault(rt_systemData.diskTransfers, 0);
            if (this.system.agentVersion == '')
                this.system.agentVersion = getValueOrDefault(rt_systemData.agentVersion, 'N/A');
        },

        /***********************************RECUPERO DATI**********************************************************/
        async retrieveDataFromSocket() {
            let self = this;

            self.$root.socket("subscribe_pc", self.idPC)
                .then(() => {
                    //console.log(new Date());
                    self.$root.socket("STARTSENDINFO")
                        //console.log(new Date());

                });

            this.$root.socket("GetDiskInfo", false, false, false)
                .then(response => {
                    if (response == null) self.disks = [];
                    else self.disks = response.disks;
                });

            var requestData = {
                nodeID: "",
                path: "C:\\",
                options: this.exploreFSOptions
            };
            this.$root.socket("GetExploreFs", requestData).then(response => self.GetExploreFsCallback(response));

            this.$root.socket("GetEventLogs", "System", 1).then(events => {
                Object.assign(self.eventlogs.list, events);
            });






        },

        convertToCronParserFormat(cronExpression) {
            // Dividi la stringa cron in campi
            const cronParts = cronExpression.split(' ');

            // Se la stringa ha 7 campi, rimuoviamo il primo campo (secondi)
            if (cronParts.length === 7) {
                cronParts.shift(); // Rimuove il campo dei secondi
            }

            // Gestione dei campi non necessari (es: '?', '1/1') e conversione a 5 campi
            return cronParts
                .map((part, index) => {
                    // Rimuove '?' che non è supportato da cron-parser
                    if (part === '?') return '*';

                    // Rimuove '1/1' dai campi mese/giorno del mese
                    if (index >= 2 && (part === '1/1' || part === '1')) return '*';

                    return part;
                })
                .slice(0, 5) // Assicuriamo che ci siano solo 5 campi
                .join(' ');
        },

        nextSched(schedString = "") {
            if (schedString == "") {
                return "";
            }
            var self = this;
            try {
                var compatibleString = this.convertToCronParserFormat(schedString)

                var options = {
                    tz: Intl.DateTimeFormat().resolvedOptions().timeZone
                };

                var interval = this.cronParser.parseExpression(compatibleString, options);

                const nextDate = interval.next().toDate();
                const formattedDate = self.$moment(nextDate).format('LLL'); // Output formattato in base al locale
                return formattedDate;
            } catch (err) {
                console.log('Error: ' + err.message);
            }
        },

        async removeFromLicense() {
            var dialogID = Math.floor(100000 + Math.random() * 900000).toString();
            let self = this;
            this.$root.$on(dialogID, ok => {

                if (!ok) {
                    self.$root.$off(dialogID);
                    return;
                }

                self.$root.socket("remfromlicense")
                    .then((res) => {
                        self.$root.toast(this.$t(PC_MESSAGES.LICENSE_REMOVED), 3000, "success");
                        self.$root.$off(dialogID);
                        history.back()
                    });
            });

            this.$root.openYesNoDialog(dialogID, this.$t("Remove license"), this.$t("Are you sure to remove license?"));
        },

        async reconnectToLicense() {
            var dialogID = Math.floor(100000 + Math.random() * 900000).toString();
            let self = this;
            this.$root.$on(dialogID, ok => {

                if (!ok) {
                    self.$root.$off(dialogID);
                    return;
                }

                self.$api.addToLicense(self.idPC).then((res) => {
                    if (res != null)
                        self.$root.toast(this.$t(PC_MESSAGES.LICENSE_ADDED), 3000, "success");
                    self.$root.$off(dialogID);
                });
            });

            this.$root.openYesNoDialog(dialogID, this.$t("Reconnect to license"), this.$t("Are you sure?"));
        },

        async openDialogGroup(group, index) {
            let self = this;
            self.selectedgroup = await this.$api.getGroup(group.id);
            this.$root.$emit("OPENGROUPVIEWDIALOG", self.selectedgroup, self.$session.getUsers(), self.$session.getPCList(), self.pc.groups[index]);
        },

        async setDefaultData() {
            let self = this;
            //PANNELLO SX
            self.socket_backups = null;
            var apiJobs = await this.$api.getBackups(self.idPC, 99, 25, 0, 0);
            if (apiJobs == null)
                self.api_backups = [];
            else {
                apiJobs = apiJobs.filter(job => job.id_computer == self.idPC);
                if (apiJobs.length == 0) {
                    self.api_backups = [];
                } else {
                    self.api_backups = distinctBy(new DefaultObjectBuilder().fromAPIJobsToSocketJobs(apiJobs), "id");
                }
            }

            //self.api_backups = new DefaultObjectBuilder().fromAPIJobsToSocketJobs(apiJobs);
            self.restores = [];


            self.disks = []; //socket
            self.processesList = []; // socket realtime
            self.filesystem = { //socket
                files: [],
                directories: [],
                drives: [],
                network: [],
                driveSelected: "C:\\",
                path: [],
                directorySelected: "C:\\"
            };
            self.eventlogs = {
                list: [], //socket
                type: 1,
                level: "System"
            };
        },

        async getOTP() {
            var Key_OTP = await this.$api.getOTP(this.idPC);
            console.log(Key_OTP);

            var win = window.open('iperiusremote://' + Key_OTP + '/', '_blank');
            win.getValue = function() {
                return Key_OTP;
            };

        },

        async editComputer() {

            this.pc.groups = window.Metro.getPlugin($("#groupselect"), 'select').val();
            var result = await this.$api.editPC(this.pc);
            console.log(result);
            if (result) {
                this.$root.toast(this.$t(PC_MESSAGES.SAVED), 3000, "success");
            } else {

                Object.keys(result).forEach(key => {

                    this.$root.toast(key + ":" + result[key], 3000, "alert");
                });
            }

        },


        saveCustomPw() {
            if (this.pc.custom_password != this.pc.custom_password2) {
                this.$root.toast(this.$t("Passwords are not equals"), 3000, "alert");
                return;
            }
            if (this.pc.custom_password == "" || this.pc.custom_password == null) {
                this.$root.toast(this.$t("Passwords are not equals or empty"), 3000, "alert");
                return;
            }
            this.$api.savePCPassword(this.pc).then((result) => {
                if (result) {
                    this.$root.toast(this.$t(PC_MESSAGES.SAVED), 3000, "success");
                    this.pc.is_protected = 1;
                } else {

                    Object.keys(result).forEach(key => {

                        this.$root.toast(key + ":" + result[key], 3000, "alert");
                    });
                }
            });


        },

        removeCustomPw() {
            if (!this.pc.is_protected) {
                if (confirm(this.$t("Are you sure?"))) {
                    this.pc.custom_password = "";
                    this.pc.custom_password2 = "";

                    //disabilito la checkbox?
                    this.$api.removePCPassword(this.pc).then((result) => {
                        if (result) {
                            this.$root.toast(this.$t(PC_MESSAGES.PASSWORD_REMOVED), 3000, "success");
                        } else {

                            Object.keys(result).forEach(key => {

                                this.$root.toast(key + ":" + result[key], 3000, "alert");
                            });
                        }
                    });
                }
            }
        },

        sendInstallMessage(idupdate) {
            this.$root.socket("installwinupdate", idupdate);
        },



        refreshEventLogs() {
            var self = this;
            this.$root.socket("GetEventLogs", self.eventlogs.level, self.eventlogs.type).then((logs) => self.eventlogs.list = logs);
        },



        updateAgent() {
            alert("DA IMPLEMENTARE PARTE AGENT");
            //this.$root.socket("UpdateAgent");
        },

        /***************************************PROCESSI******************************************************************/
        loginTerminal() {
            var self = this;
            this.$root.socket("startcmd", self.terminal.username, self.terminal.password)
                .then(response => {
                    if (response != "") {
                        self.terminal.terminalLogged = true;
                        self.terminal.terminalText += response.text;
                    }
                    console.log(response);
                });
        },

        messageToTerminal() {
            var self = this;
            this.$root.socket("runcmd", self.terminal.terminalInput);
            //self.terminal.terminalText += self.terminal.terminalInput;
            self.terminalInput = "";
        },

        openTerminal() {
            this.terminal.toggleTerminal = true;
            Metro.window.show("#terminalwindow");

        },

        loginTerminal() {
            var self = this;
            this.$root.socket("startcmd", self.terminal.username, self.terminal.password)
                .then(response => {
                    if (response != "") {
                        self.terminal.terminalLogged = true;
                        self.terminal.terminalText += response.text;
                    }
                    console.log(response);
                });
        },

        runApp() {
            var self = this;
            self.$root.socket("runprocess", self.runAppPath);
        },
        async killApp(pid) {
            let self = this;
            var dialogID = Math.floor(100000 + Math.random() * 900000).toString();

            this.$root.$on(dialogID, (kill) => {
                if (kill)
                    self.$root.socket("killprocess", pid + "");
                self.$router.removeHash(); // prototype implementato in main.js
                self.$root.$off(dialogID);
            })

            this.$root.openYesNoDialog(dialogID, this.$t("Kill process"), "Are you sure to kill this process?");

        },

        /*************************************FILESYSTEM******************************************************************/
        async navigate(directory) {
            let self = this;
            var requestData;
            if (directory == "up") {
                self.filesystem.directorySelected = self.filesystem.path[self.filesystem.path.length - 1];
                self.filesystem.path.pop();
                requestData = {
                    nodeID: "",
                    path: self.filesystem.directorySelected,
                    options: this.exploreFSOptions
                };
            } else {
                self.filesystem.path.push(self.filesystem.directorySelected);
                self.filesystem.directorySelected = directory;
                requestData = {
                    nodeID: "",
                    path: directory,
                    options: this.exploreFSOptions
                };
            }
            self.GetExploreFsCallback(await self.$root.socket("GetExploreFs", requestData));
        },

        async changeDrive(drive) {
            let self = this;
            self.filesystem.path = [];
            self.filesystem.driveSelected = drive;
            self.filesystem.directorySelected = drive;
            self.filesystem.files = [];
            self.filesystem.directories = [];
            var requestData = {
                nodeID: "",
                path: self.filesystem.driveSelected,
                options: this.exploreFSOptions
            };
            self.$root.socket("GetExploreFs", requestData).then(result => self.GetExploreFsCallback(result));
        },

        //Chiude la finestre di restore
        reset() {

            this.type_number = -1;
            this.restoreType = -1

            this.selectedindex = -1;

            //this.$root.$emit("closeFileSystemSidebar");
        },


        openRenameItemDialog(path, type) {
            var node = new ClientTreeNode(null, null, path, type);
            this.$root.$emit("OPENRENAMEITEMDIALOG", node, this.filesystem.directorySelected);
        },
        openDeleteItemDialog(path, type) {
            var node = new ClientTreeNode(null, null, path, type);
            this.$root.$emit("OPENDELETEITEMDIALOG", node);
        },
        /************************************BACKUP************************************************************************/
        /*async logJob(idBackup) {
            let self = this;
            self.backupJobLogs = await this.$api.getBackupLogs(idBackup);
            if (self.backupJobLogs.length != 0)
                window.Metro.charms.toggle("#log");
        },*/
        async getLog(log, index) {
            let self = this;
            if (index == self.selectedIndex) {
                self.backupJobLogs[self.selectedIndex].partial_log = null;
                self.selectedIndex = -1;
                return;
            }
            if (self.selectedIndex != -1) {
                self.backupJobLogs[self.selectedIndex].partial_log = null;
            }
            self.selectedIndex = index;
            self.backupJobLogs[self.selectedIndex].partial_log = await this.$root.socket("GetLog", log.logfile_name);
        },

        async jobAction(action, job) {
            switch (action) {
                case 'run': // arg = backup id
                    this.$root.socket("RunJob", job.id);
                    break;
                case 'stop':
                    this.$root.socket("stopjob", job.id);
                    break;
                case 'delete':
                    this.deleteJob(job);
                    break;
                case 'restore':
                    this.restoreJob(job);
                    break;
                case 'see':
                    this.backupIDForView = job.id;
                    this.openBackupView(job.id);
                    break;
                case 'edit':
                    this.$router.push(this.$route.path + '/backup/' + job.id);
                    break;
                case 'duplicate':
                    this.duplicateJob(job);
                    break;
            }
        },

        async restoreAction(action, resId) {
            switch (action) {
                case 'stop':
                    this.$root.socket("STOPRESTORE", resId);
                    break;
                case 'delete':
                    this.deleteRestore(resId);
                    break;
            }
        },
        async deleteRestore(resId) {

            let self = this;

            if (confirm(this.$t("Delete the selected restore ?"))) {

                if (await this.$root.socket("DELETERESTORE", resId)) {
                    delete self.restores[resId];
                    //this.$delete(self.restores, resId);
                }

            }
        },


        async duplicateJob(job) {
            //Step 1 : Recupero il job tramite API
            var APIjob = await this.$api.getBackupWithID(job.id);
            if (APIjob == null) {
                this.$root.toast(this.$t(BACKUP_MESSAGES.NOT_FOUND), 3000, "warning");
                //this.$root.$emit("CLOSEBACKUPVIEW");
                return;
            }

            var date = new Date();
            var dupl = {
                "id": null,
                "name": APIjob.name + " " + date.getDate() + "" + (date.getMonth() + 1) + "" + date.getFullYear() + "" + date.getHours() + "" + date.getMinutes() + "" + date.getSeconds(),
                "type": APIjob.type,
                "id_computer": APIjob.id_computer,
                "sources": APIjob.sources.map(s => {
                    s.id = null;
                    s.id_job = null;
                    return s;
                }),
                "destinations": APIjob.destinations.map(d => {
                    d.id = null;
                    d.id_job = null;
                    return d;
                }),
                "scheduling": APIjob.scheduling,
                "options": APIjob.options
            };

            //Se il pc è offline, si salva in una memoria locale
            if ($this.session.noPCConnectedToSocket()) {
                this.$root.toast(this.$t(BACKUP_MESSAGES.NOT_CREATED, { 0: dupl.name }), 5000, "info");
                return;
            }

            //Step 1 : Invio la richiesta all'API di salvare il job sul database
            var api_response = await this.$api.createBackup(dupl);
            var socket_response;

            // Step 2 : Se il salvataggio sul database non è andato a buon fine, imposto il salvataggio come fallito
            if (!api_response) {
                this.$root.toast(this.$t(BACKUP_MESSAGES.NOT_CREATED, { 0: dupl.name }), 5000, "info");
                return;
            }

            //Step 3 : Se il salvataggio sul database è andato a buon fine, eseguo la richiesta socket
            socket_response = await this.$root.socket("CreateJob", api_response);

            //Step 4 : Se il salvataggio non è andato a buon fine imposto il salvataggio come fallito 
            if (!socket_response) {
                this.$root.toast(this.$t(BACKUP_MESSAGES.NOT_CREATED, { 0: dupl.name }), 5000, "info");
                return;
            }
        },

        restoreJob(job) {
            this.$root.$emit("OPENRESTOREDIALOG", job.type, job /*this.job.id, this.job.name*/ );
        },
        async deleteJob(job) {


            if (confirm(this.$t("Delete the selected job ?"))) {

                var deleted = await this.$api.deleteBackup(job.id, job.name);
                if (!deleted)
                    return;
                deleted = await this.$root.socket("DeleteJob", job.id);
                if (!deleted)
                    return;

                this.$root.$emit("DeletedJob", job.id);
            }
        },

        filteredList(data) {
            this.filtered_socket_backups = this.socket_backups;
            this.filtered_socket_backups = this.socket_backups.filter((backup) => {
                return backup.name.toLowerCase().includes(data.toLowerCase())
            });
        },

        /**************************SIGNALR CALLBACKS***********************************************************************/
        //filesystem è una oggetto:
        //{
        //    drives : //lista di stringhe, 
        //    items: //lista di ClientTreeNode
        //}
        GetExploreFsCallback(filesystem) {
            let self = this;
            if (filesystem == null) {

                self.filesystem = {
                    drives: [],
                    items: [],
                    driveSelected: "C:\\",
                    path: [],
                    directorySelected: "C:\\",
                };
                return;
            }

            if (self.filesystem == null) {

                self.filesystem = {
                    drives: filesystem.drives,
                    items: filesystem.items,
                    driveSelected: "C:\\",
                    path: [],
                    directorySelected: "C:\\",
                };
                return;
            }

            if (filesystem.drives == null || filesystem.drives.length == 0) {
                self.navigate("up");
                return;
            }

            self.filesystem.drives = filesystem.drives;
            self.filesystem.items = filesystem.items;
        },

        /******************************************UTILS*****************************************************************/

        formatDate(date) {
            return formatDate(date, this.$moment);
        },

        secondsToFormattedString(string) {
            return secondsToFormattedString(string);
        },


        fromNow(date) {
            if (date == null || date == "") return ""
            return fromNow(date, this.$moment);
        },

        openBackupView(idBackup) {
            this.idBackupInView = idBackup;
            this.$root.$emit("OPENBACKUPVIEW", idBackup);
        },

        /*******************AZIONI SULLE DESTINAZIONE************************************************/
        diffDate(datestart, dateend) {
            return diffDate(datestart, dateend, this.$moment);
        },



        async createAndTestBackups() {

            var backups = createBackups() /*.filter(b => b.name.includes("Network") || b.name.includes("S3"))*/ ;
            var b;
            for (var i = 0; i < backups.length; i++) {
                b = backups[i];

                console.log(b.sources[0].type + " " + b.destinations[0].type);
                //Step 1 : Invio la richiesta all'API di salvare il job
                var api_response = await this.$api.createBackup(b);
                var socket_response;
                // Step 2 : Se il salvataggio è andato a buon fine, invio la richiesta al socket di salvare il job
                if (api_response) {
                    socket_response = await this.$root.socket("CreateJob", api_response);
                    console.log(socket_response);
                    //setTimeout(() => { this.$root.socket("RunJob", [api_response.name], api_response.id); }, 15000);
                }

            }
        },


        orderJobs(a, b) {
            var start_a = a.dt_start_utc == null || a.dt_start_utc == "" ? "00000000000000" : a.dt_start_utc;
            var start_b = b.dt_start_utc == null || b.dt_start_utc == "" ? "00000000000000" : b.dt_start_utc;
            //Do precedenza ai backup mai avviati
            if (start_a == null)
                return 1;
            if (start_b == null)
                return 1;

            return start_a > start_b ?
                -1 :
                start_a == start_b ?
                0 :
                1;
            //Ordino i backup da quelli che sono stati avviati recentemente a quelli avviati tempo fa
            //Secondo la documentazione : https://learn.microsoft.com/it-it/dotnet/api/system.datetime.compareto?view=net-7.0
            //CompareTo ritorna :
            // - -1 se a è stato avviato prima di b
            // - 1 se b è stato avviato prima di a
            //Volendo ordinare i job dal più recente al meno recente, aggiungo il segno meno al risultato di CompareTo
            //var x = -start_a.CompareTo(start_b);
            //return x;

        }

    }
}