//const qs = require('querystring');
import { ButtonLoader } from "../components/utils/ButtonLoader.vue"
import { isValid } from "../../public/assets/js/utilitiesmodule";
import api from "../router/api";
import VueI18n from "vue-i18n";
import { LOGIN_FAILED } from "../../public/assets/js/messages";

export default {
    name: "Login",
    data() {
        return {
            userlogin: {
                username: null,
                password: null,
                rememberme: false
            },
        };
    },
    props: {
        msg: String,
        code: String
    },
    beforeRouteLeave(to, from, next) {

        if (this.$root.checkAuth()) {
            next()
        } else {
            next(false)
        }
    },
    beforeDestroy: function() {
        this.$root.$off("socketEventConnected");
        this.$root.$off("socketEventDisconnected");
        //alert("destroy COMPUTER.JS");
        //this.$root.newPage("login", "destroy");
    },
    beforeCreate() {
        console.log(this.$route);
    },
    created: function() {
        this.$session.setRoomPC(null);
        this.$root.socket("SETPC", null);
        //alert("CREATED COMPUTERS.JS");
        //this.$root.newPage("login", "created");
        this.$store.commit('SET_LAYOUT', 'simple-layout');
    },
    mounted: function() {

        /*         if (isValid(this.$route.query.code)) {
                    cookieManager.setCookie("authCode", this.$route.query.code);
                    close();
                }

                //Caso 2 : Durante la procedura di creazione di un service account di tipo 13 (Onedrive),
                //è stata effettuata la richiesta per convalidare i permessi dell'applicazione appena registrata sulla piattaforma di Azure
                if (isValid(this.$route.query.admin_consent) && this.$route.query.admin_consent == "True") {
                    cookieManager.setCookie("admin_consent", this.$route.query.admin_consent == "True");
                    close();
                } */

        //this.$root.newPage("login", "mounted");
        this.$session.setRoomPC(null);

        //Se nella richiesta dell'auth code:
        //response_mode == fragment -> this.$route.hash è una stringa che contiene 'code='
        //response_mode == query -> this.$route.query.code è l'authCode

        if (!isValid(this.$route.query)) return;

        //Caso 1 : Durante la procedura di creazione di un service account di tipo 10 (Google Drive) o di tipo 13 (Onedrive),
        //è stata effettuata la richiesta per l'authorization code.
        if (isValid(this.$route.query.code)) {
            cookieManager.setCookie("authCode", this.$route.query.code);
            close();
        }

        //Caso 2 : Durante la procedura di creazione di un service account di tipo 13 (Onedrive),
        //è stata effettuata la richiesta per convalidare i permessi dell'applicazione appena registrata sulla piattaforma di Azure
        if (isValid(this.$route.query.admin_consent) && this.$route.query.admin_consent == "True") {
            cookieManager.setCookie("admin_consent", this.$route.query.admin_consent == "True");
            close();
        } /* */

        if (this.$root.checkAuth() != null) {
            this.$store.commit('SET_LAYOUT', 'nav-layout')
            this.$router.replace("/dashboard");
        }



    },
    components: {
        ButtonLoader
    },
    methods: {

        async login() {
            let self = this;
            console.log(self.$t);
            var authObj = await api.login(this.userlogin.username, this.userlogin.password, self);

            if (authObj == null) {
                return;
            }

            if (authObj.message == "Require2FA") {
                self.$router.replace("/login2FA");
            } else {

                //authObj.localPublicIP = await this.$api.getLocalPublicIP();
                if (!this.$session.exists()) {
                    this.$session.start();
                }
                if (self.userlogin.rememberme) {
                    localStorage.setItem("AUTH", JSON.stringify(authObj));
                } else {
                    self.$session.setAuth(authObj);
                }

                self.$session.setLicense({ id: authObj.id_master_account, assignee: authObj.nome_licenza });
                self.$store.commit('SET_LAYOUT', 'nav-layout')
                self.$router.replace('/dashboard');

            }



        }
    }
};