<template>
    <div v-on:keyup.esc="$root.$emit('CLOSESOURCEDIALOG')" class="dialog overflow w-100-sm w-100-md w-75-lg w-50-xl h-75 draggable-item" v-cloak>

        <DialogTitle :title="$t('Configure Source')" :defaultTitle="$t('Configure Source')" :subTitle="source.type_name"
            @closeDialog="$root.$emit('CLOSESOURCEDIALOG')" />

        <!--
            E' stata nascosta questa parte. Da non cancellare perchè potresse servire in futuro
            <div class="p-2 h-100" v-show="steps[0]">
                        <h6>{{ $t("Select the type of Drive Image backup you want to configure") }}:</h6>

                        <div class="form-group p-2">
                            <input type="radio" data-role="radio"
                                :data-caption="$t('Iperius Drive Image / Disk Cloning / P2V')"
                                name="driveimage_type" checked><br>
                                <small>{{ $t("Image File Format : VHD/VHDX.") }}</small><br>
                                <small>{{ $t("Hard disk cloning: creates an exact copy of an entire disk drive.")}}</small><br>
                                <small>{{ $t("P2V: it can be used to virtualize phisycal machines. The backup file can be immediately booted as an Hyper-V VM Restore of individual files.")}}</small><br>
                                <small>{{ $t("Restore on different hardware.") }}</small>
                        </div>
                    </div>
                -->
        <div v-show="step == STEP_SELECTDRIVES" class="dialog-content">
            <!--INFOMAZIONI INIZIALI PRIMA DELLA RICHIESTA DEI DISCHI-------------------------------------------------------->

            <div class="form-group d-flex flex-column">
                <h6 class="text-bold">
                    <span class="mif-info icon mr-1" />
                    <span>{{ $t('Initial options') }}</span>
                </h6>
                <input type="checkbox" v-model="includeRemovableDrives" :data-caption="$t('Include removable drives')"
                    data-role="checkbox" :disabled="listDriveStatus == LISTDRIVES_STOPPED && source.driveImgSource != null"/>
                <input type="checkbox" v-model="includeVirtulDisks" :data-caption="$t('Include virtual disks')"
                    data-role="checkbox" :disabled="listDriveStatus == LISTDRIVES_STOPPED && source.driveImgSource != null"/>
            </div>

            <div class="form-group row">
                <button class="button" :disabled="listDriveStatus != LISTDRIVES_NOTSTARTED" @click="getDisks">{{
                    $t("Select disks") }} 
                </button>
                <div v-if="listDriveStatus == LISTDRIVES_STARTED" class="p-2">
                    <span class="mif-spinner2 ani-spin ml-2" />
                </div>
            </div>

            <!--SELEZIONE DEI DISCHI-------------------------------------------------------->
            <NoDataAvailable v-if="listDriveStatus == LISTDRIVES_STOPPED && source.driveImgSource == null"
                :message="$t('No disk available')" class="form-group" />

            <div v-else-if="listDriveStatus == LISTDRIVES_STOPPED && source.driveImgSource != null"
                style="min-width: 49vw;" class="form-group h-50">

                <div v-for="disk in source.driveImgSource" :key="disk.caption">
                    <label class="text-bold"> <span class='mif-drive' /> <span>
                            {{ $t("Disk") }} {{ disk.index }}
                            -
                            {{ disk.caption }} ({{ disk.type }}) [{{ prettyBytes(disk.size) }}]</span></label>
                    <!-------------------------------------------------------------------------------------------------->
                    <ul data-role="treeview" class="p-0">
                        <li class="tree-node d-flex">
                            <input type="checkbox" data-role="checkbox" v-model="disk.selected" />
                            <ul class="d-flex scroll-x">
                                <li v-for="(partition, index) in disk.partitions" :key="disk.index + '-' + index"
                                    class="border bg-white mb-3 ml-2 mr-2 border-1 overflow"
                                    style="min-width: 200px; max-width: 300px">
                                    <div class="content pr-2 ">
                                        <div class="p-1">
                                            <input type="checkbox" v-on:change="checkDrive(disk, partition)"
                                                v-model.lazy="partition.selected" data-role="checkbox"
                                                :data-caption="partition.volumeLabel + ' (' + partition.volumeDriveLetter + ')'"
                                                data-style="1">
                                            <br><small class="reduce-3 m-0" style="white-space: nowrap;">
                                                {{ partition.volumeNotfreeSpace | prettyBytes }}/{{  partition.volumeCapacity | prettyBytes }}
                                            </small>
                                        </div>
                                        <div>
                                            <div data-role="progress" :data-value="partition.volumeSpacePercent"
                                                data-small="true" data-cls-bar="bg-red" data-cls-back="bg-darkGray"
                                                data-role-progress="true" class="progress small pr-3 ml-1 ">
                                                <div class="bar small bg-dark"
                                                    :class="{ 'bg-red': (partition.volumeSpacePercent > 90 && partition.volumeDriveLetter != 'None') }"
                                                    :style="{ width: partition.volumeSpacePercent + '%' }"></div>
                                            </div>
                                        </div>
                                        <div class="pl-2 pr-3">
                                            <span class="reduce-3">
                                                <span
                                                    v-bind:class="{ 'mif-windows': partition.volumeIsWinVolume }"></span>
                                                {{ partition.volumeFileSystem }}
                                            </span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <!-------------------------------------------------------------------------------------------------->
                    <!--<div class="d-flex">
                        <input class='pt-3' v-model='disk.selected' v-on:change="checkAllDrives(disk)" type='checkbox'
                            data-role='checkbox' :data-indeterminate="disk.indeterminate">


                        <div v-for="(partition, index) in disk.partitions" :key="partition.volumeLabel"
                            class="border bg-white mr-1 border-1" :style="{ width: partition.UISize + '%' }">

                            <div class="content pr-2 ">
                                <div class="p-1">
                                    <input type="checkbox" v-on:change="checkDrive(disk, index)"
                                        v-model.lazy="partition.selected" data-role="checkbox"
                                        :data-caption="partition.volumeLabel + ' (' + partition.volumeDriveLetter + ')'"
                                        data-style="1">
                                    <br><small class="reduce-3 m-0" style="white-space: nowrap;">
                                        {{ partition.volumeNotfreeSpace | prettyBytes }}/{{ partition.volumeCapacity |prettyBytes}}</small>
                                </div>
                                <div>
                                    <div data-role="progress" :data-value="partition.volumeSpacePercent" data-small="true"
                                        data-cls-bar="bg-red" data-cls-back="bg-darkGray" data-role-progress="true"
                                        class="progress small pr-3 ml-1 ">
                                        <div class="bar small bg-dark"
                                            :class="{ 'bg-red': (partition.volumeSpacePercent > 90 && partition.volumeDriveLetter != 'None') }"
                                            :style="{ width: partition.volumeSpacePercent + '%' }"></div>
                                    </div>
                                </div>
                                <div class="pl-2 pr-3">
                                    <span class="reduce-3">
                                        <span v-bind:class="{ 'mif-windows': partition.volumeIsWinVolume }"></span>
                                        {{ partition.volumeFileSystem }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>-->
                </div>
            </div>
        </div>



        <div v-show="step == STEP_SELECTOPTIONS" class="dialog-content">
            <!--TIPO DI BACKUP-------------------------------------------------------->
            <div class="form-group">
                <span class="text-bold">
                    <span class="mif-file-upload icon mr-1" />
                    <span>{{ $t('Backup') }}</span>
                </span>
                <div class="d-flex flex-justify-between">
                    
                    <div v-if="cbtStatus != null" class="w-75 p-1">
                        <label>{{ $t("Type") }}:</label>
                        <select v-if="cbtStatus != null" v-model="backupTypeSelected" @change="onBackupTypeChanged" class="select">
                            <option v-for="backupType in $root.selectValues.driveImageBackupTypes"
                                :disabled="backupType.value != 0 && (!cbtStatus.cbtDriverInstalled || !cbtStatus.cbtProviderInstalled)"
                                :value="backupType" :key="backupType.value">{{ $t(backupType.text) }}
                            </option>
                        </select>
                    </div>

                    <div class="w-25 p-1">
                        <label>{{ $t("Number of copies") }}:</label>
                        <input type="text" class="rounded" data-role="spinner" id="nCopiesSpinner" :data-default-value="1" :data-min-value="1"
                            :data-max-value="365" :disabled="backupTypeSelected.value == 1" />
                    </div>
                </div>
            </div>

            <!--OPZIONI-------------------------------------------------------->
            <div class="form-group d-flex flex-column">
                <span class="text-bold">
                    <span class="mif-file-upload icon mr-1" />
                    <span>{{ $t('Options') }}</span>
                </span>

                <input v-model="source.options.createPCNameFolder" type="checkbox" data-role="checkbox"
                    :data-caption="$t('Create folder with computer name')">

                <input v-model="source.options.disableDasdIo" type="checkbox" data-role="checkbox"
                    :data-caption="$t('Enable I/O Boundary Checks by the file system')">

                <input v-model="source.options.resetCBT" type="checkbox" data-role="checkbox"
                    :data-caption="$t('Reset CBT at each backup (a full backup will alwais be performed')">



                <!--<input v-model="source.enableCompression" type="checkbox" data-role="checkbox"
                    :data-caption="$t('Enable Compression')">
                <p>{{ $t("Archive File Custom Name") }} </p>
                <input :disabled="!source.enableCompression" v-model.lazy="source.options.archiveFileCustomName"
                    type="text" data-role="input">
                <label class="text-small text-muted">{{ $t("If blank, default name will be used") }}</label>
                <input :disabled="!source.enableCompression" v-model="source.options.useArchivePassword" type="checkbox"
                    data-role="checkbox" :data-caption="$t('Protect zip with Password')">
                <p>{{ $t("Archive Password") }}</p>
                <input data-role="input" class="metro-input" type="password" v-model="source.options.archivePassword"
                    :placeholder="$t('Enter Password')" data-prepend="<span class='mif-lock'></span>" />
                <small class="text-muted">{{ $t("Required") }}</small>-->
            </div>

            <div v-if="cbtStatus != null" class="form-group d-flex flex-column">
                <span class="text-bold">
                    <span>{{ $t('Status of CBT components (required for incremental and differential backup)') }}</span>
                </span>

                <span class="pt-2">{{ $t('CBT Driver') }} : {{ $t(cbtStatus.cbtDriverInstalled ? "Installed" : "Not installed") }}</span>
                <span class="pt-2">{{ $t('VSS Provider') }} : {{ $t(cbtStatus.cbtProviderInstalled ? "Installed" : "Not installed") }} </span>
                <div class="d-flex  flex-align-center p-4">
                    <button class="button" :disabled="cbtStatus.cbtDriverInstalled && cbtStatus.cbtProviderInstalled"
                        @click="installCBTComponents">{{ $t("Install CBT components (requires reboot)") }} </button>
                    <button class="button" :disabled="!cbtStatus.cbtDriverInstalled && !cbtStatus.cbtProviderInstalled"
                        @click="uninstallCBTComponents">{{ $t("Uninstall CBT components (requires reboot)") }} </button>
                </div>
            </div>
        </div>

        <div class="dialog-actions">
            <button class="button alert" @click="$root.$emit('CLOSESOURCEDIALOG')">{{ $t("Cancel") }}</button>
            <button class="button" :disabled="step == STEP_SELECTDRIVES" @click="step -= 1"><span class="mif-arrow-left" /><span>{{ $t("Prev") }}</span></button>
            <button class="button" :disabled="step == STEP_SELECTOPTIONS || source.driveImgSource == null || source.driveImgSource.every(disk => !disk.selected && !disk.indeterminate)" @click="step += 1">
                <span>{{ $t("Next") }}</span> <span class="mif-arrow-right" />
            </button>
            <button class="button primary" :disabled="step == STEP_SELECTDRIVES" @click="saveSource">{{ $t("Save")}}</button>
        </div>
    </div>
</template>
<script>
import DialogTitle from "../../utils/DialogTitle";
import { isValid, prettyBytes, syncronizeArray } from "../../../../public/assets/js/utilitiesmodule";
import NoDataAvailable from "../../utils/NoDataAvailable.vue";
import { DISK_MESSAGES } from "../../../../public/assets/js/messages";
import { waitSeconds } from "../../../../public/assets/js/utilitiesmodule";

import { draggableMixin } from '../../../mixins/draggable-mixin'


export default {

    mixins: [draggableMixin],
    name: "DriveImageSource",
    props: {
        source: {
            type: Object,
            required: true
        }
    },
    filters: {
        prettyBytes: function (bytes, precision) {
            return prettyBytes(bytes, precision);
        }
    },
    components: {
        "DialogTitle": DialogTitle,
        NoDataAvailable
    },
    data() {
        return {
            step: 0,
            STEP_SELECTDRIVES: 0,
            STEP_SELECTOPTIONS: 1,

            LISTDRIVES_NOTSTARTED: -1,
            LISTDRIVES_STARTED: 0,
            LISTDRIVES_STOPPED: 1,

            listDriveStatus: -1,

            includeRemovableDrives: true,
            includeVirtulDisks: true,

            visible: false,

            backupTypeSelected: null,
            cbtStatus: null
        }
    },

    created() {

        //Se la sorgente è stata aperta in edit, popolo backupTypeSelected
        if (isValid(this.source.driveImgSource)) {
            this.backupTypeSelected = this.$root.selectValues.driveImageBackupTypes.find(bt => bt.character == this.source.options.backupType);
            this.getDisks();
        }
        else //altrimenti per default la imposto a FULL
            this.backupTypeSelected = this.$root.selectValues.driveImageBackupTypes[0];

        let self = this;
        setTimeout(function () {
            var nCopiesSpinner = window.Metro.get$el("#nCopiesSpinner").data("spinner");
            nCopiesSpinner.val(self.source.options.nCopies);
            nCopiesSpinner = nCopiesSpinner.options;
            nCopiesSpinner.onButtonClick = (x, y, val) => {
                self.source.options.nCopies = y;
            };
            nCopiesSpinner.onArrowClick = (x, y, val) => {
                self.source.options.nCopies = y;
                //self.onChangeNCopies(val);
            };
        }, 500);
    },

    methods: {

        async getDisks() {
            let self = this;

            //Caso 1 : Aperto in edit ma ancora backup non salvato
            if (this.source.id == null && this.source.driveImgSource != null) {
                this.listDriveStatus = this.LISTDRIVES_STOPPED;
                return;
            }


            this.listDriveStatus = this.LISTDRIVES_STARTED;

            var socketResponse = await this.$root.socket("GetDiskInfo", this.includeRemovableDrives, this.includeVirtulDisks, true);
            var newDisks = socketResponse.disks;
            this.cbtStatus = socketResponse.cbt_status;

            this.listDriveStatus = this.LISTDRIVES_STOPPED;

            /*if (newDisks.length != 0) {
                newDisks = this.handleInfoRequestDiskJson(newDisks);
                //newDisks = JSON.stringify(newDisks);
                //newDisks = JSON.parse(newDisks);
            }*/

            //Caso 2 : Aperto in create
            if (this.source.driveImgSource == null || newDisks.length == 0) {
                self.source.driveImgSource = newDisks;
                this.step = this.STEP_SELECTDRIVES;
                return;
            }

            //Caso 3: Si sta modificando una sorgente già creata, modifico i nuovi dischi
            var oldDisks = self.source.driveImgSource;

            //self.source.driveImgSource = this.syncronizeArray(oldDisks, newDisks, 0, null);
            var syncronizationResult = syncronizeArray(0, oldDisks, newDisks);

            self.source.driveImgSource = syncronizationResult.finalArray;

            syncronizationResult.messages.forEach(async messageObj => {
                self.$root.toast(this.$t(messageObj.message, messageObj.args), 3000, "warning");
                await waitSeconds(3);
            });
        },

        checkAllDrives(disk) {
            disk.indeterminate = false;
            disk.partitions.forEach(partition => {
                partition.selected = disk.selected;
            });
        },
        checkDrive(disk, partition) {
            if (partition.volumeIsWinVolume) {
                console.log("is Win");
                disk.partitions.forEach(p => {
                    if (p.volumeIsWinVolume)
                        p.selected = partition.selected;
                })
            }
            disk.selected = disk.partitions.every(p => p.selected);
            disk.indeterminate = disk.selected || disk.partitions.some(p => p.selected);

        },

        onBackupTypeChanged() {
            this.source.options.backupType = this.backupTypeSelected.character;
            this.source.options.isCBT = this.backupTypeSelected.value != 0; // != normale full
            if (this.backupTypeSelected.value == 1) {//FULL patching 
                this.source.options.nCopies = 1;
                var nCopiesSpinner = window.Metro.get$el("#nCopiesSpinner").data("spinner");
                nCopiesSpinner.val(1);
            }

        },

        installCBTComponents() {
            alert("NON IMPLEMENTATO");
        },
        uninstallCBTComponents() {
            alert("NON IMPLEMENTATO");
        },
        saveSource() {
            this.$root.$emit("saveBackupSource", this.source);
        },

        syncronizeArray(oldArray, newArray, level, parentObj) {
            var array = newArray.map(d => { d.old = false; return d }).concat(oldArray.map(d => { d.old = true; return d }));
            var comparator = level == 0 ? "caption" : "volumeDriveLetter";
            array.sort((a, b) => a[comparator] <= b[comparator] ? -1 : 1);


            var a, b;
            for (var i = 0; i < array.length - 1; i++) {
                a = array[i];
                b = array[i + 1];
                console.log(a[comparator] + " " + b[comparator]);

                //Caso 1 : Trovo lo stesso oggetto:
                if (a[comparator] == b[comparator]) {
                    if (a.selected) {
                        b.selected = true;
                        if (level == 0) {
                            b.partitions = b.partitions.map(p => {
                                p.selected = true;
                                return p;
                            });
                        }


                    } else if (a.indeterminate) {
                        b.indeterminate = true;
                        b.partitions = this.syncronizeArray(a.partitions, b.partitions, level + 1, a);
                    }
                    i++;// ho trovato una coppia -> analizzo la prossima coppia
                    newArray.push(b);
                    continue;
                }

                //Caso 2 : Trovo 2 elementi e a è old, è stato cancellato
                if (a.old) {
                    if (level == 0)
                        this.$root.toast(this.$t(DISK_MESSAGES.NOT_FOUND, {0 : a.caption}), 3000, "warning");
                    else
                        this.$root.toast(this.$t(DISK_MESSAGES.NOT_FOUND_PARTITION, {0:a.volumeDriveLetter, 1:parentObj.caption}), 3000, "warning");
                    continue;
                }

                //Caso 3: trovo 2 guid diversi e a non è old, a è un disco nuovo
                if (level == 0)
                    this.$root.toast(this.$t(DISK_MESSAGES.FOUND_NEW, {0 : a.caption}), 3000, "warning");
                else
                    this.$root.toast(this.$t(DISK_MESSAGES.FOUND_NEW_PARTITION, {0:a.volumeDriveLetter, 1:parentObj.caption}), 3000, "warning");
                newArray.push(a);
            }

            return newArray;

        },
        handleInfoRequestDiskJson(disksArray) {

            return disksArray.map(d => {
                d.NofPartitions = d.nofPartitions;
                d.partitions = d.partitions.map(p => {
                    p.indeterminate = false;

                    if (p.volumeDriveLetter != undefined) {
                        p.GptPartitionStyle = p.gptPartitionStyle;
                        p.StartingOffset = p.startingOffset;
                    } else {
                        p.volumeLabel = p.volumeLabel != undefined && p.volumeLabel != ""
                            ? p.volumeLabel
                            : p.gptPartitionStyle;
                        p.volumeDriveLetter = "None";
                        p.volumeFreeSpace = p.volumeFreeSpace != undefined ? p.volumeFreeSpace : 0;
                        p.volumeCapacity = p.partitionLength;
                        p.volumeIsWinVolume = "";
                        p.volumeFileSystem = "";
                    }
                    p.volumeNotfreeSpace = p.volumeCapacity - p.volumeFreeSpace;
                    p.volumeSpacePercent = (p.volumeNotfreeSpace * 100) / p.volumeCapacity;
                    p.UISize = Math.round(100 / d.size * p.volumeCapacity) < 15 ? 15 : Math.round(100 / d.size * p.volumeCapacity);

                    return p;

                });

                return d;
            });

            /* var disks = [];
            // //creo un oggetto per ogni Disco 
            // //var disksArray = res;
            for (var i = 0; i < disksArray.length; i++) {
                var disk = {};
                disk.index = disksArray[i].index;
                disk.guid = disksArray[i].guid;
                disk.type = disksArray[i].type;
                disk.caption = disksArray[i].caption;
                disk.size = disksArray[i].size;
                disk.selected = disksArray[i].selected;
                disk.NofPartitions = disksArray[i].nofPartitions;
                var partitions = disksArray[i].partitions;
                disk.partitions = [];
                for (var j = 0; j < partitions.length; j++) {
                    var partition = {};
                    if (partitions[j].volumeDriveLetter != undefined) {
                        partition.selected = partitions[j].selected;
                        partition.indeterminate = false;
                        partition.partitionNumber = partitions[j].partitionNumber;
                        partition.volumeDeviceID = partitions[j].volumeDeviceID;
                        partition.volumeLabel = partitions[j].volumeLabel;
                        partition.volumeDriveLetter = partitions[j].volumeDriveLetter;
                        partition.volumeFreeSpace = partitions[j].volumeFreeSpace;
                        partition.volumeCapacity = partitions[j].volumeCapacity;
                        partition.volumeNotfreeSpace = partition.volumeCapacity - partition.volumeFreeSpace;
                        partition.volumeSpacePercent = (partition.volumeNotfreeSpace * 100) / partition.volumeCapacity;
                        partition.volumeIsWinVolume = partitions[j].volumeIsWinVolume;
                        partition.volumeFileSystem = partitions[j].volumeFileSystem;
                        partition.partitionLength = partitions[j].partitionLength;
                        partition.GptPartitionStyle = partitions[j].gptPartitionStyle;
                        partition.StartingOffset = partitions[j].startingOffset;
                        partition.UISize = Math.round(100 / disk.size * partition.volumeCapacity) < 15 ? 15 : Math.round(100 / disk.size * partition.volumeCapacity);
                        disk.partitions.push(partition);

                    } else {
                        partition.selected = partitions[j].selected;
                        partition.indeterminate = false;
                        partition.partitionNumber = partitions[j].partitionNumber;
                        partition.volumeDeviceID = partitions[j].volumeDeviceID;
                        partition.volumeLabel = partitions[j].volumeLabel != undefined && partitions[j].volumeLabel != "" ? partitions[j].volumeLabel : partitions[j].gptPartitionStyle;
                        partition.volumeDriveLetter = "None";
                        partition.volumeFreeSpace = partitions[j].volumeFreeSpace != undefined ? partitions[j].volumeFreeSpace : 0;
                        partition.volumeCapacity = partitions[j].partitionLength;
                        partition.partitionLength = partitions[j].partitionLength;
                        partition.volumeNotfreeSpace = partition.volumeCapacity - partition.volumeFreeSpace;
                        partition.volumeSpacePercent = (partition.volumeNotfreeSpace * 100) / partition.volumeCapacity;
                        partition.volumeIsWinVolume = "";
                        partition.volumeFileSystem = "";
                        partition.GptPartitionStyle = partitions[j].GptPartitionStyle;
                        partition.StartingOffset = partitions[j].StartingOffset;
                        partition.UISize = Math.round(100 / disk.size * partition.volumeCapacity) < 15 ? 15 : Math.round(100 / disk.size * partition.volumeCapacity);
                        disk.partitions.push(partition);
                    }
                }


                disks.push(disk);
            }
            return disks; */
        },
        prettyBytes: function (bytes, precision) {
            return prettyBytes(bytes, precision);
        },

    },
}
</script>