<template>
    <span>
        <span v-if="isrunning" class="fg-black" :title="$t('Running')"> 
            <span v-if="progress!=null || isrunning" class="text-bold fg-dark">
                <div class="text-center">{{ progress }}%</div>
                <div data-role="progress" data-cls-buffer="bg-dark" data-cls-back="bg-dark" data-type="load" :data-value="progress" :data-buffer="100" data-small="true"></div>
            </span>  
        </span>
        <span v-else-if="lastresult == $root.ResultValue.OK" class="fg-green text-bold mif-checkmark" :title="$t('Ok')"></span>
        <span v-else-if="lastresult == $root.ResultValue.SomeWarnings" class="fg-orange text-bold mif-warning" :title="$t('Some Warnings')"></span>
        <span v-else-if="lastresult == $root.ResultValue.Error" class="fg-red text-bold mif-cross-light" :title="$t('Error')"></span>
        
        <span v-else-if="lastresult == $root.ResultValue.Aborted" class="fg-red text-bold mif-checkmark" :title="$t('Aborted by user')"></span>
        <span v-else-if="lastresult == $root.ResultValue.NeverExecuted"  :title="$t('Never executed')"> </span>
        <span v-else-if="lastresult == $root.ResultValue.TerminatedAbnormally" class="fg-violet text-bold mif-cross-light" :title="$t('Abnormally terminated')"></span>
    </span>
</template>
<script>
export default {
    name: "BackupLastResult",
    props: {
        lastresult: {
            type: Number,
            required: true
        },
        progress: {
            type: Number,
            required: false
        },
        isrunning: {
            type: Boolean,
            required: false
        }
    }

}
</script>