import Navigation from "../components/utils/Navigation";
export default {
    name: "Storage",
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Storage Accounts',
        // all titles will be injected into this template
        titleTemplate: '%s | Iperius One '
    },
    data() {
        return {

            loader: false,


        }
    },


    props: {},
    mounted: function() {},
    created: function() {
        //this.$store.commit('SET_LAYOUT', 'nav-layout')
    },
    components: {

        Navigation,
    },
    methods: {

        goBack() {
            window.history.length > 1 ? this.$router.go(-1) : this.$router.replace('/')
        },
        getName() {
            return this.auth = this.$root.checkAuth().firstName + " " + this.$root.checkAuth().lastName;
        },


        async getRoles() {
            let self = this;
            var roles = await this.$api.getRoles();
            self.$store.commit('SET_ROLES', roles);
        }
    }
};