var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "h-100 w-100 overflow" },
    [
      _c(
        "div",
        {
          attrs: {
            "data-role": "charms",
            id: "accountselected",
            "data-position": "left",
            "data-cls-charms": "bg-white fg-dark border-right bd-gray ",
          },
          on: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              return _vm.closeCharm()
            },
          },
        },
        [
          _vm.accountselected
            ? _c("div", { staticClass: "info-box" }, [
                _c("span", {
                  staticClass: "button square closer",
                  on: {
                    click: function ($event) {
                      return _vm.closeCharm()
                    },
                  },
                }),
                _c(
                  "div",
                  { staticClass: "info-box-content" },
                  [
                    _vm.accountselected != null
                      ? _c("SARepoView", {
                          attrs: {
                            repository: null,
                            serviceAccount: _vm.accountselected,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
      _c("Navigation", { attrs: { pageName: "accounts" } }),
      _vm.serviceAccounts == null
        ? _c("div", { staticClass: "d-flex flex-justify-center p-10" }, [
            _c("span", {
              staticClass: "mif-spinner2 fg-primary ani-spin mif-3x",
            }),
          ])
        : _c(
            "div",
            {},
            [
              _c(
                "ul",
                {
                  staticClass: "bg-white border m-4 p-2 rounded",
                  attrs: { "data-role": "listview", "data-view": "icons" },
                },
                _vm._l(_vm.$root.getServiceAccountsTypes(), function (t) {
                  return t == _vm.$root.PlatformsTypesEnum.Cloud
                    ? _c("li", {
                        attrs: {
                          "data-icon": "<span class='mif-cloud fg-black'>",
                          "data-caption": "Cloud",
                          id: "cloud_toggle",
                        },
                      })
                    : t == _vm.$root.PlatformsTypesEnum.Microsoft
                    ? _c("li", {
                        attrs: {
                          "data-icon": _vm.$root.platformsTypes[t].icon,
                          "data-caption": _vm.$root.platformsTypes[t].name,
                          id: "microsoft_toggle",
                        },
                      })
                    : t == _vm.$root.PlatformsTypesEnum.Email
                    ? _c("li", {
                        attrs: {
                          "data-icon": _vm.$root.platformsTypes[t].icon,
                          "data-caption": _vm.$root.platformsTypes[t].name,
                          id: "email_toggle",
                        },
                      })
                    : _c("li", {
                        attrs: {
                          disabled:
                            _vm.$root.platformsTypes[t].needSocketConnection &&
                            _vm.$session.noPCConnectedToSocket(),
                          "data-icon": _vm.$root.platformsTypes[t].icon,
                          "data-caption": _vm.$root.platformsTypes[t].name,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$root.$emit(
                              "OPENSERVICEACCOUNTDIALOG",
                              t
                            )
                          },
                        },
                      })
                }),
                0
              ),
              _c(
                "div",
                {
                  staticClass: "pl-8 pb-8",
                  attrs: {
                    "data-role": "dropdown",
                    "data-toggle-element": "#cloud_toggle",
                  },
                },
                [
                  _c(
                    "ul",
                    {
                      attrs: { "data-role": "listview", "data-view": "tiles" },
                    },
                    _vm._l(_vm.$root.getCloudTypes(), function (t) {
                      return _c("li", {
                        key: t,
                        attrs: {
                          "data-icon": _vm.platformsTypes[t].icon,
                          "data-caption": _vm.platformsTypes[t].name,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$root.$emit(
                              "OPENSERVICEACCOUNTDIALOG",
                              t
                            )
                          },
                        },
                      })
                    }),
                    0
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "pl-8 pb-8",
                  attrs: {
                    "data-role": "dropdown",
                    "data-toggle-element": "#microsoft_toggle",
                  },
                },
                [
                  _c(
                    "ul",
                    {
                      attrs: { "data-role": "listview", "data-view": "tiles" },
                    },
                    _vm._l(_vm.$root.getMicrosoftTypes(), function (t) {
                      return _c("li", {
                        key: t,
                        attrs: {
                          "data-icon": _vm.platformsTypes[t].icon,
                          "data-caption": _vm.platformsTypes[t].name,
                          disabled:
                            _vm.platformsTypes[t].needSocketConnection &&
                            _vm.$session.noPCConnectedToSocket(),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$root.$emit(
                              "OPENSERVICEACCOUNTDIALOG",
                              t
                            )
                          },
                        },
                      })
                    }),
                    0
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "pl-8 pb-8",
                  attrs: {
                    "data-role": "dropdown",
                    "data-toggle-element": "#email_toggle",
                  },
                },
                [
                  _c(
                    "ul",
                    {
                      attrs: { "data-role": "listview", "data-view": "tiles" },
                    },
                    _vm._l(_vm.$root.getEmailTypes(), function (t) {
                      return _c("li", {
                        key: t,
                        attrs: {
                          "data-icon": _vm.platformsTypes[t].icon,
                          "data-caption": _vm.platformsTypes[t].name,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$root.$emit(
                              "OPENSERVICEACCOUNTDIALOG",
                              t
                            )
                          },
                        },
                      })
                    }),
                    0
                  ),
                ]
              ),
              _vm.serviceAccounts == null
                ? _c("div", { staticClass: "d-flex flex-justify-center" }, [
                    _c("span", {
                      staticClass: "mif-spinner2 fg-primary ani-spin mif-3x",
                    }),
                  ])
                : _vm._e(),
              _vm.serviceAccounts.length == 0
                ? _c("NoDataAvailable", {
                    attrs: { message: _vm.$t("No accounts available") },
                  })
                : _c("div", { staticClass: "pl-4 pr-4" }, [
                    _c(
                      "table",
                      {
                        staticClass:
                          "table border striped small m-0 compact bg-white",
                        attrs: {
                          "data-check": false,
                          "data-check-name": "chkBox",
                          "data-table-search-title": _vm.$t(
                            "Search saved credentials"
                          ),
                          "data-cls-search": "w-100-sm w-25-xl w-25-lg w-50-md",
                          "data-cell-wrapper": "false",
                          "data-role": "table",
                          "data-rows": "20",
                          "data-show-rows-steps": "false",
                          "data-show-pagination": "true",
                          "data-horizontal-scroll": "true",
                        },
                      },
                      [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { staticClass: "sortable-column" }, [
                              _vm._v(_vm._s(_vm.$t("Name"))),
                            ]),
                            _c("th", { staticClass: "sortable-column" }, [
                              _vm._v(_vm._s(_vm.$t("Type"))),
                            ]),
                            _c("th", { staticClass: "w-10" }),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          _vm._l(
                            _vm.serviceAccounts,
                            function (serviceAccount, index) {
                              return _c("tr", { key: serviceAccount.id }, [
                                _c("td", { staticClass: "text-left" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "button flat-button",
                                      attrs: { href: "#" + serviceAccount.id },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(serviceAccount.name) + " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("td", [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "badge inline bg-cobalt fg-white p-1 mr-1",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.platformsTypes[
                                              serviceAccount.type
                                            ].name.toUpperCase()
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("td", [
                                  _c(
                                    "div",
                                    { staticClass: "no-wrap pos-relative" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "button dropdown-toggle",
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "mif-menu text-bold",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "ul",
                                        {
                                          staticClass:
                                            "d-menu border drop-left bg-white",
                                          attrs: { "data-role": "dropdown" },
                                        },
                                        [
                                          _c(
                                            "li",
                                            {
                                              staticClass:
                                                "bg-lightcobalt fg-white",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href:
                                                      "#" + serviceAccount.id,
                                                    title: _vm.$t("Edit"),
                                                  },
                                                },
                                                [
                                                  _c("span", {
                                                    staticClass:
                                                      "mif-pencil icon mr-2",
                                                  }),
                                                  _vm._v(
                                                    _vm._s(_vm.$t("Edit")) + " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("li", [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    "#details:" +
                                                    serviceAccount.id,
                                                  title: _vm.$t("Details"),
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass:
                                                    "mif-eye icon mr-2",
                                                }),
                                                _vm._v(
                                                  _vm._s(_vm.$t("Details")) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("li", [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    "#delete:" +
                                                    serviceAccount.id,
                                                  title: _vm.$t("Delete"),
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass:
                                                    "mif-bin icon mr-2",
                                                }),
                                                _vm._v(
                                                  _vm._s(_vm.$t("Delete")) + " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ])
                            }
                          ),
                          0
                        ),
                      ]
                    ),
                  ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }