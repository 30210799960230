var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "bg-white charms fg-dark right-side w-100 h-100 m-0 no-overflow",
      attrs: { "data-role": "charms", id: "plugin", "data-position": "right" },
    },
    [
      _vm._m(0),
      _c("div", { staticClass: "m-7 card-content" }, [
        _c("h3", [
          _c("span", {
            staticClass: "badge inline mif-add bg-cobalt fg-white",
          }),
          _vm._v(" " + _vm._s(_vm.$t("License")) + " "),
        ]),
        _c("form", { staticClass: "row", attrs: { novalidate: "" } }, [
          _c("div", { staticClass: "col-8 col-md-8 col-lg-8 p-8" }, [
            _c("h3", { staticClass: "mb-4" }, [
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("Select the workloads you want to protect")) +
                    " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "block-wrapper mb-4" }, [
              _c("div", { staticClass: "row d-flex small-gutters mb-4" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "form-group col-12 col-md-4 col-lg-4 pr-lg-0 flex-self-end",
                  },
                  [
                    _vm._m(1),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.lazy.number",
                          value: _vm.license.num_vms,
                          expression: "license.num_vms",
                          modifiers: { lazy: true, number: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "number",
                        "data-role": "spinner",
                        "data-min-value": "0",
                        "data-max-value": "300",
                        id: "vmware",
                      },
                      domProps: { value: _vm.license.num_vms },
                      on: {
                        change: function ($event) {
                          _vm.$set(
                            _vm.license,
                            "num_vms",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "form-group col-12 col-md-4 col-lg-4 pr-lg-0 flex-self-end",
                  },
                  [
                    _c("label", { staticClass: "text-bold" }, [
                      _c("p", { staticClass: "mb-0" }, [
                        _vm._v(" " + _vm._s(_vm.$t("Server")) + " "),
                        _c("span", {
                          staticClass: "cic-icon cic-icon-info",
                          attrs: { placement: "top" },
                        }),
                      ]),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.lazy.number",
                          value: _vm.license.num_servers,
                          expression: "license.num_servers",
                          modifiers: { lazy: true, number: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "number",
                        "data-role": "spinner",
                        "data-min-value": "0",
                        "data-max-value": "200",
                        id: "physicalServer",
                      },
                      domProps: { value: _vm.license.num_servers },
                      on: {
                        change: function ($event) {
                          _vm.$set(
                            _vm.license,
                            "num_servers",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "form-group col-12 col-md-4 col-lg-4 pr-lg-0 flex-self-end",
                  },
                  [
                    _c("label", { staticClass: "text-bold" }, [
                      _c("p", { staticClass: "mb-0" }, [
                        _vm._v(" " + _vm._s(_vm.$t("Workstation")) + " "),
                        _c("span", {
                          staticClass: "mif-info",
                          attrs: {
                            "data-role": "hint",
                            "data-hint-position": "top",
                            "data-hint-text": "This is a hinted button",
                          },
                        }),
                        _c("span", {
                          staticClass: "cic-icon cic-icon-info",
                          attrs: { placement: "top" },
                        }),
                      ]),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.lazy.number",
                          value: _vm.license.num_workstations,
                          expression: "license.num_workstations",
                          modifiers: { lazy: true, number: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "number",
                        "data-role": "spinner",
                        "data-min-value": "0",
                        "data-max-value": "200",
                        id: "workstation",
                      },
                      domProps: { value: _vm.license.num_workstations },
                      on: {
                        change: function ($event) {
                          _vm.$set(
                            _vm.license,
                            "num_workstations",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]
                ),
                _vm._m(2),
              ]),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.lazy.number",
                      value: _vm.license.plugin_database,
                      expression: "license.plugin_database",
                      modifiers: { lazy: true, number: true },
                    },
                  ],
                  attrs: {
                    type: "checkbox",
                    "data-role": "switch",
                    "true-value": "1",
                    "false-value": "0",
                    "data-caption": _vm.$t(
                      "Backup Exchange On-Premises / Exchange 365"
                    ),
                  },
                  domProps: {
                    checked: Array.isArray(_vm.license.plugin_database)
                      ? _vm._i(_vm.license.plugin_database, null) > -1
                      : _vm._q(_vm.license.plugin_database, "1"),
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.license.plugin_database,
                        $$el = $event.target,
                        $$c = $$el.checked ? "1" : "0"
                      if (Array.isArray($$a)) {
                        var $$v = _vm._n(null),
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.license,
                              "plugin_database",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.license,
                              "plugin_database",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.license, "plugin_database", $$c)
                      }
                    },
                  },
                }),
                _c("br"),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.lazy.number",
                      value: _vm.license.plugin_exchange,
                      expression: "license.plugin_exchange",
                      modifiers: { lazy: true, number: true },
                    },
                  ],
                  attrs: {
                    type: "checkbox",
                    "data-role": "switch",
                    "true-value": "1",
                    "false-value": "0",
                    "data-caption": _vm.$t("Backup Database (SQL Server)"),
                  },
                  domProps: {
                    checked: Array.isArray(_vm.license.plugin_exchange)
                      ? _vm._i(_vm.license.plugin_exchange, null) > -1
                      : _vm._q(_vm.license.plugin_exchange, "1"),
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.license.plugin_exchange,
                        $$el = $event.target,
                        $$c = $$el.checked ? "1" : "0"
                      if (Array.isArray($$a)) {
                        var $$v = _vm._n(null),
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.license,
                              "plugin_exchange",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.license,
                              "plugin_exchange",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.license, "plugin_exchange", $$c)
                      }
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "block-wrapper mb-5 mt-5" }, [
              _c("h3", [_vm._v(_vm._s(_vm.$t("Subscription period")))]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 col-lg-10 mb-1" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.lazy.number",
                        value: _vm.license.subscription_duration,
                        expression: "license.subscription_duration",
                        modifiers: { lazy: true, number: true },
                      },
                    ],
                    staticClass: "ultra-thin cycle-marker",
                    attrs: {
                      "data-role": "slider",
                      "data-hint-mask": "$1 anni",
                      "data-hint-always": "true",
                      "data-show-min-max": "true",
                      "data-min": "1",
                      "data-max": "5",
                      "data-hint-position": "top",
                      "data-cls-complete": "bg-cobalt",
                      "data-cls-marker": "bg-cobalt rounded",
                      "data-cls-hint": "bg-cobalt fg-white enlarge",
                      "data-accuracy": "1",
                      id: "slider",
                    },
                    domProps: { value: _vm.license.subscription_duration },
                    on: {
                      change: function ($event) {
                        _vm.$set(
                          _vm.license,
                          "subscription_duration",
                          _vm._n($event.target.value)
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "col-12 text-muted" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "Multi-year contracts may include additional discounts."
                        )
                      ) +
                      " "
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm.pricing
            ? _c(
                "div",
                {
                  staticClass:
                    "col-4 col-md-4 p-8 bg-cobalt fg-white border-radius-6 shadow-1",
                },
                [
                  _c("div", [
                    _c("h4", { staticClass: "mb-3" }, [
                      _vm._v(_vm._s(_vm.pricing.description)),
                    ]),
                    _c("div", { staticClass: "mb-4" }, [
                      _c("p", { staticClass: "mb-0 text-muted" }, [
                        _vm._v(" - "),
                        _c("span", { staticClass: "fg-white text-bold" }, [
                          _vm._v(
                            _vm._s(_vm.pricing.subscription_duration) + " "
                          ),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.pricing.subscription_duration == 1
                                ? _vm.$t("year")
                                : _vm.$t("years")
                            ) +
                              " " +
                              _vm._s(_vm.$t("subscription"))
                          ),
                        ]),
                      ]),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.pricing.num_vms != 0,
                              expression: "pricing.num_vms != 0",
                            },
                          ],
                          staticClass: "mb-0 text-muted",
                        },
                        [
                          _vm._v("- "),
                          _c("span", { staticClass: "fg-white text-bold" }, [
                            _vm._v(_vm._s(_vm.pricing.num_vms) + " "),
                          ]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("Iperius VM License")) + " "),
                          ]),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.pricing.num_servers != 0,
                              expression: "pricing.num_servers != 0",
                            },
                          ],
                          staticClass: "mb-0 text-muted",
                        },
                        [
                          _vm._v("- "),
                          _c("span", { staticClass: "fg-white text-bold" }, [
                            _vm._v(_vm._s(_vm.pricing.num_servers) + " "),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("Iperius Server License")) + " "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.pricing.num_workstations != 0,
                              expression: "pricing.num_workstations != 0",
                            },
                          ],
                          staticClass: "mb-0 text-muted",
                        },
                        [
                          _vm._v("- "),
                          _c("span", { staticClass: "fg-white text-bold" }, [
                            _vm._v(_vm._s(_vm.pricing.num_workstations) + " "),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("Iperius Workstation License")) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.pricing.plugin_exchange != 0,
                              expression: "pricing.plugin_exchange != 0",
                            },
                          ],
                          staticClass: "mb-0 text-muted fadeIn",
                        },
                        [
                          _vm._v("- "),
                          _c("span", { staticClass: "fg-white text-bold" }, [
                            _vm._v(_vm._s(_vm.pricing.plugin_exchange) + " "),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "Iperius Backup for Exchange (On - Premises / 365)"
                                )
                              ) + " "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.pricing.plugin_database != 0,
                              expression: "pricing.plugin_database != 0",
                            },
                          ],
                          staticClass: "mb-0 text-muted",
                        },
                        [
                          _vm._v("- "),
                          _c("span", { staticClass: "fg-white text-bold" }, [
                            _vm._v(_vm._s(_vm.pricing.plugin_database) + " "),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "Iperius Backup for Database (Sql Server)"
                                )
                              ) + " "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "loader-wrapper mt-4 border-top pt-3" },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c("div", { staticClass: "text-muted m-0" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("Total price")) + " "
                                ),
                              ]),
                              _vm.pricing &&
                              _vm.pricing.price &&
                              _vm.pricing.price.price
                                ? _c("h2", { staticClass: "m-0 price mb-0" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.pricing.price.price.toFixed(1)
                                        ) +
                                        " " +
                                        _vm._s(_vm.pricing.price.currency) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "inline-form d-flex flex-align-baseline" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.license.discount_code,
                              expression: "license.discount_code",
                            },
                          ],
                          staticClass:
                            "form-group input mb-4 text-center w-100 text-upper",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("Add discount code here"),
                          },
                          domProps: { value: _vm.license.discount_code },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.license,
                                "discount_code",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c(
                          "button",
                          {
                            staticClass: "button primary",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.getPricing()
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("Apply")) + " ")]
                        ),
                      ]
                    ),
                    _c("div", [
                      _c(
                        "button",
                        {
                          staticClass: "button primary large mr-4 popover",
                          attrs: { type: "button" },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Buy now")) + " ")]
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
        ]),
        _c("footer", { staticClass: "footer p-6" }, [
          _c("div", { staticClass: "footer__row" }, [
            _c("div", [
              _c("div", { staticClass: "row mb-3" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("h3", { staticClass: "mb-4" }, [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.$t("More information")) + " "),
                    ]),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 col-sm-6 col-md-5" }, [
                      _c("p", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://www.iperiusbackup.com/smb-backup-solutions.html?ad=in-text-link",
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "cic-icon cic-icon-link",
                            }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("Small business solutions")) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c("p", [
                        _c("span", { staticClass: "cic-icon cic-icon-link" }),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://www.iperiusbackup.com/backup-software-comparison-for-small-business.html?ad=in-text-link",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("Comparison of competitive features")
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-12 col-sm-6 col-md-5" }, [
                      _c("p", [
                        _c("span", { staticClass: "cic-icon cic-icon-link" }),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://www.iperiusbackup.com/smb-vmware-hyper-v-essentials.html?ad=in-text-link",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("iperiusbackup Backup Essentials")
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c("p", [
                        _c("span", { staticClass: "cic-icon cic-icon-link" }),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://www.iperiusbackup.com/smb-vmware-hyper-v-essentials-download.html",
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("Download the trial")) + " "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "footer__row" }, [
            _c("div", [
              _c("div", { staticClass: "row mb-3" }, [
                _c("div", { staticClass: "col-12" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "The price displayed is based on the current Manufacturer's Suggested Resale Price (MSRP)."
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "col-12" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "Price is not a quote and is provided for informational purposes only. Does not include any local taxes(VAT, GST or sales tax).Iperius Backup sells through channel partners, so contact your partner for detailed pricing and quotes.If you don't have a referral partner you can"
                        )
                      ) +
                      " "
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://www.iperiusbackup.com/it/buy-iperiusbackup-products-pricing.html",
                        target: "_blank",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("find a reseller")))]
                  ),
                  _vm._v(" " + _vm._s(_vm.$t("or contact")) + " "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://www.iperiusbackup.com/it/salesinc.html",
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("the sales department iperiusbackup"))
                      ),
                    ]
                  ),
                  _vm._v(" " + _vm._s(_vm.$t("for assistance.")) + " "),
                ]),
              ]),
            ]),
          ]),
          _vm._m(3),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "#" } }, [
      _c("span", {
        staticClass: "mif-cross-light mif-2x",
        attrs: { onclick: "window.Metro.charms.toggle('#plugin');" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "text-bold" }, [
      _c("p", { staticClass: "mb-0" }, [_vm._v(" VM, VMware, Hyper-V ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "form-group col-12 col-md-4 col-lg-4 pr-lg-0 flex-self-end",
      },
      [
        _c("label", { staticClass: "text-bold" }, [
          _c("p", { staticClass: "mb-0" }, [
            _vm._v(" Storage (FTp/S3) (TB) "),
            _c("span", {
              staticClass: "cic-icon cic-icon-info",
              attrs: { placement: "top" },
            }),
          ]),
        ]),
        _c("input", {
          staticClass: "form-control",
          attrs: {
            type: "number",
            "data-role": "spinner",
            "data-min-value": "0",
            "data-max-value": "200",
            id: "nasPack",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "footer__row bg-light mt-5" }, [
      _c("div", [
        _c("div", { staticClass: "row align-items-center" }, [
          _c(
            "div",
            { staticClass: "col-12 col-md-6 mb-3 mb-md-0 text-muted" },
            [
              _c("span", [
                _vm._v("© 2021 iperiusbackup"),
                _c("sup", [_vm._v("Ⓡ")]),
                _vm._v(" Software"),
              ]),
              _c("span", { staticClass: "mx-2" }, [_vm._v("|")]),
              _c(
                "a",
                {
                  staticClass: "text-muted",
                  attrs: {
                    target: "_blank",
                    href: "https://www.iperiusbackup.com/eula.html",
                  },
                },
                [_vm._v("EULA")]
              ),
              _c("span", { staticClass: "mx-2" }, [_vm._v("|")]),
              _c(
                "a",
                {
                  staticClass: "text-muted",
                  attrs: {
                    target: "_blank",
                    href: "https://www.iperiusbackup.com/privacy-policy.html",
                  },
                },
                [_vm._v("Privacy Policy & Cookie")]
              ),
            ]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }