export const draggableMixin = {
          mounted() {
                    setTimeout(() => {
                              const draggableElements = document.querySelectorAll('.draggable-item');
                              const parentElement = document.querySelector('.shifted-content'); // Div principale

                              draggableElements.forEach(el => {
                                        const header = el.querySelector('.dialog-title');
                                        if (!header) return; // Procedi solo se c'è un header con classe .dialog-title

                                        let offsetX = 0, offsetY = 0, isDragging = false;

                                        /* // Centra il div all'interno del contenitore al caricamento
                                        if (parentElement) {
                                                  const parentRect = parentElement.getBoundingClientRect();
                                                  el.style.left = `${parentRect.left + parentRect.width / 2}px`;
                                                  el.style.top = `${parentRect.top + parentRect.height / 2}px`;
                                                  el.style.transform = 'translate(-50%, -50%)';
                                        } */

                                        // Funzione per centrare l'elemento
                                        const centerElement = () => {
                                                  if (parentElement) {
                                                            const parentRect = parentElement.getBoundingClientRect();
                                                            el.style.left = `${parentRect.left + parentRect.width / 2}px`;
                                                            el.style.top = `${parentRect.top + parentRect.height / 2}px`;
                                                            el.style.transform = 'translate(-50%, -50%)';
                                                  }
                                        };

                                        centerElement();


                                        const onResize = () => {
                                                  if (!isDragging) {
                                                            centerElement();
                                                  }
                                        };
                                        window.addEventListener('resize', onResize);

                                        header.addEventListener('mousedown', (e) => {
                                                  isDragging = true;
                                                  el.classList.add('draggable-on'); // Aggiunge la classe durante il drag
                                                  offsetX = e.clientX - el.getBoundingClientRect().left;
                                                  offsetY = e.clientY - el.getBoundingClientRect().top;
                                                  el.style.zIndex = 1001; // Porta l'elemento in primo piano durante il drag
                                        });

                                        document.addEventListener('mousemove', (e) => {
                                                  if (isDragging && parentElement) {
                                                            const parentRect = parentElement.getBoundingClientRect();
                                                            const elWidth = el.offsetWidth;
                                                            const elHeight = el.offsetHeight;
                                                            let newLeft = e.clientX - offsetX;
                                                            let newTop = e.clientY - offsetY;

                                                            // Limita il movimento all'interno del parent container
                                                            newLeft = Math.max(parentRect.left, Math.min(newLeft, parentRect.right - elWidth));
                                                            newTop = Math.max(parentRect.top, Math.min(newTop, parentRect.bottom - elHeight));

                                                            el.style.left = `${newLeft}px`;
                                                            el.style.top = `${newTop}px`;
                                                            el.style.transform = 'none'; // Disabilita il centering durante il drag
                                                  }
                                        });

                                        document.addEventListener('mouseup', () => {
                                                  isDragging = false;
                                                  el.classList.remove('draggable-on'); // Rimuove la classe dopo il drag
                                                  el.style.zIndex = 1000; // Riporta l'elemento alla posizione originale
                                        });
                              });
                    }, 250); 
          }
};
