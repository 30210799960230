import { platformsTypes } from '../../public/assets/js/utilitiesmodule';
import Navigation from "../components/utils/Navigation";
import NoDataAvailable from '../components/utils/NoDataAvailable';
import SARepoView from '../components/dialogs/view/SARepoView';

export default {
    name: "Accounts",
    data() {
        return {
            serviceAccounts: null,
            selectedType: -1,
            selectedIndex: -1,
            accselected: null,
            accountselected: null,
            computers: [],

            platformsTypes: [],
            microsoftTypes: [],
            emailTypes: [],
        };
    },
    props: {
        msg: String,
    },
    components: {

        NoDataAvailable,
        Navigation,
        SARepoView
    },
    watch: {
        '$route': {
            handler: function(to) {
                if (to.hash == "")
                    return;

                //Si vuole cancellare
                if (to.hash.startsWith("#delete:")) {
                    this.deleteServiceAccount(to.hash.replace("#delete:", ""));
                    return;
                }

                if (to.hash.startsWith("#details:")) {
                    this.$root.$emit("OPENSAREPOVIEW", this.$session.getServiceAccountWithID(to.hash.replace("#details:", "")));
                    return;
                }

                if (to.hash != "") {
                    var idServiceAccount = to.hash.replace("#", "");
                    this.$root.$emit('OPENSERVICEACCOUNTDIALOG', this.$session.getServiceAccountWithID(idServiceAccount))
                }
            },
            deep: true,
            immediate: true
        }
    },
    beforeDestroy: function() {
        this.$root.$off("socketEventConnected");
        this.$root.$off("socketEventDisconnected");
        this.$root.$off("REFRESHSERVICEACCOUNTS");
        this.serviceAccounts = null; // Pulire i dati memorizzati
        this.computers = null;
        //alert("destroy accounts.JS");
        //this.$root.checkConnectionTimerAction("stop");
        //this.$root.newPage("accounts", "destroy");
    },
    created: function() {
        //alert("CREATED COMPUTERS.JS");
        //this.$root.newPage("accounts", "created");
        this.$session.setRoomPC(null);
        this.$root.socket("SETPC", null);
        console.log(this.$session);
        this.serviceAccounts = this.$session.getServiceAccounts();
        let self = this;
        this.$root.$on("REFRESHSERVICEACCOUNTS", () => self.serviceAccounts = self.$session.getServiceAccounts());

        this.platformsTypes = platformsTypes;
        this.microsoftTypes = this.$root.getMicrosoftTypes();
        this.emailTypes = this.$root.getEmailTypes();
        this.$root.$on("socketEventConnected", () => {
            self.$root.socketConnected = true;
            self.$root.checkConnectionTimerLight();
        });
        //"socketEventDisconnected" viene emesso se la connessione socket non è attiva
        this.$root.$on("socketEventDisconnected", () => {
            self.$root.socketConnected = false;
        });
        this.$root.checkSocket();

        this.$root.$on("REFRESHSERVICEACCOUNTS", () => {
            self.serviceAccounts = self.$session.getServiceAccounts();
        });


    },
    mounted: function() {
        //this.$root.newPage("accounts", "mounted");
        this.$session.setRoomPC(null);
        this.computers = this.$session.getPCList();
        this.platformsTypes = platformsTypes;
        var remoteMenu = Metro.getPlugin('#remotemenu', 'dropdown');
        if (remoteMenu)
            remoteMenu.open();

    },

    methods: {
        /***************************************PC********************************/
        checkClick() {
            console.log("checkbox clicked....")
        },

        async deleteServiceAccount(idSA) {
            var self = this;
            var serviceAccount = this.$session.getServiceAccountWithID(idSA);

            var dialogID = Math.floor(100000 + Math.random() * 900000).toString();
            var dialogTitle = this.$t("Delete Service Account \"{0}\"", { 0: serviceAccount.name });
            var dialogContent = this.$t('Are you sure to delete service account \"{0}\"? ', { 0: serviceAccount.name });

            this.$root.$on(dialogID, async ok => {
                if (!ok) {
                    self.$root.$off(dialogID);
                    self.$router.removeHash(); // prototype implementato in main.js
                    return;
                }

                ok = await self.$api.deleteServiceAccount(serviceAccount.id, serviceAccount.name);

                if (!ok) {
                    self.$root.$off(dialogID);
                    self.$router.removeHash(); // prototype implementato in main.js
                    return;
                }
                self.$root.deleteServiceAccount(idSA);
                self.serviceAccounts.splice(self.serviceAccounts.findIndex(sa => sa.id == idSA), 1);
                self.$root.$off(dialogID);
                self.$router.removeHash(); // prototype implementato in main.js
                location.reload();
            });

            //Avvio la richiesta che mi risponderà con un $emit
            this.$root.openYesNoDialog(dialogID, dialogTitle, dialogContent)

        },

        see(sa) {
            this.accountselected = sa;
            window.Metro.charms.toggle("#accountselected");
        },

        closeCharm() {
            window.Metro.charms.toggle("#reposelected");
        }
    },
}