var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.selecteduser
    ? _c(
        "div",
        {
          staticClass:
            "dialog light w-100-sm w-100-md w-75-lg w-50-xl h-75 draggable-item",
          attrs: { id: "user_dialog" },
        },
        [
          _c("div", { staticClass: "dialog-title" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.selecteduser.id == null
                    ? _vm.$t("Create New User")
                    : _vm.selecteduser.username
                ) +
                " "
            ),
            _c("span", {
              staticClass: "button square closer",
              on: {
                click: function ($event) {
                  return _vm.$root.$emit("CLOSEUSERVIEWDIALOG")
                },
              },
            }),
          ]),
          _c("div", { staticClass: "dialog-content overflow" }, [
            _c("form", [
              _c("div", { attrs: { id: "profile" } }, [
                _c("div", { staticClass: "form-group row" }, [
                  _c("label", [_c("b", [_vm._v(_vm._s(_vm.$t("Username")))])]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selecteduser.username,
                        expression: "selecteduser.username",
                      },
                    ],
                    attrs: {
                      "data-role": "input",
                      disabled: _vm.selecteduser.id != null,
                      type: "text",
                      required: "",
                      "data-prepend": "<span class='mif-user'></span>",
                    },
                    domProps: { value: _vm.selecteduser.username },
                    on: {
                      keydown: _vm.$root.blockSpaces,
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.selecteduser,
                          "username",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.$t("Required"))),
                  ]),
                ]),
                _c("div", { staticClass: "form-group row" }, [
                  _c("div", { staticClass: "col-6" }, [
                    _c("label", [
                      _c("b", [_vm._v(_vm._s(_vm.$t("Firstname")))]),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selecteduser.firstname,
                          expression: "selecteduser.firstname",
                        },
                      ],
                      attrs: {
                        "data-role": "input",
                        type: "text",
                        required: "",
                        "data-prepend": "<span class='mif-user'></span>",
                      },
                      domProps: { value: _vm.selecteduser.firstname },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.selecteduser,
                            "firstname",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v(_vm._s(_vm.$t("Required"))),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-6" }, [
                    _c("label", [
                      _c("b", [_vm._v(_vm._s(_vm.$t("Lastname")))]),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selecteduser.lastname,
                          expression: "selecteduser.lastname",
                        },
                      ],
                      attrs: {
                        "data-role": "input",
                        type: "text",
                        required: "",
                        "data-prepend": "<span class='mif-user'></span>",
                      },
                      domProps: { value: _vm.selecteduser.lastname },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.selecteduser,
                            "lastname",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v(_vm._s(_vm.$t("Required"))),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group row pb-1" }, [
                  _c("div", { staticClass: "col-6" }, [
                    _c("label", [_vm._v(_vm._s(_vm.$t("Email")))]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selecteduser.email,
                          expression: "selecteduser.email",
                        },
                      ],
                      attrs: {
                        "data-role": "input",
                        type: "email",
                        required: "",
                        "data-prepend": "<span class='mif-mail'></span>",
                      },
                      domProps: { value: _vm.selecteduser.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.selecteduser,
                            "email",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v(_vm._s(_vm.$t("Required"))),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-6" }, [
                    _c("label", [_vm._v(_vm._s(_vm.$t("User Role")))]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selecteduser.role,
                            expression: "selecteduser.role",
                          },
                        ],
                        staticClass: "select",
                        attrs: {
                          disabled:
                            _vm.selecteduser.id ==
                            _vm.$session.getAuth()?.id_user,
                        },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.selecteduser,
                              "role",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(this.$session.getRoles(), function (role) {
                        return _c(
                          "option",
                          { key: role.id, domProps: { value: role.name } },
                          [_vm._v(" " + _vm._s(role.name) + " ")]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
                _vm.selecteduser.id == null
                  ? _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectAllGroups,
                              expression: "selectAllGroups",
                            },
                          ],
                          attrs: {
                            "data-class": "ml-3",
                            "data-on": "on",
                            "data-off": "off",
                            type: "checkbox",
                            "data-role": "switch",
                            "data-caption-position": "right",
                            "data-caption": _vm.$t("Assign all groups"),
                          },
                          domProps: {
                            checked: Array.isArray(_vm.selectAllGroups)
                              ? _vm._i(_vm.selectAllGroups, null) > -1
                              : _vm.selectAllGroups,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.selectAllGroups,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.selectAllGroups = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.selectAllGroups = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.selectAllGroups = $$c
                              }
                            },
                          },
                        }),
                      ]),
                      _vm.selectAllGroups == false
                        ? _c("div", { staticClass: "col-12" }, [
                            _c("label", [
                              _vm._v(_vm._s(_vm.$t("Allowed Groups"))),
                            ]),
                            _c(
                              "select",
                              {
                                attrs: {
                                  "data-role": "select",
                                  "data-filter": "false",
                                  multiple: "",
                                  "data-cls-selected-item":
                                    "bg-light border pr-2 rounded",
                                  id: "groupselect",
                                  name: "ids_groups",
                                },
                              },
                              _vm._l(
                                _vm.$session.getGroups(),
                                function (group) {
                                  return _c(
                                    "option",
                                    {
                                      key: group.id_group,
                                      domProps: {
                                        selected: _vm.selecteduser.groups.find(
                                          (elem) => elem == group.id_group
                                        ),
                                        value: group.id_group,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(group.name_group) + " "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm.selecteduser.id != null
                  ? _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("label", [_vm._v(_vm._s(_vm.$t("Allowed Groups")))]),
                        _c(
                          "select",
                          {
                            attrs: {
                              "data-role": "select",
                              "data-filter": "false",
                              multiple: "",
                              "data-cls-selected-item":
                                "bg-light border pr-2 rounded",
                              id: "groupselect",
                              name: "ids_groups",
                            },
                          },
                          _vm._l(_vm.$session.getGroups(), function (group) {
                            return _c(
                              "option",
                              {
                                key: group.id_group,
                                domProps: {
                                  selected: _vm.selecteduser.groups.find(
                                    (elem) => elem == group.id_group
                                  ),
                                  value: group.id_group,
                                },
                              },
                              [_vm._v(" " + _vm._s(group.name_group) + " ")]
                            )
                          }),
                          0
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.selecteduser.id != null
                  ? _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("label", [_vm._v(_vm._s(_vm.$t("Password")))]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selecteduser.oldPassword,
                              expression: "selecteduser.oldPassword",
                            },
                          ],
                          attrs: {
                            "data-role": "input",
                            autocomplete: "false",
                            type: "password",
                            required: "",
                            "data-prepend": "<span class='mif-lock'></span>",
                          },
                          domProps: { value: _vm.selecteduser.oldPassword },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.selecteduser,
                                "oldPassword",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "form-group row" }, [
                  _c("div", { staticClass: "col-6" }, [
                    _c("label", [_vm._v(_vm._s(_vm.$t("New Password")))]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selecteduser.password,
                          expression: "selecteduser.password",
                        },
                      ],
                      attrs: {
                        "data-role": "input",
                        autocomplete: "false",
                        type: "password",
                        required: "",
                        "data-prepend": "<span class='mif-lock'></span>",
                      },
                      domProps: { value: _vm.selecteduser.password },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.selecteduser,
                            "password",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v(_vm._s(_vm.$t("Required"))),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-6" }, [
                    _c("label", [_vm._v(_vm._s(_vm.$t("Retype Password")))]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selecteduser.confirmPassword,
                          expression: "selecteduser.confirmPassword",
                        },
                      ],
                      attrs: {
                        "data-role": "input",
                        autocomplete: "false",
                        type: "password",
                        required: "",
                        "data-prepend": "<span class='mif-lock'></span>",
                      },
                      domProps: { value: _vm.selecteduser.confirmPassword },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.selecteduser,
                            "confirmPassword",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v(_vm._s(_vm.$t("Required"))),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group row" }, [
                  _c("div", { staticClass: "p-2" }, [
                    _c("h6", { staticClass: "text-bold" }, [
                      _vm._v(_vm._s(_vm.$t("Set Two Factor Authentication"))),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.startEnabling,
                          expression: "startEnabling",
                        },
                      ],
                      attrs: {
                        "data-class": "ml-3",
                        disabled:
                          this.$root.checkAuth().id_user != _vm.selecteduser.id,
                        "data-on": "on",
                        "data-off": "off",
                        type: "checkbox",
                        "data-role": "switch",
                        "data-caption-position": "right",
                        "data-caption": _vm.$t("TFA on this account"),
                      },
                      domProps: {
                        checked: Array.isArray(_vm.startEnabling)
                          ? _vm._i(_vm.startEnabling, null) > -1
                          : _vm.startEnabling,
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$a = _vm.startEnabling,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.startEnabling = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.startEnabling = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.startEnabling = $$c
                            }
                          },
                          function ($event) {
                            return _vm.toggleTFA()
                          },
                        ],
                      },
                    }),
                  ]),
                  _vm.checkTFA.step1 != null && _vm.checkTFA.step2 == null
                    ? _c("div", [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "cell-md-5 cell-sm-12 text-center" },
                            [
                              _c(
                                "div",
                                { staticClass: "p-6" },
                                [
                                  _c("Qrcode", {
                                    attrs: { value: _vm.checkTFA.step1 },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "cell-md-7" }, [
                            _c("div", { staticClass: "mb-3 pt-4 text-left" }, [
                              _c("label", { staticClass: "h6" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "Scan the QR code on the page using an app such as Google Authenticator, 2 FAS, Microsoft Authenticator, Twilio Authy."
                                      )
                                    )
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "inline-form" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selecteduser.TFAcode,
                                    expression: "selecteduser.TFAcode",
                                  },
                                ],
                                staticClass: "input rounded w-50 p-1",
                                attrs: {
                                  maxlength: "6",
                                  minlength: "6",
                                  type: "text",
                                  placeholder: _vm.$t(
                                    "Insert Code from Authenticator"
                                  ),
                                  required: "",
                                },
                                domProps: { value: _vm.selecteduser.TFAcode },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.selecteduser,
                                      "TFAcode",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c(
                                "button",
                                {
                                  staticClass: "button text-upper primary",
                                  attrs: {
                                    disabled:
                                      _vm.selecteduser.TFAcode == null ||
                                      _vm.selecteduser.TFAcode.length < 6,
                                  },
                                  on: { click: _vm.enableTFA },
                                },
                                [_vm._v(_vm._s(_vm.$t("Enable TFA")))]
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "dialog-actions" }, [
            _c(
              "button",
              { staticClass: "button primary", on: { click: _vm.saveUser } },
              [_vm._v(_vm._s(_vm.$t("Save")))]
            ),
            _vm.selecteduser.id != null
              ? _c(
                  "button",
                  {
                    staticClass: "button error ml-2",
                    attrs: {
                      disabled:
                        _vm.$root.checkAuth().id_user == _vm.selecteduser.id ||
                        _vm.selecteduser.level == "2",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.deleteUser(_vm.selecteduser)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("Delete")))]
                )
              : _vm._e(),
            _c(
              "button",
              {
                staticClass: "button",
                on: {
                  click: function ($event) {
                    return _vm.$root.$emit("CLOSEUSERVIEWDIALOG")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("Cancel")))]
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }