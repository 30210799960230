var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "dialog overflow w-100-sm w-100-md w-75-lg w-50-xl h-75 draggable-item",
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.$root.$emit("CLOSESOURCEDIALOG")
        },
      },
    },
    [
      _c("DialogTitle", {
        attrs: {
          title: _vm.$t("Configure Source"),
          defaultTitle: _vm.$t("Configure Source"),
          subTitle: _vm.source.type_name,
        },
        on: {
          closeDialog: function ($event) {
            return _vm.$root.$emit("CLOSESOURCEDIALOG")
          },
        },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step == _vm.STEP_SELECTDRIVES,
              expression: "step == STEP_SELECTDRIVES",
            },
          ],
          staticClass: "dialog-content",
        },
        [
          _c("div", { staticClass: "form-group d-flex flex-column" }, [
            _c("h6", { staticClass: "text-bold" }, [
              _c("span", { staticClass: "mif-info icon mr-1" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("Initial options")))]),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.includeRemovableDrives,
                  expression: "includeRemovableDrives",
                },
              ],
              attrs: {
                type: "checkbox",
                "data-caption": _vm.$t("Include removable drives"),
                "data-role": "checkbox",
                disabled:
                  _vm.listDriveStatus == _vm.LISTDRIVES_STOPPED &&
                  _vm.source.driveImgSource != null,
              },
              domProps: {
                checked: Array.isArray(_vm.includeRemovableDrives)
                  ? _vm._i(_vm.includeRemovableDrives, null) > -1
                  : _vm.includeRemovableDrives,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.includeRemovableDrives,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        (_vm.includeRemovableDrives = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.includeRemovableDrives = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.includeRemovableDrives = $$c
                  }
                },
              },
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.includeVirtulDisks,
                  expression: "includeVirtulDisks",
                },
              ],
              attrs: {
                type: "checkbox",
                "data-caption": _vm.$t("Include virtual disks"),
                "data-role": "checkbox",
                disabled:
                  _vm.listDriveStatus == _vm.LISTDRIVES_STOPPED &&
                  _vm.source.driveImgSource != null,
              },
              domProps: {
                checked: Array.isArray(_vm.includeVirtulDisks)
                  ? _vm._i(_vm.includeVirtulDisks, null) > -1
                  : _vm.includeVirtulDisks,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.includeVirtulDisks,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.includeVirtulDisks = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.includeVirtulDisks = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.includeVirtulDisks = $$c
                  }
                },
              },
            }),
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "button",
              {
                staticClass: "button",
                attrs: {
                  disabled: _vm.listDriveStatus != _vm.LISTDRIVES_NOTSTARTED,
                },
                on: { click: _vm.getDisks },
              },
              [_vm._v(_vm._s(_vm.$t("Select disks")) + " ")]
            ),
            _vm.listDriveStatus == _vm.LISTDRIVES_STARTED
              ? _c("div", { staticClass: "p-2" }, [
                  _c("span", { staticClass: "mif-spinner2 ani-spin ml-2" }),
                ])
              : _vm._e(),
          ]),
          _vm.listDriveStatus == _vm.LISTDRIVES_STOPPED &&
          _vm.source.driveImgSource == null
            ? _c("NoDataAvailable", {
                staticClass: "form-group",
                attrs: { message: _vm.$t("No disk available") },
              })
            : _vm.listDriveStatus == _vm.LISTDRIVES_STOPPED &&
              _vm.source.driveImgSource != null
            ? _c(
                "div",
                {
                  staticClass: "form-group h-50",
                  staticStyle: { "min-width": "49vw" },
                },
                _vm._l(_vm.source.driveImgSource, function (disk) {
                  return _c("div", { key: disk.caption }, [
                    _c("label", { staticClass: "text-bold" }, [
                      _c("span", { staticClass: "mif-drive" }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("Disk")) +
                            " " +
                            _vm._s(disk.index) +
                            " - " +
                            _vm._s(disk.caption) +
                            " (" +
                            _vm._s(disk.type) +
                            ") [" +
                            _vm._s(_vm.prettyBytes(disk.size)) +
                            "]"
                        ),
                      ]),
                    ]),
                    _c(
                      "ul",
                      {
                        staticClass: "p-0",
                        attrs: { "data-role": "treeview" },
                      },
                      [
                        _c("li", { staticClass: "tree-node d-flex" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: disk.selected,
                                expression: "disk.selected",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              "data-role": "checkbox",
                            },
                            domProps: {
                              checked: Array.isArray(disk.selected)
                                ? _vm._i(disk.selected, null) > -1
                                : disk.selected,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = disk.selected,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        disk,
                                        "selected",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        disk,
                                        "selected",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(disk, "selected", $$c)
                                }
                              },
                            },
                          }),
                          _c(
                            "ul",
                            { staticClass: "d-flex scroll-x" },
                            _vm._l(
                              disk.partitions,
                              function (partition, index) {
                                return _c(
                                  "li",
                                  {
                                    key: disk.index + "-" + index,
                                    staticClass:
                                      "border bg-white mb-3 ml-2 mr-2 border-1 overflow",
                                    staticStyle: {
                                      "min-width": "200px",
                                      "max-width": "300px",
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "content pr-2" }, [
                                      _c("div", { staticClass: "p-1" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model.lazy",
                                              value: partition.selected,
                                              expression: "partition.selected",
                                              modifiers: { lazy: true },
                                            },
                                          ],
                                          attrs: {
                                            type: "checkbox",
                                            "data-role": "checkbox",
                                            "data-caption":
                                              partition.volumeLabel +
                                              " (" +
                                              partition.volumeDriveLetter +
                                              ")",
                                            "data-style": "1",
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              partition.selected
                                            )
                                              ? _vm._i(
                                                  partition.selected,
                                                  null
                                                ) > -1
                                              : partition.selected,
                                          },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$a = partition.selected,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        partition,
                                                        "selected",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        partition,
                                                        "selected",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    partition,
                                                    "selected",
                                                    $$c
                                                  )
                                                }
                                              },
                                              function ($event) {
                                                return _vm.checkDrive(
                                                  disk,
                                                  partition
                                                )
                                              },
                                            ],
                                          },
                                        }),
                                        _c("br"),
                                        _c(
                                          "small",
                                          {
                                            staticClass: "reduce-3 m-0",
                                            staticStyle: {
                                              "white-space": "nowrap",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("prettyBytes")(
                                                    partition.volumeNotfreeSpace
                                                  )
                                                ) +
                                                "/" +
                                                _vm._s(
                                                  _vm._f("prettyBytes")(
                                                    partition.volumeCapacity
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("div", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "progress small pr-3 ml-1",
                                            attrs: {
                                              "data-role": "progress",
                                              "data-value":
                                                partition.volumeSpacePercent,
                                              "data-small": "true",
                                              "data-cls-bar": "bg-red",
                                              "data-cls-back": "bg-darkGray",
                                              "data-role-progress": "true",
                                            },
                                          },
                                          [
                                            _c("div", {
                                              staticClass: "bar small bg-dark",
                                              class: {
                                                "bg-red":
                                                  partition.volumeSpacePercent >
                                                    90 &&
                                                  partition.volumeDriveLetter !=
                                                    "None",
                                              },
                                              style: {
                                                width:
                                                  partition.volumeSpacePercent +
                                                  "%",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                      _c("div", { staticClass: "pl-2 pr-3" }, [
                                        _c(
                                          "span",
                                          { staticClass: "reduce-3" },
                                          [
                                            _c("span", {
                                              class: {
                                                "mif-windows":
                                                  partition.volumeIsWinVolume,
                                              },
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  partition.volumeFileSystem
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]),
                      ]
                    ),
                  ])
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step == _vm.STEP_SELECTOPTIONS,
              expression: "step == STEP_SELECTOPTIONS",
            },
          ],
          staticClass: "dialog-content",
        },
        [
          _c("div", { staticClass: "form-group" }, [
            _c("span", { staticClass: "text-bold" }, [
              _c("span", { staticClass: "mif-file-upload icon mr-1" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("Backup")))]),
            ]),
            _c("div", { staticClass: "d-flex flex-justify-between" }, [
              _vm.cbtStatus != null
                ? _c("div", { staticClass: "w-75 p-1" }, [
                    _c("label", [_vm._v(_vm._s(_vm.$t("Type")) + ":")]),
                    _vm.cbtStatus != null
                      ? _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.backupTypeSelected,
                                expression: "backupTypeSelected",
                              },
                            ],
                            staticClass: "select",
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.backupTypeSelected = $event.target
                                    .multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                _vm.onBackupTypeChanged,
                              ],
                            },
                          },
                          _vm._l(
                            _vm.$root.selectValues.driveImageBackupTypes,
                            function (backupType) {
                              return _c(
                                "option",
                                {
                                  key: backupType.value,
                                  attrs: {
                                    disabled:
                                      backupType.value != 0 &&
                                      (!_vm.cbtStatus.cbtDriverInstalled ||
                                        !_vm.cbtStatus.cbtProviderInstalled),
                                  },
                                  domProps: { value: backupType },
                                },
                                [_vm._v(_vm._s(_vm.$t(backupType.text)) + " ")]
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "w-25 p-1" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("Number of copies")) + ":")]),
                _c("input", {
                  staticClass: "rounded",
                  attrs: {
                    type: "text",
                    "data-role": "spinner",
                    id: "nCopiesSpinner",
                    "data-default-value": 1,
                    "data-min-value": 1,
                    "data-max-value": 365,
                    disabled: _vm.backupTypeSelected.value == 1,
                  },
                }),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "form-group d-flex flex-column" }, [
            _c("span", { staticClass: "text-bold" }, [
              _c("span", { staticClass: "mif-file-upload icon mr-1" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("Options")))]),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.source.options.createPCNameFolder,
                  expression: "source.options.createPCNameFolder",
                },
              ],
              attrs: {
                type: "checkbox",
                "data-role": "checkbox",
                "data-caption": _vm.$t("Create folder with computer name"),
              },
              domProps: {
                checked: Array.isArray(_vm.source.options.createPCNameFolder)
                  ? _vm._i(_vm.source.options.createPCNameFolder, null) > -1
                  : _vm.source.options.createPCNameFolder,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.source.options.createPCNameFolder,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.source.options,
                          "createPCNameFolder",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.source.options,
                          "createPCNameFolder",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.source.options, "createPCNameFolder", $$c)
                  }
                },
              },
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.source.options.disableDasdIo,
                  expression: "source.options.disableDasdIo",
                },
              ],
              attrs: {
                type: "checkbox",
                "data-role": "checkbox",
                "data-caption": _vm.$t(
                  "Enable I/O Boundary Checks by the file system"
                ),
              },
              domProps: {
                checked: Array.isArray(_vm.source.options.disableDasdIo)
                  ? _vm._i(_vm.source.options.disableDasdIo, null) > -1
                  : _vm.source.options.disableDasdIo,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.source.options.disableDasdIo,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.source.options,
                          "disableDasdIo",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.source.options,
                          "disableDasdIo",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.source.options, "disableDasdIo", $$c)
                  }
                },
              },
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.source.options.resetCBT,
                  expression: "source.options.resetCBT",
                },
              ],
              attrs: {
                type: "checkbox",
                "data-role": "checkbox",
                "data-caption": _vm.$t(
                  "Reset CBT at each backup (a full backup will alwais be performed"
                ),
              },
              domProps: {
                checked: Array.isArray(_vm.source.options.resetCBT)
                  ? _vm._i(_vm.source.options.resetCBT, null) > -1
                  : _vm.source.options.resetCBT,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.source.options.resetCBT,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.source.options,
                          "resetCBT",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.source.options,
                          "resetCBT",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.source.options, "resetCBT", $$c)
                  }
                },
              },
            }),
          ]),
          _vm.cbtStatus != null
            ? _c("div", { staticClass: "form-group d-flex flex-column" }, [
                _c("span", { staticClass: "text-bold" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "Status of CBT components (required for incremental and differential backup)"
                        )
                      )
                    ),
                  ]),
                ]),
                _c("span", { staticClass: "pt-2" }, [
                  _vm._v(
                    _vm._s(_vm.$t("CBT Driver")) +
                      " : " +
                      _vm._s(
                        _vm.$t(
                          _vm.cbtStatus.cbtDriverInstalled
                            ? "Installed"
                            : "Not installed"
                        )
                      )
                  ),
                ]),
                _c("span", { staticClass: "pt-2" }, [
                  _vm._v(
                    _vm._s(_vm.$t("VSS Provider")) +
                      " : " +
                      _vm._s(
                        _vm.$t(
                          _vm.cbtStatus.cbtProviderInstalled
                            ? "Installed"
                            : "Not installed"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "d-flex flex-align-center p-4" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      attrs: {
                        disabled:
                          _vm.cbtStatus.cbtDriverInstalled &&
                          _vm.cbtStatus.cbtProviderInstalled,
                      },
                      on: { click: _vm.installCBTComponents },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("Install CBT components (requires reboot)")
                        ) + " "
                      ),
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      attrs: {
                        disabled:
                          !_vm.cbtStatus.cbtDriverInstalled &&
                          !_vm.cbtStatus.cbtProviderInstalled,
                      },
                      on: { click: _vm.uninstallCBTComponents },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("Uninstall CBT components (requires reboot)")
                        ) + " "
                      ),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _c("div", { staticClass: "dialog-actions" }, [
        _c(
          "button",
          {
            staticClass: "button alert",
            on: {
              click: function ($event) {
                return _vm.$root.$emit("CLOSESOURCEDIALOG")
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("Cancel")))]
        ),
        _c(
          "button",
          {
            staticClass: "button",
            attrs: { disabled: _vm.step == _vm.STEP_SELECTDRIVES },
            on: {
              click: function ($event) {
                _vm.step -= 1
              },
            },
          },
          [
            _c("span", { staticClass: "mif-arrow-left" }),
            _c("span", [_vm._v(_vm._s(_vm.$t("Prev")))]),
          ]
        ),
        _c(
          "button",
          {
            staticClass: "button",
            attrs: {
              disabled:
                _vm.step == _vm.STEP_SELECTOPTIONS ||
                _vm.source.driveImgSource == null ||
                _vm.source.driveImgSource.every(
                  (disk) => !disk.selected && !disk.indeterminate
                ),
            },
            on: {
              click: function ($event) {
                _vm.step += 1
              },
            },
          },
          [
            _c("span", [_vm._v(_vm._s(_vm.$t("Next")))]),
            _vm._v(" "),
            _c("span", { staticClass: "mif-arrow-right" }),
          ]
        ),
        _c(
          "button",
          {
            staticClass: "button primary",
            attrs: { disabled: _vm.step == _vm.STEP_SELECTDRIVES },
            on: { click: _vm.saveSource },
          },
          [_vm._v(_vm._s(_vm.$t("Save")))]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }