var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _vm.isrunning
      ? _c(
          "span",
          { staticClass: "fg-black", attrs: { title: _vm.$t("Running") } },
          [
            _vm.progress != null || _vm.isrunning
              ? _c("span", { staticClass: "text-bold fg-dark" }, [
                  _c("div", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.progress) + "%"),
                  ]),
                  _c("div", {
                    attrs: {
                      "data-role": "progress",
                      "data-cls-buffer": "bg-dark",
                      "data-cls-back": "bg-dark",
                      "data-type": "load",
                      "data-value": _vm.progress,
                      "data-buffer": 100,
                      "data-small": "true",
                    },
                  }),
                ])
              : _vm._e(),
          ]
        )
      : _vm.lastresult == _vm.$root.ResultValue.OK
      ? _c("span", {
          staticClass: "fg-green text-bold mif-checkmark",
          attrs: { title: _vm.$t("Ok") },
        })
      : _vm.lastresult == _vm.$root.ResultValue.SomeWarnings
      ? _c("span", {
          staticClass: "fg-orange text-bold mif-warning",
          attrs: { title: _vm.$t("Some Warnings") },
        })
      : _vm.lastresult == _vm.$root.ResultValue.Error
      ? _c("span", {
          staticClass: "fg-red text-bold mif-cross-light",
          attrs: { title: _vm.$t("Error") },
        })
      : _vm.lastresult == _vm.$root.ResultValue.Aborted
      ? _c("span", {
          staticClass: "fg-red text-bold mif-checkmark",
          attrs: { title: _vm.$t("Aborted by user") },
        })
      : _vm.lastresult == _vm.$root.ResultValue.NeverExecuted
      ? _c("span", { attrs: { title: _vm.$t("Never executed") } })
      : _vm.lastresult == _vm.$root.ResultValue.TerminatedAbnormally
      ? _c("span", {
          staticClass: "fg-violet text-bold mif-cross-light",
          attrs: { title: _vm.$t("Abnormally terminated") },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }