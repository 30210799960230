<template>
    <div v-on:keyup.esc="$root.$emit('CLOSERESTOREDIALOG')" class="dialog light w-100-sm w-100-md w-75-lg  w-50-xl h-75"
        v-cloak>

        <DialogTitle :title="printDialogTitle()" :defaultTitle="printDialogTitle()" :subTitle="printDialogSubTitle()"
            @closeDialog="$root.$emit('CLOSERESTOREDIALOG')" />

        <div v-if="source.folderSource" class="dialog-content p-4 overflow">

            <!--SCELTA PERCORSI-->
            <div v-if="steps[0]" class="p-10">
                <div class="row from-group mb-4 w-100 text-center">
                    <label>{{ $t('Choosing folder containing backup') }}</label>
                    <input class="input w-75 mr-2" type="text" v-model="source.folderSource.paths[0].path"
                        v-if="source.folderSource.paths.length!=0" :title="source.folderSource.paths[0].path">
                    <input :placeholder="$t('Choosing folder containing backup')" class="input w-75 mr-2" type="text"
                        v-if="source.folderSource.paths.length==0">
                    <a class="button flat-button " href="#" :title="$t('Click to browse the file system')"
                        v-on:click="toggleFileSystemSidebar()"><span class="mif mif-folder-open "></span></a>
                </div>
                <!-- essendo la selezione di un solo elemento non ha senso inserire una lista sparuta e graficamente orribile-->
                <!-- <NoDataAvailable v-if="!source.folderSource" :message="$t('No item selected')" /> -->
                <!--VISUALIZZAZIONE DEGLI ELEMENTI-->
                <!-- <div v-else class="overflow mt-4 h-vh-33">
                    <div class="cell">
                        <table class="table striped d-table-lg">
                            <tbody>
                                <tr v-for="res in source.folderSource.paths" v-bind:key="res.id">
                                    <td class="d-flex">
                                        <span :class="getMetroIcon(res.type, res.path)" class="px-2" />
                                        <span v-if="source.options.recreateCompletePath" class="flex-self-center">
                                            {{res.path }}</span>
                                        <span v-else class="flex-self-center">
                                            {{ (res.path.indexOf('/') != -1 ? res.path.split('/') :
                                            res.path.split('\\')).at(-1) }}</span>
                                    </td>
                                    <td><span class='mif-bin' @click="$root.$emit('uncheckNode', res)" /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div> -->
            </div>

            <div v-show="steps[1]" class="container-fluid">

                <div v-if="listbackup.length < 1" data-role="activity" data-type="metro" data-style="dark"></div>

                <!--div class="row mt-3" v-if="HyperVBackupDetails.ibvhconfig">
                    <label><b>{{ $t('Virtual Machine') }}: {{ HyperVBackupDetails.ibvhconfig.vmName + ' (' +
                        HyperVBackupDetails.ibvhconfig.vmId + ')' }}</b></label>
                </div-->

                <div class="data" v-if="HyperVBackupDetails.ibvhconfig">
                    <div class="caption">{{ HyperVBackupDetails.ibvhconfig.vmName + ' (' +
                        HyperVBackupDetails.ibvhconfig.vmId + ')' }}</div>
                    <div class="content"><span class="text-muted">{{ $t('Virtual Machine') }}</span></div>
                </div>


                <!--div class="row" v-if="HyperVBackupDetails.ibvhconfig">
                    <label>{{ HyperVBackupDetails.path }} </label>
                </div-->

                <div class="data" v-if="HyperVBackupDetails.ibvhconfig">
                    <div class="caption">{{ HyperVBackupDetails.path }}</div>
                    <div class="content"><span class="text-muted">{{ $t('Path') }}</span></div>
                </div>

                <div id="HyperVBackupList" class="border bd-light p-4 w-100 mb-3 mt-3"
                    style="overflow-y: scroll;overflow-x: hidden;">

                    <!--table data-role="sorter" data-sort-dir="desc" class="table striped text-small " data-show-search="true"
                        data-show-rows-steps="false" data-search-min-length="3" data-search-threshold="300">

                        <thead>
                            <tr>
                                <td>Sel</td>
                                <td data-name="dt_start_utc" data-sortable="true" data-format="int">{{ $t('Date') }}</td>
                                <td data-name="dt_end_utc" data-sortable="true">{{ $t('Value') }}</td>
                                <td data-name="backup_size" data-sortable="true">{{ $t('Size') }}</td>
                                <td data-name="copied_files" data-sortable="true" data-sort-dir="desc">{{ $t('Type') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(lb) in listbackup" :key="lb.numOrder" track-by="log.id">
                                <td>
                                    <input class="ml-5" name="r1" type='radio' data-role='radio'
                                        @click="putBackupInHyperVRestoreModel(lb)">
                                </td>
                                <td>{{ convertDate(lb.dt) }}</td>
                                <td>{{ lb.numOrder.padStart(3, '0') }}</td>
                                <td>{{ lb.dirSize | prettyBytes }}</td>
                                <td>{{ backupTypeToName(lb.backupType, lb.numOrder) }}</td>
                            </tr>
                        </tbody>
                    </table-->
                    <ul v-if="listbackup.length > 0" data-role="listview" data-view="content" data-select-node="true">

                        <li v-for="(lb) in listbackup" :key="lb.numOrder" data-icon="<span class='mif-stack'>"
                            :data-caption="convertDate(lb.dt)"
                            :data-content="`<span class='text-muted'>${ $t('Copy number') + ' <b>' + lb.numOrder.padStart(3, '0') + '</b> ' + $t('Type') + ' <b>' + backupTypeToName(lb.backupType, lb.numOrder) + '</b> ' + $t('Size') + ' <b>' + prettyBytes(lb.dirSize,2) }</b></span>`"
                            @click="putBackupInHyperVRestoreModel(lb)">
                        </li>

                    </ul>
                    <p v-else>{{ $t('No Backup available') }}</p>

                </div>
                <!--div class="row mt-3" v-if="HyperVBackupDetails.ibvhconfig">
                    <label><b>{{ $t('First run') }}: {{ convertDate(HyperVBackupDetails.ibvhconfig.firstExecution)
                    }}</b></label>
                </div-->
                <div class="data" v-if="HyperVBackupDetails.ibvhconfig">
                    <div class="caption">{{ convertDate(HyperVBackupDetails.ibvhconfig.firstExecution)
                        }}</div>
                    <div class="content"><span class="text-muted">{{ $t('First run') }}</span></div>
                </div>

            </div>
            <!-- IMPOSTAZIONE NOME E PERCORSO PER RESTORE-->
            <div v-show="steps[2]" class="container-fluid">

                <div v-if="!HyperVBackupDetails.ibvhconfig" data-role="activity" data-type="metro" data-style="dark">
                </div>

                <div v-if="HyperVBackupDetails.ibvhconfig" class="row mt-4">
                    <b>{{ $t('Select the destination of restore')}}:</b></div>

                <div class="row mt-4" v-if="HyperVBackupDetails.ibvhconfig">
                    <label>{{ $t('Virtual machine name (the name must not already exist)') }}:</label>
                </div>
                <div class="row" v-if="HyperVBackupDetails.ibvhconfig">
                    <input type="text" class="w-50" data-role="textbox" v-model="suggestedRestoredVmName" />
                </div>
                <div class="row mt-4" v-if="HyperVBackupDetails.ibvhconfig">
                    <input type="radio" data-role="radio" id="default" value="Default" v-model="picked"
                        :data-caption="$t('Copy the disk file to the default folder')" />
                </div>
                <div class="row mt-0" v-if="HyperVBackupDetails.ibvhconfig">
                    <input type="radio" data-role="radio" id="custom" value="Custom" v-model="picked"
                        :data-caption="$t('Copy the disk file in the following folder')" />
                </div>
                <div class="row mt-1" v-if="HyperVBackupDetails.ibvhconfig">
                    <div class="cell-8">
                        <input type="text" v-if="customCopyFilePath.length == 0" data-role="input"
                            :class="{ 'disabled': picked != 'Custom' }">
                        <input type="text" v-else data-role="input" v-model="customCopyFilePath[0].path"
                            :class="{ 'disabled': picked != 'Custom' }">
                    </div>
                    <div class="cell-4">
                        <button class="button primary ml-2" :class="{ 'disabled': picked != 'Custom' }"
                            v-on:click="toggleFileSystemSidebar_CustomCopyPath()">{{ $t('Select path') }}</button>
                    </div>
                </div>
                <div class="row mt-4" v-if="HyperVBackupDetails.ibvhconfig">
                    <input type="checkbox" data-role="checkbox" id="newid" name="r1" data-style="1" v-model="newId"
                        :data-caption="$t('Generate new Id if the virtual machine already exists')">
                </div>
            </div>

            <!--div v-show="steps[3]" class="container-fluid">

                <div v-if="HyperVTestVmReport" class="row mt-4"><b>{{ $t('Virtual machine compatibility report') }}:</b>
                </div>

                <div v-if="!scanTest" data-role="activity" data-type="metro" data-style="dark"></div>

                <div class="row mt-3" v-if="!scanTest">
                    <label>{{ $t('Virtual Machine information') }}</label>
                </div>


                <div v-if="scanTest" id="scanTest" class="pb-4 w-100" style="overflow: scroll;">

                    <table data-role="sorter" data-sort-dir="desc" class="table striped text-small " data-show-search="true"
                        data-show-rows-steps="false" data-search-min-length="3" data-search-threshold="300">

                        <thead>
                            <tr>
                                <td data-name="key" data-sortable="true" data-format="int">{{ $t('Key') }}</td>
                                <td data-name="value" data-sortable="true">{{ $t('Value') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(value, key) in scanTest" v-bind:key="key">
                                <td>{{ key }}</td>
                                <td>{{ value }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="row mt-3" v-if="!incompatIssue">
                    <label>{{ $t('Compatibility issue') }}</label>
                </div>


                <div v-if="incompatIssue" id="incompatIssue" class="pb-4 w-100" style="overflow: scroll;">

                    <table data-role="sorter" data-sort-dir="desc" class="table striped text-small " data-show-search="true"
                        data-show-rows-steps="false" data-search-min-length="3" data-search-threshold="300">

                        <thead>
                            <tr>
                                <td data-name="dt_start_utc" data-sortable="true" data-format="int">{{ $t('Issue id') }}</td>
                                <td data-name="dt_end_utc" data-sortable="true">{{ $t('Issue description') }}</td>
                                <td data-name="backup_size" data-sortable="true">{{ $t('Solvability') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(is) in incompatIssue" :key="is.errorId" track-by="log.id">
                                <td>{{ is.errorId }}</td>
                                <td>{{ is.errorMessage }}</td>
                                <td>{{ is.isSolvable }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div-->

            <!-- COMPATIBILITY REPORT E VM INFO-->
            <div v-show="steps[3]" class="container-fluid">

                <div v-if="HyperVTestVmReport" class="row mt-3"><b>{{ $t('Virtual machine compatibility report') }}:</b>
                </div>

                <div class="row mt-3" v-if="scanTest">
                    <label>{{ $t('Virtual Machine information') }}</label>
                </div>

                <div class="w-100 text-center" v-if="!scanTest">
                    <span class="mif-spinner2 ani-spin mif-3x"></span>
                </div>

                <div v-if="scanTest" id="scanTest" class="pb-4 w-100" style="overflow-y: scroll;overflow-x: hidden;">

                    <table data-role="sorter" data-sort-dir="desc" class="table striped text-small "
                        data-show-search="true" data-show-rows-steps="false" data-search-min-length="3"
                        data-search-threshold="300">

                        <thead>
                            <tr>
                                <td data-name="key" data-sortable="true" data-format="int">{{ $t('Key') }}</td>
                                <td data-name="value" data-sortable="true">{{ $t('Value') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(value, key) in scanTest" v-bind:key="key">
                                <td>{{ key }}</td>
                                <td>{{ value }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="row mt-3">
                    <label>{{ $t('Compatibility issue') }}</label>
                </div>

                <div v-if="incompatIssue.length>0" id="incompatIssue" class="pb-4 w-100"
                    style="overflow-y: scroll;overflow-x: hidden;">

                    <table data-role="sorter" data-sort-dir="desc" class="table striped text-small "
                        data-show-search="true" data-show-rows-steps="false" data-search-min-length="3"
                        data-search-threshold="300">

                        <thead>
                            <tr>
                                <td data-name="dt_start_utc" data-sortable="true" data-format="int">{{ $t('Issue id') }}
                                </td>
                                <td data-name="dt_end_utc" data-sortable="true">{{ $t('Issue description') }}</td>
                                <td data-name="backup_size" data-sortable="true">{{ $t('Solvability') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(is) in incompatIssue" :key="is.errorId" track-by="log.id">
                                <td>{{ is.errorId }}</td>
                                <td>{{ is.errorMessage }}</td>
                                <td>{{ is.isSolvable }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row mt-3" v-else>
                    <span class="mif-verified fg-green mif-2x mr-2"></span>
                    <span class="label">{{ $t('No issues found') }}</span>
                </div>
            </div>


            <!--SUMMARY END BEFORE START RESTORE -->
            <!--div v-show="steps[4]" class="container-fluid">

                <div v-if="true" class="row mt-4 mb-2"><b>{{ $t('Summary') }}:</b></div>

                <div class="row mb-2">
                    <div v-if="true" class="cell-3">{{ $t('Server') }}</div>
                    <div v-if="HyperVTestVMCompatibilityModel" class="cell">{{ HyperVTestVMCompatibilityModel.HyperVHost
                    }}-{{ getPCOSFullVersion() }}</div>
                </div>

                <div class="row mb-2">
                    <div class="cell-3">{{ $t('Backup path') }}</div>
                    <div v-if="HyperVBackupDetails" class="cell">{{ HyperVBackupDetails.path }}</div>
                </div>

                <div class="row mb-2">
                    <div class="cell-3">{{ $t('Selected backup') }}</div>
                    <div class="cell">{{ convertDate(selBackupInfo.date) }} - {{ (selBackupInfo.number == 0 ? 'FULL' :
                        selBackupInfo.number.padStart(3, '0')) }} - {{ selBackupInfo.dimensione | prettyBytes }} - {{
        selBackupInfo.tipo }}</div>
                </div>
                <div class="row mb-2">
                    <div class="cell-3">{{ $t('Name of the target VM') }}</div>
                    <div class="cell">{{ suggestedRestoredVmName }}</div>
                </div>
                <div class="row mb-2">
                    <div class="cell-3">{{ $t('Compatibility report') }}</div>
                    <div class="cell" v-if="!canRestore">{{ $t('Not solvable issue') }}</div>
                    <div class="cell" v-else-if="incompatIssue.length > 0">{{ $t('Solvable issue') }}</div>
                    <div class="cell" v-else>{{ $t('Ok') }}</div>
                </div>

            </div-->

            <div v-show="steps[4]" class="container-fluid">

                <div v-if="true" class="row mt-4 mb-2"><b>{{ $t('Summary') }}:</b></div>

                <ul v-if="HyperVTestVMCompatibilityModel.HyperVHost!=''" data-role="listview" data-view="content"
                    data-select-node="false">
                    <li data-icon="<span class='mif-server'>"
                        :data-caption="(HyperVTestVMCompatibilityModel.HyperVHost) + ' on ' + getPCOSFullVersion()"
                        :data-content="`<span class='text-muted'>${$t('Server')}</span>`">
                    </li>
                    <li data-icon="<span class='mif-folder'>"
                        :data-caption="HyperVTestVMCompatibilityModel.VmBackupFolder"
                        :data-content="`<span class='text-muted'>${$t('Backup path')}</span>`">
                    </li>
                    <li data-icon="<span class='mif-stack'>"
                        :data-caption="convertDate(selBackupInfo.date) +' '+ (selBackupInfo.number == 0 ? 'FULL' : selBackupInfo.number.padStart(3, '0')) +' '+ prettyBytes(selBackupInfo.dimensione,2) +' '+ selBackupInfo.tipo"
                        :data-content="`<span class='text-muted'>${$t('Selected backup')}</span>`">
                    </li>
                    <li data-icon="<span class='mif-desktop_windows'>" :data-caption="suggestedRestoredVmName"
                        :data-content="`<span class='text-muted'>${$t('Name of the target VM')}</span>`">
                    </li>
                    <!--li data-icon="<span class='mif-codescan'>"
                        :data-caption="computedCaption"
                        :data-content="`<span class='text-muted'>${$t('Compatibility report')}</span>`">
                    </li-->
                    <!--[TG messo così perchè il :data-caption non sembrerebbe reactive] -->
                    <li class="node">
                        <span class="icon">
                            <span class="mif-codescan"></span>
                        </span>
                        <div class="data">
                            <div class="caption">{{computedCaption}}</div>
                            <div class="content"><span class="text-muted">{{ $t('Compatibility report') }}</span></div>
                        </div>
                    </li>
                </ul>
            </div>

            <div v-if="steps[5] && restore" class="container-fluid">

                <div class="row mt-4 ml-2">
                    <div v-if="[$root.PlatformsTypesEnum.ESXi, $root.PlatformsTypesEnum.HyperV].includes(restore.type)"
                        class="cell-8">
                        <label>{{ restore.vmname }}</label>
                        <br />
                        <!--  <span class='badge inline bg-black fg-white m-2 p-1 w-33'>{{backup.scheduling==null || backup.scheduling==''?"On Demand":"Queued"}}</span> -->
                        <span class='badge inline bg-cobalt fg-white p-1 mt-2 mr-1'>{{
                            restore.type_name
                            }}</span>
                        <span class='badge inline bg-cobalt fg-white p-1 mt-2 mr-1'>{{
                            restore.datacenter
                            }}</span>
                        <span class='badge inline bg-cobalt fg-white p-1 mt-2 mr-1'>n. {{
                            restore.backupnumber
                            }}</span>
                        <span class='badge inline bg-cobalt fg-white p-1 mt-2 mr-1'>{{
                            restore.datastore + '-' + restore.host
                            }}</span>
                    </div>
                    <div v-else class="cell-8">
                        <label>{{ restore.restorename }}</label>
                    </div>
                    <div class="cell-8">
                        <label v-if="restore.dt_start_utc != '' && restore.dt_start_utc != null">
                            {{ restore.dt_start_utc.substr(0, 8) + "T" + restore.dt_start_utc.substr(8) | utcAsLocal |
                            moment("MM/DD/YY hh:mm A") }}</label>
                    </div>
                    <div class="cell-8">
                        <RestoreLastResult :lastresult="restore.lastresult" />
                    </div>
                    <div class="cell-6">
                        <div v-show="restore.progress != null && restore.progress != 0">
                            <div class="clear">
                                <small v-if="restore.current_operation.length <= 50" class="place-left" data-role="hint"
                                    data-hint-position="top" :data-hint-text="restore.current_operation">
                                    {{ restore.current_operation }}</small>
                                <small v-else-if="restore.current_operation <= 100" class="place-left" data-role="hint"
                                    data-hint-position="top" :data-hint-text="restore.current_operation">
                                    {{ restore.current_operation.substr(0, 50) + "..." }}</small>
                                <small v-else class="place-left" data-role="hint" data-hint-position="top"
                                    :data-hint-text="restore.current_operation">
                                    {{restore.current_operation.substr(0,50) + "..." +
                                    restore.current_operation.substr(-50, 50)}}</small>
                            </div>
                            <div data-role="progress" data-type="line" :data-value="restore.progress" data-small="true"
                                data-cls-bar="bg-cobalt" data-role-progress="true" class="progress small">
                                <div class="bar ribbed-cobalt" :style="{ width: restore.progress + '%' }">
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="cell-2">
                        <small v-if="restore.isRunning" class="place-right text-bold enlarge-1">
                            {{restore.progress}}%</small>
                    </div>
                </div>

            </div>

        </div>

        <div class="dialog-actions">
            <div class="ml-auto">
                <button class="button error float-right" @click="$root.$emit('CLOSERESTOREDIALOG')">{{ $t('Close')
                    }}</button>
                <button class="button" :class="{ 'disabled': steps[0] }" @click="back"><span
                        class="mif-arrow-left"></span> {{ $t('Prev') }}</button>
                <button class="button"
                    :class="{ 'disabled': (steps[0] && (!source.folderSource.paths || source.folderSource.paths.length == 0 || source.folderSource.paths[0].path == '')) || (steps[1] && bkpDirSelected == '') || (steps[4]) || (steps[5]) || (steps[3] && !canRestore) }"
                    @click="next">{{ $t('Next') }} <span class="mif-arrow-right"> </span> </button>
                <button class="button primary float-right" v-show="steps[4]" @click="beginRestore"><span
                        class="mif-checkmark pr-2"></span>{{ $t('Restore') }}</button>
            </div>
        </div>

    </div>
</template>
<script>
import DialogTitle from "../../utils/DialogTitle";
import NoDataAvailable from "../../utils/NoDataAvailable.vue";
import { isValid, prettyBytes, getMetroIcon} from '../../../../public/assets/js/utilitiesmodule';
import moment from 'moment'
import RestoreLastResult from "../../utils/RestoreLastResult";

export default {
    name: "HyperVManualRestore",
    props: {
        source: {
            type: Object,
            required: true
        }
    },
    components: {
        "DialogTitle": DialogTitle,
        "NoDataAvailable": NoDataAvailable,
        "RestoreLastResult": RestoreLastResult,
    },
    filters: {
        prettyBytes: function (bytes, precision) {
            return prettyBytes(bytes, precision);
        }
    },

    data() {
        return {
            steps: [1, 0, 0, 0, 0, 0],

            drives: [],
            driveSelected: 'C:\\',


            virtualMachines: [],
            wait: true,
            waitvm: true,
            bkpDirSelected: '',
            checkedNames: [],
            id_service_account: -2,

            go: false,
            justOneGuid: '',

            restores: null, // sochet [GT - 06/06/2023]
            restore: null,


            HyperVBackupDetails: {
                path: ''
            },

            // array con lista delle cartelle dei backup effattuati
            listbackup: [],
            customCopyFilePath: [],
            picked: 'Default',
            newId: true,
            canRestore: false,

            HyperVRestoreModel: {},

            waitHVVMTest: true,
            // struttura dati da inviare per il test di compatibilità della Vm
            HyperVTestVMCompatibilityModel: {
                HyperVHost: '',
                Username: '',
                Password: '',
                VmBackupFolder: '',
                BackupType: '',
                CopyNumber: '',
                VmGuid: '',
                NetAccount: '',
                NetPwd: '',
                ckbNewVmId: '',
                LogFilePath: '',
                ReturnedError: '',
                result: 0,
                status: '',
            },
            HyperVTestVmReport: {},
            scanTest: null,
            incompatIssue: [],

            job: {},
            logs: [],

            dataStore: null,
            esxiselected: null,

            folder: null,

            connection: null,

            pc_isconnected: '',
            portchech: null,
            port: null,
            virtualMachineName: '',
            suggestedRestoredVmName: '',

            selBackupInfo: {
                date: '',
                number: '',
                dimensione: 0,
                tipo: ''
            }



        }
    },
    computed: {
        computedCaption() {
            return !this.canRestore 
            ? this.$t('Not solvable issue') 
            : (this.incompatIssue.length > 0 ? this.$t('Solvable issue') : this.$t('Ok'));
        }
    },
    created: function () {
       
    },

    methods: {

        printDialogTitle() {
            let self = this;
            return self.$t("Configure") + " " + self.source.type_name + " " + self.$t("restore");
        },

        printDialogSubTitle() {
            let self = this;
            return self.source.type_name + " restore";
        },

        prettyBytes: function (bytes, precision) {
            return prettyBytes(bytes, precision);
        },
        
        convertDate(date) {
            date = this.$moment(date.substr(0, 8) + "T" + date.substr(8), "YYYYMMDD HH:mm:ss");
            return this.$moment.utc(date).format("ddd DD/MM/YYYY HH:mm:ss");
        },
        backupTypeToName(tipo, numOrder) {


            if (numOrder == '0') return 'Full';

            switch (tipo.toLowerCase().trim()) {
                case "f":
                    return this.$t('Complete (Full)');
                case "i":
                    return this.$t('Incremental');
                case "d":
                    return this.$t('Differential');
                default:
                    return '';
            }

        },

        /*********************METODI***********************************************/

        putBackupInHyperVRestoreModel(lb) {

            let self = this;

            // se cambio il backup, svuoto la variabile che contiene l'esito del test di compatibilità
            // per permettere la ripetizione del test
            self.HyperVTestVmReport = {};
            self.scanTest = null,
                self.incompatIssue.splice(0, self.incompatIssue.length),


                self.bkpDirSelected = lb.numOrder;

            self.selBackupInfo.date = lb.dt;
            self.selBackupInfo.number = lb.numOrder;
            self.selBackupInfo.dimensione = lb.dirSize;
            self.selBackupInfo.tipo = self.backupTypeToName(self.HyperVBackupDetails.ibvhconfig.backupType, lb.numOrder);

        },

        /* SUBSCRIBE METODO SOCKET PER RESTORE EFFETTIVO*/
        async beginRestore() {
            var next = true;
            let self = this;

            self.go = confirm(this.$t("Restore the selected backup?"));


            if (self.go) {

                // faccio vedere la sezione del progress
                this.steps.forEach((step, index) => {
                    if (step == 1) {
                        if (next) {
                            this.$set(this.steps, index + 1, true)
                            this.$set(this.steps, index, false)
                            next = false;

                        }
                    }
                });

                self.justOneGuid = self.uuidv4();

                self.HyperVRestoreModel.Id = self.justOneGuid
                self.HyperVRestoreModel.ID_LOG = '';
                self.HyperVRestoreModel.ID_JOB = '';
                self.HyperVRestoreModel.VmName = self.suggestedRestoredVmName;
                self.HyperVRestoreModel.VmDestinationFolder = self.HyperVBackupDetails.path;
                self.HyperVRestoreModel.BackupType = self.HyperVBackupDetails.ibvhconfig.backupType;
                self.HyperVRestoreModel.VmGuids = self.HyperVBackupDetails.ibvhconfig.vmId;
                self.HyperVRestoreModel.CopyNumber = (self.selBackupInfo.number == 0 ? 'FULL' : self.bkpDirSelected.padStart(3, '0'));
                self.HyperVRestoreModel.GenerateNewId = self.newId;
                self.HyperVRestoreModel.HyperVHost = 'localhost';
                self.HyperVRestoreModel.Username = '';
                self.HyperVRestoreModel.Password = '';
                if (self.customCopyFilePath.length > 0) {
                    self.HyperVRestoreModel.VhdCustomFolder = self.customCopyFilePath[0].path;
                } else {
                    self.HyperVRestoreModel.VhdCustomFolder = '';
                }
                self.HyperVRestoreModel.type = self.source.type; 
                self.HyperVRestoreModel.type_name = self.source.type;

                console.log("[HyperVManualRestore.beginRestore]");

                this.$root.socket("HYPERV_RUNRESTORE", self.HyperVRestoreModel);

                // invoca agent per dare start ad invio delle informazioni sul restore
                self.idPC = this.$session.getRoomPC().id;
                //###DA INSERIRE IN OGNI COMPONENTE CHE NECESSITA DI CHIAMATE SOCKET###
                // "checkSocket" avvia la connessione socket se ferma
                // "socketEventConnected" viene emesso quando la connessione socket è attiva, quindi nella sua callback si possono avviare tutte le istruzioni che riguardano le chiamate socket
                // "socketEventDisconnected" viene emesso quando la connessione socket non è attiva
                this.$root.$on("socketEventConnected", () => {
                    self.$root.socketConnected = true;
                    self.pc_isconnected = true;
                    self.$root.socket("subscribe_pc", self.idPC).then(() => 
                        self.$root.socket("STARTSENDINFO")
                    );
                });
                this.$root.$on("socketEventDisconnected", () =>{
                    self.$root.socketConnected = false;
                    self.pc_isconnected = false;
                });
                //this.$root.checkSocket();


                // -------------------------------------------------------------------- 


                // subscrive per ricevere il progress del restore ---------------------
                this.$root.$on("addRestoreToPage", restores => {
                    // TODO
                    console.log('Restore in popup');
                    if (restores){
                        console.log(restores);
                        self.restore = restores[self.justOneGuid];
                    }
                    //self.restores = restores;                   

                });
                // --------------------------------------------------------------------                  

            }
        },

        uuidv4() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
                .replace(/[xy]/g, function (c) {
                    const r = Math.random() * 16 | 0,
                        v = c == 'x' ? r : (r & 0x3 | 0x8);
                    return v.toString(16);
                });
        },

        toggleFileSystemSidebar() {

            var config = {
                type: this.$root.PlatformsTypesEnum.FileFolder, // sempre numero 8 per selezionare file/folder
                includeFiles: false,
                multipleChoice: false,
                client: null
            };

            this.$root.$emit(
                "toggleFileSystemSidebar",
                this.source.folderSource.paths,
                config
            );
        },

        toggleFileSystemSidebar_CustomCopyPath() {

            var config = {
                type: this.$root.PlatformsTypesEnum.FileFolder, // sempre numero 8 per selezionare file/folder
                includeFiles: false,
                multipleChoice: false,
                client: null
            };

            this.$root.$emit(
                "toggleFileSystemSidebar",
                this.customCopyFilePath,
                config
            );
        },

        getPCOSFullVersion() {

            var pc = this.$session.getRoomPC();
            if (pc) {
                return pc.os_version_full;
            }

            return '';

        },

        next() {
            var next = true;
            let self = this;

            // ricerca e lettura backup e suo file di configurazione con i dettagli interni
            // richiesta inoltrata all'agent
            if (self.steps[0]) {

                if (self.source.folderSource.paths.length > 0 && self.source.folderSource.paths[0].path != '') {
                    self.getHyperVBackupConfigurationFromFileSystem(self.source.folderSource.paths[0].path);
                    this.$root.$emit("closeFileSystemSidebar");
                }
            }

            // devo valorizzare il campo suggerito per la nuova vm da ripristinare
            if (self.steps[1]) {
                // es: Ubuntu20_restored_23173655
                self.suggestedRestoredVmName = self.HyperVBackupDetails.ibvhconfig.vmName + '_restored_' + moment().format('YYYYMMDDhhmmss');
            }

            // se ho cliccato next su step due dove ho inserito ultimi parametri
            // devo fare il test di compatibilità della VM con HyperV

            if (self.steps[2] && self.HyperVBackupDetails.ibvhconfig && self.suggestedRestoredVmName != '') {
                self.HyperVTestVMCompatibilityModel.HyperVHost = 'localhost';
                self.HyperVTestVMCompatibilityModel.Username = '';
                self.HyperVTestVMCompatibilityModel.Password = '';
                self.HyperVTestVMCompatibilityModel.VmBackupFolder = self.HyperVBackupDetails.path; //+ (self.bkpDirSelected == 0 ? 'FULL_' + self.HyperVBackupDetails.ibvhconfig.vmId : self.bkpDirSelected.padStart(3, '0') + '_' + self.HyperVBackupDetails.ibvhconfig.vmId);
                self.HyperVTestVMCompatibilityModel.BackupType = self.HyperVBackupDetails.ibvhconfig.backupType;
                self.HyperVTestVMCompatibilityModel.CopyNumber = (self.selBackupInfo.number == 0 ? 'FULL' : self.bkpDirSelected.padStart(3, '0'));
                self.HyperVTestVMCompatibilityModel.VmGuid = self.HyperVBackupDetails.ibvhconfig.vmId;
                self.HyperVTestVMCompatibilityModel.NetAccount = '';
                self.HyperVTestVMCompatibilityModel.NetPwd = '';
                self.HyperVTestVMCompatibilityModel.ckbNewVmId = self.newId;
                self.HyperVTestVMCompatibilityModel.LogFilePath = '';
                self.HyperVTestVMCompatibilityModel.ReturnedError = '';
                self.HyperVTestVMCompatibilityModel.result = 0;
                self.HyperVTestVMCompatibilityModel.status = '';

                // variabile per far vedere il progess mentre verifica la compatibilità
                self.aitHVVMTest = true;
                if (!self.HyperVTestVmReport.status) {
                    self.RunCompatibilityTest(self.HyperVTestVMCompatibilityModel);
                }
                self.waitHVVMTest = false;

            }




            this.steps.forEach((step, index) => {
                if (step == 1) {
                    if (next) {
                        this.$set(this.steps, index + 1, true)
                        this.$set(this.steps, index, false)
                        next = false;

                    }
                }
            });

        },

        back() {
            var next = true;
            this.steps.forEach((step, index) => {
                if (step == 1) {
                    if (next) {
                        this.$set(this.steps, index - 1, true)
                        this.$set(this.steps, index, false)
                        next = false;

                    }
                }
            });

        },
        

        // chiamata socket per leggere la configurazione di un backup dal suo percorso
        // come backup software win
        async getHyperVBackupConfigurationFromFileSystem(path) {

            let self = this;

            if (path != '') {
                self.HyperVBackupDetails.path = path;
                var HyperVBkpConfig = await this.$root.socket("HYPERV_CHECKPATHANDGETBACKUPDETAILS", self.HyperVBackupDetails);
                if (HyperVBkpConfig != null) {
                    self.HyperVBackupDetails = HyperVBkpConfig;
                    self.listbackup = self.HyperVBackupDetails.ibvhconfig.listBackup;
                }

                if (self.listbackup.length < 1) {
                    self.steps[0] = true;
                    self.steps[1] = false;
                    alert(self.$t('No valid backups found'), self.$t('Validity check of backup files'), 'warning');

                }

            }

        },

        async RunCompatibilityTest(HVTestModel) {
            let self = this;

            var testres = await this.$root.socket("HYPERV_COMPATIBILITYTEST", HVTestModel);
            if (testres != null) {
                self.HyperVTestVmReport = testres;
                if (self.HyperVTestVmReport.scan && self.HyperVTestVmReport.scan.length > 0) {
                    self.scanTest = self.HyperVTestVmReport.scan[0];
                }

                self.canRestore = true;
                if (testres.incompatibilityModels) {

                    self.incompatIssue = testres.incompatibilityModels;

                    for (let index = 0; index < self.incompatIssue.length; index++) {
                        //console.log('Solvable:' + self.incompatIssue[index].isSolvable);
                        if (!self.incompatIssue[index].isSolvable) { // se c'è almeno un errore non solvable blocco il restore all'utente
                            self.canRestore = false;
                            break;
                        }

                    }

                }

            }

        },

        getMetroIcon(type, path){
                return getMetroIcon(type, path);
            }
    },

    

}
</script>