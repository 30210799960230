var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog light w-100-sm w-100-md w-75-lg w-50-xl h-75",
      attrs: { id: "microsoftsource" },
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.$root.$emit("CLOSESOURCEDIALOG")
        },
      },
    },
    [
      _c("DialogTitle", {
        attrs: {
          title: _vm.$t("Configure Source"),
          defaultTitle: _vm.$t("Configure Source"),
          subTitle: _vm.source.type_name,
        },
        on: {
          closeDialog: function ($event) {
            return _vm.$root.$emit("CLOSESOURCEDIALOG")
          },
        },
      }),
      _vm.step == _vm.STEP_SA_AND_SOURCE
        ? _c(
            "div",
            { staticClass: "dialog-content d-flex flex-column overflow" },
            [
              _c("SARepoSelect", {
                attrs: {
                  type: _vm.$root.getMicrosoftTypes(),
                  idSA: _vm.source.id_service_account,
                  idRepo: null,
                },
                on: { sendSATestResult: _vm.sendSATestResult },
              }),
              _c("SelectedResourcesLayout", {
                staticClass: "flex-grow",
                attrs: {
                  buttonText: _vm.buttonText,
                  singleItem: false,
                  isDisabled: !_vm.testResult.ok,
                  list: _vm.source.folderSource.paths,
                  heightPercentage: 40,
                  canCancel: true,
                },
                on: { clickFunction: _vm.toggleFileSystemSidebar },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step == _vm.STEP_FILTERS,
              expression: "step == STEP_FILTERS",
            },
          ],
          staticClass: "dialog-content",
        },
        [
          _c("div", { staticClass: "form-group" }, [
            _c("h6", { staticClass: "text-bold" }, [
              _c("span", { staticClass: "mif-filter icon mr-1" }),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("Filter by extensions")) +
                    " (" +
                    _vm._s(_vm.$t("Optional")) +
                    ")"
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _c("div", { staticClass: "cell-12 d-flex flex-align-center" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.extensionsFilterAction,
                    expression: "source.options.extensionsFilterAction",
                  },
                ],
                attrs: {
                  type: "checkbox",
                  "data-role": "switch",
                  "data-material": "false",
                  "true-value": 1,
                  "false-value": 0,
                },
                domProps: {
                  checked: Array.isArray(
                    _vm.source.options.extensionsFilterAction
                  )
                    ? _vm._i(_vm.source.options.extensionsFilterAction, null) >
                      -1
                    : _vm._q(_vm.source.options.extensionsFilterAction, 1),
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.source.options.extensionsFilterAction,
                      $$el = $event.target,
                      $$c = $$el.checked ? 1 : 0
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.source.options,
                            "extensionsFilterAction",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.source.options,
                            "extensionsFilterAction",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(
                        _vm.source.options,
                        "extensionsFilterAction",
                        $$c
                      )
                    }
                  },
                },
              }),
              _vm.source.options.extensionsFilterAction == 0
                ? _c("label", [
                    _vm._v(
                      " " + _vm._s(_vm.$t("Exclude specified extensions")) + " "
                    ),
                  ])
                : _c("label", [
                    _vm._v(_vm._s(_vm.$t("Include only specified extensions"))),
                  ]),
            ]),
          ]),
          _vm._m(0),
          _c("div", { staticClass: "form-group" }, [
            _c("h6", { staticClass: "text-bold" }, [
              _c("span", { staticClass: "mif-filter icon mr-1" }),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("Filter by file size")) +
                    " (" +
                    _vm._s(_vm.$t("Optional")) +
                    ")"
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.source.options.useFileSizeFilter,
                  expression: "source.options.useFileSizeFilter",
                },
              ],
              attrs: {
                type: "checkbox",
                "data-caption": _vm.$t("Use file size filter"),
                "data-role": "checkbox",
              },
              domProps: {
                checked: Array.isArray(_vm.source.options.useFileSizeFilter)
                  ? _vm._i(_vm.source.options.useFileSizeFilter, null) > -1
                  : _vm.source.options.useFileSizeFilter,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.source.options.useFileSizeFilter,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.source.options,
                          "useFileSizeFilter",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.source.options,
                          "useFileSizeFilter",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.source.options, "useFileSizeFilter", $$c)
                  }
                },
              },
            }),
            _c("div", { staticClass: "w-100 d-flex flex-align-center" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.source.options.fileSizeFilterAction,
                      expression: "source.options.fileSizeFilterAction",
                    },
                  ],
                  staticClass: "mr-2 h-100",
                  attrs: { disabled: !_vm.source.options.useFileSizeFilter },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.source.options,
                        "fileSizeFilterAction",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(
                  _vm.$root.selectValues.fileSizeFilterAction,
                  function (value, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: index } },
                      [_vm._v(_vm._s(_vm.$t(value)))]
                    )
                  }
                ),
                0
              ),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.source.options.fileSizeFilterCompare,
                      expression: "source.options.fileSizeFilterCompare",
                    },
                  ],
                  staticClass: "mr-2 h-100",
                  attrs: { disabled: !_vm.source.options.useFileSizeFilter },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.source.options,
                        "fileSizeFilterCompare",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(
                  _vm.$root.selectValues.fileSizeFilterCompare,
                  function (value, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: index } },
                      [_vm._v(_vm._s(_vm.$t(value)))]
                    )
                  }
                ),
                0
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.fileSize,
                    expression: "source.options.fileSize",
                  },
                ],
                staticClass: "mr-2",
                staticStyle: { width: "50px" },
                attrs: {
                  type: "number",
                  disabled: !_vm.source.options.useFileSizeFilter,
                },
                domProps: { value: _vm.source.options.fileSize },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.source.options,
                      "fileSize",
                      $event.target.value
                    )
                  },
                },
              }),
              _c("span", [_vm._v(" MB")]),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step == _vm.STEP_BACKUP,
              expression: "step == STEP_BACKUP",
            },
          ],
          staticClass: "dialog-content",
        },
        [
          _c("div", { staticClass: "form-group" }, [
            _c("h6", { staticClass: "text-bold" }, [
              _c("span", { staticClass: "mif-file-upload icon mr-1" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("Backup")))]),
            ]),
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _c("label", [_vm._v(_vm._s(_vm.$t("Type")) + ":")]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.backupType,
                    expression: "source.options.backupType",
                  },
                ],
                attrs: { "data-role": "select", "data-filter": "false" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.source.options,
                      "backupType",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              _vm._l(
                Object.keys(_vm.$root.selectValues.backupTypes),
                function (key) {
                  return _c("option", { key: key, domProps: { value: key } }, [
                    _vm._v(
                      _vm._s(_vm.$t(_vm.$root.selectValues.backupTypes[key]))
                    ),
                  ])
                }
              ),
              0
            ),
          ]),
          _c(
            "div",
            { staticClass: "form-group row d-flex flex-justify-between" },
            [
              _c("label", [_vm._v(_vm._s(_vm.$t("Number of copies")) + ":")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.nCopies,
                    expression: "source.options.nCopies",
                  },
                ],
                attrs: { type: "number", max: "365", "data-role": "input" },
                domProps: { value: _vm.source.options.nCopies },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.source.options, "nCopies", $event.target.value)
                  },
                },
              }),
            ]
          ),
          _c(
            "div",
            { staticClass: "form-group row d-flex flex-justify-between" },
            [
              _c("label", [
                _vm._v(_vm._s(_vm.$t("Number of parallel threads")) + ":"),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.nThreads,
                    expression: "source.options.nThreads",
                  },
                ],
                attrs: {
                  type: "number",
                  min: "1",
                  max: "10",
                  "data-role": "input",
                },
                domProps: { value: _vm.source.options.nThreads },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.source.options,
                      "nThreads",
                      $event.target.value
                    )
                  },
                },
              }),
            ]
          ),
        ]
      ),
      _vm.step == _vm.STEP_COMPRESSION
        ? _c(
            "div",
            {
              staticClass: "dialog-content p-4",
              attrs: { disabled: _vm.source.options.backupType == "U" },
            },
            [
              _c("h6", { staticClass: "text-bold" }, [
                _c("span", { staticClass: "mif-file-zip icon mr-1" }),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("Zip compression")) +
                      " (" +
                      _vm._s(_vm.$t("Optional")) +
                      ")"
                  ),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.source.options.useArchiveCompression,
                      expression: "source.options.useArchiveCompression",
                    },
                  ],
                  attrs: {
                    type: "checkbox",
                    "data-role": "checkbox",
                    "data-caption": _vm.$t("Enable zip compression"),
                  },
                  domProps: {
                    checked: Array.isArray(
                      _vm.source.options.useArchiveCompression
                    )
                      ? _vm._i(_vm.source.options.useArchiveCompression, null) >
                        -1
                      : _vm.source.options.useArchiveCompression,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.source.options.useArchiveCompression,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.source.options,
                              "useArchiveCompression",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.source.options,
                              "useArchiveCompression",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(
                          _vm.source.options,
                          "useArchiveCompression",
                          $$c
                        )
                      }
                    },
                  },
                }),
                _c("br"),
              ]),
              _c(
                "div",
                {
                  staticClass: "form-group d-flex flex-justify-between",
                  attrs: {
                    disabled: !_vm.source.options.useArchiveCompression,
                  },
                },
                [
                  _c("div", [
                    _c("label", [_vm._v(_vm._s(_vm.$t("Archive type")))]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.source.options.archiveType,
                            expression: "source.options.archiveType",
                          },
                        ],
                        staticClass: "input",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.source.options,
                              "archiveType",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(
                        _vm.$root.selectValues.archiveTypes,
                        function (value, index) {
                          return _c(
                            "option",
                            { key: index, domProps: { value: index } },
                            [_vm._v(" " + _vm._s(_vm.$t(value)) + " ")]
                          )
                        }
                      ),
                      0
                    ),
                  ]),
                  _c("div", { staticClass: "d-none" }, [
                    _c("label", [_vm._v(_vm._s(_vm.$t("Paths in archive")))]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.source.options.pathsInArchive,
                            expression: "source.options.pathsInArchive",
                          },
                        ],
                        staticClass: "input",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.source.options,
                              "pathsInArchive",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(
                        _vm.$root.selectValues.pathsInArchive,
                        function (value, index) {
                          return _c(
                            "option",
                            { key: index, domProps: { value: index } },
                            [_vm._v(_vm._s(_vm.$t(value)) + " ")]
                          )
                        }
                      ),
                      0
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "form-group",
                  attrs: {
                    disabled: !_vm.source.options.useArchiveCompression,
                  },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.source.options.useArchiveFileCustomName,
                        expression: "source.options.useArchiveFileCustomName",
                      },
                    ],
                    attrs: { type: "checkbox", "data-role": "checkbox" },
                    domProps: {
                      checked: Array.isArray(
                        _vm.source.options.useArchiveFileCustomName
                      )
                        ? _vm._i(
                            _vm.source.options.useArchiveFileCustomName,
                            null
                          ) > -1
                        : _vm.source.options.useArchiveFileCustomName,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.source.options.useArchiveFileCustomName,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.source.options,
                                "useArchiveFileCustomName",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.source.options,
                                "useArchiveFileCustomName",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(
                            _vm.source.options,
                            "useArchiveFileCustomName",
                            $$c
                          )
                        }
                      },
                    },
                  }),
                  _c("label", [
                    _vm._v(_vm._s(_vm.$t("Give the zip file a custom name"))),
                  ]),
                  _c("br"),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.source.options.archiveFileCustomName,
                        expression: "source.options.archiveFileCustomName",
                      },
                    ],
                    staticClass: "input",
                    attrs: {
                      type: "text",
                      disabled: !_vm.source.options.useArchiveFileCustomName,
                      required: "",
                    },
                    domProps: {
                      value: _vm.source.options.archiveFileCustomName,
                    },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.source.options,
                          "archiveFileCustomName",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "form-group",
                  attrs: {
                    disabled: !_vm.source.options.useArchiveCompression,
                  },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.source.options.useArchivePassword,
                        expression: "source.options.useArchivePassword",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      "data-role": "checkbox",
                      "data-caption": _vm.$t(
                        "Protect zip files using password"
                      ),
                    },
                    domProps: {
                      checked: Array.isArray(
                        _vm.source.options.useArchivePassword
                      )
                        ? _vm._i(_vm.source.options.useArchivePassword, null) >
                          -1
                        : _vm.source.options.useArchivePassword,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.source.options.useArchivePassword,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.source.options,
                                "useArchivePassword",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.source.options,
                                "useArchivePassword",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(
                            _vm.source.options,
                            "useArchivePassword",
                            $$c
                          )
                        }
                      },
                    },
                  }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "form-group",
                  attrs: {
                    disabled:
                      !_vm.source.options.useArchiveCompression ||
                      !_vm.source.options.useArchivePassword,
                  },
                },
                [
                  _c("label", [_vm._v(_vm._s(_vm.$t("Password")) + ":")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.source.options.archivePassword,
                        expression: "source.options.archivePassword",
                      },
                    ],
                    attrs: {
                      "data-role": "input",
                      type: "password",
                      placeholder: _vm.$t("Enter Password"),
                      "data-prepend": "<span class='mif-lock'></span>",
                      disabled:
                        !_vm.source.options.useArchiveCompression ||
                        !_vm.source.options.useArchivePassword,
                    },
                    domProps: { value: _vm.source.options.archivePassword },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.source.options,
                          "archivePassword",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.$t("Required"))),
                  ]),
                  _c("br"),
                  _c("label", [
                    _vm._v(_vm._s(_vm.$t("Protection type")) + ":"),
                  ]),
                  _c("br"),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.source.options.protectionType,
                          expression: "source.options.protectionType",
                        },
                      ],
                      staticClass: "input",
                      attrs: {
                        disabled:
                          !_vm.source.options.useArchiveCompression ||
                          !_vm.source.options.useArchivePassword,
                      },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.source.options,
                            "protectionType",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(
                      _vm.$root.selectValues.protectionTypes,
                      function (value, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: index } },
                          [_vm._v(_vm._s(_vm.$t(value)) + " ")]
                        )
                      }
                    ),
                    0
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "dialog-actions" }, [
        _c(
          "button",
          {
            staticClass: "button primary",
            attrs: { disabled: _vm.step == 0 },
            on: {
              click: function ($event) {
                return _vm.setStep(_vm.step - 1)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("Prev")))]
        ),
        !_vm.showSaveButton
          ? _c(
              "button",
              {
                staticClass: "button primary",
                attrs: {
                  disabled:
                    _vm.step == _vm.STEP_COMPRESSION ||
                    _vm.source.folderSource.paths.length == 0,
                },
                on: {
                  click: function ($event) {
                    return _vm.setStep(_vm.step + 1)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("Next")))]
            )
          : _vm._e(),
        _vm.showSaveButton
          ? _c(
              "button",
              { staticClass: "button primary", on: { click: _vm.saveSource } },
              [_vm._v(_vm._s(_vm.$t("Save")))]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-group row pb-5" }, [
      _c("div", { staticClass: "cell-12 d-flex" }, [
        _c("input", {
          staticClass: "flex-self-center",
          attrs: {
            id: "taginput",
            type: "text",
            "data-role": "tag-input",
            "data-tag-separator": ";",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }