var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog light w-100-sm w-100-md w-75-lg w-50-xl",
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.$root.$emit("CLOSERESTOREDIALOG")
        },
      },
    },
    [
      _c("DialogTitle", {
        attrs: {
          title: _vm.$t("MS Exchange Restore"),
          defaultTitle: _vm.$t("MS Exchange restore"),
          subTitle: _vm.$t("MS Exchange Backups"),
        },
        on: {
          closeDialog: function ($event) {
            return _vm.$root.$emit("CLOSERESTOREDIALOG")
          },
        },
      }),
      _vm.step == _vm.STEP_SELECTBACKUPCOPY
        ? _c(
            "div",
            { staticClass: "dialog-content" },
            [
              _vm.isManual
                ? _c("div", { staticClass: "row h6 text-bold m-4" }, [
                    _c("div", { staticClass: "cell-12" }, [
                      _vm._v(_vm._s(_vm.$t("Choose source type"))),
                    ]),
                  ])
                : _vm._e(),
              _vm.isManual
                ? _c("div", { staticClass: "form-group" }, [
                    _c(
                      "ul",
                      {
                        attrs: {
                          "data-role": "listview",
                          "data-view": "icons",
                          "data-select-node": "true",
                          "data-selectable": "false",
                        },
                      },
                      _vm._l(
                        _vm.platformsTypes[_vm.restore.type]
                          .backupDestinationTypes,
                        function (sourceType) {
                          return _c("li", {
                            key: sourceType,
                            attrs: {
                              "data-icon": _vm.platformsTypes[sourceType].icon,
                              "data-caption":
                                _vm.platformsTypes[sourceType].name,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.setSourceType(sourceType)
                              },
                            },
                          })
                        }
                      ),
                      0
                    ),
                  ])
                : _vm._e(),
              ![-1, _vm.$root.PlatformsTypesEnum.FileFolder].includes(
                _vm.restore.source_type
              )
                ? _c("SARepoSelect", {
                    attrs: {
                      type: _vm.restore.source_type,
                      idSA: _vm.restore.source.id_service_account,
                      idRepo: null,
                    },
                    on: { sendSATestResult: _vm.sendSATestResult },
                  })
                : _vm._e(),
              _vm.isManual
                ? _c("SelectedResourcesLayout", {
                    attrs: {
                      list: _vm.sourceData.selectedNodes,
                      singleItem: true,
                      canCancel: false,
                      buttonText: _vm.$t("Select the folder of a backup copy"),
                      isDisabled:
                        _vm.restore.source_type !=
                          _vm.$root.PlatformsTypesEnum.FileFolder &&
                        _vm.sourceData.client == null,
                      heightPercentage: 30,
                    },
                    on: {
                      clickFunction: function ($event) {
                        return _vm.toggleFileSystemSidebar("CHOOSEBACKUPCOPY")
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.step == _vm.STEP_SELECTRESTOREOPTIONS
        ? _c("div", { staticClass: "dialog-content" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("h6", { staticClass: "text-bold" }, [
                _vm._v(_vm._s(_vm.$t("What is to be recovered?")) + ":"),
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.restore.restoreType,
                      expression: "restore.restoreType",
                    },
                  ],
                  staticClass: "select w-100",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.restore,
                        "restoreType",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { domProps: { value: 1 } }, [
                    _vm._v(_vm._s(_vm.$t("Recreate full copy from backup"))),
                  ]),
                  _c("option", { domProps: { value: 2 } }, [
                    _vm._v(
                      _vm._s(_vm.$t("Recreate a specific copy from backup"))
                    ),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("h6", { staticClass: "text-bold" }, [
                _vm._v(_vm._s(_vm.$t("Select a copy"))),
              ]),
              _c(
                "div",
                { staticClass: "overflow" },
                _vm._l(_vm.fileConfig.iterations, function (iteration) {
                  return _c(
                    "div",
                    { key: iteration.index, staticClass: "d-flex mb-4" },
                    [
                      _c("input", {
                        staticClass: "mr-5",
                        attrs: {
                          name: "iter",
                          type: "radio",
                          "data-role": "radio",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onIterationChanged(iteration)
                          },
                        },
                      }),
                      _c("div", { staticClass: "pr-4" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-justify-between w-100 pr-3",
                          },
                          [
                            _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "badge inline bg-cobalt fg-white p-1",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.platformsTypes[iteration.sourceType]
                                          .name
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("span", {
                                staticClass: "mif-arrow-right mx-1",
                              }),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "badge inline bg-cobalt fg-white p-1",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.platformsTypes[
                                          iteration.destinationType
                                        ].name
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "text-bold" }, [
                              _c("label", { staticClass: "mr-2" }, [
                                _vm._v(_vm._s(_vm.$t("Copy number")) + ":"),
                              ]),
                              _vm._v(
                                _vm._s(
                                  iteration.index +
                                    1 +
                                    " / " +
                                    (iteration.nCopies + 1)
                                )
                              ),
                            ]),
                          ]
                        ),
                        _c("div", [
                          _c("small", [
                            _c("b", [
                              _vm._v(_vm._s(_vm.$t("Backup folder")) + " : "),
                            ]),
                            _vm._v(" " + _vm._s(iteration.iterationFolder)),
                          ]),
                        ]),
                        _c("small", { staticClass: "text-bold" }, [
                          _c("label", { staticClass: "mr-2" }, [
                            _vm._v(_vm._s(_vm.$t("Start date")) + ":"),
                          ]),
                          _vm._v(
                            _vm._s(
                              _vm._f("moment")(
                                _vm._f("utcAsLocal")(iteration.lastStart),
                                "MM/DD/YY hh:mm A"
                              )
                            )
                          ),
                        ]),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step == _vm.STEP_DESTINATION,
              expression: "step == STEP_DESTINATION",
            },
          ],
          staticClass: "dialog-content",
        },
        [
          _c("div", { staticClass: "row h6 text-bold m-4" }, [
            _c("div", { staticClass: "cell-12" }, [
              _vm._v(_vm._s(_vm.$t("Configure destination"))),
            ]),
          ]),
          _c("SARepoSelect", {
            attrs: {
              type: _vm.restore.type,
              idSA: _vm.restore.destination_idSA,
              idRepo: null,
            },
            on: { sendSATestResult: _vm.sendSATestResult },
          }),
          _vm.destinationData.client != null
            ? _c("SelectedResourcesLayout", {
                attrs: {
                  list: _vm.destinationData.selectedNodes,
                  singleItem: false,
                  canCancel: true,
                  buttonText: _vm.$t("Select the mailboxes to restore"),
                  heightPercentage: 0,
                },
                on: {
                  clickFunction: function ($event) {
                    return _vm.toggleFileSystemSidebar("SELECTMAILBOXES")
                  },
                },
              })
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.destinationData.client != null,
                  expression: "destinationData.client != null",
                },
              ],
              staticClass: "form-group row pb-5 w-100",
            },
            [
              _c("label", [_vm._v(_vm._s(_vm.$t("Custom folder")) + ":")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.restore.destination_customFolder,
                    expression: "restore.destination_customFolder",
                  },
                ],
                staticClass: "metro-input",
                attrs: {
                  "data-role": "input",
                  type: "text",
                  placeholder: _vm.$t(
                    "Enter the name of the customised folder"
                  ),
                },
                domProps: { value: _vm.restore.destination_customFolder },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.restore,
                      "destination_customFolder",
                      $event.target.value
                    )
                  },
                },
              }),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(_vm._s(_vm.$t("Required"))),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "dialog-actions" }, [
        _vm.waiting == -1
          ? _c("div", [
              _c("span", {
                staticClass: "mif-spinner2 fg-primary ani-spin ml-5",
              }),
            ])
          : _vm._e(),
        _vm.step != 1 ||
        _vm.restore.source_type != _vm.$root.PlatformsTypesEnum.FileFolder
          ? _c("input", {
              staticClass: "button primary ml-2",
              attrs: {
                disabled: _vm.step == _vm.STEP_SELECTBACKUPCOPY,
                type: "button",
                value: _vm.$t("Prev"),
              },
              on: {
                click: function ($event) {
                  return _vm.setStep(_vm.step - 1)
                },
              },
            })
          : _vm._e(),
        _c("input", {
          staticClass: "button primary ml-2",
          attrs: {
            type: "button",
            value: _vm.$t("Next"),
            disabled: !_vm.enableNextButton,
          },
          on: {
            click: function ($event) {
              return _vm.setStep(_vm.step + 1)
            },
          },
        }),
        _c("input", {
          staticClass: "button primary ml-2",
          attrs: {
            type: "button",
            value: _vm.$t("Start"),
            disabled:
              _vm.step != _vm.STEP_DESTINATION ||
              _vm.destinationData.selectedNodes.length == 0 ||
              _vm.restore.destination_customFolder == "",
          },
          on: { click: _vm.startRestore },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }