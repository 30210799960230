var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Navigation", { attrs: { pageName: "computers" } }),
      _vm.filteredcomputers == null
        ? _c("div", { staticClass: "d-flex flex-justify-center p-10" }, [
            _c("span", {
              staticClass: "mif-spinner2 fg-primary ani-spin mif-3x",
            }),
          ])
        : _vm.filteredcomputers.length == 0
        ? _c("NoDataAvailable", {
            attrs: { message: _vm.$t("No computers available") },
          })
        : _c("div", [
            _c(
              "div",
              { staticClass: "tiles-grid d-flex flex-wrap mx-8 p-8" },
              _vm._l(_vm.filteredcomputers, function (pc) {
                return _c(
                  "div",
                  {
                    key: pc.id,
                    staticClass: "mx-8 rounded",
                    staticStyle: { "max-width": "150px" },
                    attrs: { width: "100" },
                  },
                  [
                    _c(
                      "router-link",
                      {
                        key: pc.id,
                        staticClass: "bg-transparent",
                        attrs: {
                          disabled: !_vm.$session.isConnectedToSocket(pc.id),
                          to: "/computer/" + pc.id,
                          event: _vm.$session.isConnectedToSocket(pc.id)
                            ? "click"
                            : "",
                        },
                      },
                      [
                        _c("p", { staticClass: "icon text-center p-0" }, [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "26",
                                height: "26",
                                fill: "none",
                                viewBox: "0 0 26 26",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M6.75 22a.75.75 0 0 1-.102-1.493l.102-.007h1.749v-2.498H4.25a2.25 2.25 0 0 1-2.245-2.096L2 15.752V5.25a2.25 2.25 0 0 1 2.096-2.245L4.25 3h15.499a2.25 2.25 0 0 1 2.245 2.096l.005.154v10.502a2.25 2.25 0 0 1-2.096 2.245l-.154.005h-4.25V20.5h1.751a.75.75 0 0 1 .102 1.494L17.25 22H6.75Zm7.248-3.998h-4l.001 2.498h4l-.001-2.498Z",
                                  fill: "#212121",
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "badge bg-transparent",
                              class: {
                                "fg-green": _vm.$session.isConnectedToSocket(
                                  pc.id
                                ),
                                "fg-red": !_vm.$session.isConnectedToSocket(
                                  pc.id
                                ),
                              },
                              staticStyle: { "font-size": "30px" },
                            },
                            [_vm._v("•")]
                          ),
                        ]),
                        _c("p", { staticClass: "text-center text-bold p-0" }, [
                          _vm._v(" " + _vm._s(pc.name) + " "),
                        ]),
                      ]
                    ),
                    pc.description
                      ? _c(
                          "p",
                          { staticClass: "text-small text-center p-0 m-0" },
                          [_vm._v(" " + _vm._s(pc.description))]
                        )
                      : _vm._e(),
                    pc.ipaddress_public
                      ? _c(
                          "p",
                          { staticClass: "text-small text-center p-0 m-0" },
                          [_vm._v(" " + _vm._s(pc.ipaddress_public))]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }