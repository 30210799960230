<template>
    <div class="dropdown-button">
        <a class="button" :title="computer.backup_license_status == 2 ? 'Waiting to restart agent' : ''"
            :class="computer.backup_license_status == 2 ? 'fg-white bg-amazon' : ' dropdown-toggle'">
            <div class="text-left">
                <span class="mif-admin_panel_settings mif-3x"></span>
            </div>
        </a>
        <ul class="d-menu border place-right text-center" data-role="dropdown">
            <li v-if="computer.backup_license_status == 2">
                <a class="text-bold" :title="$t('Waiting to restart agent')">
                    {{ $t('Waiting to restart agent') }}
                </a>
            </li>
            <li>
                <a :title="$t('Remove from license')" @click.prevent="$parent.removeFromLicense(computer)"
                    :class="computer.backup_license_status == 1 ? '' : 'fg-gray disabled'">
                    <span class="icon mif-2x mif-link_off"></span> {{ $t('Remove from license') }}
                </a>
            </li>
            <li>
                <a :title="$t('Add to license')" @click.prevent="$parent.reconnectToLicense(computer)"
                    :class="computer.backup_license_status == 3 ? '' : 'fg-gray disabled'">
                    <span class="icon mif-2x mif-link_on"></span> {{ $t('Add to license') }}
                </a>
            </li>


        </ul>
    </div>
</template>
<script>
export default {
    name: "PCLicenseLink",
    props: {
        computer: {
            type: Object,
            required: true
        }
    },
    methods: {

    }
    

}
</script>