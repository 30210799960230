var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _c("div", [
      _vm.singleItem
        ? _c("div", { staticClass: "mb-4" }, [
            _c("label", [_vm._v(_vm._s(_vm.$t(_vm.buttonText)))]),
            _c(
              "div",
              {
                staticClass: "input",
                attrs: {
                  disabled: _vm.isDisabled,
                  title: _vm.list.length == 1 ? _vm.list[0].path : "",
                },
                on: {
                  click: function ($event) {
                    return _vm.$emit("clickFunction")
                  },
                },
              },
              [
                _vm.list.length == 1
                  ? _c("input", {
                      attrs: {
                        type: "text",
                        readonly: "",
                        "data-role": "input",
                        "data-validate": "required",
                        "data-role-input": "true",
                      },
                      domProps: { value: _vm.list[0].path },
                    })
                  : _c("input", {
                      attrs: {
                        type: "text",
                        "data-role": "input",
                        "data-validate": "required",
                        "data-role-input": "true",
                      },
                    }),
                _c("div", { staticClass: "button-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      attrs: { type: "button", disabled: _vm.isDisabled },
                    },
                    [_c("span", { staticClass: "mif-folder" })]
                  ),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      !_vm.singleItem
        ? _c("input", {
            staticClass: "button primary mb-2",
            attrs: {
              type: "button",
              value: _vm.$t(_vm.buttonText),
              disabled: _vm.isDisabled,
            },
            on: {
              click: function ($event) {
                return _vm.$emit("clickFunction")
              },
            },
          })
        : _vm._e(),
    ]),
    !_vm.singleItem && _vm.list.length > 0
      ? _c(
          "div",
          {
            staticClass: "overflow mt-2",
            style: "max-height:" + _vm.height + "px",
          },
          [
            _c(
              "table",
              { staticClass: "table striped compact table-compact" },
              [
                _c(
                  "tbody",
                  _vm._l(_vm.list, function (res) {
                    return _c(
                      "tr",
                      { key: res.id, attrs: { title: res.path } },
                      [
                        _c(
                          "td",
                          { staticClass: "d-flex flex-align-baseline" },
                          [
                            _c("span", { class: _vm.getClass(res) }),
                            _vm._v(" " + _vm._s(res.path.split("?")[0])),
                          ]
                        ),
                        _c("td", [
                          _vm.canCancel
                            ? _c(
                                "a",
                                {
                                  staticClass: "button flat-button",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeNode(res)
                                    },
                                  },
                                },
                                [_c("span", { staticClass: "mif-bin" })]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }