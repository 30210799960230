var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "w-100 h-100 overflow" },
    [
      _vm.selectedcomputer
        ? _c(
            "div",
            {
              attrs: {
                "data-role": "charms",
                id: "computerselected",
                "data-position": "left",
                "data-cls-charms":
                  "bg-light border border-top-bottom fg-dark mt-1 overflow",
              },
            },
            [
              _c("div", { staticClass: "row p-1" }, [
                _c("h4", { staticClass: "m-0 p-2" }, [
                  _vm._v(_vm._s(_vm.selectedcomputer?.name)),
                ]),
                _c("div", { staticClass: "cell d-flex flex-justify-end" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button bg-transparent",
                      on: {
                        click: function ($event) {
                          return _vm.closeCharm()
                        },
                      },
                    },
                    [
                      _c("span", {
                        staticClass: "icon mif mif-close mif-cross",
                      }),
                    ]
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "info-box-content p-3" },
                [
                  _c("label", { staticClass: "m-0" }, [
                    _vm._v(_vm._s(_vm.selectedcomputer?.description)),
                  ]),
                  _c("br"),
                  _c("label", [
                    _vm._v(_vm._s(_vm.selectedcomputer?.organization_name)),
                  ]),
                  _c("br"),
                  _c("label", { staticClass: "m-0" }, [
                    _vm._v(_vm._s(_vm.selectedcomputer?.os_version_full)),
                  ]),
                  _c("br"),
                  _c("br"),
                  _c("label", [
                    _c("span", {
                      class: {
                        "mif-checkmark fg-green":
                          _vm.selectedcomputer?.disk_alert == 0,
                      },
                    }),
                    _vm._v(" Disk Status"),
                  ]),
                  _c("br"),
                  _c("label", [
                    _c("span", {
                      class: {
                        "mif-checkmark fg-green":
                          _vm.selectedcomputer?.ram_alert == 0,
                      },
                    }),
                    _vm._v(" Ram Status: "),
                    _c("b", [
                      _vm._v(
                        _vm._s(_vm._f("prettyBytes")(_vm.selectedcomputer.ram))
                      ),
                    ]),
                  ]),
                  _c("br"),
                  _c("label", [
                    _c("span", {
                      class: {
                        "mif-checkmark fg-green":
                          _vm.selectedcomputer?.av_alert == 0,
                      },
                    }),
                    _vm._v(" Protection"),
                  ]),
                  _c("br"),
                  _c("br"),
                  _c("label", [
                    _vm._v(
                      "IP LAN: " + _vm._s(_vm.selectedcomputer?.ipaddress_lan)
                    ),
                  ]),
                  _c("br"),
                  _c("label", [
                    _vm._v(
                      "IP Public: " +
                        _vm._s(_vm.selectedcomputer?.ipaddress_public)
                    ),
                  ]),
                  _c("br"),
                  _c("br"),
                  _c("label", [_vm._v(_vm._s(_vm.$t("Groups")) + ":")]),
                  _c("br"),
                  _vm._l(_vm.selectedcomputer?.groups, function (group) {
                    return _c(
                      "button",
                      {
                        key: group.id,
                        staticClass: "button fg-dark mr-2 mt-2 mini",
                      },
                      [
                        _c(
                          "router-link",
                          {
                            key: group.id,
                            staticClass: "flat-button link",
                            attrs: { to: "/group/" + group.id },
                          },
                          [_vm._v(_vm._s(group.name))]
                        ),
                      ],
                      1
                    )
                  }),
                  _c("br"),
                  _c("br"),
                  _vm.selectedcomputer?.notifications
                    ? _c("table", { staticClass: "table compact striped" }, [
                        _c("thead", [
                          _c("tr", [
                            _c("td"),
                            _c("td", { staticClass: "text-center no-wrap" }, [
                              _vm._v(_vm._s(_vm.$t("EMAIL"))),
                            ]),
                            _c("td", { staticClass: "text-center no-wrap" }, [
                              _vm._v(_vm._s(_vm.$t("MOBILE PUSH")) + " "),
                            ]),
                          ]),
                        ]),
                        _c("tbody", [
                          _c("tr", [
                            _c("td", [
                              _vm._v(_vm._s(_vm.$t("Backup Notifications"))),
                            ]),
                            _c("td", [
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("input", {
                                  attrs: {
                                    type: "checkbox",
                                    "data-caption-position": "left",
                                    "data-role": "switch",
                                    "data-on": "on",
                                  },
                                  domProps: {
                                    checked:
                                      _vm.selectedcomputer?.notifications[1]
                                        .enabled_email == "1",
                                  },
                                }),
                              ]),
                            ]),
                            _c("td", [
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("input", {
                                  attrs: {
                                    type: "checkbox",
                                    "data-caption-position": "left",
                                    disabled: "",
                                    "data-role": "switch",
                                    "data-on": "on",
                                  },
                                  domProps: {
                                    checked:
                                      _vm.selectedcomputer?.notifications[1]
                                        .enabled_push == "1",
                                  },
                                }),
                              ]),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", [
                              _vm._v(_vm._s(_vm.$t("System Notifications"))),
                            ]),
                            _c("td", [
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("input", {
                                  attrs: {
                                    type: "checkbox",
                                    "data-caption-position": "left",
                                    "data-role": "switch",
                                    "data-on": "on",
                                  },
                                  domProps: {
                                    checked:
                                      _vm.selectedcomputer?.notifications[0]
                                        .enabled_email == "1",
                                  },
                                }),
                              ]),
                            ]),
                            _c("td", [
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("input", {
                                  attrs: {
                                    type: "checkbox",
                                    "data-caption-position": "left",
                                    disabled: "",
                                    "data-role": "switch",
                                    "data-on": "on",
                                  },
                                  domProps: {
                                    checked:
                                      _vm.selectedcomputer?.notifications[0]
                                        .enabled_push == "1",
                                  },
                                }),
                              ]),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", [
                              _vm._v(
                                " " + _vm._s(_vm.$t("Backup Reports")) + " "
                              ),
                            ]),
                            _c("td", [
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("input", {
                                  attrs: {
                                    type: "checkbox",
                                    "data-caption-position": "left",
                                    "data-role": "switch",
                                    "data-on": "on",
                                  },
                                  domProps: {
                                    checked:
                                      _vm.selectedcomputer?.notifications[3]
                                        .enabled_email == "1",
                                  },
                                }),
                              ]),
                            ]),
                            _c("td", [
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("input", {
                                  attrs: {
                                    type: "checkbox",
                                    "data-caption-position": "left",
                                    disabled: "",
                                    "data-role": "switch",
                                    "data-on": "on",
                                  },
                                  domProps: {
                                    checked:
                                      _vm.selectedcomputer?.notifications[3]
                                        .enabled_push == "1",
                                  },
                                }),
                              ]),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.$t("Disconnect Notifications")) + " "
                              ),
                            ]),
                            _c("td", [
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("input", {
                                  attrs: {
                                    type: "checkbox",
                                    "data-caption-position": "left",
                                    "data-role": "switch",
                                    "data-on": "on",
                                  },
                                  domProps: {
                                    checked:
                                      _vm.selectedcomputer?.notifications[2]
                                        .enabled_email == "1",
                                  },
                                }),
                              ]),
                            ]),
                            _c("td", [
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("input", {
                                  attrs: {
                                    type: "checkbox",
                                    "data-caption-position": "left",
                                    disabled: "",
                                    "data-role": "switch",
                                    "data-on": "on",
                                  },
                                  domProps: {
                                    checked:
                                      _vm.selectedcomputer?.notifications[2]
                                        .enabled_push == "1",
                                  },
                                }),
                              ]),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ],
                2
              ),
              _c("div", { staticClass: "spacer" }),
              _c("div", { staticClass: "cell d-flex flex-justify-end p-5" }, [
                _c(
                  "button",
                  { staticClass: "button primary", attrs: { type: "submit" } },
                  [_vm._v(_vm._s(_vm.$t("Save")))]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c("Navigation", { attrs: { pageName: "dashboard" } }),
      _vm.fullStats
        ? _c(
            "div",
            {
              staticClass: "row pl-2 pb-4",
              attrs: {
                "data-role": "collapse",
                "data-toggle-element": "#fullstats",
                "data-collapsed": "true",
              },
            },
            [
              _c("div", { staticClass: "cell-lg-3 cell-md-3 col-sm-6" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "more-info-box card rounded p-2 bg-primary fg-white w-100",
                  },
                  [
                    _c("div", { staticClass: "content" }, [
                      _c("h4", { staticClass: "text-bold mb-0" }, [
                        _vm._v(_vm._s(_vm.fullStats?.nOfWorkstations)),
                      ]),
                      _c("div", [_vm._v(_vm._s(_vm.$t("Workstations")))]),
                      _c("h4", { staticClass: "text-bold mb-0" }, [
                        _vm._v(_vm._s(_vm.fullStats?.nOfServers)),
                      ]),
                      _c("div", [_vm._v(_vm._s(_vm.$t("Servers")))]),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "cell-lg-3 cell-md-3 col-sm-6" }, [
                _c("div", { staticClass: "more-info-box card rounded p-2" }, [
                  _c("div", { staticClass: "content" }, [
                    _c("h2", { staticClass: "text-bold mb-0" }, [
                      _vm._v(
                        _vm._s(_vm.fullStats?.nOfWorkstations) +
                          "/" +
                          _vm._s(_vm.fullStats?.nOfServers)
                      ),
                    ]),
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.$t("Workstations")) +
                          "/" +
                          _vm._s(_vm.$t("Servers"))
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "cell-lg-3 cell-md-3 col-sm-6" }, [
                _c("div", { staticClass: "more-info-box card rounded p-2" }, [
                  _c("div", { staticClass: "content" }, [
                    _c("h2", { staticClass: "text-bold mb-0" }, [
                      _vm._v(
                        _vm._s(_vm.fullStats?.nOfWorkstations) +
                          "/" +
                          _vm._s(_vm.fullStats?.nOfServers)
                      ),
                    ]),
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.$t("Workstations")) +
                          "/" +
                          _vm._s(_vm.$t("Servers"))
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "cell-lg-3 cell-md-3 col-sm-6" }, [
                _c("div", { staticClass: "more-info-box card rounded p-2" }, [
                  _c("div", { staticClass: "content" }, [
                    _c("h2", { staticClass: "text-bold mb-0" }, [
                      _vm._v(
                        _vm._s(_vm.fullStats?.nOfWorkstations) +
                          "/" +
                          _vm._s(_vm.fullStats?.nOfServers)
                      ),
                    ]),
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.$t("Workstations")) +
                          "/" +
                          _vm._s(_vm.$t("Servers"))
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "cell-lg-3 cell-md-3 col-sm-6" }, [
                _c("div", { staticClass: "more-info-box card rounded p-2" }, [
                  _c("div", { staticClass: "content" }, [
                    _c("h2", { staticClass: "text-bold mb-0" }, [
                      _vm._v(
                        _vm._s(_vm.fullStats?.nOfWorkstations) +
                          "/" +
                          _vm._s(_vm.fullStats?.nOfServers)
                      ),
                    ]),
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.$t("Workstations")) +
                          "/" +
                          _vm._s(_vm.$t("Servers"))
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "cell-lg-3 cell-md-3 col-sm-6" }, [
                _c(
                  "div",
                  {
                    staticClass: "more-info-box card rounded p-2",
                    class:
                      _vm.fullStats?.nPcDiskAlert != 0 ? "bg-lightyellow" : "",
                  },
                  [
                    _c("div", { staticClass: "content" }, [
                      _c("h2", { staticClass: "text-bold mb-0" }, [
                        _vm._v(_vm._s(_vm.fullStats?.nPcDiskAlert)),
                      ]),
                      _c("div", [
                        _vm._v(_vm._s(_vm.$t("Devices with Disk Alert"))),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "cell-lg-3 cell-md-3 col-sm-6" }, [
                _c(
                  "div",
                  {
                    staticClass: "more-info-box card rounded p-2",
                    class:
                      _vm.fullStats?.nPcAvAlert != 0 ? "bg-lightyellow" : "",
                  },
                  [
                    _c("div", { staticClass: "content" }, [
                      _c("h2", { staticClass: "text-bold mb-0" }, [
                        _vm._v(_vm._s(_vm.fullStats?.nPcAvAlert)),
                      ]),
                      _c("div", [
                        _vm._v(_vm._s(_vm.$t("Devices with Antivirus Alert"))),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "cell-lg-3 cell-md-3 col-sm-6" }, [
                _c(
                  "div",
                  {
                    staticClass: "more-info-box card rounded p-2",
                    class:
                      _vm.fullStats?.nPcAvAlert != 0 ? "bg-lightyellow" : "",
                  },
                  [
                    _c("div", { staticClass: "content" }, [
                      _c("h2", { staticClass: "text-bold mb-0" }, [
                        _vm._v(_vm._s(_vm.fullStats?.nPcAvAlert)),
                      ]),
                      _c("div", [
                        _vm._v(_vm._s(_vm.$t("Devices with Antivirus Alert"))),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "row pl-5 pr-5 w-100" }, [
        _c("div", { staticClass: "w-100" }, [
          _c(
            "ul",
            {
              staticStyle: { "z-index": "1" },
              attrs: {
                "data-role": "tabs",
                "data-expand": "true",
                "data-cls-tabs": "bg-transparent",
              },
            },
            [
              _c("li", [
                _c("a", { attrs: { href: "#all_computers" } }, [
                  _c("label", { staticClass: "pr-1" }, [
                    _vm.computers == null
                      ? _c("span", {
                          staticClass: "mif-spinner2 fg-primary ani-spin",
                        })
                      : _vm._e(),
                    _c("span", [
                      _vm.computers != null
                        ? _c(
                            "span",
                            { staticClass: "badge bg-primary fg-white inline" },
                            [_vm._v(_vm._s(Object.keys(_vm.computers).length))]
                          )
                        : _vm._e(),
                      _vm._v(" " + _vm._s(_vm.$t("Devices")) + " "),
                    ]),
                  ]),
                ]),
              ]),
              _c("li", [
                _c("a", { attrs: { href: "#all_backups" } }, [
                  _c("label", { staticClass: "pr-1" }, [
                    _vm.jobs == null
                      ? _c("span", {
                          staticClass: "mif-spinner2 fg-primary ani-spin",
                        })
                      : _vm._e(),
                    _c("span", { staticClass: "no-wrap" }, [
                      _vm._v(" " + _vm._s(_vm.$t("Latest Backups"))),
                    ]),
                  ]),
                ]),
              ]),
              _c("li", [
                _c("a", { attrs: { href: "#all_sessions" } }, [
                  _c("label", { staticClass: "pr-1" }, [
                    _vm.sessions == null
                      ? _c("span", {
                          staticClass: "mif-spinner2 fg-primary ani-spin",
                        })
                      : _vm._e(),
                    _c("span", { staticClass: "no-wrap" }, [
                      _vm._v(" " + _vm._s(_vm.$t("Latest Remote Sessions"))),
                    ]),
                  ]),
                ]),
              ]),
              _c("li", [
                _c("a", { attrs: { href: "#activities" } }, [
                  _c("label", { staticClass: "pr-1" }, [
                    _c("span", { staticClass: "no-wrap" }, [
                      _vm._v(" " + _vm._s(_vm.$t("Activities"))),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "w-100 bg-white bordered border border-top-none",
            staticStyle: { height: "calc(78vh)", "overflow-y": "scroll" },
            attrs: { id: "activities" },
          },
          [
            _vm.events && _vm.types
              ? _c(
                  "table",
                  {
                    staticClass: "p-0 table striped bg-white m-0 compact",
                    attrs: {
                      id: "event-list",
                      "data-check": false,
                      "data-check-name": "chkBox",
                      "data-show-search": "true",
                      "data-show-activity": "false",
                      "data-show-rows-steps": "false",
                      "data-show-pagination": "true",
                      "data-cell-wrapper": "false",
                      "data-table-search-title": _vm.$t("Search Activity"),
                      "data-cls-search": "w-100-sm w-25-xl w-25-lg w-50-md p-4",
                      "data-role": "table",
                      "data-rows": "20",
                      "data-horizontal-scroll": "false",
                    },
                  },
                  [
                    _c("thead", { staticClass: "mb-0" }, [
                      _c("tr", { staticClass: "mb-3" }, [
                        _c("th", [_vm._v(_vm._s(_vm.$t("Message")))]),
                        _c("th", { attrs: { "data-sortable": "true" } }, [
                          _vm._v(_vm._s(_vm.$t("Event"))),
                        ]),
                        _c("th", { attrs: { "data-sortable": "true" } }, [
                          _vm._v(_vm._s(_vm.$t("Created at"))),
                        ]),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.events, function (event) {
                        return _c(
                          "tr",
                          {
                            class: { "": !event.is_read },
                            attrs: { id: event.id },
                          },
                          [
                            _c("td", [
                              _c("b", [_vm._v(_vm._s(_vm.$t(event.msg)))]),
                              _c("br"),
                              _vm._v(_vm._s(_vm.$t(event.subtitle))),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "badge inline bg-cobalt fg-white p-1 mr-1",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        _vm.types.find(function (el) {
                                          return el.id == event.type ? el : null
                                        }).name
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.formatDate(event.dt_ins)) + " "
                              ),
                              _c("br"),
                              _vm._v(
                                "(" + _vm._s(_vm.fromNow(event.dt_ins)) + ")"
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "w-100",
            staticStyle: { height: "calc(78vh)", "overflow-y": "scroll" },
            attrs: { id: "all_backups" },
          },
          [
            _vm.jobs
              ? _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-justify-start p-3 bg-white bordered border border-top-none border-bottom-none",
                  },
                  [
                    _c("div", { staticClass: "col-auto pr-2 pl-0" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filters.backupName,
                            expression: "filters.backupName",
                          },
                        ],
                        staticClass:
                          "input bg-graywhite pl-4 text-bold text-small",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t("Backup Name"),
                        },
                        domProps: { value: _vm.filters.backupName },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.filters,
                              "backupName",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "col-auto pr-2 pl-2" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filters.numrows,
                              expression: "filters.numrows",
                            },
                          ],
                          staticClass:
                            "bg-graywhite pl-2 select text-bold text-small",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.filters,
                                "numrows",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "25" } }, [
                            _vm._v("25 " + _vm._s(_vm.$t("rows"))),
                          ]),
                          _c("option", { attrs: { value: "50" } }, [
                            _vm._v("50 " + _vm._s(_vm.$t("rows"))),
                          ]),
                          _c("option", { attrs: { value: "100" } }, [
                            _vm._v("100 " + _vm._s(_vm.$t("rows"))),
                          ]),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-auto flex-justify-end m-0 d-flex flex-align-end pr-2",
                      },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.filters.lastresult,
                                expression: "filters.lastresult",
                              },
                            ],
                            staticClass:
                              "bg-graywhite pl-2 select text-bold text-small",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.filters,
                                  "lastresult",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v(_vm._s(_vm.$t("All"))),
                            ]),
                            _vm._l(
                              _vm.filters.resultList,
                              function (key, value) {
                                return _c(
                                  "option",
                                  { key: key, domProps: { value: key } },
                                  [_vm._v(_vm._s(value) + " ")]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "cell-auto" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button primary",
                          on: {
                            click: function ($event) {
                              return _vm.getJobs()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("Filter")) + " ")]
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _c(
              "table",
              {
                staticClass:
                  "striped compact table w-100 bg-white bordered border border-top-none",
                attrs: { id: "backup-list" },
              },
              [
                _c("thead", { staticClass: "mb-0" }, [
                  _c("tr", { staticClass: "mb-3" }, [
                    _c(
                      "td",
                      {
                        staticClass: "text-center",
                        staticStyle: {
                          "vertical-align": "middle",
                          width: "20px",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("Average Result")))]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "text-center",
                        staticStyle: {
                          "vertical-align": "middle",
                          width: "20px",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("Last Result")))]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "text-left",
                        staticStyle: {
                          "vertical-align": "middle",
                          width: "100px",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("Name")))]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "text-center",
                        staticStyle: {
                          "vertical-align": "middle",
                          width: "50px",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("Type")))]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "text-left",
                        staticStyle: { "vertical-align": "middle" },
                      },
                      [_vm._v(_vm._s(_vm.$t("Device")))]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "text-right",
                        staticStyle: {
                          "vertical-align": "middle",
                          "white-space": "nowrap",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("Started")) +
                            "/" +
                            _vm._s(_vm.$t("Ended")) +
                            "/" +
                            _vm._s(_vm.$t("Duration"))
                        ),
                      ]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "text-center",
                        staticStyle: {
                          "vertical-align": "middle",
                          "white-space": "nowrap",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("Files")) + " ")]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "text-center",
                        staticStyle: { "vertical-align": "middle" },
                      },
                      [_vm._v(_vm._s(_vm.$t("Size")))]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "text-center",
                        staticStyle: {
                          "vertical-align": "middle",
                          width: "60px",
                        },
                        attrs: { colspan: "2" },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("Notifications")))]
                    ),
                  ]),
                ]),
                _c(
                  "tbody",
                  [
                    !_vm.jobs
                      ? _c("tr", { staticClass: "pt-10" }, [
                          _c("td", {
                            staticClass: "p-6",
                            attrs: {
                              colspan: "7",
                              "data-role": "activity",
                              "data-type": "metro",
                              "data-style": "dark",
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm.jobs != null && Object.keys(_vm.jobs).length == 0
                      ? _c("tr", { staticClass: "pt-10" }, [
                          _c(
                            "td",
                            {
                              staticClass: "p-6 text-center",
                              attrs: { colspan: "12" },
                            },
                            [
                              _c("NoDataAvailable", {
                                attrs: {
                                  message: _vm.$t("No Backups available"),
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._l(_vm.jobs, function (backup, index) {
                      return _c("tr", { key: backup.id + "-" + index }, [
                        _c(
                          "td",
                          { staticClass: "text-center" },
                          [
                            _c("BackupLastResult", {
                              attrs: { lastresult: backup.average_result },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "text-center" },
                          [
                            _c("BackupLastResult", {
                              attrs: { lastresult: backup.last_result },
                            }),
                          ],
                          1
                        ),
                        _c("td", { staticClass: "text-left" }, [
                          _c(
                            "label",
                            {
                              staticClass: "button flat-button",
                              attrs: { title: backup.name },
                              on: {
                                click: function ($event) {
                                  return _vm.openBackupView(
                                    backup.id,
                                    backup.id_computer
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  backup.name.length > 25
                                    ? backup.name.substring(0, 25) + "..."
                                    : backup.name
                                )
                              ),
                            ]
                          ),
                        ]),
                        _c("td", { staticClass: "text-right" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "badge inline bg-cobalt fg-white p-1 mr-1",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$root.platformsTypes[
                                    backup.type
                                  ].name.toUpperCase()
                                ) + " "
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "td",
                          { staticClass: "text-left" },
                          [
                            _c("PCLink", {
                              attrs: { pc: _vm.computers[backup.id_computer] },
                            }),
                          ],
                          1
                        ),
                        _c("td", { staticClass: "text-right" }, [
                          backup.dt_start_utc != "" &&
                          backup.dt_start_utc != null
                            ? _c("label", [
                                _vm._v(
                                  _vm._s(_vm.formatDate(backup.dt_start_utc))
                                ),
                              ])
                            : _vm._e(),
                          _c("br"),
                          backup.dt_end_utc != "" && backup.dt_end_utc != null
                            ? _c("label", [
                                _vm._v(
                                  _vm._s(_vm.formatDate(backup.dt_end_utc))
                                ),
                              ])
                            : _vm._e(),
                          _c("br"),
                          backup.dt_end_utc != "" && backup.dt_end_utc != null
                            ? _c("label", { staticClass: "text-bold" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.diffDate(
                                      backup.dt_start_utc,
                                      backup.dt_end_utc
                                    )
                                  )
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            " " + _vm._s(backup.last_backup_copiedfiles) + " "
                          ),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("prettyBytes")(backup.last_backup_size)
                              ) +
                              " "
                          ),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _c("span", {
                            staticClass: "mif-mobile mif-2x",
                            class: { "fg-lightgray": backup.enabled_push == 0 },
                          }),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _c("span", {
                            staticClass: "mif-mail mif-2x",
                            class: {
                              "fg-lightgray": backup.enabled_email == 0,
                            },
                          }),
                        ]),
                      ])
                    }),
                  ],
                  2
                ),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "w-100",
            staticStyle: { height: "calc(78vh)", "overflow-y": "auto" },
            attrs: { id: "all_sessions" },
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticClass:
                  "h-100 m-0 op-dark-hi p-0 pos-absolute pos-center w-100 z-2",
              },
              [
                _c("span", {
                  staticClass:
                    "ani-spin fg-white mif-5x mif-spinner2 mt-13-minus pos-center",
                }),
              ]
            ),
            _vm.sessions
              ? _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-justify-end p-3 bg-white bordered border border-top-none border-bottom-none",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-auto" },
                      [
                        _c("router-link", { attrs: { to: "/sessions" } }, [
                          _c("button", { staticClass: "button" }, [
                            _vm._v(
                              _vm._s(_vm.$t("Show all remote sessions")) + " "
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _c("div", [
              _c(
                "table",
                {
                  staticClass:
                    "striped compact table w-100 bg-white bordered border border-top-none",
                  attrs: { id: "session-list" },
                },
                [
                  _c("thead", { staticClass: "mb-0" }, [
                    _c("tr", {}, [
                      _c(
                        "th",
                        {
                          staticClass: "text-left no-wrap",
                          staticStyle: { "vertical-align": "left" },
                        },
                        [_vm._v(_vm._s(_vm.$t("Operator")))]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-left no-wrap",
                          staticStyle: { "vertical-align": "left" },
                        },
                        [_vm._v(_vm._s(_vm.$t("From Device")))]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-left no-wrap",
                          staticStyle: { "vertical-align": "middle" },
                        },
                        [_vm._v(_vm._s(_vm.$t("To Device")))]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-center",
                          staticStyle: { "vertical-align": "middle" },
                        },
                        [_vm._v(_vm._s(_vm.$t("Organization")))]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-right no-wrap",
                          staticStyle: { "vertical-align": "middle" },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("Started")) +
                              " / " +
                              _vm._s(_vm.$t("Ended")) +
                              " / " +
                              _vm._s(_vm.$t("Duration"))
                          ),
                        ]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-center",
                          staticStyle: { "vertical-align": "middle" },
                        },
                        [_vm._v(_vm._s(_vm.$t("Notes")))]
                      ),
                      _c("th", {
                        staticClass: "text-center",
                        staticStyle: { "vertical-align": "middle" },
                      }),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    [
                      !_vm.sessions
                        ? _c("tr", { staticClass: "pt-10" }, [
                            _c("td", {
                              staticClass: "p-6",
                              attrs: {
                                colspan: "7",
                                "data-role": "activity",
                                "data-type": "metro",
                                "data-style": "dark",
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm._l(_vm.sessions, function (session, index) {
                        return [
                          _c("tr", [
                            _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    session.firstname + " " + session.lastname
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("td", { staticClass: "text-left no-wrap" }, [
                              _vm._v(
                                " " +
                                  _vm._s(session.name_computer_control) +
                                  " (" +
                                  _vm._s(session.id_remote_control) +
                                  ") "
                              ),
                            ]),
                            _c("td", { staticClass: "text-left no-wrap" }, [
                              _vm._v(
                                " " +
                                  _vm._s(session.name_computer_host) +
                                  " (" +
                                  _vm._s(session.id_remote_host) +
                                  ") "
                              ),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(
                                " " + _vm._s(session.organization_name) + " "
                              ),
                            ]),
                            _c("td", { staticClass: "text-right" }, [
                              session.dt_start != "" && session.dt_start != null
                                ? _c("label", [
                                    _vm._v(
                                      _vm._s(_vm.formatDate(session.dt_start))
                                    ),
                                  ])
                                : _vm._e(),
                              _c("br"),
                              session.dt_end != "" && session.dt_end != null
                                ? _c("label", [
                                    _vm._v(
                                      _vm._s(_vm.formatDate(session.dt_end))
                                    ),
                                  ])
                                : _vm._e(),
                              _c("br"),
                              session.dt_end != "" && session.dt_end != null
                                ? _c("label", { staticClass: "text-bold" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.diffDate(
                                          session.dt_start,
                                          session.dt_end
                                        )
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              session.notes[0]
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: session.notes[0].note,
                                        expression: "session.notes[0].note",
                                      },
                                    ],
                                    attrs: {
                                      type: "text",
                                      "data-role": "input",
                                    },
                                    domProps: { value: session.notes[0].note },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          session.notes[0],
                                          "note",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  })
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: session.notedesc,
                                        expression: "session.notedesc",
                                      },
                                    ],
                                    attrs: {
                                      type: "text",
                                      "data-role": "input",
                                    },
                                    domProps: { value: session.notedesc },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          session,
                                          "notedesc",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                            ]),
                            _c("td", [
                              _c(
                                "button",
                                {
                                  staticClass: "button",
                                  on: {
                                    click: function ($event) {
                                      return _vm.saveNote(session)
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    staticClass: "icon mif-2x mif-floppy-disk2",
                                  }),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "w-100",
            staticStyle: { height: "calc(79vh)", overflow: "auto" },
            attrs: { id: "all_computers" },
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticClass:
                  "h-100 m-0 op-dark-hi p-0 pos-absolute pos-center w-100 z-2",
              },
              [
                _c("span", {
                  staticClass:
                    "ani-spin fg-white mif-5x mif-spinner2 mt-13-minus pos-center",
                }),
              ]
            ),
            _vm.computers
              ? _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-justify-start p-3 bg-white bordered border border-top-none border-bottom-none",
                  },
                  [
                    _c("div", { staticClass: "cell-auto pr-2 pl-0" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filters.id_group,
                              expression: "filters.id_group",
                            },
                          ],
                          staticClass:
                            "bg-graywhite pl-2 select text-bold text-small",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.filters,
                                "id_group",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v(_vm._s(_vm.$t("All Groups"))),
                          ]),
                          _vm._l(_vm.groups, function (group) {
                            return _c(
                              "option",
                              {
                                key: group.id_group,
                                domProps: { value: group.id_group },
                              },
                              [_vm._v(_vm._s(group.name_group) + " ")]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "cell-auto flex-justify-end m-0 d-flex flex-align-end pr-2",
                      },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.filters.status,
                                expression: "filters.status",
                              },
                            ],
                            staticClass:
                              "bg-graywhite pl-2 select text-bold text-small",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.filters,
                                  "status",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v(_vm._s(_vm.$t("Online & Offline"))),
                            ]),
                            _c("option", { attrs: { value: "1" } }, [
                              _vm._v(_vm._s(_vm.$t("Online only"))),
                            ]),
                            _c("option", { attrs: { value: "0" } }, [
                              _vm._v(_vm._s(_vm.$t("Offline only"))),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "table",
              {
                staticClass:
                  "striped compact table w-100 bg-white bordered border border-top-none",
                attrs: { id: "computers-list" },
              },
              [
                _c("thead", { staticClass: "mb-0" }, [
                  _c("tr", { staticClass: "mb-3" }, [
                    _c(
                      "td",
                      {
                        staticClass: "text-left pl-4",
                        staticStyle: {
                          "vertical-align": "middle",
                          width: "100px",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("Device")))]
                    ),
                    _c("td", {
                      staticClass: "text-center",
                      staticStyle: {
                        "vertical-align": "middle",
                        width: "20px",
                      },
                    }),
                    _c(
                      "td",
                      {
                        staticClass: "text-left",
                        staticStyle: {
                          "vertical-align": "middle",
                          "min-width": "100px",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("Last Backup")) + " ")]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "text-center",
                        staticStyle: {
                          "vertical-align": "middle",
                          width: "100px",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("Groups")) + " ")]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "text-center",
                        staticStyle: { "vertical-align": "middle" },
                      },
                      [_vm._v(_vm._s(_vm.$t("Organization")))]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "text-center",
                        staticStyle: {
                          "vertical-align": "middle",
                          "white-space": "nowrap",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("Private IP")))]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "text-center",
                        staticStyle: {
                          "vertical-align": "middle",
                          "white-space": "nowrap",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("Public IP")))]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "text-center",
                        staticStyle: {
                          "vertical-align": "middle",
                          "white-space": "nowrap",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("Remote Ver.")))]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "text-center",
                        staticStyle: {
                          "vertical-align": "middle",
                          "white-space": "nowrap",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("Backup Ver.")))]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "text-center",
                        staticStyle: {
                          "vertical-align": "middle",
                          width: "100px",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("Remote Access")))]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "text-center",
                        staticStyle: { "vertical-align": "middle" },
                      },
                      [_vm._v(_vm._s(_vm.$t("License Status")))]
                    ),
                    _c("td", { staticClass: "text-center" }),
                  ]),
                ]),
                _c(
                  "tbody",
                  [
                    _vm.computers == null
                      ? _c("tr", { staticClass: "pt-10" }, [_vm._m(0)])
                      : _vm._e(),
                    _vm.computers != null &&
                    Object.keys(_vm.computers).length == 0
                      ? _c("tr", { staticClass: "pt-10" }, [
                          _c(
                            "td",
                            {
                              staticClass: "p-6 text-center",
                              attrs: { colspan: "12" },
                            },
                            [
                              _c("NoDataAvailable", {
                                attrs: {
                                  message: _vm.$t("No Devices available"),
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._l(_vm.computers, function (computer, id) {
                      return _vm.filters.status == "" ||
                        (_vm.$root.connectionsStates[computer.id] &&
                          _vm.filters.status == "1") ||
                        (!_vm.$root.connectionsStates[computer.id] &&
                          _vm.filters.status == "0")
                        ? _c("tr", { key: id }, [
                            _c(
                              "td",
                              {},
                              [_c("PCLink", { attrs: { pc: computer } })],
                              1
                            ),
                            _c("td", { staticClass: "text-center" }, [
                              computer.last_job_result != "" &&
                              computer.last_job_name != ""
                                ? _c("span", {
                                    staticClass:
                                      "c-context-menu mif-checkmark mr-1",
                                    class: {
                                      "fg-red": computer.last_job_result == 0,
                                      "fg-green": computer.last_job_result == 1,
                                      "fg-orange":
                                        computer.last_job_result == 2,
                                      "fg-darkgray":
                                        computer.last_job_result == 99,
                                    },
                                  })
                                : _vm._e(),
                            ]),
                            _c("td", { staticClass: "text-left" }, [
                              computer.last_job_result != "" &&
                              computer.last_job_name != ""
                                ? _c("div", [
                                    computer.last_job_name
                                      ? _c("span", [
                                          _vm._v(
                                            " " + _vm._s(computer.last_job_name)
                                          ),
                                        ])
                                      : _vm._e(),
                                    _c("br"),
                                    computer.last_job_dt_start
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.formatDate(
                                                  computer.last_job_dt_start
                                                )
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "flat-button",
                                  attrs: {
                                    "data-cls-hint": "drop-shadow",
                                    "data-role": "hint",
                                    "data-hint-text":
                                      _vm.$root.createhintGroups(
                                        computer.groups
                                      ),
                                  },
                                },
                                [_vm._v(_vm._s(computer.groups.length) + " ")]
                              ),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(computer.organization_name)),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(computer.ipaddress_lan) + " "),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(computer.ipaddress_public) + " "),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(computer.version_remote) + " "),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(computer.version_backup) + " "),
                            ]),
                            _c(
                              "td",
                              { staticClass: "text-center" },
                              [_c("PCRemoteLink", { attrs: { pc: computer } })],
                              1
                            ),
                            _c(
                              "td",
                              { staticClass: "text-center" },
                              [
                                _c("PCLicenseLink", {
                                  attrs: { computer: computer },
                                }),
                              ],
                              1
                            ),
                            _c("td", { staticClass: "text-center" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "button tertiary",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openComputerView(computer)
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    staticClass: "mif-menu_open mif-2x",
                                  }),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e()
                    }),
                  ],
                  2
                ),
              ]
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", { staticClass: "p-6", attrs: { colspan: "7" } }, [
      _c("div", {
        attrs: {
          "data-role": "activity",
          "data-type": "metro",
          "data-style": "color",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }