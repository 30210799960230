<template>
    <!--span>
        <b v-if="lastresult == $root.ResultValue.OK" class="fg-green" :title="$t('Restore completed successfully')"><span class="mif-checkmark mr-3"></span></b>
        <b v-if="lastresult == $root.ResultValue.Error" class="fg-orange" :title="$t('Some Errors')"></b>
        <b v-if="lastresult == $root.ResultValue.SomeWarnings" class="fg-orange" :title="$t('Some Warnings')"></b>
        <b v-if="lastresult == $root.ResultValue.Running" class="fg-darkGray" :title="$t('Running')"></b>
        <b v-if="lastresult == $root.ResultValue.Aborted" class="fg-black" :title="$t('Aborted by user')"></b>
        <b v-if="lastresult == $root.ResultValue.NeverExecuted" class="fg-black" :title="$t('Never executed')"></b>
        <b v-if="lastresult == $root.ResultValue.TerminatedAbnormally" class="fg-red text-bold mif-cross-light" :title="$t('Abnormally terminated')"></b>
    </span-->
    <span>
        <span v-if="lastresult == $root.ResultValue.OK" class="fg-green text-bold mif-checkmark mif-2x" :title="$t('Ok')"></span>
        <span v-if="lastresult == $root.ResultValue.SomeWarnings" class="fg-orange text-bold mif-warning mif-2x" :title="$t('Some Warnings')"></span>
        <span v-if="lastresult == $root.ResultValue.Error" class="fg-red text-bold mif-cross mif-2x" :title="$t('Error')"></span>
        <span v-if="lastresult == $root.ResultValue.Running" class="fg-black mif-spinner ani-spin" :title="$t('Running')"></span>
        <span v-if="lastresult == $root.ResultValue.Aborted" class="fg-red text-bold mif-checkmark" :title="$t('Aborted by user')"></span>
        <span v-if="lastresult == $root.ResultValue.NeverExecuted"  :title="$t('Never executed')"> </span>
        <span v-if="lastresult == $root.ResultValue.TerminatedAbnormally" class="fg-violet text-bold mif-cross-light" :title="$t('Abnormally terminated')"></span>
    </span>
</template>
<script>
export default {
    name: "RestoreLastResult",
    props: {
        lastresult: {
            type: Number,
            required: true
        }
    }
}
</script>