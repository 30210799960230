var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dropdown-button" }, [
    _c(
      "a",
      {
        staticClass: "button",
        class:
          _vm.computer.backup_license_status == 2
            ? "fg-white bg-amazon"
            : " dropdown-toggle",
        attrs: {
          title:
            _vm.computer.backup_license_status == 2
              ? "Waiting to restart agent"
              : "",
        },
      },
      [_vm._m(0)]
    ),
    _c(
      "ul",
      {
        staticClass: "d-menu border place-right text-center",
        attrs: { "data-role": "dropdown" },
      },
      [
        _vm.computer.backup_license_status == 2
          ? _c("li", [
              _c(
                "a",
                {
                  staticClass: "text-bold",
                  attrs: { title: _vm.$t("Waiting to restart agent") },
                },
                [_vm._v(" " + _vm._s(_vm.$t("Waiting to restart agent")) + " ")]
              ),
            ])
          : _vm._e(),
        _c("li", [
          _c(
            "a",
            {
              class:
                _vm.computer.backup_license_status == 1
                  ? ""
                  : "fg-gray disabled",
              attrs: { title: _vm.$t("Remove from license") },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$parent.removeFromLicense(_vm.computer)
                },
              },
            },
            [
              _c("span", { staticClass: "icon mif-2x mif-link_off" }),
              _vm._v(" " + _vm._s(_vm.$t("Remove from license")) + " "),
            ]
          ),
        ]),
        _c("li", [
          _c(
            "a",
            {
              class:
                _vm.computer.backup_license_status == 3
                  ? ""
                  : "fg-gray disabled",
              attrs: { title: _vm.$t("Add to license") },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$parent.reconnectToLicense(_vm.computer)
                },
              },
            },
            [
              _c("span", { staticClass: "icon mif-2x mif-link_on" }),
              _vm._v(" " + _vm._s(_vm.$t("Add to license")) + " "),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-left" }, [
      _c("span", { staticClass: "mif-admin_panel_settings mif-3x" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }