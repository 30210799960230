var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "dialog light center text-small rounded w-100-sm w-100-md w-75-lg w-50-xl draggable-item",
    },
    [
      _c("DialogTitle", {
        attrs: {
          title:
            _vm.serviceAccount.id == null
              ? _vm.$t("Add Service Account")
              : _vm.serviceAccount.name,
          defaultTitle:
            _vm.serviceAccount.id == null
              ? _vm.$t("Add Service Account")
              : _vm.$t("Edit/Test Service Account"),
          subTitle: _vm.serviceAccount.type_name,
        },
        on: {
          closeDialog: function ($event) {
            return _vm.$root.$emit("CLOSESERVICEACCOUNTDIALOG")
          },
        },
      }),
      _c("div", { staticClass: "dialog-content overflow" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v(_vm._s(_vm.$t("Name")))]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.serviceAccount.name,
                expression: "serviceAccount.name",
              },
            ],
            attrs: {
              "data-role": "input",
              type: "text",
              maxlength: "50",
              placeholder: _vm.$t("Enter friendly name"),
              "data-prepend": "<span class='mif-pencil'></span>",
            },
            domProps: { value: _vm.serviceAccount.name },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.serviceAccount, "name", $event.target.value)
              },
            },
          }),
          _c("small", { staticClass: "text-muted" }, [
            _vm._v(_vm._s(_vm.$t("Required"))),
          ]),
        ]),
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v(_vm._s(_vm.$t("Username")))]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.serviceAccount.username,
                expression: "serviceAccount.username",
              },
            ],
            attrs: {
              "data-role": "input",
              type: "text",
              required: "",
              "data-prepend": "<span class='mif-user'></span>",
              autocomplete: "off",
            },
            domProps: { value: _vm.serviceAccount.username },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.serviceAccount, "username", $event.target.value)
              },
            },
          }),
          _c("small", { staticClass: "text-muted" }, [
            _vm._v(_vm._s(_vm.$t("Required"))),
          ]),
        ]),
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v(_vm._s(_vm.$t("Password")))]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.serviceAccount.password,
                expression: "serviceAccount.password",
              },
            ],
            staticClass: "metro-input",
            attrs: {
              "data-role": "input",
              type: "password",
              placeholder: _vm.$t("Enter Password"),
              "data-prepend": "<span class='mif-lock'></span>",
              autocomplete: "false/",
            },
            domProps: { value: _vm.serviceAccount.password },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.serviceAccount, "password", $event.target.value)
              },
            },
          }),
          _c("small", { staticClass: "text-muted" }, [
            _vm._v(_vm._s(_vm.$t("Required"))),
          ]),
        ]),
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v(_vm._s(_vm.$t("Path")))]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.serviceAccount.options.path,
                expression: "serviceAccount.options.path",
              },
            ],
            attrs: {
              "data-role": "input",
              type: "text",
              "data-validate": "required",
            },
            domProps: { value: _vm.serviceAccount.options.path },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(
                  _vm.serviceAccount.options,
                  "path",
                  $event.target.value
                )
              },
            },
          }),
          _c("small", { staticClass: "text-muted" }, [
            _vm._v(_vm._s(_vm.$t("Required"))),
          ]),
        ]),
        _vm.$session.getRoomPC() == null
          ? _c("div", { staticClass: "form-group" }, [
              _c("label", [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("Select PC to Test Service Account"))),
                ]),
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.testPC,
                      expression: "testPC",
                    },
                  ],
                  staticClass: "select cell-9",
                  attrs: { disabled: _vm.$session.getPCList().length == 0 },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.testPC = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(_vm.$session.getPCList(), function (pc) {
                  return _c(
                    "option",
                    {
                      attrs: {
                        "data-append": pc.note,
                        disabled: !_vm.$root.isConnected(pc.id),
                      },
                      domProps: { value: pc },
                    },
                    [_vm._v(" " + _vm._s(pc.name) + " ")]
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "dialog-actions" }, [
        _vm.tested == _vm.TEST_WAIT
          ? _c("div", [_c("span", { staticClass: "mif-spinner2 ani-spin" })])
          : _vm._e(),
        _c(
          "button",
          {
            staticClass: "button alert mr-2",
            on: {
              click: function ($event) {
                return _vm.$root.$emit("CLOSESERVICEACCOUNTDIALOG")
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("Cancel")))]
        ),
        _c(
          "button",
          {
            staticClass: "button primary mr-2",
            attrs: { disabled: !_vm.enableTest },
            on: { click: _vm.test },
          },
          [_vm._v(" " + _vm._s(_vm.$t("Test account")) + " ")]
        ),
        _c(
          "button",
          {
            staticClass: "button primary",
            attrs: { disabled: !_vm.enableSave },
            on: {
              click: function ($event) {
                return _vm.$root.saveServiceAccount(_vm.serviceAccount)
              },
            },
          },
          [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }