import { getFirstValueOrDefault, getValueOrDefault, isValid, platformsTypes, PlatformsTypesEnum } from "../../public/assets/js/utilitiesmodule";

export class DefaultObjectBuilder {

    constructor() {}

    getRepository(type) {
        var repository = {
            id: null,
            name: '',
            type: type,
            type_name: platformsTypes[type].name,
            id_service_account: -2, // -1 = custom
            path: '',
            options: {}
        };

        return repository;
    }

    getServiceAccount(type, extraData) {
        var serviceAccount = {
            id: null,
            name: '',
            type: type,
            type_name: platformsTypes[type].name,
            client_id: "",
            client_secret: "",
            username: "",
            password: "",
            use_ssl: false,
            region: "",
            options: {}
        };

        //Cloud
        /*         if (platformsTypes[type].mainType == PlatformsTypesEnum.Cloud) {
                    
                    serviceAccount.client_secret = "";
                    serviceAccount.region = PlatformsTypesEnum.Cloud_AmazonS3Storage, PlatformsTypesEnum.Cloud_IperiusS3Storage, PlatformsTypesEnum.Cloud_S3CompatibleStorage].includes(serviceAccount.type) ? "":undefined;
                    //serviceAccount.auth_code = "";
                    serviceAccount.url = [PlatformsTypesEnum.Cloud_IperiusS3Storage, PlatformsTypesEnum.Cloud_S3CompatibleStorage].includes(type) ? true : undefined;"";
                    serviceAccount.use_ssl = [PlatformsTypesEnum.Cloud_IperiusS3Storage, PlatformsTypesEnum.Cloud_S3CompatibleStorage].includes(type) ? true : undefined;
                    serviceAccount.options.signatureversion = type == PlatformsTypesEnum.Cloud_S3CompatibleStorage ? 2 : undefined;
                    serviceAccount.options.appName = [PlatformsTypesEnum.Cloud_OneDrive].includes(type) ? true : undefined;
                    serviceAccount.options.tenant_id = [PlatformsTypesEnum.Cloud_OneDrive].includes(type) ? true : undefined;
                    return serviceAccount;
                }

        if (platformsTypes[type].mainType == PlatformsTypesEnum.Microsoft) {
            serviceAccount.client_id = "";
            serviceAccount.client_secret = "";
            serviceAccount.region = "";
            serviceAccount.options.appName = "";
            serviceAccount.options.tenant_id = "";
            serviceAccount.options.useModernAuth = true;
            serviceAccount.options.server = "";
            serviceAccount.options.domain = "";
            //serviceAccount.options.IncludeChildDomains = true; //a cosa serve?
            serviceAccount.username = "";
            serviceAccount.password = "";
            return serviceAccount;
        }

        //Email
        if (platformsTypes[type].mainType == PlatformsTypesEnum.Email) { // extradata è l'email provider selezionato
            serviceAccount.type = extraData.Id;
            serviceAccount.type_name = extraData.Name;
            serviceAccount.url = extraData.Url;
            serviceAccount.username = "";
            serviceAccount.password = "";
            serviceAccount.use_ssl = extraData.UseSSL;
            serviceAccount.port = extraData.Port;
            serviceAccount.options = {
                senderAddress: "",
                usePop3: false,
                popUsername: "",
                popPassword: "",
                popServer: "",
                popPort: 0
            };
            return serviceAccount;
        }

        //Database
        if (platformsTypes[type].mainType == PlatformsTypesEnum.Database) {
            serviceAccount.url = "";
            serviceAccount.port = type == PlatformsTypesEnum.Database_SQLServer ? 1433 : 21;
            serviceAccount.username = "";
            serviceAccount.password = "";
            serviceAccount.options = {
                useTcpIpNetLib: false,
                usePersistSecInfo: false,
                useIntSecSSPI: false,
                useTrustConn: false,
                useEncConn: false,
                useWinAuth: true,
            }
            return serviceAccount;
        }

        switch (type) {
            case PlatformsTypesEnum.FTP:
                serviceAccount.url = "";
                serviceAccount.port = 21;
                serviceAccount.username = "";
                serviceAccount.password = "";
                serviceAccount.options.connectionType = "ftp";
                serviceAccount.options.passiveMode = false;
                return serviceAccount;

            case PlatformsTypesEnum.ESXi:
                serviceAccount.url = "";
                serviceAccount.username = "";
                serviceAccount.password = "";
                return serviceAccount;

            case PlatformsTypesEnum.Network:
                serviceAccount.username = "";
                serviceAccount.password = "";
                return serviceAccount;
        }
        */
        return serviceAccount;
    }

    getRestoreSource(type, backup) {
        var source = {
            id: null,
            id_service_account: -2,
            type: type,
            type_name: platformsTypes[type].name,
            enableCompression: false,
            options: {
                archiveType: 0,
                archiveFileCustomName: "",
                useArchivePassword: false,
                archivePassword: ""
            },
        };

        switch (type) {
            case PlatformsTypesEnum.DriveImage: // DriveImage
                source.driveImgSource = null;
                source.options.disableDasdIo = true;
                break;

            case PlatformsTypesEnum.HyperV: //HyperV
                source.folderSource = {
                    paths: [], //Lista di oggetti di tipo {path : '', type : int -> FileFolderEntityType}
                };

                source.hvSource = null;
                source.options.rct = 1; // [Andrea-Alberto 06/08/2024 - Backup di HyperV è sempre RCT]
                source.options.backupType = "full";
                source.options.nCopies = 1;
                source.options.autoAddNewItems = false;
                source.options.disableDasdIo = null;

                break;

            case PlatformsTypesEnum.ESXi: //ESXi
                source.folderSource = {
                    paths: [], //Lista di oggetti di tipo {path : '', type : int -> FileFolderEntityType}
                };
                source.esxiSource = null;

                source.options = {

                    //GENERALE
                    copySecurityPermissions: false,
                    recreateCompletePath: true,

                    //FILTRI
                    specifiedExtensions: "",
                    extensionsFilterAction: 0,

                    useFileSizeFilter: false,
                    fileSizeFilterAction: 0,
                    fileSizeFilterCompare: 0,
                    fileSize: 0, // in MB

                    //ESCLUSIONI
                    excludedPaths: [],
                    excludeSubFolders: false,

                    //COMPRESSIONE
                    useArchiveCompression: false,

                    useArchiveFileCustomName: false,
                    archiveFileCustomName: "",

                    useArchivePassword: false,
                    archivePassword: "",

                    protectionType: 0,
                    pathsInArchive: 1, // percorso completo nello zip di default

                    //BACKUP
                    backupType: "F",
                    nCopies: 1,
                    syncronize: false, // non utilizzato
                    backupMode: "STD",
                    cbtPort: 0,
                    nOfCopies: 1,
                    autoAddNewItems: false,

                };

                break;

            case 5:
            case PlatformsTypesEnum.Microsoft_ExchangeOnPremises:
            case PlatformsTypesEnum.Microsoft_ExchangeOnPremisesEWS:
            case PlatformsTypesEnum.Microsoft_Exchange365:
                //return { "id": "19593ff0-5e38-446e-808c-6aa127f31940", "jobID": "", "version": "", "type": 52, "type_name": "Exchange OnLINE", "isExchangeOnline": true, "isMultipleRestore": false, "overWrite": false, "isPST": true, "source_idSA": -2, "source_path": "G:\\Exchange_Test_Veronica\\PST Destination\\v.bachetti@iperius.onmicrosoft.com.pst", "source_type": 8, "source_type_name": "FileFolder", "destination_idSA": "ab900a23-1ee5-45e2-93d7-2d4fd6a281c7", "destination_path": "kkkkkkkkkkkkkkkkkkkkkkkkkkkkk" };
                //!!!TOLTO DESTINATIONS E MAILBOXES!!!!
                return {
                    id: "",
                    idBackup: isValid(backup) ? backup.id : '',
                    backupName: isValid(backup) ? backup.name : '',
                    version: '',
                    type: type,
                    type_name: platformsTypes[type].name,

                    restoreType: -1,
                    index: -1,

                    source_idSA: isValid(backup) ? backup.destinations[0].id_service_account : -2,
                    source_path: "",
                    source_type: isValid(backup) ? backup.destinations[0].type : 8,
                    source_type_name: isValid(backup) ? backup.destinations[0].type_name : platformsTypes[8].name,

                    destination_idSA: isValid(backup) ? backup.sources[0].id_service_account : -2,
                    destination_accounts: "",
                    destination_customFolder: "Restore " + new Date().getFullYear() + "-" + new Date().getMonth() + "-" + new Date().getDate()

                }; /**/
                /*source.folderSource = {
                    paths: [], //Lista di oggetti di tipo {path : '', type : int -> FileFolderEntityType}
                };

                source.exchangeSource = null;
                source.options.outputFormat = "PST";
                //source.options.includeArchiveMailbox = false;
                //source.options.includePublicFolders = false;
                source.options.truncateExchangeLogs = false;
                source.options.badItemsLimit = 50;
                break;*/

            case 6: // Database
            case PlatformsTypesEnum.Database_SQLServer:
                //case 61:
                source.dbSource = null;
                source.options.verifyBackup = false;
                source.options.useNativeComp = false;
                source.options.useCopyOnly = false;
                source.options.enableAppend = false;
                source.options.appendTextBkp = "";
                source.options.singleComp = false;
                source.options.backupLog = false;
                source.options.shrinkLog = false;
                source.options.alterDbRecModel = false;
                break;
        }

        return source;
    }

    getSource(type) {
        var source = {
            id: null,
            id_service_account: -2,
            type: type,
            type_name: platformsTypes[type].name,
            enableCompression: false,
            options: {
                archiveType: 0,
                archiveFileCustomName: "",
                useArchivePassword: false,
                archivePassword: ""
            },
        };

        switch (type) {
            case PlatformsTypesEnum.DriveImage: // DriveImage
                source.driveImgSource = null;
                source.options.disableDasdIo = true;
                source.options.createPCNameFolder = true;
                source.options.backupType = "F";
                source.options.nCopies = 1;
                source.options.isCBT = true;
                source.options.resetCBT = true;
                break;

            case PlatformsTypesEnum.HyperV: //HyperV
                source.hvSource = null;
                source.options.rct = 1;
                source.options.backupType = "full";
                source.options.nCopies = 1;
                source.options.autoAddNewItems = false;
                source.options.disableDasdIo = null;

                break;

            case PlatformsTypesEnum.ESXi: //ESXi
                source.esxiSource = null;
                source.options.backupMode = "STD";
                source.options.backupType = "F";
                source.options.cbtPort = 0;
                source.options.nOfCopies = 1;
                source.options.autoAddNewItems = false;
                break;

            case 5:
            case PlatformsTypesEnum.Microsoft_ExchangeOnPremises:
            case PlatformsTypesEnum.Microsoft_ExchangeOnPremisesEWS:
            case PlatformsTypesEnum.Microsoft_Exchange365:
                source.exchangeSource = null;
                source.options.outputFormat = "PST";
                //source.options.useParallelMode = true;
                source.options.backupType = "F"
                source.options.nCopies = 1
                source.options.nThreads = 6;
                source.options.nDownloadThreads = 6;
                source.options.buffer = 150;
                source.options.includeArchive = false;
                source.options.includePFolders = false;
                source.options.includeCalendar = false;
                source.options.includeContacts = false;
                source.options.includeDrafts = false;
                source.options.includeDeletedItems = false;
                source.options.includeInbox = true;
                source.options.includeJunkeMails = false;
                source.options.includeOutbox = false;
                source.options.includeSentItems = false;
                source.options.includeTasks = false;
                source.options.truncateExchangeLogs = false;
                source.options.badItemsLimit = 50;
                break;


            case 6: // Database
            case PlatformsTypesEnum.Database_MySQL:
            case PlatformsTypesEnum.Database_SQLServer:
                source.dbSource = null;
                source.options.verifyBackup = false;
                source.options.useNativeComp = false;
                source.options.useCopyOnly = false;
                source.options.enableAppend = false;
                source.options.appendTextBkp = "";
                source.options.singleComp = false;
                source.options.backupLog = false;
                source.options.shrinkLog = false;
                source.options.alterDbRecModel = false;
                break;

                //Questi tipi di sorgenti sono relativamente semplici,
                // quindi per praticità utilizzo lo stesso modello
            case PlatformsTypesEnum.FileFolder: // File/Folder
            case PlatformsTypesEnum.Microsoft_SharePoint: //sharepoint
            case PlatformsTypesEnum.Microsoft_Teams: // teams
            case PlatformsTypesEnum.Microsoft_OneDriveForBusiness: //onedrive
                source.folderSource = {
                    paths: [], //Lista di oggetti di tipo {path : '', type : int -> FileFolderEntityType}
                };
                source.options = {

                    //GENERALE
                    copySecurityPermissions: false,
                    recreateCompletePath: true,
                    nThreads: 10,

                    //FILTRI
                    specifiedExtensions: "",
                    extensionsFilterAction: 0,

                    useFileSizeFilter: false,
                    fileSizeFilterAction: 0,
                    fileSizeFilterCompare: 0,
                    fileSize: 0, // in MB

                    //ESCLUSIONI
                    excludedPaths: type == 8 ? [] : "",
                    excludeSubFolders: false,

                    //COMPRESSIONE
                    useArchiveCompression: false,

                    useArchiveFileCustomName: false,
                    archiveFileCustomName: "",

                    useArchivePassword: false,
                    archivePassword: "",

                    protectionType: 0,
                    pathsInArchive: 1, // percorso completo nello zip di default

                    //BACKUP
                    backupType: "F",
                    nCopies: 1,
                    syncronize: false, // non utilizzato
                    //RESTORE
                    restoreCustomFolder: ""
                };
                break;

            case PlatformsTypesEnum.Network: // Network
                source.networkSource = {
                    paths: [], //Lista di oggetti di tipo {path : '', type : int -> FileFolderEntityType}
                };
                source.options = {
                    //GENERALE
                    copySecurityPermissions: false,
                    recreateCompletePath: true,
                    nThreads: 10,

                    //FILTRI
                    specifiedExtensions: "",
                    extensionsFilterAction: 0,

                    useFileSizeFilter: false,
                    fileSizeFilterAction: 0,
                    fileSizeFilterCompare: 0,
                    fileSize: 0, // in MB

                    //ESCLUSIONI
                    excludedPaths: type == 8 ? [] : "",
                    excludeSubFolders: false,

                    //COMPRESSIONE
                    useArchiveCompression: false,

                    useArchiveFileCustomName: false,
                    archiveFileCustomName: "",

                    useArchivePassword: false,
                    archivePassword: "",

                    protectionType: 0,
                    pathsInArchive: 1, // percorso completo nello zip di default

                    //BACKUP
                    backupType: "F",
                    nCopies: 1,
                    syncronize: false, // non utilizzato
                    //RESTORE
                    restoreCustomFolder: ""

                };
                break;
        }

        return source;
    }

    getDestination(type, backupSource) {
        var destination = {
            id: null,
            name: '',
            type: type,
            type_name: platformsTypes[type].name,
            id_service_account: isValid(backupSource) ? backupSource.id_service_account : -2,
            id_repository: -2,
            path: "",
            options: {},
        };

        return destination;
    }

    //Corrisponde a ...\iperius-enterprise-agent\IperiusEWP\BackupSources\FileFolder\Model\RestoreFileModel.cs
    getRestoreFile(type, backup) {
        var restoreFile = {
            id: "", // id del job di restore
            idBackup: isValid(backup) ? backup.id : "", // id del job di backup
            backupName: isValid(backup) ? backup.name : "",
            type: type,
            type_name: platformsTypes[type].name,
            /**
             * 0 = Scarica dei singoli elementi
             * 1 = ricostruisci copia full
             * 2 = ricostruisci una copia specifica
             */
            restoreType: -1,
            createFolderWithBackupName: false,
            index: -1,
            source: this.getRestoreFileSource(isValid(backup) ? backup.destinations[0] : null),
            destination: isValid(backup) ?
                this.getDestination(type, backup.sources[0]) : this.getDestination(type, null)
        };

        return restoreFile;
    }

    getRestoreFileSource(backupDestination) {
        var restoreFileSource = {
            id_service_account: isValid(backupDestination) ? backupDestination.id_service_account : -2,
            type: isValid(backupDestination) ? backupDestination.type : -1,
            type_name: isValid(backupDestination) ? backupDestination.type_name : "",

            paths: [],
            options: {
                //GENERALE

                //FILTRI
                specifiedExtensions: "",
                extensionsFilterAction: 0,

                useFileSizeFilter: false,
                fileSizeFilterAction: 0,
                fileSizeFilterCompare: 0,
                fileSize: 0, // in MB

                //ESCLUSIONI
                excludedPaths: [],
                excludeSubFolders: false,

                //COMPRESSIONE
                useArchiveCompression: false,

                useArchiveFileCustomName: false,
                archiveFileCustomName: "",

                useArchivePassword: false,
                archivePassword: "",

                protectionType: 0,
                pathsInArchive: 1, // percorso completo nello zip di default

                //RESTORE
                restoreInOriginalPosition: true
            }
        };
        return restoreFileSource;

    }

    getCompleteBackup(apiBackup, socketBackup) {
        var backup = {
            //ID
            "id": apiBackup.id,
            "id_computer": apiBackup.id_computer,
            "id_group": apiBackup.id_group,
            "id_master_account": apiBackup.id_master_account,
            //NAME
            "name": apiBackup.name,
            "name_computer": apiBackup.name_computer,
            "name_group": apiBackup.name_group,
            //DATE
            "dt_start_utc": getFirstValueOrDefault(apiBackup.dt_start_utc, socketBackup.dt_start_utc, ""),
            "dt_end_utc": getFirstValueOrDefault(apiBackup.dt_end_utc, socketBackup.dt_end_utc, ""),
            "inserted_at": getValueOrDefault(apiBackup.inserted_at, ""),
            "updated_at": isValid(apiBackup.updated_at) ? apiBackup.updated_at : "",

            //ESECUZIONE JOB
            "isRunning": isValid(socketBackup.isRunning) ? socketBackup.isRunning : "",
            "current_operation": isValid(socketBackup.current_operation) ? socketBackup.current_operation : "",
            "progress": isValid(socketBackup.progress) ? socketBackup.progress : "",
            "processed_data": isValid(socketBackup.processed_data) ? socketBackup.processed_data : "",

            "last_backup_copiedfiles": isValid(apiBackup.last_backup_copiedfiles) ? apiBackup.last_backup_copiedfiles : "",
            "last_backup_size": isValid(apiBackup.last_backup_size) ? apiBackup.last_backup_size : "",
            "last_result": getFirstValueOrDefault(apiBackup.last_result, socketBackup.lastresult, ""),

            //MAIN INFOS
            "type": getFirstValueOrDefault(apiBackup.type, socketBackup.type, ""),
            "type_name": isValid(apiBackup.type) ? platformsTypes[apiBackup.type].name : isValid(socketBackup.type) ? platformsTypes[socketBackup.type].name : "",
            "scheduling": getFirstValueOrDefault(apiBackup.scheduling, socketBackup.scheduling),

            //SOURCES/DESTINATIONS
            "sources": apiBackup.sources,
            "destinations": apiBackup.destinations,

            //LOGS : Verranno richiesti solo se necessario
            "logs": null,
        };

        return backup;
    }


    getPolicy() {
        var emptypolicy = {
            "refreshID": 1,
            "enabled_email": false,
            "enabled_push": false,
            "id": null,
            "id_type": "",
            "name": "",
            "name_type": "",
            "computers": [],
            "jobs": [],
            "policy": {
                "misfire_alert": false,
                "scheduling": [""],
                "included_categories": [],
                "update_time": null,
            },
        };
        return emptypolicy;
    }

    getGroup() {
        return {
            "address": "",
            /*
            1 = gruppo privato creato successivamente
            2 = gruppo privato di default
            3 = gruppo shared di default
            4 = gruppo shared creato successivamente
            */
            "computers": [],
            "email": "",
            "id": null,
            "id_master_account": "",
            "name": "",
            "notes": "",
            "phone_number": "",
            "users": [],
        };
    }

    getUser() {
        var emptyuser = {
            "id": null,
            "username": "",
            "firstname": "",
            "lastname": "",
            "name": "",
            "address": "",
            "level": "2",
            "computers": [],
            "groups": [],
            "status": "1",
            "mfa_enabled": "false",
            "role": "User",
            "id_master_account": "",
        };

        return emptyuser;
    }

    /**
     * apiJobs è un array di oggetti del tipo:
     *     {
        
    },
     */
    fromAPIJobsToSocketJobs(apiJobs) {
        return apiJobs.map(job => {
            return {
                current_operation: "",
                dt_end_utc: job.dt_end_utc, // 2023-08-04T13:45:24Z -> 20230804172436
                dt_start_utc: job.dt_start_utc, // 2023-08-04T13:45:24Z
                id: job.id,
                isRunning: false,
                lastresult: job.last_result,
                name: job.name,
                processed_data: 0,
                progress: 0,
                scheduling: job.scheduling,
                type: parseInt(job.type),
                type_name: platformsTypes[job.type]
            };
        });
    }

}