var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row p-1 pl-6 pr-6" }, [
    _c("div", { staticClass: "cell d-flex flex-align-center p-0" }, [
      _c("h3", { staticClass: "dashboard-section-title text-left-md m-0" }, [
        _c("ul", { staticClass: "breadcrumbs" }, [
          _vm._m(0),
          _vm.pageName != "dashboard"
            ? _c(
                "button",
                {
                  staticClass: "page-link button",
                  on: {
                    click: function ($event) {
                      return _vm.$root.goBack()
                    },
                  },
                },
                [_c("i", { staticClass: "mif-arrow-left" })]
              )
            : _vm._e(),
          _c(
            "li",
            { staticClass: "page-item" },
            [
              _c(
                "router-link",
                { staticClass: "page-link pl-0", attrs: { to: "/dashboard" } },
                [
                  _c("table", [
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticClass: "px-1 py-0",
                          class: {
                            h2: _vm.pageName == "dashboard",
                            "p-4": _vm.pageName != "dashboard",
                          },
                        },
                        [_c("label", [_vm._v(_vm._s(_vm.$t("Dashboard")))])]
                      ),
                    ]),
                    _vm.pageName == "dashboard"
                      ? _c("tr", [
                          _c("td", { staticClass: "px-1 py-0" }, [
                            _vm._v(
                              _vm._s(_vm.$t("License")) +
                                " : " +
                                _vm._s(
                                  _vm.$t(_vm.$session.getLicense().assignee)
                                )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm.accounts
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page-link", attrs: { to: "/accounts" } },
                    [
                      _vm.pageName == "accounts"
                        ? _c("span", { staticClass: "mr-1" })
                        : _vm._e(),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("Credentials Locker"))),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.repositories
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page-link", attrs: { to: "/repo" } },
                    [
                      _vm.pageName == "repositories"
                        ? _c("span", { staticClass: "mr-1" })
                        : _vm._e(),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("Backup Repositories"))),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.computer && _vm.$session.getRoomPC() != null && _vm.pc
            ? _c("li", { staticClass: "page-item" }, [
                _c(
                  "select",
                  {
                    staticClass: "select input w-auto ml-3 mr-3",
                    on: { change: _vm.navigateToComputer },
                  },
                  _vm._l(_vm.$session.getPCList(), function (pc2) {
                    return _c(
                      "option",
                      {
                        key: pc2.id,
                        staticClass: "no-wrap",
                        attrs: {
                          disabled: !_vm.$session.isConnectedToSocket(pc2.id),
                        },
                        domProps: {
                          selected: pc2.id == _vm.pc.id,
                          value: pc2.id,
                        },
                      },
                      [_vm._v(" " + _vm._s(pc2.name) + " ")]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _vm.backup
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page-link", attrs: { to: "/backup/" } },
                    [
                      _vm.pageName == "backup"
                        ? _c("span", { staticClass: "mr-1" })
                        : _vm._e(),
                      _vm._v(
                        _vm._s(
                          _vm.data.editing
                            ? _vm.data.backupName
                            : _vm.$t("Create Backup")
                        )
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.restore
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page-link", attrs: { to: "/restore" } },
                    [
                      _vm.pageName == "restore"
                        ? _c("span", { staticClass: "mr-1 mif-arrow-up" })
                        : _vm._e(),
                      _vm._v(_vm._s(_vm.$t("Restore Backup"))),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.backupView
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "page-link no-wrap",
                      attrs: { to: "/backupview/" + _vm.data.job.id },
                    },
                    [
                      _vm.pageName == "backupview"
                        ? _c("span", { staticClass: "mr-1 mif-news" })
                        : _vm._e(),
                      _vm._v(_vm._s(_vm.data.job.name)),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.groups
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page-link", attrs: { to: "/groups" } },
                    [
                      _vm.pageName == "groups"
                        ? _c("span", { staticClass: "mr-1" })
                        : _vm._e(),
                      _vm._v(_vm._s(_vm.$t("Groups"))),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.policies
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page-link", attrs: { to: "/policies" } },
                    [
                      _vm.pageName == "policies"
                        ? _c("span", { staticClass: "mr-1" })
                        : _vm._e(),
                      _vm._v(_vm._s(_vm.$t("Policies"))),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.group
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "page-link no-wrap",
                      attrs: { to: "/group/" + _vm.data.group.id },
                    },
                    [
                      _vm.pageName == "group"
                        ? _c("span", { staticClass: "mr-1 mif-folder-open" })
                        : _vm._e(),
                      _vm._v(_vm._s(_vm.data.group.name)),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.users
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page-link", attrs: { to: "/users" } },
                    [
                      _vm.pageName == "users" ? _c("span") : _vm._e(),
                      _vm._v(_vm._s(_vm.$t("Users"))),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.pageName == "settings"
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page-link", attrs: { to: "/settings" } },
                    [
                      _c("span", { staticClass: "mr-1" }),
                      _vm._v(_vm._s(_vm.$t("Settings"))),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.pageName == "licenses"
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page-link", attrs: { to: "/licenses" } },
                    [
                      _c("span", { staticClass: "mr-1" }),
                      _vm._v(_vm._s(_vm.$t("Licenses"))),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.pageName == "schedulation"
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page-link", attrs: { to: "/scheduling" } },
                    [
                      _c("span", { staticClass: "mr-1" }),
                      _vm._v(_vm._s(_vm.$t("Backup Scheduling"))),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.pageName == "remotestats"
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page-link", attrs: { to: "/remotestats" } },
                    [
                      _c("span", { staticClass: "mr-1" }),
                      _vm._v(_vm._s(_vm.$t("Remote statistics"))),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.pageName == "backupstats"
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page-link", attrs: { to: "/backupstats" } },
                    [
                      _c("span", { staticClass: "mr-1" }),
                      _vm._v(_vm._s(_vm.$t("Backup statistics"))),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.pageName == "sessions"
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page-link", attrs: { to: "/sessions" } },
                    [
                      _c("span", { staticClass: "mr-1" }),
                      _vm._v(_vm._s(_vm.$t("Sessions"))),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.pageName == "activesessions"
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page-link", attrs: { to: "/sessions" } },
                    [
                      _c("span", { staticClass: "mr-1" }),
                      _vm._v(_vm._s(_vm.$t("Active Sessions"))),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ]),
    _vm.pageName == "computers"
      ? _c(
          "div",
          { staticClass: "cell d-flex flex-align-center flex-justify-end" },
          [
            _c("ul", { staticClass: "listview" }, [
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "button primary fg-white mr-2",
                      attrs: { to: "/repo" },
                    },
                    [
                      _c("span", { staticClass: "mr-1 icon p-0 m-0 pr-1" }, [
                        _c(
                          "svg",
                          {
                            staticStyle: { width: "26", height: "26" },
                            attrs: {
                              width: "24",
                              height: "24",
                              fill: "none",
                              viewBox: "0 0 24 24",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M10 2a4 4 0 0 1 4 4v2h2.5A1.5 1.5 0 0 1 18 9.5V11c-.319 0-.637.11-.896.329l-.107.1c-.812.845-1.656 1.238-2.597 1.238-.783 0-1.4.643-1.4 1.416v2.501c0 2.374.924 4.22 2.68 5.418L3.5 22A1.5 1.5 0 0 1 2 20.5v-11A1.5 1.5 0 0 1 3.5 8H6V6a4 4 0 0 1 4-4Zm8.284 10.122c.992 1.036 2.091 1.545 3.316 1.545.193 0 .355.143.392.332l.008.084v2.501c0 2.682-1.313 4.506-3.873 5.395a.385.385 0 0 1-.253 0c-2.476-.86-3.785-2.592-3.87-5.13L14 16.585v-2.5c0-.23.18-.417.4-.417 1.223 0 2.323-.51 3.318-1.545a.389.389 0 0 1 .566 0ZM10 13.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM10 4a2 2 0 0 0-2 2v2h4V6a2 2 0 0 0-2-2Z",
                                fill: "#212121",
                              },
                            }),
                          ]
                        ),
                      ]),
                      _c("span", { staticClass: "text-small fg-white" }, [
                        _vm._v(_vm._s(_vm.$t("Backup Repositories"))),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "button primary fg-white",
                      attrs: { to: "/accounts" },
                    },
                    [
                      _c("span", { staticClass: "mr-1 icon p-0 m-0 pr-1" }),
                      _c("span", { staticClass: "text-small fg-white" }, [
                        _vm._v(_vm._s(_vm.$t("Credentials Locker"))),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c("li", { staticClass: "ml-2" }, [
                _c("input", {
                  attrs: {
                    type: "text",
                    "data-role": "input",
                    "data-search-button": "true",
                    placeholder: "Search...",
                  },
                  on: { change: _vm.emitFilter, input: _vm.emitFilter },
                }),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm.pageName == "groups"
      ? _c(
          "div",
          {
            staticClass: "cell d-flex flex-align-center flex-justify-end pr-1",
          },
          [
            _c("ul", { staticClass: "listview" }, [
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "button primary mr-1",
                    on: {
                      click: function ($event) {
                        return _vm.$root.$emit("OPENGROUPVIEWDIALOG")
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "icon mif-2x mif-add" }),
                    _c("span", [_vm._v(_vm._s(_vm.$t("Add Group")))]),
                  ]
                ),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm.pageName == "policies"
      ? _c(
          "div",
          {
            staticClass: "cell d-flex flex-align-center flex-justify-end pr-1",
          },
          [
            _c("ul", { staticClass: "listview" }, [
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "button primary mr-1",
                    on: {
                      click: function ($event) {
                        return _vm.$root.$emit("OPENPOLICYVIEWDIALOG")
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "icon mif-2x mif-add" }),
                    _c("span", {}, [_vm._v(_vm._s(_vm.$t("Add Policy")))]),
                  ]
                ),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm.pageName == "dashboard"
      ? _c(
          "div",
          { staticClass: "cell d-flex flex-align-center flex-justify-end" },
          [
            _c("ul", { staticClass: "listview" }, [
              _c("li", { staticClass: "ml-2 mr-2" }, [
                _c(
                  "a",
                  {
                    staticClass: "button primary",
                    attrs: {
                      onclick: "window.Metro.charms.toggle('#agentbuilding');",
                    },
                  },
                  [
                    _c("span", { staticClass: "icon mif-download" }),
                    _c("span", {}, [_vm._v(_vm._s(_vm.$t("Agent download")))]),
                  ]
                ),
              ]),
              _c("li", [
                _c(
                  "button",
                  { staticClass: "button", attrs: { id: "fullstats" } },
                  [_vm._v(_vm._s(_vm.$t("Toggle statistics")))]
                ),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm.pageName == "licenses"
      ? _c(
          "div",
          { staticClass: "cell d-flex flex-align-center flex-justify-end" },
          [
            _c("ul", { staticClass: "listview" }, [
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "button primary ml-4",
                    attrs: {
                      onclick: "window.Metro.charms.toggle('#plugin');",
                    },
                  },
                  [
                    _c("span", {}),
                    _vm._v(_vm._s(_vm.$t("Upgrade your License")) + " "),
                  ]
                ),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm.pageName == "users"
      ? _c(
          "div",
          { staticClass: "cell d-flex flex-align-center flex-justify-end" },
          [
            _c("ul", { staticClass: "listview" }, [
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "button primary mr-2",
                    on: {
                      click: function ($event) {
                        return _vm.$root.$emit("OPENUSERVIEWDIALOG")
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "icon mif-2x mif-add" }),
                    _c("span", [_vm._v(_vm._s(_vm.$t("Add User")))]),
                  ]
                ),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm.pageName == "computer"
      ? _c(
          "div",
          {
            staticClass: "cell d-flex flex-align-center flex-justify-end pr-4",
          },
          [
            _c("div", {}, [
              _c(
                "ul",
                { staticClass: "listview" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "mr-2 text-bold",
                      attrs: {
                        to: this.$route.path + "/backup",
                        disabled: !_vm.$session.isConnectedToSocket(_vm.pc.id),
                      },
                    },
                    [
                      _c("button", { staticClass: "button primary" }, [
                        _vm._v(_vm._s(_vm.$t("Add Backup"))),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "dropdown-button place-right mr-2" },
                    [
                      _c(
                        "a",
                        { staticClass: "button dropdown-toggle primary pl-5" },
                        [
                          _c("div", { staticClass: "text-left" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("Restore Backup")) + " "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "ul",
                        {
                          staticClass: "d-menu border place-right",
                          attrs: { "data-role": "dropdown" },
                        },
                        [
                          _vm._l(_vm.restoreTypes, function (t) {
                            return _vm.platformsTypes != null
                              ? [
                                  _vm._l(
                                    _vm.$root.getMicrosoftTypes(),
                                    function (microsoft_t) {
                                      return t == 5
                                        ? _c("li", { key: microsoft_t }, [
                                            _c(
                                              "a",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$root.$emit(
                                                      "OPENRESTOREDIALOG",
                                                      microsoft_t,
                                                      null
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v("Microsoft "),
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.platformsTypes[
                                                        microsoft_t
                                                      ].name
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ])
                                        : _vm._e()
                                    }
                                  ),
                                  t != 5
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.$root.$emit(
                                                  "OPENRESTOREDIALOG",
                                                  t,
                                                  null
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.platformsTypes[t].name
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              : _vm._e()
                          }),
                        ],
                        2
                      ),
                    ]
                  ),
                  _c("PCRemoteLink", {
                    staticClass: "mr-2",
                    attrs: {
                      pc: _vm.pc,
                      disabled: !_vm.$session.isConnectedToSocket(_vm.pc.id),
                      complete: "true",
                    },
                  }),
                  _vm._m(1),
                ],
                1
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      { staticClass: "ml-3 mr-3 button square", attrs: { id: "menu-toggle" } },
      [_c("span", { staticClass: "mif-menu" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "button",
        attrs: { onclick: "window.Metro.charms.toggle('#notifications-pc');" },
      },
      [_c("span", { staticClass: "mif-notifications mif-2x" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }