var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog light w-100-sm w-50 w-50-lg w-50-xl pos-center h-75",
      attrs: { id: "destinationBackup" },
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.close()
        },
      },
    },
    [
      _c("DialogTitle", {
        attrs: {
          title: _vm.destination.name ?? "Create Backup Destination",
          defaultTitle: _vm.$t("Add or Create Backup Destination"),
          subTitle: _vm.destination.type_name,
        },
        on: {
          closeDialog: function ($event) {
            return _vm.close()
          },
        },
      }),
      _c(
        "div",
        { staticClass: "dialog-content overflow" },
        [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { staticClass: "text-bold" }, [
              _vm._v(_vm._s(_vm.$t("Backup Destination name"))),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.destination.name,
                  expression: "destination.name",
                },
              ],
              staticClass: "input",
              attrs: { "data-role": "input" },
              domProps: { value: _vm.destination.name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.destination, "name", $event.target.value)
                },
              },
            }),
          ]),
          _vm.destination.type != _vm.$root.PlatformsTypesEnum.FileFolder
            ? _c("div", { staticClass: "form-group d-flex flex-column" }, [
                _c("label", { staticClass: "text-bold" }, [
                  _vm._v(_vm._s(_vm.$t("Choose credentials"))),
                ]),
                _c("input", {
                  attrs: {
                    type: "radio",
                    "data-caption": _vm.$t("Create or select a repository"),
                    "data-role": "radio",
                    name: "r1",
                    selected:
                      _vm.destination.id_repository != "" &&
                      _vm.destination.id_repository != "-2",
                  },
                  on: {
                    click: function ($event) {
                      _vm.credentialMode = "repo"
                    },
                  },
                }),
                _c("input", {
                  attrs: {
                    type: "radio",
                    "data-caption": _vm.$t(
                      "Create or select a service account"
                    ),
                    "data-role": "radio",
                    name: "r1",
                    selected:
                      _vm.destination.id_service_account != "" &&
                      _vm.destination.id_service_account != "-2" &&
                      (_vm.destination.id_repository == "" ||
                        _vm.destination.id_repository == "-2"),
                  },
                  on: {
                    click: function ($event) {
                      _vm.credentialMode = "sa"
                    },
                  },
                }),
              ])
            : _vm._e(),
          _vm.destination.type != _vm.$root.PlatformsTypesEnum.FileFolder &&
          _vm.credentialMode == "repo"
            ? _c("SARepoSelect", {
                attrs: {
                  type: _vm.destination.type,
                  idSA: null,
                  idRepo: _vm.destination.id_repository,
                },
                on: { setRepository: _vm.setRepository },
              })
            : _vm._e(),
          _vm.destination.type != _vm.$root.PlatformsTypesEnum.FileFolder &&
          _vm.credentialMode == "sa"
            ? _c("SARepoSelect", {
                attrs: {
                  type: _vm.destination.type,
                  idSA: _vm.destination.id_service_account,
                  idRepo: null,
                },
                on: { sendSATestResult: _vm.sendSATestResult },
              })
            : _vm._e(),
          _c("SelectedResourcesLayout", {
            attrs: {
              buttonText: _vm.$t("Choose folder destination"),
              singleItem: true,
              isDisabled:
                _vm.destination.type !=
                  _vm.$root.PlatformsTypesEnum.FileFolder && !_vm.testResult.ok,
              list: _vm.selectedNodes,
              heightPercentage: 0,
              canCancel: true,
            },
            on: { clickFunction: _vm.toggleFileSystemSidebar },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "dialog-actions" }, [
        _c("div", { staticClass: "ml-auto" }, [
          _c(
            "button",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  return _vm.close()
                },
              },
            },
            [
              _c("span", { staticClass: "mif-close" }),
              _vm._v(" " + _vm._s(_vm.$t("Cancel"))),
            ]
          ),
          _c(
            "button",
            {
              staticClass: "button primary",
              attrs: { disabled: !_vm.enableSave },
              on: { click: _vm.save },
            },
            [_vm._v(" " + _vm._s(_vm.$t("Save")))]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }